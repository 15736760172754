import React, { useEffect, useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import * as yup from "yup"

import Link from "~/components/Link/Link"
import {
  Body,
  Button,
  Fieldset,
  PasswordInput,
  TextInput,
} from "~/components/ui"
import useAutoRedirect from "~/hooks/useAutoRedirect"
import pathFromFullId from "~/routes/pathFromFullId"
import { LoginRequest, useLoginMutation } from "~/state/api/auth"
import { authSelector } from "~/state/modules/auth"
import getFormValidators from "~/utils/formValidation"

import css from "./LoginForm.module.scss"

type LoginFormProps = {
  onLogIn?: () => void
  handleRegisterLink?: () => void
  handleForgotPasswordLink?: () => void
}

export function LoginForm({
  onLogIn,
  handleRegisterLink,
  handleForgotPasswordLink,
}: LoginFormProps) {
  const intl = useIntl()
  const [login, { isLoading, isSuccess, isError }] = useLoginMutation()
  const { email, passwordLite } = getFormValidators(intl)
  const redirectUrl = pathFromFullId("locale/profile", intl)
  const { isLoggedIn } = useSelector(authSelector)

  useAutoRedirect(redirectUrl, !onLogIn && (isSuccess || isLoggedIn))

  useEffect(() => {
    if ((isSuccess || isLoggedIn) && onLogIn) {
      onLogIn()
    }
  }, [isSuccess, isLoggedIn, onLogIn])

  const validationSchema = useMemo(
    () => yup.object().shape({ email, password: passwordLite }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, ...form } = useForm<LoginRequest>({
    resolver: yupResolver(validationSchema),
  })

  const submit = form.handleSubmit((values: LoginRequest) => {
    login(values)
  })

  const { errors, isSubmitted } = form.formState

  return (
    <form className={css.root} onSubmit={submit} noValidate>
      {isError && isSubmitted && (
        <Body
          variant="body3"
          color="error"
          align="center"
          noMargin
          role="alert"
          className={css.errorMessage}
        >
          <FormattedMessage id="form/text:incorrect-login-credentials" />
        </Body>
      )}

      <Fieldset error={errors.email?.message}>
        <TextInput
          {...register("email")}
          type="email"
          placeholder={intl.formatMessage({ id: "form/text:label-email" })}
          errors={errors.email}
          className={css.input}
        />
      </Fieldset>
      <Fieldset error={errors.password?.message}>
        <PasswordInput
          {...register("password")}
          placeholder={intl.formatMessage({ id: "form/text:label-password" })}
          errors={errors.password}
          className={css.input}
        />
      </Fieldset>

      <Button
        className={css.button}
        type="submit"
        disabled={isLoading}
        color="original"
      >
        <FormattedMessage id="profile/text:to-login" />
      </Button>

      <Button
        variant="tertiary"
        underline
        onClick={handleForgotPasswordLink}
        color="original"
        className={css.forgotPassword}
      >
        {handleForgotPasswordLink ? (
          <FormattedMessage id="profile/text:i-forgot-my-password" />
        ) : (
          <Link to="profile/send-reset-password">
            <FormattedMessage id="profile/text:i-forgot-my-password" />
          </Link>
        )}
      </Button>

      <Body align="center" variant="body3">
        <FormattedMessage id="profile/text:no-account" />
        {` `}
        {handleRegisterLink ? (
          <span onClick={handleRegisterLink} className={css.clickableSpan}>
            <FormattedMessage id="profile/text:register" />
          </span>
        ) : (
          <Link to="profile/sign-up">
            <FormattedMessage id="profile/text:register" />
          </Link>
        )}
      </Body>
    </form>
  )
}
