import React, { CSSProperties, useEffect, useState } from "react"

import cn from "classnames"

import PauseSVG from "./playerIcons/pause.svg"
import PlaySVG from "./playerIcons/play.svg"
import PlayerProgressBar from "./PlayerProgressBar/PlayerProgressBar"
import usePlayerControls, { PlayerProps } from "./usePlayerControls"
import RecipeVideoPaywall from "./VideoPaywall/VideoPaywall"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Modal from "~/components/Modals/Modal"
import { Body } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import useIsOnScreen from "~/hooks/useIsOnScreen"
import { Recipe } from "~/models/Recipe"
import { formatTimestamp } from "~/utils/time"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./VideoPlayer.module.scss"

type VideoFormat = "landscape" | "square" | "vertical" | "other"
type ResponsiveVideoFormat = { mobile: VideoFormat; desktop: VideoFormat }

const isResponsiveFormat = (arg: any): arg is ResponsiveVideoFormat => {
  return !!arg.mobile && !!arg.desktop
}

let formatMobile = "square"
let formatDesktop = "desktop"

interface PropTypes extends Omit<PlayerProps, "paywallEnabled"> {
  src: string
  poster: string
  verticalColor: CSSProperties["color"]
  format: VideoFormat | ResponsiveVideoFormat
  ratio?: number // height/width, not the opposite
  videoFit?: "contain" | "cover"
  paywall?: {
    recipe: Recipe
    paywallEnabled: boolean
  }
  loop?: boolean
  openInFullscreen?: boolean
  modalMode?: "absolute" | "fixed"
  setIsFullscreen?: (isFullscreen: boolean) => void
  isFullscreenFromParent?: boolean
  pauseOutsideViewport?: boolean
  setMutedFromParent?: (muted: boolean) => void
  muted?: boolean
}

function VideoPlayer(props: PropTypes) {
  const {
    poster,
    src,
    verticalColor,
    format,
    ratio = 1,
    paywall,
    videoFit = "cover",
    loop,
    openInFullscreen,
    modalMode,
    setIsFullscreen,
    isFullscreenFromParent,
    pauseOutsideViewport,
    setMutedFromParent,
    muted,
    ...playerOptions
  } = props

  const [videoEl, state, methods] = usePlayerControls({
    src,
    paywallEnabled: !!paywall?.paywallEnabled,
    ...playerOptions,
  })

  const {
    isFullscreen,
    isLoaded,
    percentTime,
    isPlaying,
    displayInitialPlayIcon,
    isMuted,
    disableControls,
    showPaywall,
  } = state

  const {
    setFullscreen,
    setMuted,
    goBackward,
    goForward,
    play,
    pause,
    setTimeFromPercent,
  } = methods

  useEffect(() => {
    if (openInFullscreen) {
      setFullscreen(true)
      setTimeFromPercent(0)
    }
  }, [])

  useEffect(() => {
    if (muted === false || muted === true) {
      setMuted(muted)
    }
  }, [muted])

  const isOnScreen = useIsOnScreen(videoEl)

  useEffect(() => {
    if (pauseOutsideViewport) {
      !isOnScreen && pause()
      isOnScreen && !isPlaying && play()
    }
  }, [isOnScreen])

  const [isMouseMove, setIsMouseMove] = useState<any>()
  const [isMouseNotMoving, setIsMouseNotMoving] = useState<any>()

  const mouseMove = () => {
    clearTimeout(isMouseMove)
    setIsMouseNotMoving(false)
    setIsMouseMove(
      setTimeout(function () {
        setIsMouseNotMoving(true)
      }, 2000)
    )
  }

  const showPoster = !isLoaded || (percentTime > 99 && !isPlaying)

  const landscapeFormat = `${(9 / 16) * 100}%`
  const squareFormat = "100%"
  const verticalFormat = `${(16 / 9) * 100}%`
  const otherFormat = `${ratio * 100}%`

  const FORMATS: {
    [key in VideoFormat]: string
  } = {
    landscape: landscapeFormat,
    square: squareFormat,
    vertical: verticalFormat,
    other: otherFormat,
  }

  if (!isResponsiveFormat(format)) {
    formatMobile = FORMATS[format]
    formatDesktop = FORMATS[format]
  } else {
    formatMobile = FORMATS[format.mobile]
    formatDesktop = FORMATS[format.desktop]
  }

  const isCompact = useIsOnCompact()
  return (
    <div
      style={
        {
          "--current-theme-color": verticalColor,
          "--video-format-ratio": ratio * 100 + "%",
        } as CSSProperties
      }
    >
      <Modal
        unmountOnExit={false}
        open={isFullscreen}
        onClose={() => !openInFullscreen && setFullscreen(false)}
        mode={modalMode}
      >
        {(modalRef, isOpen) => (
          <div
            className={cn(css.playerWrapper, {
              [css.active]: isOpen && (!openInFullscreen || !isCompact),
              [css.blackStripes]: videoFit === "contain" && !showPaywall,
            })}
            ref={modalRef}
            style={{
              ...({
                "--video-format-mobile": formatMobile,
                "--video-format-desktop": formatDesktop,
              } as CSSProperties),
            }}
          >
            <img
              className={cn(css.poster, {
                [css.hidePoster]: !showPoster,
                [css.containPosition]: isFullscreenFromParent,
              })}
              src={poster}
              style={{ objectFit: videoFit }}
            />

            {!showPaywall && src && (
              <>
                <video
                  className={cn(css.videoPlayer, {
                    [css.hideVideo]: showPoster,
                  })}
                  ref={videoEl}
                  controls={false}
                  muted
                  playsInline
                  style={{ objectFit: videoFit }}
                  loop={loop}
                >
                  <source src={src} type="application/x-mpegURL" />
                </video>

                <div
                  className={cn(css.controlsOverlay, {
                    [css.showControls]: !disableControls && !isMouseNotMoving,
                    [css.showPlay]: displayInitialPlayIcon,
                  })}
                  onMouseMove={() => {
                    mouseMove()
                  }}
                >
                  <div
                    className={isMuted ? css.buttonMute : css.buttonUnMute}
                    onClick={() => {
                      setMutedFromParent && setMutedFromParent(!isMuted)
                      setMuted(!isMuted)
                    }}
                  />

                  <div
                    className={
                      isFullscreen || isFullscreenFromParent
                        ? css.buttonNotFullscreen
                        : css.buttonFullscreen
                    }
                    onClick={() => {
                      if (setIsFullscreen) {
                        setIsFullscreen(!isFullscreenFromParent)
                      } else {
                        setFullscreen(!isFullscreen)
                      }
                    }}
                  />

                  {!!videoEl.current?.duration && (
                    <div className={css.activeTime}>
                      <Body variant="body1" semiBold color="white">
                        {formatTimestamp(videoEl.current?.currentTime || 0) ||
                          "0:00"}
                        {" / "}
                        {formatTimestamp(videoEl.current.duration)}
                      </Body>
                    </div>
                  )}

                  <div className={css.wrapper}>
                    <div
                      className={css.goBackwardButton}
                      onClick={goBackward}
                    />

                    <div className={css.playPauseButton}>
                      <ColorableSVG
                        className={cn({
                          [css.showPlayPauseButton]: !isPlaying,
                        })}
                        href={PlaySVG}
                        mainColor={verticalColor as string}
                        title="Play"
                        onClick={play}
                      />
                      <ColorableSVG
                        className={cn({
                          [css.showPlayPauseButton]: isPlaying,
                        })}
                        href={PauseSVG}
                        mainColor={verticalColor as string}
                        title="Pause"
                        onClick={pause}
                      />
                    </div>

                    <div className={css.goForwardButton} onClick={goForward} />
                  </div>

                  <PlayerProgressBar
                    className={css.progressBar}
                    percentTime={percentTime}
                    verticalColor={verticalColor}
                    onPercentTimeChange={setTimeFromPercent}
                  />
                </div>
              </>
            )}

            {paywall && showPaywall && (
              <RecipeVideoPaywall
                show={showPaywall}
                vertical={paywall.recipe.vertical}
              />
            )}
          </div>
        )}
      </Modal>
    </div>
  )
}

export default VideoPlayer
