import dynamicPages from "../gatsby/dynamicPages"
import {
  CreatePageCustomArguments,
  CreatePageCustomScriptArguments,
} from "../types/global-types"
import { isDev } from "../utils/general"
import DynamicRoute, { DynamicRouteOptions } from "./DynamicRoute"
import { Translator } from "./Route"

export interface FallbackRouteOptions extends DynamicRouteOptions {
  matchPath: string
  statusCode?: number
}

const IS_AMPLIFY = process.env.IS_AMPLIFY ?? false
export const FALLBACK_URL_PART = "___fallback"

export default class FallbackRoute extends DynamicRoute {
  matchPath: string
  statusCode: number
  localPath: string

  constructor({ matchPath, statusCode, ...options }: FallbackRouteOptions) {
    super(options)

    this.matchPath = matchPath
    this.statusCode = statusCode ?? (IS_AMPLIFY ? 404 : 200) // Set 200 when aws amplify redirect problem is solved
    this.localPath = `___fallback-route-${super.getId()}`
  }

  async create(args: CreatePageCustomArguments): Promise<void> {
    const fullPath = this.getFullPath(args.translate, {})

    args.createRedirect({
      matchPath: this.matchPath,
      toPath: fullPath,
      absoluteToPath: true,
      redirectInBrowser: false,
      statusCode: this.statusCode,
    })

    const newArgs = {
      ...args,
      //By setting args.defaultContext.fallbackRouteParams, it will add a script to the component (see withTemplate HOC)
      defaultContext: {
        ...args.defaultContext,
        fallbackRouteParams: {
          path: fullPath,
          matchPath: `/${this.getParentPath(args.translate, {})}${this.matchPath
            .split("/")
            .filter(part => !!part)
            .join("/")}`,
        },
      },
    }

    if (isDev) {
      await this.createDevPage(args)
    } else {
      await super.create(newArgs)
    }
  }

  getParentPath(translate: Translator, params?: any): string {
    if (this.parent) {
      return this.parent.getFullPath(translate, params) ?? ""
    } else {
      return ""
    }
  }

  getFullPath(translate: Translator, params?: any): string {
    let parentPath = this.getParentPath(translate, params)
    if (parentPath && !isDev) {
      const parentParts = parentPath.split("/")
      parentParts.splice(1, 0, FALLBACK_URL_PART)
      parentPath = parentParts.join("/")
    } else if (!isDev) {
      parentPath = FALLBACK_URL_PART
    }

    return `/${parentPath}${this.localPath}/`
  }

  async createDevPage(args: CreatePageCustomArguments): Promise<void> {
    if (this.script) {
      const createPageScript = dynamicPages.get(this.script)
      if (createPageScript) {
        await createPageScript(this.prefillCreatePageArgs(args, this.component))
      } else {
        throw new Error(
          `Couldn't generate route ${this.getFullIdString()}: page creation script “${
            this.script
          }” is not exposed in src/gatsby/dynamicPages/`
        )
      }
    } else {
      args.createPage({
        component: this.component,
        context: { ...args.defaultContext },
        matchPath: this.matchPath,
      })
    }
  }

  prefillCreatePageArgs(
    args: CreatePageCustomArguments,
    component: string
  ): CreatePageCustomScriptArguments {
    return {
      ...args,
      createPage: scriptArgs =>
        args.createPage({
          ...scriptArgs,
          component,
          matchPath: this.matchPath,
        }),
    }
  }
}
