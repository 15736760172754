import React from "react"

import {
  componentResolverFromMap,
  PrismicPreviewProvider,
} from "gatsby-plugin-prismic-previews"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import createStore from "./src/state/store"
import Landing from "./src/templates/Landing/Landing"
import { linkResolver } from "./src/utils/PrismicLink"
import { Loader } from "~/components/ui"
const FullScreenLoader = () => (
  <div
    style={{
      width: `100%`,
      minHeight: `100vh`,
      display: `flex`,
      alignContent: `center`,
      justifyContent: `center`,
    }}
  >
    <Loader />
  </div>
)

// Instantiating store in `wrapRootElement` handler ensures:
//  - there is fresh store for each SSR page
//  - it will be called only once in browser, when React mounts
export function wrapWithProviderAndGate({ element }) {
  const { store, persistor } = createStore()

  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: "chefclub",
          linkResolver,
          componentResolver: componentResolverFromMap({
            landing: Landing,
          }),
        },
      ]}
    >
      <Provider store={store}>
        <PersistGate loading={<FullScreenLoader />} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    </PrismicPreviewProvider>
  )
}

export const wrapWithProvider = ({ element }) => {
  const { store } = createStore()

  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: "chefclub",
          linkResolver,
          componentResolver: componentResolverFromMap({
            landing: Landing,
          }),
        },
      ]}
    >
      <Provider store={store}>{element}</Provider>
    </PrismicPreviewProvider>
  )
}
