import React, { CSSProperties } from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import Tiles from "~/components/slices/Tiles/Tiles"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"

interface PropTypes extends PrismicTilesDefault {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function PrismicNavMenuTilesDefault(props: PropTypes) {
  const content = props.items.map(item => {
    return {
      illustration: item.image.url,
      text: item.title,
      link: item.link?.url,
    }
  })
  return (
    <SliceSection {...props}>
      <Tiles headerIsNotHx={true} content={content} {...props.primary} />
    </SliceSection>
  )
}

export default PrismicNavMenuTilesDefault

interface primary {
  title: PrismicStructuredText
  seeMoreLink: PrismicLink
  colorSeeMoreLink: CSSProperties["color"]
}

interface items {
  title: string
  image: {
    url: string
  }
  link: {
    url: string
  }
}

export interface PrismicTilesDefault extends PrismicNavSlice {
  sliceType: "tiles"
  variation: "default"
  primary: primary
  items: items[]
}

export const query = graphql`
  fragment PrismicTilesDefault on PrismicTilesDefault {
    id
    sliceType: slice_type
    variation
    items {
      title
      image {
        url
      }
      link {
        ...PrismicLink
      }
    }
    primary {
      title {
        ...PrismicStructuredText
      }
      seeMoreLink {
        ...PrismicLink
      }
      colorSeeMoreLink
    }
  }
`
