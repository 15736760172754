import React from "react"

import { graphql } from "gatsby"

import { LatestLanding } from "../Landing"
import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import LatestLandings, {
  LatestLandingProps,
} from "~/components/slices/LatestLandings/LatestLandings"

interface PropTypes extends PrismicLandingDataBodyLatestLandings {
  landings?: LatestLanding[]
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function LandingBodyLatestLandings({ landings, ...props }: PropTypes) {
  return (
    <SliceSection margin="large" anchorId={props.primary.anchorId} {...props}>
      <LatestLandings {...{ landings, ...props }} />
    </SliceSection>
  )
}

export default LandingBodyLatestLandings

export interface PrismicLandingDataBodyLatestLandings extends PrismicSlice {
  sliceType: "latest_landings"
  primary: LatestLandingProps["primary"]
}

export const query = graphql`
  fragment PrismicLandingDataBodyLatestLandings on PrismicLandingDataBodyLatestLandings {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      anchorId: id
      titleLatestLanding: title_latest_landings
      maxNumber: maxnumber
      tag: tag
      tagColor: tag_color
    }
  }
`
