import { CreatePageCustomScriptArguments } from "../../types/global-types"
import create404Pages from "./create404Pages"
import createCategoryPages from "./createCategoryPages"
import createCocreationLandingPage from "./createCocreationLandingPage"
import createCocreationPage from "./createCocreationPage"
import createCommunityHomeLandingPage from "./createCommunityHomeLandingPage"
import createCreatorPages from "./createCreatorPages"
import createHomeHubPages from "./createHomeHubPages"
import createHomePages from "./createHomePages"
import createHubPages from "./createHubPages"
import createIngredientSitemapPages from "./createIngredientSitemapPages"
import createKidsConverterPages from "./createKidsConverterPages"
import createKidsHomeLandingPages from "./createKidsHomeLandingPages"
import createLandingPagePreview from "./createLandingPagePreview"
import createLandingPages from "./createLandingPages"
import createLegalNoticesCreatorsPages from "./createLegalNoticesCreatorsPages"
import createLegalNoticesPages from "./createLegalNoticesPages"
import createOurStoryPages from "./createOurStoryPages"
import createRecipePages from "./createRecipePages"
import createShowPages from "./createShowPages"
import createShowsHomePage from "./createShowsHomePage"
import createVerticalPages from "./createVerticalPages"
import createVideoPages from "./createVideoPages"
import createWorkshopPages from "./createWorkshopPages"

type CreateDynamicPageScript = (
  args: CreatePageCustomScriptArguments
) => Promise<void>

export default new Map<string, CreateDynamicPageScript>([
  ["createHomePages", createHomePages],
  ["createIngredientSitemapPages", createIngredientSitemapPages],
  ["create404Pages", create404Pages],
  ["createCategoryPages", createCategoryPages],
  ["createVerticalPages", createVerticalPages],
  ["createLandingPages", createLandingPages],
  ["createLandingPagePreview", createLandingPagePreview],
  ["createLegalNoticesPages", createLegalNoticesPages],
  ["createLegalNoticesCreatorsPages", createLegalNoticesCreatorsPages],
  ["createOurStoryPages", createOurStoryPages],
  ["createRecipePages", createRecipePages],
  ["createKidsConverterPages", createKidsConverterPages],
  ["createKidsHomeLandingPages", createKidsHomeLandingPages],
  ["createCocreationLandingPage", createCocreationLandingPage],
  ["createWorkshopPages", createWorkshopPages],
  ["createCommunityHomeLandingPage", createCommunityHomeLandingPage],
  ["createCreatorPages", createCreatorPages],
  ["createCocreationPage", createCocreationPage],
  ["createHubPages", createHubPages],
  ["createHomeHubPages", createHomeHubPages],
  ["createShowsHomePage", createShowsHomePage],
  ["createShowPages", createShowPages],
  ["createVideoPages", createVideoPages],
])
