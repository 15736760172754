import React, { useRef, useState } from "react"

import { useIntl } from "react-intl"
import { useReactToPrint } from "react-to-print"

import Print from "./assets/print.svg"
import Reload from "./assets/reload.svg"
import { Units } from "./data/Units"
import IndicationsCard from "./IndicationsCard/IndicationsCard"
import KiddozConversionCard from "./KiddozConversionCard/KiddozConversionCard"
import KiddozConversionForm from "./KiddozConversionForm/KiddozConversionForm"
import { KiddozName, Kinds } from "./model"
import PrintConversions from "./PrintConversions/PrintConversions"
import { Body, Col, Heading, Row } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import useVisualViewport from "~/hooks/useVisualViewport"

import css from "./KidsConverterV2.module.scss"

interface KiddozConversion {
  kiddoz: KiddozName | undefined
}

export interface KiddozEquivalence {
  kiddoz: KiddozConversion[]
  ingredient: Kinds
  initialValue: {
    units: Units
    quantity: number
  }
}

const KidsConverterV2 = () => {
  const indicationRef = useRef<null | HTMLDivElement>(null)
  const viewport = useVisualViewport()

  const intl = useIntl()

  const [kiddoz, setKiddoz] = useState<KiddozEquivalence[]>()

  const componentRef = useRef()

  const isCompact = useIsOnCompact()

  const removeKiddoz = (index: number) => {
    setKiddoz((prevKiddoz: KiddozEquivalence[] | undefined) => {
      if (prevKiddoz) {
        const newKiddoz = [...prevKiddoz]
        newKiddoz.splice(index, 1)
        return newKiddoz
      }
      return prevKiddoz
    })
  }

  const modifyKiddoz = (index: number, kiddoz: KiddozEquivalence) => {
    setKiddoz((prevKiddoz: KiddozEquivalence[] | undefined) => {
      if (prevKiddoz) {
        const newKiddoz = [...prevKiddoz]
        newKiddoz[index] = kiddoz
        return newKiddoz
      }
      return [kiddoz]
    })
  }

  const addKiddoz = (kiddoz: KiddozEquivalence) => {
    setKiddoz((prevKiddoz: KiddozEquivalence[] | undefined) => {
      if (prevKiddoz) {
        return [...prevKiddoz, kiddoz]
      }
      return [kiddoz]
    })
  }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current ?? null,
  })

  const print = () => {
    if (componentRef.current) {
      handlePrint()
    }
  }

  const scrollToForm = () => {
    if (indicationRef?.current) {
      const y =
        indicationRef?.current?.getBoundingClientRect().bottom -
        viewport.visualViewport.height +
        40
      window.scrollBy({ top: y, behavior: "smooth" })
    }
  }

  return (
    <Row className={css.root} Tag="section">
      {kiddoz && (
        <Col width={[12, 5]} className={css.results}>
          <KiddozConversionCard
            kiddoz={kiddoz}
            removeKiddoz={removeKiddoz}
            modifyKiddoz={modifyKiddoz}
          />
          {!isCompact && <IndicationsCard />}
        </Col>
      )}
      {(isCompact || !kiddoz) && (
        <Col width={[12, 5]} className={css.indications}>
          <IndicationsCard />
        </Col>
      )}
      <Col width={[12, 7]} className={css.calculatorContainer}>
        <div className={css.headers}>
          <Heading color="white" variant="medium">
            {intl.formatMessage({ id: "kids-converter/recipe-converter" })}
          </Heading>
          <div className={css.actions}>
            <div className={css.action}>
              <img src={Print} />
              <Body
                className={css.text}
                color="white"
                variant="body3"
                semiBold
                onClick={() => print()}
              >
                {intl.formatMessage({ id: "kids-converter/action:print" })}
              </Body>
            </div>
            <div className={css.action} onClick={() => setKiddoz([])}>
              <img src={Reload} />
              <Body className={css.text} color="white" variant="body3" semiBold>
                {intl.formatMessage({ id: "kids-converter/action:reset" })}
              </Body>
            </div>
          </div>
        </div>
        <div ref={indicationRef}>
          <KiddozConversionForm
            action={(kiddoz: KiddozEquivalence) => {
              addKiddoz(kiddoz)
              isCompact && scrollToForm()
            }}
          />
        </div>
      </Col>
      <div style={{ display: "none" }}>
        <PrintConversions kiddoz={kiddoz} ref={componentRef} />
      </div>
    </Row>
  )
}

export default KidsConverterV2
