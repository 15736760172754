import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface PrismicAppSettingsHomeQuery {
  allShow: {
    nodes: [
      {
        id: string
        slug: string
      }
    ]
  }
}

export default async function createShowPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, reporter, createPage, currentLocale, defaultContext } = args

  //Use, if exists, appSettings key instead of lingohub key
  const result = await graphql<PrismicAppSettingsHomeQuery>(
    `
      query createShowPages($lang: String) {
        allShow(filter: { language: { eq: $lang } }) {
          nodes {
            id
            slug
          }
        }
      }
    `,
    {
      lang: args.currentLocale.id,
    }
  )

  if (result.data?.allShow.nodes.length) {
    reporter.info(`Found ${result.data.allShow.nodes.length} show pages`)

    for (const show of result.data.allShow.nodes) {
      try {
        createPage({
          routeParams: { slug: show.slug },
          context: {
            ...defaultContext,
            id: show.id,
          },
        })
      } catch (e) {
        console.log("ERROR")
      }
    }
  }
}
