type ReadFromSessionStorage = <T>(
  key: string,
  serialize?: boolean
) => T | undefined

export const readFromSessionStorage: ReadFromSessionStorage = (
  key,
  serialize = true
) => {
  try {
    if (typeof window === "undefined") {
      throw new Error(`Tried writing sessionStorage key “${key}” during SSR.`)
    }

    const data = window.sessionStorage.getItem(key)

    if (data === null || data === "undefined") {
      return undefined
    }

    return serialize ? JSON.parse(data) : data
  } catch (error) {
    console.error(error)
    return undefined
  }
}

type SaveInSessionStorage = <T>(
  key: string,
  value: T,
  serialize?: boolean
) => void

export const saveInSessionStorage: SaveInSessionStorage = (
  key,
  value,
  serialize = true
) => {
  try {
    if (typeof window === "undefined") {
      throw new Error(`Tried writing sessionStorage key “${key}” during SSR.`)
    }

    let serializedValue: string = JSON.stringify(value)

    if (!serialize && typeof value === "string") {
      serializedValue = value
    }

    // Save to session storage
    window.sessionStorage.setItem(key, serializedValue)

    // We dispatch a custom event so every useSessionStorage hook are notified
    window.dispatchEvent(new Event("session-storage"))
  } catch (error) {
    console.error(error)
  }
}

export const removeFromSessionStorage = (key: string): void => {
  try {
    if (typeof window === "undefined") {
      throw new Error(`Tried removing sessionStorage key “${key}” during SSR.`)
    }

    window.sessionStorage.removeItem(key)

    // We dispatch a custom event so every useSessionStorage hook are notified
    window.dispatchEvent(new Event("session-storage"))
  } catch (error) {
    console.error(error)
  }
}
