import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import cn from "classnames"
import debounce from "lodash/debounce"

import { ItemSize } from "../slices/FreeCarousel/FreeCarousel"
import CarouselRecipeCard, {
  ViewMore,
} from "~/components/Carousel/CarouselRecipeCard/CarouselRecipeCard"
import useEventListener from "~/hooks/useEventListener"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PartialRecipe } from "~/models/PartialRecipe"

import css from "./Carousel.module.scss"

interface PropTypes {
  children: ReactElement[]
  itemSize?: ItemSize
  width?: number | undefined
  itemsPerPage?: number
  dotsColor?: string
  scrollLoop?: boolean
  beginAtIndex?: number
  topArrowPosition?: number
  infiniteScroll?: boolean
}

// TODO: Support the width parameter better.
// • position the arrows correctly. right now, they need to be coerced into play through external CSS (see Reviews.module.scss, which does this)
// • implement responsive behavior when a width is specified (instead of always enforcing it)

function Carousel({
  children,
  itemSize,
  width,
  itemsPerPage = itemSize === "big" ? 3 : 5,
  dotsColor = "var(--black-color)",
  scrollLoop = false,
  beginAtIndex,
  topArrowPosition,
  infiniteScroll,
}: PropTypes) {
  const isCompact = useIsOnCompact()
  const [pageIndex, setPageIndex] = useState(0)
  const [copyChildren, setCopyChildren] = useState<ReactElement[]>([
    ...children,
  ])

  const carouselRef = useRef<HTMLDivElement | null>(null)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const itemCount = copyChildren.length
  const pageCount = Math.ceil(itemCount / itemsPerPage)

  const itemMarginCompact = 16
  const itemMarginRegular = 24
  const itemMargin = isCompact ? itemMarginCompact : itemMarginRegular

  const widthIsExplicit = width !== undefined
  const baseWidth = (widthIsExplicit ? width : 936) as number

  let itemWidthCompact, itemWidthRegular

  if (widthIsExplicit) {
    itemWidthCompact =
      (baseWidth - itemMarginCompact * (itemsPerPage - 1)) / itemsPerPage
    itemWidthRegular =
      (baseWidth - itemMarginRegular * (itemsPerPage - 1)) / itemsPerPage
  } else {
    // Same calculation, but base width differs between compact and regular
    itemWidthCompact =
      (784 - itemMarginCompact * (itemsPerPage - 1)) / itemsPerPage
    itemWidthRegular =
      (936 - itemMarginRegular * (itemsPerPage - 1)) / itemsPerPage
  }

  const itemWidth = isCompact ? itemWidthCompact : itemWidthRegular

  const pageWidth = (itemWidth + itemMargin) * itemsPerPage - itemMargin

  const prev = () => {
    scrollTo(
      pageWidth *
        (pageIndex > 0 ? pageIndex - 1 : scrollLoop ? pageCount - 1 : 0)
    )
  }

  const next = () => {
    const lastPage = pageCount - 1
    scrollTo(
      pageWidth *
        (pageIndex < lastPage ? pageIndex + 1 : scrollLoop ? 0 : lastPage)
    )
  }

  const goTo = (index: number) => {
    scrollTo(pageWidth * index)
  }

  useEffect(() => {
    //For infiniteScroll
    if (matchMedia("(max-width: 767px)").matches && infiniteScroll) {
      setCopyChildren([
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
        ...React.Children.map(children, child => React.cloneElement(child)),
      ])
      if (carouselRef.current) {
        carouselRef.current.scrollLeft +=
          (itemWidth + itemMargin) * children.length * 3
      }
    }

    //For beginAtIndex
    if (
      matchMedia("(max-width: 767px)").matches &&
      beginAtIndex &&
      !infiniteScroll
    ) {
      goTo(beginAtIndex)
    }
  }, [])

  const scrollTo = (left: number) => {
    if (carouselRef?.current) {
      carouselRef.current.scrollTo({
        left,
        behavior: "smooth",
      })
    }
  }

  //For infiniteScroll
  const infiniteScrollEffect = useCallback(
    debounce((itemIndex: number, itemWidth: number) => {
      if (carouselRef?.current) {
        carouselRef.current.style.overflowX = "hidden"
        if (itemIndex > 6 * children.length) {
          carouselRef.current.scrollLeft -= itemWidth * children.length * 3
        } else if (itemIndex < 2 * children.length) {
          carouselRef.current.scrollLeft += itemWidth * children.length * 3
        }
        setTimeout(() => {
          if (carouselRef?.current) {
            carouselRef.current.style.overflowX = "auto"
          }
        }, 10)
      }
    }, 100),
    [carouselRef]
  )

  useEventListener(
    "scroll",
    () => {
      const left = carouselRef.current?.scrollLeft || 0

      let itemIndex = Math.ceil(left / (itemWidth + itemMargin))
      const currentPage = Math.floor(itemIndex / itemsPerPage)

      const isLastPage = itemIndex + itemsPerPage >= itemCount
      const newPageIndex = isLastPage ? pageCount - 1 : currentPage

      setPageIndex(newPageIndex)
      if (infiniteScroll && isCompact && carouselRef?.current) {
        const itemWidth =
          carouselRef.current.children[0].clientWidth * 0.85 + 16
        itemIndex = Math.ceil(left / itemWidth)
        infiniteScrollEffect(itemIndex, itemWidth)
      }
    },
    carouselRef
  )

  return (
    <div
      ref={rootRef}
      className={css.root}
      style={
        {
          "--carousel-item-width-compact":
            itemSize === "big" ? "247px" : itemWidthCompact + "px",
          "--carousel-item-width-regular": itemWidthRegular + "px",
          "--carousel-item-margin-compact": itemMarginCompact + "px",
          "--carousel-item-margin-regular": itemMarginRegular + "px",
        } as CSSProperties
      }
    >
      <div ref={carouselRef} className={css.carousel}>
        {copyChildren.map((child, index) => (
          <React.Fragment key={index}>{child}</React.Fragment>
        ))}
      </div>

      {pageCount > 1 && (
        <div
          className={css.control}
          style={
            {
              "--carousel-control-arrow-top": topArrowPosition
                ? `${topArrowPosition}px`
                : `calc((var(--carousel-item-width-compact) ${
                    itemSize === "big" ? `+ ${itemMarginRegular}px` : ""
                  } - 20px) / 2)`,
            } as CSSProperties
          }
        >
          {(scrollLoop || pageIndex > 0) && (
            <button className={css.leftArrow} onClick={prev}>
              Left arrow
            </button>
          )}

          {(scrollLoop || pageIndex < pageCount - 1) && (
            <button className={css.rightArrow} onClick={next}>
              Right arrow
            </button>
          )}

          {!infiniteScroll && (
            <div
              className={css.dots}
              style={
                {
                  "--carousel-control-dots-color": dotsColor,
                } as CSSProperties
              }
            >
              {Array.from({ length: pageCount }).map((_, i) => (
                <button
                  key={i}
                  onClick={() => goTo(i)}
                  className={cn(css.dot, {
                    [css.active]: i === pageIndex,
                  })}
                >
                  {i}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

interface RecipeCarouselProps {
  recipes: (string | PartialRecipe)[]
  itemSize?: ItemSize
  isPreview?: boolean
  isDarkTheme?: boolean
  isVertical?: boolean
  viewMore?: ViewMore
  eagerImages?: boolean
}

export function RecipeCarousel({
  recipes,
  itemSize,
  isPreview,
  isDarkTheme,
  isVertical,
  viewMore,
  eagerImages,
}: RecipeCarouselProps) {
  const itemCount = itemSize === "big" ? 3 : 5
  return (
    <Carousel>
      {recipes
        .slice(0, !!viewMore ? itemCount : recipes.length)
        .map((item, i) => (
          <CarouselRecipeCard
            key={i}
            recipe={item}
            isPreview={isPreview}
            isDarkTheme={isDarkTheme}
            isVertical={isVertical}
            viewMore={i === itemCount - 1 && viewMore ? viewMore : undefined}
            eagerImages={i < itemCount ? eagerImages : undefined}
          />
        ))}
    </Carousel>
  )
}

export default Carousel
