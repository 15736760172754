export function RichEnum<
  Case,
  CaseMap extends Record<string, Case>,
  RichEnum extends {
    [CaseKey in keyof CaseMap]: CaseMap[CaseKey] & { _case: CaseKey }
  }
>(caseMap: CaseMap): RichEnum {
  const enrichedEnum = caseMap as unknown as RichEnum

  for (const caseName in enrichedEnum) {
    enrichedEnum[caseName]._case = caseName
  }

  return enrichedEnum
}

export type RichCase<T> = T[keyof T] & { _case: keyof T }
