function getIubConfigFromUrl() {
  pathElems = window.location.pathname.split("/")
  pathLocale = "en-us"
  if (pathElems.length > 0) {
    pathLocale = pathElems[1]
  }
  switch (pathLocale) {
    case "fr":
      return { lang: "fr", policy: 48780379 }
    case "de":
      return { lang: "de", policy: 45374124 }
    case "it":
      return { lang: "it", policy: 52692307 }
    case "pt-br":
      return { lang: "pt-BR", policy: 10635983 }
    case "es-es":
      return { lang: "es", policy: 92127984 }
    case "es-mx":
      return { lang: "es", policy: 92127984 }
    default:
      return { lang: "en", policy: 99907311 }
  }
}

;(function (w, c) {
  w._iub = w._iub || []
  w._iub.csConfiguration = {
    gdprAppliesGlobally: false,
    countryDetection: true,
    floatingPreferencesButtonIcon: true,
    floatingPreferencesButtonHover: true,
    consentOnContinuedBrowsing: false,
    perPurposeConsent: true,
    enableTcf: true,
    googleAdditionalConsentMode: true,
    whitelabel: false,
    lang: c.lang,
    siteId: 1692409,
    cookiePolicyId: c.policy,
    floatingPreferencesButtonDisplay: "bottom-right",
    floatingPreferencesButtonColor: "#FFFFFF",
    banner: {
      acceptButtonDisplay: true,
      customizeButtonDisplay: true,
      acceptButtonColor: "#EC2964",
      acceptButtonCaptionColor: "white",
      customizeButtonColor: "#212121",
      customizeButtonCaptionColor: "white",
      rejectButtonDisplay: true,
      rejectButtonColor: "#212121",
      rejectButtonCaptionColor: "white",
      listPurposes: true,
      position: "float-bottom-center",
      backgroundOverlay: true,
      textColor: "white",
      backgroundColor: "#000001",
    },
    callback: {
      onPreferenceExpressedOrNotNeeded: function (preference) {
        dataLayer.push({
          iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
        })
        if (!preference) {
          dataLayer.push({
            event: "iubenda_preference_not_needed",
          })
        } else {
          if (preference.consent === true) {
            dataLayer.push({
              event: "iubenda_consent_given",
            })
          } else if (preference.consent === false) {
            dataLayer.push({
              event: "iubenda_consent_rejected",
            })
          } else if (preference.purposes) {
            for (var purposeId in preference.purposes) {
              if (preference.purposes[purposeId]) {
                dataLayer.push({
                  event: "iubenda_consent_given_purpose_" + purposeId,
                })
              }
            }
          }
        }
      },
    },
  }
})(window, getIubConfigFromUrl())
