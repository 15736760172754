import { useEffect, useState } from "react"

import { navigate } from "~/gatsby/navigate"

function useAutoRedirect(defaultTo?: string, canRedirect?: boolean) {
  const [redirect, setRedirect] = useState<string | undefined>(defaultTo)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.has("redirect_to")) {
      setRedirect(urlParams.get("redirect_to")!)
    }
  }, [])

  useEffect(() => {
    if (canRedirect && redirect) {
      navigate(redirect)
    }
  }, [canRedirect, redirect])
}

export default useAutoRedirect
