import React from "react"

import { useIntl } from "react-intl"

interface PropTypes extends React.HTMLProps<HTMLAnchorElement> {
  componentMessageIds: string[]
  children: React.ReactNode
}

const LegacyLink = ({ componentMessageIds, children, ...rest }: PropTypes) => {
  const intl = useIntl()

  const translatedPath = componentMessageIds
    .map(componentMessageId => intl.formatMessage({ id: componentMessageId }))
    .join("/")

  const completeURL = `/${intl.locale}/${translatedPath}`

  return (
    <a href={completeURL} {...rest}>
      {children}
    </a>
  )
}
export default LegacyLink
