import { CreatePageCustomArguments } from "../types/global-types"
import Route, { RouteOptions } from "./Route"

export interface StaticRouteOptions extends RouteOptions {
  component: string
}

export default class StaticRoute extends Route {
  component: string

  constructor({ component, ...options }: StaticRouteOptions) {
    super(options)

    this.component = component
  }

  async create({
    defaultContext,
    createPage,
  }: CreatePageCustomArguments): Promise<void> {
    createPage({
      component: this.component,
      context: { ...defaultContext },
    })
  }
}
