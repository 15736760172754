import { RichEnum } from "./RichEnum"

type PropMap = Record<string, any> // TODO: move down? or move other stuff up?

export default class InitFromMap {
  static defaultValues = {}

  constructor(map: Record<string, unknown>) {
    const currentClass = this.constructor as typeof InitFromMap

    Object.assign(this, currentClass.defaultValues)
    Object.assign(this, map)
  }

  // Utility function: create a rich enum directly from argument maps
  static RichEnum<
    MappedCase,
    CaseName extends string,
    RawCaseMap extends Record<CaseName, PropMap>,
    MappedCaseMap extends {
      [CaseKey in keyof RawCaseMap]: MappedCase
    },
    RichEnum extends {
      [CaseKey in keyof MappedCaseMap]: MappedCase & { _case: CaseKey }
    }
  >(this: new ({}) => MappedCase, rawCaseMap: RawCaseMap): RichEnum {
    // Map maps to instances
    const mappedCaseMap: MappedCaseMap = {} as MappedCaseMap

    for (const caseName in rawCaseMap) {
      const propMap = rawCaseMap[caseName]
      mappedCaseMap[caseName as keyof MappedCaseMap] = new this(
        propMap
      ) as MappedCaseMap[keyof MappedCaseMap]
    }

    // Create and return rich enum
    return RichEnum(mappedCaseMap) as unknown as RichEnum
  }
}
