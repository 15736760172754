import { CreatePageCustomScriptArguments } from "../../types/global-types"
import createLandingPages from "./createLandingPages"

export default async function create404Pages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const landingId =
    args.currentLocale.messages?.["config:prismic-404-landing-id"]

  if (landingId && landingId !== "") {
    await createLandingPages(args, landingId as string)
  }
}
