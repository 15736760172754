import React from "react"

import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

import useSiteMetadata from "~/hooks/useSiteMetadata"

const HomePageSEO = () => {
  const intl = useIntl()
  const lang = intl.locale
  const { siteUrl } = useSiteMetadata()

  const founders = ["Thomas", "Jonathan", "Axel"]

  const organizationMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Chefclub",
    url: `${siteUrl}/${lang}/`,
    logo: `${siteUrl}/assets/chefclub-organization-logo-black.svg`,
    foundingDate: "2016",
    founders: founders.map(founder => ({
      "@type": "Person",
      name: `${founder} Lang`,
    })),
    sameAs: [
      intl.formatMessage({ id: "url:shopify-shop" }),
      intl.formatMessage({ id: "url:pinterest-account" }),
      intl.formatMessage({ id: "url:instagram-account" }),
      intl.formatMessage({ id: "url:facebook-account" }),
    ],
  }

  const searchBoxMarkup = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: `${siteUrl}/${lang}/`,
    potentialAction: {
      "@type": "SearchAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: `${siteUrl}/${lang}/${intl.formatMessage({
          id: "path:search",
        })}?q={search_term_string}`,
      },
      "query-input": `required name=search_term_string`,
    },
  }
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organizationMarkup)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(searchBoxMarkup)}
      </script>
    </Helmet>
  )
}

export default HomePageSEO
