import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import fetch from "isomorphic-fetch"

import { authSelector } from "./modules/auth"
import { RootState } from "./store"
import { getLocaleFromLocation } from "~/utils/locales"

// TODO: use centralized env vars
const GATSBY_CHEFCLUB_API_URL = process.env.GATSBY_CHEFCLUB_API_URL

export const getBaseQuery = (basePath?: string) =>
  fetchBaseQuery({
    baseUrl: `${GATSBY_CHEFCLUB_API_URL}/v1${basePath || ""}`,
    fetchFn: fetch,
    prepareHeaders: (headers, { getState }) => {
      const token = authSelector(getState() as RootState).token

      if (token) {
        headers.set("Authorization", `Bearer+Email ${token}`)
      }

      headers.set("Accept-Language", getLocaleFromLocation())

      return headers
    },
  })
