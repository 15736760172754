import React, {
  CSSProperties,
  HTMLProps,
  useEffect,
  useRef,
  useState,
} from "react"

import cn from "classnames"

import { Body, HTMLTypographyElement } from "../ui"

import css from "./DotsTagsList.module.scss"

type PropTypes = HTMLProps<HTMLSpanElement> & {
  tags: string[]
  mainColor?: string
}
const DotsTagsList = ({
  tags,
  mainColor = "#ffffff",
  className,
}: PropTypes) => {
  const firstElement = useRef<HTMLTypographyElement | null>(null)
  const lastElement = useRef<HTMLTypographyElement | null>(null)
  const [isOnlyOneRow, setIsOnlyOneRow] = useState(true)

  //Check if the last element has the same offset top as the first element
  const getIsOnlyOneRow = () => {
    if (firstElement.current && lastElement.current) {
      return firstElement.current.offsetTop === lastElement.current.offsetTop
    }
    return true
  }

  useEffect(() => {
    setIsOnlyOneRow(getIsOnlyOneRow())
  }, [])

  return (
    <div className={cn(className, css.tags)}>
      {tags.map((tag, index) => (
        <Body
          color="white"
          variant="body3"
          semiBold
          ref={
            index === 0
              ? firstElement
              : index === tags.length - 1
              ? lastElement
              : null
          }
          key={tag}
          className={cn({
            [css.tag]: index != 0,
            [css.lastTagInOtherRow]: index === tags.length - 1 && !isOnlyOneRow,
          })}
          style={
            {
              "--dotColor": `${mainColor}`,
            } as CSSProperties
          }
        >
          {tag}
        </Body>
      ))}
    </div>
  )
}

export default DotsTagsList
