import dynamicPages from "../gatsby/dynamicPages"
import {
  CreatePageCustomArguments,
  CreatePageCustomScriptArguments,
} from "../types/global-types"
import StaticRoute, { StaticRouteOptions } from "./StaticRoute"

export interface DynamicRouteOptions extends StaticRouteOptions {
  script?: string
}

export default class DynamicRoute extends StaticRoute {
  script?: string

  constructor({ script, ...options }: DynamicRouteOptions) {
    super(options)

    this.script = script
  }

  async create(args: CreatePageCustomArguments): Promise<void> {
    if (this.script) {
      const createPageScript = dynamicPages.get(this.script)
      if (createPageScript) {
        await createPageScript(this.prefillCreatePageArgs(args, this.component))
      } else {
        await super.create(args)
      }
    } else {
      await super.create(args)
    }
  }

  // Used before calling page creation script; so that the script doesn't have to specify the component when calling createPage
  prefillCreatePageArgs(
    args: CreatePageCustomArguments,
    component: string
  ): CreatePageCustomScriptArguments {
    return {
      ...args,
      createPage: scriptArgs => args.createPage({ ...scriptArgs, component }),
    }
  }
}
