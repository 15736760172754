import { deduplicate } from "../../utils/array"

export interface PrismicBodyLandingListWithHighlightedItem {
  slice_type: "list_with_highlighted_item"
  primary: {
    source: { uid: null | string }
  }
}
export interface PrismicBodyTextRecipeColumns {
  slice_type: "text_recipe_columns"
  primary: { recipe_uid: null | string }
}

export interface PrismicBodyRecipeCarousel {
  slice_type: "recipe_carousel"
  items: [{ recipe_uid: null | string }]
}

export interface PrismicBodyVideoTextColumns {
  slice_type: "video_text_columns"
  primary: { video_uid: null | string }
}

export interface PrismicBodyLandingCarousel {
  slice_type: "landing_carousel"
  primary: {
    source: { uid: null | string }
  }
}

export interface PrismicBodyLatestLandings {
  slice_type: "latest_landings"
  primary: {
    title_latest_landing: string
    tag: string
    maxnumber: number
  }
}
export interface PrismicBodyLatestRecipes {
  slice_type: "latest_recipes"
  primary: {
    verticale_filter: string
  }
}

export interface PrismicBodyVideo {
  slice_type: "video"
  primary: {
    mobile_video_uid: null | string
    desktop_video_uid: null | string
  }
}
export interface PrismicBodyFullWidthBanner {
  slice_type: "full_width_banner"
  primary: {
    mobile_video_uid: null | string
    desktop_video_uid: null | string
  }
}
export interface PrismicBodyRecipeVideo {
  slice_type: "recipe_video"
  primary: {
    recipe_uid: null | string
  }
}

export interface PrismicBodyTimeline {
  slice_type: "timeline"
  primary: {
    timeline: { id: null | string }
  }
}

export type AnyPrismicSlice =
  | PrismicBodyRecipeCarousel
  | PrismicBodyLandingCarousel
  | PrismicBodyLatestLandings
  | PrismicBodyLatestRecipes
  | PrismicBodyVideo
  | PrismicBodyRecipeVideo
  | PrismicBodyVideoTextColumns
  | PrismicBodyTextRecipeColumns
  | PrismicBodyTimeline
  | PrismicBodyFullWidthBanner

type IdsExtractor = (slices: AnyPrismicSlice[]) => string[]

export const getVideoIdsFromSlices: IdsExtractor = slices => {
  const ids = slices.flatMap(slice => {
    if (
      slice.slice_type === "video" ||
      slice.slice_type === "full_width_banner"
    ) {
      const videos = []
      if (slice.primary.desktop_video_uid) {
        videos.push(slice.primary.desktop_video_uid)
      }
      if (slice.primary.mobile_video_uid) {
        videos.push(slice.primary.mobile_video_uid)
      }
      return videos
    }

    if (slice.slice_type === "video_text_columns") {
      return slice.primary.video_uid ? [slice.primary.video_uid] : []
    }

    return []
  })

  return deduplicate(ids)
}

export const getRecipeIdsFromSlices: IdsExtractor = slices => {
  const ids = slices.flatMap(slice => {
    switch (slice.slice_type) {
      case "recipe_carousel":
        return slice.items
          .map(item => (item.recipe_uid ? item.recipe_uid : ""))
          .filter(el => !!el)
      case "recipe_video":
      case "text_recipe_columns":
        return slice.primary.recipe_uid ? [slice.primary.recipe_uid] : []
      default:
        return []
    }
  })

  return deduplicate(ids)
}

export const getLandingIdsFromSlices: IdsExtractor = slices => {
  const ids = slices.flatMap(slice => {
    if (slice.slice_type === "landing_carousel") {
      return slice.primary.source.uid ? [slice.primary.source.uid] : []
    }
    return []
  })

  return deduplicate(ids)
}

export const getTimelineIdsFromSlices: IdsExtractor = slices => {
  const ids = slices.flatMap(slice => {
    if (slice.slice_type === "timeline") {
      return slice.primary.timeline?.id ? [slice.primary.timeline.id] : []
    }
    return []
  })

  return deduplicate(ids)
}

export const getVerticalRecipesIdsFromSlices: IdsExtractor = slices => {
  const ids = slices.flatMap(slice => {
    if (slice.slice_type === "latest_recipes") {
      return slice.primary.verticale_filter
        ? [slice.primary.verticale_filter.toLowerCase()]
        : []
    }
    return []
  })

  return deduplicate(ids)
}
