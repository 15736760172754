import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import CustomContents, {
  CustomContentsProps,
} from "~/components/slices/CustomContents/CustomContents"
import { Episode, Show } from "~/models/Show"
import { Video } from "~/models/Video"

interface PropTypes extends PrismicLandingDataBodyCustomContents {
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
  incrementH1Position?: () => void
  allShows: {
    nodes: Show[]
  }
  allShowsEpisodes: {
    nodes: Episode[]
  }
  showsMobileHomeVideo: Video
  showsDesktopHomeVideo: Video
}

function LandingBodyCustomContents({ primary, ...props }: PropTypes) {
  return (
    <SliceSection {...props} anchorId={primary.anchorId}>
      <CustomContents {...{ ...props, ...primary }} />
    </SliceSection>
  )
}

export default LandingBodyCustomContents

export interface PrismicLandingDataBodyCustomContents extends PrismicSlice {
  sliceType: "custom_contents"
  primary: CustomContentsProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyCustomContents on PrismicLandingDataBodyCustomContents {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      customSlice: custom_slice
      anchorId: id
    }
  }
`
