import React from "react"

import { Body } from "~/components/ui"
import { formatColor } from "~/utils/colors"

import css from "./Tag.module.scss"

export interface TagProps {
  tag: string
  tagColor?: string
  textColor?: string
}

function Tag({ tag, tagColor, textColor = "var(--black-color)" }: TagProps) {
  return (
    <div
      className={css.tag}
      style={tagColor ? { backgroundColor: formatColor(tagColor) } : {}}
    >
      <Body variant="body4" color={formatColor(textColor)} semiBold>
        {tag}
      </Body>
    </div>
  )
}
export default Tag
