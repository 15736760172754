import React, { CSSProperties } from "react"

import starEmptyImage from "./star-empty.colorable.svg"
import starFullImage from "./star-full.colorable.svg"
import starHalfImage from "./star-half.colorable.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"

type StarState = "empty" | "half" | "full"
type StarSize = "medium" | "large"

interface StarRatingProps {
  score: number
  color?: CSSProperties["color"]
  size?: StarSize
}

const StarRating = ({ score, color, size = "large" }: StarRatingProps) => {
  const ariaLabel = score + "/5"

  return (
    <div role="img" aria-label={ariaLabel} style={{ display: "inline-flex" }}>
      {[0, 1, 2, 3, 4].map(index => {
        const starFillRatio = Math.max(0, Math.min(1, score - index)) // 0 to 1
        const starState = ["empty", "half", "full"][
          Math.round(starFillRatio * 2)
        ] as StarState

        return <Star state={starState} color={color} size={size} key={index} />
      })}
    </div>
  )
}

interface StarProps {
  state: StarState
  color?: CSSProperties["color"]
  size?: StarSize
}

const Star = ({ state, color, size = "large" }: StarProps) => {
  const src = {
    empty: starEmptyImage,
    half: starHalfImage,
    full: starFullImage,
  }[state]

  return (
    <ColorableSVG
      href={src}
      mainColor={color ?? "var(--cocktails-color)"}
      width={size === "medium" ? 20 : 24}
      height={size === "medium" ? 20 : 24}
      title="Chefclub"
      style={{ margin: "0 4px" }}
    />
  )
}

export default StarRating
