import React, { useRef, useState } from "react"

import { graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"

import useElementSize from "~/hooks/useElementSize"
import { BasePrismicTimelineSlice, PrismicEmbed } from "~/models/PrismicTypes"

import css from "./timelineCardYoutube.module.scss"

function getYoutubeId(url: string): string {
  const urlParams = new URLSearchParams(url.split("?")[1])
  return urlParams.get("v") || ""
}

type PropTypes = PrismicTimelineBodyYoutube

function TimelineCardYoutube({ primary }: PropTypes) {
  const { embedUrl, width, height } = primary.youtubeUrl

  // Calc card width/height from parent width
  const rootRef = useRef(null)
  const { width: currentWidth } = useElementSize(rootRef)
  const ratio = height / width
  const embedSize = {
    width: currentWidth,
    height: ratio * currentWidth,
  }

  // Handle play/off
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  // Build embed URL
  const videoId = getYoutubeId(embedUrl)
  const videoOptions = `autoplay=1&controls=0`
  const videoUrl = `https://www.youtube.com/embed/${videoId}?${videoOptions}`

  return (
    <div ref={rootRef} className={css.embed}>
      {open ? (
        <>
          <button
            className={css.embedButton}
            type="button"
            onClick={handleClose}
          />
          <iframe
            className={css.iframe}
            src={videoUrl}
            data-allow="autoplay; geolocation *"
            frameBorder="0"
            {...embedSize}
          />
        </>
      ) : (
        <div onClick={handleOpen}>
          {primary.image.fluid && (
            <Image
              fadeIn={false}
              className={css.thumbnail}
              fluid={primary.image.fluid}
              alt={primary.image?.alt || ""}
              {...embedSize}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default TimelineCardYoutube

export interface PrismicTimelineBodyYoutube extends BasePrismicTimelineSlice {
  sliceType: "youtube"
  primary: BasePrismicTimelineSlice["primary"] & {
    image: {
      alt: string | null
      fluid: FluidObject | null
    }
    youtubeUrl: PrismicEmbed
  }
}

export const query = graphql`
  fragment PrismicTimelineV2DataBodyYoutube on PrismicTimelineV2DataBodyYoutube {
    id
    __typename
    sliceType: slice_type
    primary {
      date(formatString: "MMMM YYYY", locale: $lang)
      title {
        ...PrismicStructuredText
      }
      description {
        ...PrismicStructuredText
      }
      image {
        alt
        fluid(maxWidth: 716) {
          ...GatsbyImgixFluid_noBase64
        }
      }
      youtubeUrl: youtube_url {
        ...PrismicEmbed
      }
    }
  }
`
