import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import FlipTiles, {
  FlipTilesProps,
} from "~/components/slices/FlipTiles/FlipTiles"

interface PropTypes extends PrismicLandingDataBodyFlipTiles {
  eagerImages?: boolean
}

function LandingBodyFlipTiles(props: PropTypes) {
  return (
    <SliceSection margin="large" anchorId={props.primary.anchorId} {...props}>
      <FlipTiles {...props} />
    </SliceSection>
  )
}

export default LandingBodyFlipTiles

export interface PrismicLandingDataBodyFlipTiles extends PrismicSlice {
  sliceType: "flip_tiles"
  primary: FlipTilesProps["primary"]
  items: FlipTilesProps["items"]
  isDarkTheme?: boolean
}

export const query = graphql`
  fragment PrismicLandingDataBodyFlipTiles on PrismicLandingDataBodyFlipTiles {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      title {
        ...PrismicStructuredText
      }
      anchorId: id
    }
    items {
      image {
        gatsbyImageData
        fluid(maxWidth: 168) {
          ...GatsbyImgixFluid_noBase64
        }
      }
      backgroundColor: background_color
      text {
        ...PrismicStructuredText
      }
      link {
        ...PrismicLink
      }
    }
  }
`
