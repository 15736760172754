import React from "react"

import { useIntl } from "react-intl"

import Bin from "../assets/bin.svg"
import Pen from "../assets/pen.svg"
import { Units } from "../data/Units"
import KiddozConversionForm from "../KiddozConversionForm/KiddozConversionForm"
import { KiddozEquivalence } from "../KidsConverterV2"
import { formatIngredient, getIngredientFromSlug, kiddozIcons } from "../utils"
import { Heading } from "~/components/ui"

import css from "./KiddozConversionCard.module.scss"

interface PropTypes {
  kiddoz: KiddozEquivalence[]
  removeKiddoz: (index: number) => void
  modifyKiddoz: (index: number, kiddoz: KiddozEquivalence) => void
}
const KiddozConversionCard = ({
  kiddoz,
  removeKiddoz,
  modifyKiddoz,
  ...rest
}: PropTypes) => {
  const [isEditing, setIsEditing] = React.useState<number>()

  const intl = useIntl()

  const simpleLocale = intl.locale.split("-")[0]

  return (
    <div className={css.results}>
      {kiddoz?.map((kiddoz, index) => {
        const unitProperty = kiddoz.initialValue.quantity > 1 ? "_plural" : ""
        const unitDisplayText =
          kiddoz.initialValue.units[
            `${simpleLocale}${unitProperty}` as keyof Units
          ]
        const initialQuantityUnits = `${kiddoz.initialValue.quantity} ${unitDisplayText}`

        return (
          <div className={css.result} key={index}>
            {isEditing !== index && (
              <div className={css.header}>
                <div className={css.heading}>
                  <Heading variant="small" className={css.ingredient}>
                    {formatIngredient(kiddoz.ingredient, intl)}
                  </Heading>
                  <Heading variant="extraSmall">
                    &nbsp; ({initialQuantityUnits})
                  </Heading>
                </div>
                <div className={css.actions}>
                  <img
                    src={Pen}
                    onClick={() =>
                      setIsEditing((prevIsEditing: number | undefined) => {
                        if (prevIsEditing === index) {
                          return undefined
                        }
                        return index
                      })
                    }
                  />
                  <img src={Bin} onClick={() => removeKiddoz(index)} />
                </div>
              </div>
            )}

            {!(isEditing === index) ? (
              <div className={css.kiddozRow}>
                {kiddoz.kiddoz.length ? (
                  kiddoz.kiddoz.map((kiddoz, index) => (
                    <>
                      {kiddoz.kiddoz && (
                        <img src={kiddozIcons.get(kiddoz.kiddoz)} />
                      )}
                    </>
                  ))
                ) : (
                  <Heading
                    variant="extraSmall"
                    className={css.initialQuantityUnits}
                  >
                    {initialQuantityUnits}
                  </Heading>
                )}
                {getIngredientFromSlug(kiddoz.ingredient).icon}
              </div>
            ) : (
              <KiddozConversionForm
                action={(kiddoz: KiddozEquivalence) => {
                  modifyKiddoz(index, kiddoz)
                  setIsEditing(undefined)
                }}
                cancel={() => setIsEditing(undefined)}
                prefill={{
                  ingredient: kiddoz.ingredient,
                  quantity: kiddoz.initialValue.quantity,
                  unity: kiddoz.initialValue.units.slug,
                }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default KiddozConversionCard
