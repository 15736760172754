import React from "react"

import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"

import HeaderSection from "~/components/Layout/Header/HeaderSection"
import Layout, { LayoutOptions } from "~/components/Layout/Layout"
import { FallbackRouteParams, PageContextBase } from "~/types/global-types"
import { getIntl } from "~/utils/locales"

export interface TemplatePropTypes<ExtendedContext extends PageContextBase> {
  path: string
  navigate: (to: string, options: Record<string, unknown>) => void
  location: Location
  pageContext: ExtendedContext
}

export type TemplateDefaultPropTypes = TemplatePropTypes<PageContextBase>

export default function withTemplate<
  ExtendedContext extends PageContextBase = PageContextBase,
  T extends TemplatePropTypes<ExtendedContext> = TemplatePropTypes<ExtendedContext>
>(WrappedComponent: React.ComponentType<T>, layoutOptions: LayoutOptions = {}) {
  return (props: T) => {
    const {
      locales,
      currentLocale,
      siteLinkLists,
      searchSuggestions,
      lastCreators,
      lastShowEpisodes,
      shopUrl,
    } = props.pageContext
    const intl = getIntl(currentLocale.id)
    const mergedLayoutOptions = Object.assign({}, layoutOptions)

    // If page has a kids theme, force selection of kids section
    const prismicData = (props as any)?.data?.prismicLanding?.data
    if (prismicData?.theme === "Kids") {
      mergedLayoutOptions.forceSelectedSection = HeaderSection.Kids
    }

    return (
      <IntlProvider locale={currentLocale.id} messages={intl.messages}>
        {props.pageContext.fallbackRouteParams && (
          <Helmet>
            {/*
              Add to "window" variable the path to the csr component for matchPath purpose
              Stores the window.pagePath value in a different global variable and tricks gatsby into disabling client side routing by making this check never true:
              https://github.com/gatsbyjs/gatsby/blob/6d8f0bfdcc1a2fe2d07f03583b19ea7934b0ccf4/packages/gatsby/cache-dir/production-app.js#L144-L153
            */}
            <script>
              {`
                Object.defineProperty(window, 'pagePath', {
                  get: function() { return undefined; },
                  set: function(path) { window.chefclub__routeParams = {${Object.keys(
                    props.pageContext.fallbackRouteParams
                  ).map(
                    (key: string) =>
                      `${key} : "${
                        (props.pageContext.fallbackRouteParams ?? {})[
                          key as keyof FallbackRouteParams
                        ]
                      }"`
                  )}}; }
                });
              `}
            </script>
          </Helmet>
        )}
        <Layout
          locales={locales}
          siteLinkLists={siteLinkLists}
          location={props.location}
          options={mergedLayoutOptions}
          searchSuggestions={searchSuggestions}
          lastCreators={lastCreators}
          lastShowEpisodes={lastShowEpisodes}
          shopUrl={shopUrl}
        >
          <WrappedComponent {...props} />
        </Layout>
      </IntlProvider>
    )
  }
}
