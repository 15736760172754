import React from "react"

import cn from "classnames"
import { HitsProvided } from "react-instantsearch-core"
import { connectHits } from "react-instantsearch-dom"
import { FormattedMessage, useIntl } from "react-intl"

import noResultMaurice from "./assets/noResultMaurice.svg"
import SearchResult from "~/components/Layout/SearchLayout/SearchResult/SearchResult"
import Separator from "~/components/Separator/Separator"
import { Body, Button } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import { isIOS, isMobile } from "~/utils/detector"

import css from "./SearchResultHits.module.scss"

type propType = HitsProvided<any> & {
  query: any
  focusSearchBox?: boolean
}

const Hits = ({ hits, query, focusSearchBox }: propType) => {
  const intl = useIntl()

  if (hits.length === 0) {
    return (
      <div className={css.hitsRoot}>
        <div className={css.noResultContainer}>
          <img src={noResultMaurice} className={css.noResultImg} alt="" />
          <Body variant="body2" semiBold className={css.noResultText}>
            {intl.formatMessage({
              id: "category/text:no-results",
            })}
          </Body>
        </div>
      </div>
    )
  }

  const handleSeeAllButton = async () => {
    // const path = window.location.pathname
    await navigate(
      `/${intl.locale}/${intl.formatMessage({ id: "path:search" })}/?q=` + query
    )
    // if (path.includes(intl.formatMessage({ id: "path:search" }))) {
    //   setTimeout(() => location.reload(), 100)
    // }
  }

  return (
    <div className={css.hitsRoot}>
      <div
        className={cn(css.buttonContainer, {
          [css.buttonContainerIOS]: focusSearchBox && isIOS(),
        })}
      >
        <div className={css.onlyCompact}>
          <Separator />
        </div>
        <Button
          color="original"
          size="small"
          fullWidth
          className={cn(css.button, {
            [css.buttonIOS]: focusSearchBox && isIOS(),
          })}
          onClick={() => {
            if (!focusSearchBox && !isMobile()) {
              handleSeeAllButton()
            }
          }}
          onTouchEnd={() => {
            if (focusSearchBox && isMobile()) {
              handleSeeAllButton()
            }
          }}
        >
          <FormattedMessage id="search/action:see-all-and-filter" />
        </Button>
      </div>
      <div>
        {hits.map((hit: any) => (
          <SearchResult
            key={hit.id}
            absolute_url={hit.absolute_url}
            title={hit.title}
            pictureSrc={hit.web_cover_thumbnail}
            vertical={hit.vertical}
            author={{
              username: hit.author?.username,
              id: "ChefClub",
              profilePicture: hit.author?.image_url,
              userTypes: hit.author?.user_types,
            }}
            isChefclub={
              !(hit.author && hit.author.username) ||
              hit.author?.user_types[0] === "chefclub-staff"
            }
            chefclubColor={`var(--${
              hit.vertical === "kids" ? "kids" : "original"
            }-color)`}
          />
        ))}
      </div>
    </div>
  )
}

const SearchResultHits = connectHits<propType, any>(Hits)

export default SearchResultHits
