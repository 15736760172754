import { useEffect, useState } from "react"

interface Viewport {
  viewport: { width: number; height: number }
  visualViewport: { width: number; height: number }
}

const getViewports = () => ({
  viewport: {
    width: Math.max(
      document?.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    height: Math.max(
      document?.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
  },
  visualViewport: {
    width: window.visualViewport?.width || 375,
    height: window.visualViewport?.height || 814,
  },
})

export default function useVisualViewport(): Viewport {
  const [state, setState] = useState({
    viewport: {
      width: 375,
      height: 814,
    },
    visualViewport: {
      width: 375,
      height: 814,
    },
  }) // <- by default we set the mobile viewport
  useEffect(() => {
    setState(getViewports())
    const handleResize = () => setState(getViewports())
    window.visualViewport?.addEventListener("resize", handleResize)
    return () =>
      window.visualViewport?.removeEventListener("resize", handleResize)
  }, [])
  return state
}
