import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import ListWithHighlightedItem from "~/components/slices/ListWithHighlightedItem/ListWithHighlightedItem"
import { LatestLanding } from "~/templates/Landing/Landing"

interface PropTypes extends PrismicLandingDataBodyListWithHighlightedItem {
  allLandings: LatestLanding[] | undefined
}

function LandingBodyListWithHighlightedItem({
  allLandings,
  ...props
}: PropTypes) {
  return (
    <SliceSection anchorId={props.primary?.anchorId} {...props}>
      <ListWithHighlightedItem {...{ allLandings, ...props }} />
    </SliceSection>
  )
}

export default LandingBodyListWithHighlightedItem

export interface PrismicLandingDataBodyListWithHighlightedItem
  extends PrismicSlice {
  sliceType: "list_with_highlighted_item"
  primary: {
    titleListWithHighlightedItem: string
    toButton: { url: string }
    anchorId?: string
  }
  items: [
    {
      sourcePage: {
        uid: string
      }
    }
  ]
}

export const query = graphql`
  fragment PrismicLandingDataBodyListWithHighlightedItem on PrismicLandingDataBodyListWithHighlightedItem {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      titleListWithHighlightedItem: title_list_with_highlighted_item
      toButton: link_to {
        url
      }
      anchorId: id
    }
    items {
      sourcePage: source_page {
        uid
      }
    }
  }
`
