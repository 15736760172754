import React, {
  CSSProperties,
  FC,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

import cn from "classnames"
import { RichText } from "prismic-reactjs"
import ReactDOMServer from "react-dom/server"
import { FormattedMessage } from "react-intl"

import { ThemeContext } from "~/context/ThemeContext"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import { TextColor } from "~/types/global-types"
import { formatColor } from "~/utils/colors"
import { responsiveCN, ResponsiveValues } from "~/utils/responsiveCN"
import { capitalizeFirstLetter } from "~/utils/string"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./typography.module.scss"

// TODO: There are redundant pattern between some UI Component, make it DRY
export type HTMLTypographyElement = HTMLHeadingElement | HTMLParagraphElement
export type HeadingVariant =
  | "modal"
  | "extraExtraLarge"
  | "extraLarge"
  | "large"
  | "medium"
  | "small"
  | "extraHandDrawn"
  | "extraSmall"
  | "extraLargeHandDrawn"
  | "largeHandDrawn"
  | "mediumHandDrawn"
  | "smallHandDrawn"
  | "extraSmallHandDrawn"
  | "extraLargeCampingHoliday"
  | "largeCampingHoliday"
  | "mediumCampingHoliday"
  | "smallCampingHoliday"
  | "extraSmallCampingHoliday"
  | "extraExtraLargeCreators"
  | "extraLargeCreators"
  | "largeCreators"
  | "mediumCreators"
  | "smallCreators"
  | "extraSmallCreators"
  | "extraLargeNav"
  | "largeNav"
  | "mediumNav"
  | "smallNav"
  | "extraSmallNav"
type BodyVariant = "body1" | "body2" | "body3" | "body4" | "like" | "control"
type BodyTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div"

interface GetClassesProps {
  className?: string
  style?: CSSProperties
  align?: ResponsiveValues<"inherit" | "left" | "center" | "right" | "justify">
  color?: TextColor
  uppercase?: ResponsiveValues<boolean>
  variant?: HeadingVariant | BodyVariant
  bold?: boolean // Only util in Text Component
  semiBold?: boolean // Only util in Text Component
  noMargin?: ResponsiveValues<boolean>
}

const getResponsiveCN = responsiveCN(css)

interface StyleProps {
  className: string
  style: CSSProperties
}

const getStyleProps = ({
  className,
  style,
  align = "inherit",
  color,
  variant = "body1",
  uppercase = false,
  noMargin = false,
  bold = false,
  semiBold = false,
}: GetClassesProps): StyleProps => {
  return {
    className: cn(
      {
        [css.bold]: bold,
        [css.semiBold]: semiBold,
      },
      `variant${capitalizeFirstLetter(variant)}`,
      getResponsiveCN("uppercase", uppercase),
      getResponsiveCN("noMargin", noMargin),
      getResponsiveCN("align", align),
      className
    ),
    style: { ...(!color ? {} : { color: formatColor(color) }), ...style },
  }
}

export type TypographyProps<T = HTMLTypographyElement> = HTMLProps<T> &
  GetClassesProps & {
    Tag?: BodyTag
  }

// This component is a base for <Body>, <Heading>, etc components.
// It must be "private" to avoid usage in codebase
const Typography = forwardRef<HTMLTypographyElement, TypographyProps>(
  function Typography(
    {
      className,
      style,
      Tag = "p",
      align,
      color,
      uppercase,
      noMargin,
      variant,
      bold,
      semiBold,
      ...props
    },
    ref
  ) {
    const theme = useContext(ThemeContext)

    const themeVariantMap: any = {
      kids: {
        extraLarge: "extraLargeCampingHoliday",
        large: "largeCampingHoliday",
        medium: "mediumCampingHoliday",
        small: "smallCampingHoliday",
        extraSmall: "extraSmallCampingHoliday",
      },
      food: {
        extraLarge: "extraLargeHandDrawn",
        large: "largeHandDrawn",
        medium: "mediumHandDrawn",
        small: "smallHandDrawn",
        extraSmall: "extraSmallHandDrawn",
      },
      creators: {
        extraLarge: "extraLargeCreators",
        large: "largeCreators",
        medium: "mediumCreators",
        small: "smallCreators",
        extraSmall: "extraSmallCreators",
      },
      nav: {
        extraLarge: "extraLargeNav",
        large: "largeNav",
        medium: "mediumNav",
        small: "smallNav",
        extraSmall: "extraSmallNav",
        body1: "body1Nav",
        body2: "body2Nav",
        body3: "body3Nav",
        body4: "body4Nav",
      },
    }

    const mappedVariant: GetClassesProps["variant"] = variant
      ? themeVariantMap[theme]?.[variant] ?? variant
      : undefined

    return (
      <Tag
        {...props}
        {...getStyleProps({
          className,
          style,
          align,
          color,
          uppercase,
          variant: mappedVariant,
          noMargin,
          bold,
          semiBold,
        })}
        ref={ref}
      />
    )
  }
)

export interface BodyProps extends TypographyProps {
  variant?: BodyVariant
}

export const Body = forwardRef<HTMLTypographyElement, BodyProps>(function Body(
  { Tag = "p", variant = "body1", ...props },
  ref
) {
  return <Typography {...{ Tag, variant, ...props }} ref={ref} />
})

export const FormattedBody = forwardRef<
  HTMLTypographyElement,
  BodyProps & { translationId: string; translationValues?: any }
>(function Body(
  { Tag = "p", variant = "body1", translationId, translationValues, ...props },
  ref
) {
  return (
    <Typography {...{ Tag, variant, ...props }} ref={ref}>
      <FormattedMessage id={translationId} values={translationValues} />
    </Typography>
  )
})

export const SeeMoreBody: FC<
  BodyProps & {
    seeMoreComponent: ReactElement
    nbLines: number
  }
> = ({ seeMoreComponent, children, nbLines, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const typoRef = useRef<HTMLTypographyElement>(null)

  const [childrenHTML, setChildrenHTML] = useState<string>(
    children ? ReactDOMServer.renderToString(<>{children}</>) : ""
  )
  const [isOpen, setOpen] = useState<boolean>(false)

  useEffect(() => {
    let shortenedInnerHTML = children
      ? ReactDOMServer.renderToString(<>{children}</>)
      : ""

    if (containerRef?.current && typoRef?.current) {
      typoRef.current.innerHTML = shortenedInnerHTML

      while (typoRef.current.scrollHeight > containerRef.current.offsetHeight) {
        shortenedInnerHTML = shortenedInnerHTML.replace(/\W\s*(\S){0,50}$/, "")
        typoRef.current.innerHTML =
          shortenedInnerHTML + ReactDOMServer.renderToString(seeMoreComponent)
      }
      setChildrenHTML(typoRef.current.innerHTML)
    }
  }, [children])

  const closeStyle = {
    maxHeight: `${nbLines * 1.5}em`,
  }

  return (
    <div
      className={cn(css.seeMoreContainer, isOpen ? css.opened : null)}
      style={!isOpen ? closeStyle : undefined}
      ref={containerRef}
      onClick={() => setOpen(true)}
    >
      {isOpen ? (
        <Body {...props} ref={typoRef}>
          {children}
        </Body>
      ) : (
        <Body
          {...props}
          ref={typoRef}
          dangerouslySetInnerHTML={{ __html: childrenHTML }}
        />
      )}
    </div>
  )
}

export interface HeadingProps extends TypographyProps {
  variant?: HeadingVariant
}

export const Heading = forwardRef<HTMLTypographyElement, HeadingProps>(
  function Heading({ Tag = "h2", variant = "large", ...props }, ref) {
    return <Typography {...{ Tag, variant, ...props }} ref={ref} />
  }
)

export const FormattedHeading = forwardRef<
  HTMLTypographyElement,
  HeadingProps & { translationId: string; translationValues?: any }
>(function Heading(
  { Tag = "h2", variant = "large", translationId, translationValues, ...props },
  ref
) {
  return (
    <Typography {...{ Tag, variant, ...props }} ref={ref}>
      <FormattedMessage id={translationId} values={translationValues} />
    </Typography>
  )
})

export interface TextareaProps
  extends Omit<GetClassesProps, "variant" | "noMargin"> {
  children: ReactNode
}

export const Textarea = forwardRef<HTMLDivElement, TextareaProps>(
  ({ className, align, color, uppercase, bold, semiBold, children }, ref) => {
    return (
      <div
        ref={ref}
        {...getStyleProps({
          className: cn(className, css.textarea),
          align,
          color,
          uppercase,
          bold,
          semiBold,
        })}
      >
        {children}
      </div>
    )
  }
)

export interface PrismicTextareaProps
  extends Omit<GetClassesProps, "variant" | "noMargin"> {
  text: PrismicStructuredText
}

export const PrismicTextarea = forwardRef<HTMLDivElement, PrismicTextareaProps>(
  function PrismicTextarea({ text, ...props }, ref) {
    return (
      <Textarea {...props} ref={ref}>
        <RichText render={text.raw} />
      </Textarea>
    )
  }
)
