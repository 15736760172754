import contrast from "contrast"

export function getFontColor(color: string): "body" | "white" {
  return contrast(color) === "dark" ? "white" : "body"
}

export function getTextColor(
  backgroundColor: string,
  isFoodTheme: boolean,
  isDarkTheme?: boolean
): "body" | "white" {
  return backgroundColor
    ? isFoodTheme
      ? "white"
      : getFontColor(backgroundColor)
    : isDarkTheme
    ? "white"
    : "body"
}
