import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { getPrismicLocaleFromLocale } from "../../utils/locales"

type RecipeQuery = {
  id: string
  absolute_url: string
  slug: string
  title: string
  vertical: string
  chefclub_video_id: string
  primary_category: {
    uuid: string | null
  }
  books: string[]
  same_category_recipes: any
  other_category_recipes: any
}

async function createRecipePagesWithFilters(
  args: CreatePageCustomScriptArguments,
  filters: string[]
): Promise<void> {
  const { graphql, reporter, currentLocale } = args
  const filter = ["language: { eq: $language }", ...filters].join(",")
  const result = await graphql<{
    allRecipe: {
      totalCount: number
      nodes: RecipeQuery[]
    }
    appSettings: {
      alcohol_category: string
    }
  }>(
    `
  query createRecipePagesQuery($language: String, $prismicLanguage: String) {
    allRecipe(
      sort: {fields: last_modified, order: DESC},
      filter: { ${filter} }
      ) {
      nodes {
        id
        absolute_url
        slug
        vertical
        chefclub_video_id
        primary_category {
          uuid
        }
        books
        same_category_recipes {
          id
          slug
          title
          vertical
          web_cover
          web_cover_thumbnail
          access_mode
          language
        }
        other_category_recipes {
          id
          slug
          title
          vertical
          web_cover
          web_cover_thumbnail
          access_mode
          language
        }
      }
    }
    appSettings(lang: { eq: $prismicLanguage }) {
      alcohol_category
    }
  }
  `,
    {
      language: currentLocale.id,
      prismicLanguage: getPrismicLocaleFromLocale(currentLocale.id),
    }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data) {
    const alcohol_category = result.data.appSettings.alcohol_category
    for (const recipe of result.data.allRecipe.nodes) {
      try {
        await createRecipe(recipe, alcohol_category, args)
      } catch (e) {
        console.log("ERROR")
      }
    }
  }
}

const createRecipe = async (
  recipe: RecipeQuery,
  alcohol_category: string,
  args: CreatePageCustomScriptArguments
) => {
  const { createPage, defaultContext } = args

  const pageData: any = {
    routeParams: recipe,
    context: {
      ...defaultContext,
      id: recipe.id,
      vertical: recipe.vertical,
      chefclubVideoId: recipe.chefclub_video_id,
      primaryCategory: recipe.primary_category.uuid,
      bookId: recipe.books.length ? recipe.books[0] : "",
      alcoholCategory: alcohol_category,
      sameCategoryRecipes: recipe.same_category_recipes,
      otherCategoryRecipes: recipe.other_category_recipes,
    },
  }

  createPage(pageData)
}

export default async function createRecipePages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  await createRecipePagesWithFilters(args, [])
}
