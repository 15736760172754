import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import Reviews, { ReviewsProps } from "~/components/slices/Reviews/Reviews"

type PropTypes = PrismicLandingDataBodyReviews

function LandingBodyReviews(props: PropTypes) {
  return (
    <SliceSection
      margin="large"
      noContainer
      anchorId={props.primary.anchorId}
      {...props}
    >
      <Reviews {...props} />
    </SliceSection>
  )
}

export default LandingBodyReviews

export interface PrismicLandingDataBodyReviews
  extends PrismicSlice,
    ReviewsProps {
  sliceType: "reviews"
}

export const query = graphql`
  fragment PrismicLandingDataBodyReviews on PrismicLandingDataBodyReviews {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      starsColor: stars_color
      backgroundColor: review_background_color
      anchorId: id
    }
    items {
      reviewerName: reviewer_name {
        ...PrismicStructuredText
      }
      text: text {
        ...PrismicStructuredText
      }
      score: score
    }
  }
`
