import React from "react"

import Img, { FluidObject } from "gatsby-image"

import { Heading } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PrismicStructuredText } from "~/models/PrismicTypes"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./FullWidthBanner.module.scss"

export interface FullWidthBannerLegacyProps {
  image: {
    alt: string | null
    fluid: FluidObject | null
  }
  mobileImage?: {
    alt: string | null
    fluid: FluidObject | null
  }
  title1: PrismicStructuredText
}

type PropTypes = FullWidthBannerLegacyProps

function FullWidthBannerLegacy({ image, mobileImage, title1 }: PropTypes) {
  const isCompact = useIsOnCompact()

  return (
    <div className={css.cover}>
      {isCompact && mobileImage?.fluid?.src ? (
        <Img
          className={css.image}
          fluid={mobileImage.fluid}
          alt={image.alt || ""}
          fadeIn={false}
        />
      ) : image.fluid?.src ? (
        <Img
          className={css.image}
          fluid={image.fluid}
          alt={image.alt || ""}
          fadeIn={false}
        />
      ) : null}

      {title1.text && (
        <Heading className={css.title} align="center" color="white">
          {title1.text}
        </Heading>
      )}
    </div>
  )
}

export default FullWidthBannerLegacy
