import React from "react"

import { Heading } from "~/components/ui"

export interface StoreLocatorProps {
  mapTitle?: string
  mapUrl: string
}

interface PropTypes extends StoreLocatorProps {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function StoreLocator({ mapTitle, mapUrl, ...props }: PropTypes) {
  return (
    <>
      {mapTitle && <Heading variant="large">{mapTitle}</Heading>}
      <div style={{ position: "absolute", top: -160 }} id="store-locator" />
      <iframe
        src={mapUrl}
        height="550"
        style={{ width: `100%`, border: 0, borderRadius: `6px` }}
      />
    </>
  )
}

export default StoreLocator
