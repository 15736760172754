import React from "react"

import SearchResult from "~/components/Layout/SearchLayout/SearchResult/SearchResult"
import { Heading } from "~/components/ui"
import {
  SearchSuggestions,
  SearchSuggestionsImgGridReturn,
} from "~/gatsby/utils/getSearchSuggestions"

import css from "./NoSearchResult.module.scss"

type propType = {
  searchSuggestions: SearchSuggestions
}

type propTypeIngredients = {
  searchSuggestionsimgGrid: SearchSuggestionsImgGridReturn[]
  title?: string
}

const IngredientsSearchSuggestions = ({
  searchSuggestionsimgGrid,
  title,
}: propTypeIngredients) => {
  return (
    <div className={css.ingredientsSuggestions}>
      <Heading variant="large">{title}</Heading>
      <div className={css.ingredientsList}>
        {searchSuggestionsimgGrid.map(
          (suggestion, index) =>
            suggestion &&
            suggestion.name && (
              <div className={css.image} key={index}>
                <a href={suggestion.web_link ?? suggestion.link}>
                  <img src={suggestion.image?.fluid?.src} alt="" />
                </a>
              </div>
            )
        )}
      </div>
    </div>
  )
}

const NoSearchResult = ({ searchSuggestions }: propType) => {
  return (
    <div className={css.root}>
      {searchSuggestions?.imgText?.length &&
        searchSuggestions.imgText?.map(
          (suggestion, index) =>
            suggestion?.picto?.fluid?.src && (
              <SearchResult
                key={index}
                absolute_url={suggestion.web_link ?? suggestion.link}
                title={suggestion.title}
                pictureSrc={suggestion.picto?.fluid?.src}
                multilineTitles
              />
            )
        )}
      {searchSuggestions?.imgGrid?.length && (
        <IngredientsSearchSuggestions
          searchSuggestionsimgGrid={searchSuggestions.imgGrid}
          title={searchSuggestions.imgGridTitle}
        />
      )}
    </div>
  )
}

export default NoSearchResult
