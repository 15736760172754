import React from "react"

import { HrefButton } from "~/components/ui"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./Button.module.scss"

export interface ButtonProps {
  link: PrismicLink
  label: PrismicStructuredText
  anchorId?: string
}

interface PropTypes extends ButtonProps {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function Button({ link, label, isDarkTheme }: PropTypes) {
  return (
    <div className={css.center}>
      <HrefButton
        {...getPrismicLinkProps(link)}
        fullWidth={[true, false]}
        color={isDarkTheme ? "white" : "black"}
      >
        {label.text}
      </HrefButton>
    </div>
  )
}

export default Button
