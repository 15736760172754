import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  RefObject,
  useRef,
} from "react"

import cn from "classnames"

import VisibilityTransition from "../VisibilityTransition/VisibilityTransition"
import useEventListener from "~/hooks/useEventListener"
import useIsTouchScreen from "~/hooks/useIsTouchScreen"
import useLockedBody from "~/hooks/useLockedBody"
import useOnClickOutside from "~/hooks/useOnClickOutside"

import css from "./Modal.module.scss"

interface PropTypes {
  open?: boolean
  children:
    | ((ref: RefObject<HTMLDivElement>, open: boolean) => ReactNode)
    | ReactNode
  onClose?: (open: false) => void
  mode?: "absolute" | "fixed"
  unmountOnExit?: boolean
  backgroundOpacity?: number
  [key: string]: any //Props passed to div if multiple nodes in children, unused if only one child but in this case, add them directly to the child
  overflow?: "scroll" | "hidden" | "auto"
  zIndex?: number
}

function Modal({
  open,
  children,
  onClose,
  mode = "fixed",
  unmountOnExit = true,
  overflow = "auto",
  backgroundOpacity = 0.5,
  zIndex = 2500,
  ...props
}: PropTypes) {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const isTouchable = useIsTouchScreen()

  useEventListener("keydown", (event: KeyboardEvent) => {
    if (event.key === "Escape" && open && onClose) {
      event.preventDefault()
      event.stopImmediatePropagation()
      onClose(false)
    }
  })

  useLockedBody(open && mode === "fixed")

  //If children is not a function aka a normal child
  if (children && typeof children === "object") {
    //Case 1 : Multiple nodes in children => we add a main div with the contentRef
    if (Array.isArray(children)) {
      children = (
        <div ref={contentRef} {...props}>
          {children}
        </div>
      )
    } //Case 2 : Only one node in children => we add the contentRef to the node
    else {
      children = React.cloneElement(children as ReactElement, {
        ref: contentRef,
      })
    }
  }

  useOnClickOutside(contentRef, () => {
    if (!isTouchable && onClose) {
      onClose(false)
    }
  })

  return (
    <div
      className={cn(css.modal, {
        [mode === "absolute" ? css.absolute : css.fixed]: open,
      })}
      style={
        {
          overflow: `${overflow}`,
          zIndex: zIndex,
          backgroundColor: open
            ? `rgba(0, 0, 0, ${backgroundOpacity})`
            : undefined,
        } as CSSProperties
      }
    >
      <VisibilityTransition
        show={open}
        variant={["fade", "scale"]}
        duration={200}
        transitionProps={{
          unmountOnExit,
          exit: false,
        }}
      >
        {typeof children === "function"
          ? children(contentRef, !!open)
          : children}
      </VisibilityTransition>
    </div>
  )
}

export default Modal
