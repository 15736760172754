import { createApi } from "@reduxjs/toolkit/query/react"

import { getBaseQuery } from "../getBaseQuery"
import { Book } from "~/models/Book"

export type QRCode = {
  title: string
  json_response: {
    url: string
    type: string
    book_id: string
    recipe_id: string
    campaign: string
    channel: string
  }
  branch_url: string
  id: string
  book: string
  page: number
  channel: string
  campaign: string
}

type QRCodeData = {
  data: QRCode
}

interface unlockedBooksResponse {
  data: { id: Book }[]
}

export const bookApi = createApi({
  reducerPath: "bookApi",
  baseQuery: getBaseQuery(""),
  endpoints: builder => ({
    getBookQrCodeData: builder.query<QRCodeData, { uuid: string }>({
      query: ({ uuid }) => ({ url: `/book-qrcodes/${uuid}`, method: "GET" }),
    }),
    getGenericQrCodeData: builder.query<QRCodeData, { shortcode: string }>({
      query: ({ shortcode }) => ({
        url: `/plain-qrcodes/${shortcode}`,
        method: "GET",
      }),
    }),
    getUnlockedBooks: builder.query<unlockedBooksResponse, any>({
      query: () => ({
        url: `/buckets/default/collections/unlocked-recipes/records`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useGetBookQrCodeDataQuery,
  useGetGenericQrCodeDataQuery,
  useGetUnlockedBooksQuery,
} = bookApi
