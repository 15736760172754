import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../store"
import { Subscription } from "~/models/Subscription"

const initialState: Subscription | null = null

const slice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscription(state, action: PayloadAction<Subscription | null>) {
      state = { ...Object(state), ...action.payload }
      return state
    },
    removeSubscription(state) {
      state = initialState
      return state
    },
  },
})

export const subscriptionSelector = (state: RootState): Subscription | null =>
  state.subscriptions

export const { setSubscription, removeSubscription } = slice.actions

export default slice.reducer
