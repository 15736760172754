import React, { CSSProperties } from "react"

import cn from "classnames"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import HomePageSEO from "./HomePageSEO"
import LandingHeader from "./LandingHeader/LandingHeader"
import LandingSlice, { AnyPrismicLandingSlice } from "./LandingSlice"
import { PrismicLandingDataBodyRecipeCarousel } from "./slices/LandingBodyRecipeCarousel"
import { PrismicLandingDataBodyRecipeVideo } from "./slices/LandingBodyRecipeVideo"
import { PrismicLandingDataBodyTextRecipeColumns } from "./slices/LandingBodyTextRecipeColumns"
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb"
import SEO from "~/components/Seo/Seo"
import FullWidthBannerLegacy from "~/components/slices/FullWidthBanner/FullWidthBannerLegacy"
import { TimelineProps } from "~/components/Timeline/Timeline"
import { Container, HrefButton, Row } from "~/components/ui"
import { Theme, ThemeContext } from "~/context/ThemeContext"
import usePrismicPreview from "~/hooks/usePrismicPreview"
import useSiteMetadata from "~/hooks/useSiteMetadata"
import useSpecialLandingPath from "~/hooks/useSpecialLandingPath"
import { CoCreationProject } from "~/models/CoCreationProjects"
import { PartialRecipe, PartialRecipeWithVideo } from "~/models/PartialRecipe"
import {
  PrismicLandingType,
  PrismicLink,
  PrismicStructuredText,
} from "~/models/PrismicTypes"
import { Episode, Show } from "~/models/Show"
import { Video } from "~/models/Video"
import {
  ChefclubLocale,
  getLocaleFromPrismicLocale,
  PrismicLocale,
} from "~/utils/locales"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./Landing.module.scss"

export type PageContextLanding = {
  prismicId: string
}
//TODO : refactor
const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1.toUpperCase().replace("-", "").replace("_", "")
  })
}

const keysToCamel = function (o: any): any {
  if (Object.prototype.toString.call(o).indexOf("Object") > -1) {
    const n: any = {}

    Object.keys(o).forEach((k: any) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (Object.prototype.toString.call(o).indexOf("Array") > -1) {
    return o.map((i: any) => {
      return keysToCamel(i)
    })
  }

  return o
}
export interface LandingPropTypes extends TemplateDefaultPropTypes {
  data: LandingQuery
  pageContext: TemplateDefaultPropTypes["pageContext"] & PageContextLanding
}

function Landing(props: LandingPropTypes) {
  const intl = useIntl()
  const { siteUrl } = useSiteMetadata()
  const [data, isPreview] = usePrismicPreview(props.data)
  const prismicData = data.prismicLanding.data
  const prismicID = props.pageContext.prismicId

  const [currentPageIsMainHome, currentPageIsKidsHome] = [
    prismicID === data.appSettings.web_home_landing,
    prismicID === data.appSettings.web_home_kids_landing,
  ]

  // For breadcrumb
  let parentLanding: ParentLanding | undefined
  const parentsItems = []
  parentLanding = prismicData.parentsLanding?.document
  const specialPath = useSpecialLandingPath(
    intl.locale as ChefclubLocale,
    parentLanding?.prismicId
  )

  while (parentLanding) {
    const { data, uid } = parentLanding
    parentsItems.unshift({
      label: data.title.text || data.metaTitle,
      link:
        specialPath !== null
          ? `/${intl.locale}/${
              specialPath ? intl.formatMessage({ id: specialPath }) : ""
            }`
          : uid
          ? `/${intl.locale}/s/${uid}`
          : undefined,
    })
    parentLanding = data.parent_landing?.document
  }
  // TODO: Move darkTheme & isPreview into a React.Context

  const themeLabelMap: { [K in ThemeLabel]: Theme } = {
    Default: "default",
    Kids: "kids",
    Food: "food",
    Creators: "creators",
  }

  const theme = prismicData.theme ? themeLabelMap[prismicData.theme] : "default"

  const isHidden = data.prismicLanding.tags.includes("hidden")
  const metaNoindex = [
    {
      name: "robots",
      content: "noindex",
    },
  ]

  const hasHeader = !!(
    prismicData.title.text ||
    prismicData.description.text ||
    prismicData.mainImage.fluid
  )

  const { alternateLanguages } = data.prismicLanding

  const generateHref = (lang: PrismicLocale, uid: string) =>
    `${siteUrl}/${getLocaleFromPrismicLocale(lang)}/${
      currentPageIsMainHome ? "" : currentPageIsKidsHome ? "kids" : `s/${uid}`
    }`

  const alternatesLinks = alternateLanguages?.map(alternateLanguage => ({
    rel: "alternate",
    href: generateHref(alternateLanguage.lang, alternateLanguage.uid),
    hreflang: alternateLanguage.lang,
  }))

  return (
    <>
      {currentPageIsMainHome && <HomePageSEO />}
      <SEO
        title={prismicData.metaTitle}
        description={prismicData.metaDescription}
        image={
          prismicData.mainImage?.fluid?.src ||
          prismicData.seoImage?.fluid?.src ||
          ""
        }
        location={props.location}
        link={alternatesLinks}
        {...(isHidden && { meta: metaNoindex })}
      />

      <ThemeContext.Provider value={theme}>
        <div
          className={cn(css.root, `o-landing-${data.prismicLanding.uid}`)}
          style={
            {
              backgroundColor: prismicData.backgroundColor,
              "--current-background-color": prismicData.backgroundColor,
            } as CSSProperties
          }
        >
          {/* TODO: Do prismic migration then remove this banner, it exists as Slice now */}
          {prismicData.headerCover?.fluid && (
            <FullWidthBannerLegacy
              image={prismicData.headerCover}
              title1={prismicData.headerCoverTitle}
            />
          )}

          {parentsItems[0]?.link && parentsItems[0]?.label && (
            <div className={css.breadcrumb}>
              <Breadcrumb
                pageTitle={prismicData.title.text || prismicData.metaTitle}
                multipleParents={parentsItems}
                // Hide the breadcrumb if have a cover
                hide={!!prismicData.headerCover?.fluid}
              />
            </div>
          )}

          <Container>
            <Row className={css.sliceRow}>
              {hasHeader && (
                <LandingHeader
                  title={prismicData.title}
                  description={prismicData.description}
                  image={prismicData.mainImage}
                  isDarkTheme={prismicData.textColor === "Light"}
                  layout={
                    prismicData.headerLayout ===
                    "Centered Title and Text (one col)"
                      ? "centered"
                      : "image"
                  }
                />
              )}

              <LandingSlice
                hasHeader={hasHeader}
                body={prismicData.body}
                isPreview={isPreview}
                isDarkTheme={prismicData.textColor === "Light"}
                recipes={data.allRecipe.nodes}
                lastRecipes={data.lastRecipes.nodes}
                landings={data.allPrismicLanding.nodes}
                orderedLandings={data.orderedPrismicLanding.nodes}
                timelines={data.allTimelines.nodes}
                videos={data.allVideo.nodes}
                verticalRecipes={data.verticalRecipes.nodes}
                lastVerticalRecipes={data.lastVerticalRecipes.nodes}
                landingUID={data.prismicLanding.uid}
                coCreationProjects={data.allPrismicCocreationProject.edges}
                allShows={data.allShows}
                allShowsEpisodes={data.allShowsEpisodes}
                showsMobileHomeVideo={data.showsMobileHomeVideo}
                showsDesktopHomeVideo={data.showsDesktopHomeVideo}
              />
            </Row>
          </Container>

          {/* TODO: Do prismic migration then remove this banner, it exists as Slice now */}
          {prismicData.footerCover.fluid?.src && (
            <div className={css.footer}>
              <FullWidthBannerLegacy
                image={prismicData.footerCover}
                title1={prismicData.footerCoverTitle}
              />
            </div>
          )}

          {prismicData.footerButtonLabel && prismicData.footerLink && (
            <div className={css.stickBanner}>
              <Container className={css.container}>
                <HrefButton
                  {...getPrismicLinkProps(prismicData.footerLink)}
                  color={prismicData.footerButtonColor || "black"}
                  fullWidth
                >
                  {prismicData.footerButtonLabel}
                </HrefButton>
              </Container>
            </div>
          )}
        </div>
      </ThemeContext.Provider>
    </>
  )
}

interface ParentLanding {
  prismicId: string
  uid: string
  data: {
    title: PrismicStructuredText
    metaTitle: string
    parent_landing?: {
      document?: ParentLanding
    }
  }
}
interface ParentsLanding {
  document?: ParentLanding
}
export interface LatestLanding {
  uid: string
  data: {
    metaTitle: string
    meta_description: string
    seoImage: {
      alt: string | null
      gatsbyImageData?: IGatsbyImageData
    }
    type: string
  }
  tags: [string]
}

type ThemeLabel = "Default" | "Kids" | "Food" | "Creators"

type ArrayOfNodes<T> = { nodes: T[] }

export interface PartialLanding {
  uid: string
  data: {
    title: PrismicStructuredText
    body: [
      | PrismicLandingDataBodyRecipeCarousel
      | PrismicLandingDataBodyTextRecipeColumns
      | PrismicLandingDataBodyRecipeVideo
    ]
  }
}

interface LandingQuery {
  allPrismicLanding: ArrayOfNodes<PartialLanding>
  appSettings: {
    web_home_landing: string
    web_home_kids_landing: string
  }
  orderedPrismicLanding: ArrayOfNodes<LatestLanding>
  verticalRecipes: ArrayOfNodes<PartialRecipe>
  lastRecipes: ArrayOfNodes<PartialRecipe>
  lastVerticalRecipes: ArrayOfNodes<PartialRecipe>
  allRecipe: ArrayOfNodes<PartialRecipeWithVideo>
  allVideo: ArrayOfNodes<Video>
  allTimelines: ArrayOfNodes<TimelineProps>
  allPrismicCocreationProject: { edges: CoCreationProject[] }

  allShowsEpisodes: {
    nodes: Episode[]
  }
  allShows: {
    nodes: Show[]
  }
  showsMobileHomeVideo: Video
  showsDesktopHomeVideo: Video

  prismicLanding: {
    uid: string
    tags: [string]
    data: {
      metaTitle: string
      metaDescription: string
      landingType: PrismicLandingType
      theme: ThemeLabel | null
      title: PrismicStructuredText
      description: PrismicStructuredText
      headerLayout:
        | "Title, Text and Image (two cols)"
        | "Centered Title and Text (one col)"
      parentsLanding: ParentsLanding
      mainImage: {
        alt: string | null
        fluid: FluidObject | null
      }
      seoImage: {
        alt: string | null
        fluid: FluidObject | null
      }

      textColor: "Dark" | "Light"
      backgroundColor: CSSProperties["color"]
      headerCover: {
        alt: string | null
        fluid: FluidObject | null
      }
      headerCoverTitle: PrismicStructuredText
      footerCover: {
        alt: string | null
        fluid: FluidObject | null
      }
      footerCoverTitle: PrismicStructuredText

      footerButtonLabel: null | string
      footerButtonColor: null | CSSProperties["color"]
      footerLink: PrismicLink

      body: AnyPrismicLandingSlice[]
    }
    alternateLanguages: {
      lang: PrismicLocale
      uid: string
    }[]
  }
}

export const query = graphql`
  query LandingQuery(
    $id: String
    $lang: String
    $language: String
    $recipeIds: [String]
    $videoIds: [String]
    $landingIds: [String]
    $verticalRecipesIds: [String]
    $timelineIds: [ID]
    $verticaleIdsLatestRecipes: [String]
    $showsMobileHomeVideoId: String
    $showsDesktopHomeVideoId: String
  ) {
    allPrismicLanding(filter: { uid: { in: $landingIds } }) {
      nodes {
        uid
        data {
          title {
            ...PrismicStructuredText
          }
          body {
            ...PrismicLandingDataBodyTextRecipeColumns
            ...PrismicLandingDataBodyRecipeCarousel
          }
        }
      }
    }
    appSettings(lang: { eq: $lang }) {
      web_home_landing
      web_home_kids_landing
    }

    allTimelines: allPrismicTimelineV2(
      filter: { prismicId: { in: $timelineIds } }
    ) {
      nodes {
        ...PrismicTimelineV2
      }
    }

    allRecipe(filter: { id: { in: $recipeIds } }) {
      nodes {
        ...PartialRecipeWithVideo
      }
    }

    verticalRecipes: allRecipe(filter: { id: { in: $verticalRecipesIds } }) {
      nodes {
        ...PartialRecipe
      }
    }

    lastRecipes: allRecipe(
      limit: 5
      filter: { language: { eq: $language }, access_mode: { eq: "public" } }
    ) {
      nodes {
        ...PartialRecipe
      }
    }
    lastVerticalRecipes: allRecipe(
      limit: 5
      filter: {
        language: { eq: $language }
        access_mode: { eq: "public" }
        vertical: { in: $verticaleIdsLatestRecipes }
      }
    ) {
      nodes {
        ...PartialRecipe
      }
    }

    orderedPrismicLanding: allPrismicLanding(
      sort: { fields: first_publication_date, order: DESC }
      filter: { lang: { eq: $lang }, id: { ne: $id } }
    ) {
      nodes {
        data {
          metaTitle: meta_title
          meta_description
          seoImage: seo_image {
            alt
            gatsbyImageData
          }
          type
        }
        uid
        tags
      }
    }

    allVideo(filter: { id: { in: $videoIds } }) {
      nodes {
        ...Video
      }
    }

    allPrismicCocreationProject(
      filter: { lang: { eq: $lang }, data: { is_staging: { eq: false } } }
    ) {
      edges {
        node {
          data {
            title {
              ...PrismicStructuredText
            }
            subtitle {
              ...PrismicStructuredText
            }
            thumbnail {
              gatsbyImageData(layout: CONSTRAINED)
            }
            started
            is_staging
            finished
            conclusion {
              ...PrismicStructuredText
            }
          }
          uid
        }
      }
    }

    allShowsEpisodes: allEpisode(
      sort: { fields: published_date, order: DESC }
      filter: { language: { eq: $language } }
      limit: 10
    ) {
      nodes {
        ...Episode
      }
    }
    allShows: allShow(
      sort: { fields: position, order: ASC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        ...Show
      }
    }
    showsMobileHomeVideo: video(id: { eq: $showsMobileHomeVideoId }) {
      hostedVideoUrl: hosted_video_url
      videoPreviewThumbnail: video_preview_thumbnail
    }
    showsDesktopHomeVideo: video(id: { eq: $showsDesktopHomeVideoId }) {
      hostedVideoUrl: hosted_video_url
      videoPreviewThumbnail: video_preview_thumbnail
    }

    prismicLanding(id: { eq: $id }) {
      _previewable
      uid
      tags
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        landingType: type
        theme

        title {
          ...PrismicStructuredText
        }
        description {
          ...PrismicStructuredText
        }
        headerLayout: header_layout
        #3 parents possible, can be easily extended by adding more deep
        parentsLanding: parent_landing {
          document {
            ... on PrismicLanding {
              prismicId
              uid
              data {
                metaTitle: meta_title
                title {
                  ...PrismicStructuredText
                }
                parent_landing {
                  document {
                    ... on PrismicLanding {
                      prismicId
                      uid
                      data {
                        metaTitle: meta_title
                        title {
                          ...PrismicStructuredText
                        }
                        parent_landing {
                          document {
                            ... on PrismicLanding {
                              prismicId
                              uid
                              data {
                                metaTitle: meta_title
                                title {
                                  ...PrismicStructuredText
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        mainImage: main_image {
          alt
          fluid(maxWidth: 500) {
            ...GatsbyImgixFluid_noBase64
          }
        }
        seoImage: seo_image {
          alt
          fluid(maxWidth: 600) {
            ...GatsbyImgixFluid_noBase64
          }
        }

        textColor: text_color
        backgroundColor: background_color
        headerCover: header_cover {
          alt
          fluid(maxWidth: 1920) {
            ...GatsbyImgixFluid_noBase64
          }
        }
        headerCoverTitle: header_cover_title {
          ...PrismicStructuredText
        }

        footerCover: footer_cover {
          alt
          fluid(maxWidth: 1920) {
            ...GatsbyImgixFluid_noBase64
          }
        }
        footerCoverTitle: footer_cover_title {
          ...PrismicStructuredText
        }

        footerButtonLabel: footer_button_label
        footerButtonColor: footer_button_color
        footerLink: footer_link {
          ...PrismicLink
        }

        ...PrismicLandingBody
      }
      alternateLanguages: alternate_languages {
        lang
        uid
      }
    }
  }
`

export default withPrismicPreview(withTemplate(Landing) as any)
