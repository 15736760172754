import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

/*

this hook will merge preview data from _previewable fragments

*/

const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1: string) => {
    return $1.toUpperCase().replace("-", "").replace("_", "")
  })
}

const keysToCamel = function (o: any): any {
  if (Object.prototype.toString.call(o).indexOf("Object") > -1) {
    const n: any = {}

    Object.keys(o).forEach((k: any) => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (Object.prototype.toString.call(o).indexOf("Array") > -1) {
    return o.map((i: any) => {
      return keysToCamel(i)
    })
  }

  return o
}

export default function usePrismicPreview<T>(staticData: T): [T, boolean] {
  let { data, isPreview } = useMergePrismicPreviewData(staticData as any)

  const isPreviewing = isPreview

  if (
    data?.prismicLanding &&
    "background_color" in data?.prismicLanding?.data
  ) {
    data = keysToCamel(data)
  }

  return [data, isPreviewing]
}

export function createCamelCaseAliases(rootObject: any) {
  const alreadyProcessed = new Set()

  function recursivelyCreateAliases(object: any) {
    // Only process a given object once
    if (alreadyProcessed.has(object)) return
    alreadyProcessed.add(object)

    // Create aliases
    for (const [key, value] of Object.entries(object)) {
      // Create camel case alias for property
      const camelCasedKey = key.replace(/_(\w)/g, (_, letter) =>
        letter.toUpperCase()
      )
      object[camelCasedKey] = value

      // If property is an object, process it as well
      if (value && typeof value === "object") {
        recursivelyCreateAliases(value)
      }
    }
  }

  recursivelyCreateAliases(rootObject)
}
