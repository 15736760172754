import React from "react"

import loadable from "@loadable/component"

import { SliceVideoControls } from "./common/SliceTypes"
import { getVideoControlsProps } from "./common/SliceUtils"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PrismicLink } from "~/models/PrismicTypes"
import Vertical from "~/models/Vertical"
import { Video as IVideo } from "~/models/Video"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

const VideoPlayer = loadable(
  () => import("~/components/VideoPlayer/VideoPlayer")
)

export interface VideoProps {
  mobileVideoUid: string
  desktopVideoUid: string
  link: PrismicLink
  videoControls: SliceVideoControls
  anchorId?: string
}

interface PropTypes extends VideoProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  videos?: IVideo[]
}

function Video({
  videos,
  desktopVideoUid,
  mobileVideoUid,
  link,
  ...props
}: PropTypes) {
  const isCompact = useIsOnCompact()
  if (!videos) return null

  const mobileVideo = videos.find(video => video.id === mobileVideoUid)
  const desktopVideo = videos.find(video => video.id === desktopVideoUid)

  let video: IVideo
  if (isCompact) {
    if (!mobileVideo) return null
    video = mobileVideo
  } else {
    if (!desktopVideo) return null
    video = desktopVideo
  }

  const videoControls = getVideoControlsProps(props.videoControls)

  const Video = (
    <VideoPlayer
      poster={video.videoPreview}
      src={video.hostedVideoUrl}
      verticalColor={Vertical.Original.color}
      format={{ mobile: "square", desktop: "landscape" }}
      {...videoControls}
    />
  )

  if (videoControls?.disableControls) {
    return link ? <a {...getPrismicLinkProps(link)}>{Video}</a> : Video
  }

  // Disable the link if has controls
  return Video
}

export default Video
