import React from "react"

import { FormattedMessage, useIntl } from "react-intl"

import chefclubLogoImage from "../chefclub-logo-fif-white.svg"
import brazilFlagImage from "./assets/flags/brazil.svg"
import franceFlagImage from "./assets/flags/france.svg"
import germanyFlagImage from "./assets/flags/germany.svg"
import italyFlagImage from "./assets/flags/italy.svg"
import mexicoFlagImage from "./assets/flags/mexico.svg"
import spainFlagImage from "./assets/flags/spain.svg"
import unitedKingdomFlagImage from "./assets/flags/united-kingdom.svg"
import unitedStatesFlagImage from "./assets/flags/united-states.svg"
import FooterKlaviyoForm from "./FooterKlaviyoForm/FooterKlaviyoForm"
import AppDownloadButtons from "~/components/AppDownloadButtons/AppDownloadButtons"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import { SiteLinkLists } from "~/gatsby/utils/getSiteLinks"
import {
  facebook,
  instagram,
  pinterest,
  snapchat,
  tiktok,
  youtube,
} from "~/models/SocialNetwork/SocialNetwork"
import { Locale } from "~/types/global-types"
import { getLocaleFromPrismicLocale, PrismicLocale } from "~/utils/locales"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./Footer.module.scss"

interface PropTypes {
  availableLocales: Locale[]
  siteLinkLists: Pick<SiteLinkLists, "footerCol1Body" | "footerCol2Body">
  alternateUrls: any[]
  pageType: "Home" | "Landing" | "Other"
}

function flagImageForLanguageId(languageId: string): string {
  switch (languageId) {
    case "pt-br":
      return brazilFlagImage
    case "fr":
      return franceFlagImage
    case "de":
      return germanyFlagImage
    case "it":
      return italyFlagImage
    case "es-mx":
      return mexicoFlagImage
    case "es-es":
      return spainFlagImage
    case "en-gb":
      return unitedKingdomFlagImage
    case "en-us":
      return unitedStatesFlagImage
  }

  console.warn(`Footer.tsx: unknown language id “${languageId}”`)
  return ""
}

export default function Footer({
  availableLocales,
  siteLinkLists,
  alternateUrls: alternateUrlsProp,
  pageType,
}: PropTypes) {
  const intl = useIntl()
  const currentYear = new Date().getFullYear()
  const { footerCol1Body, footerCol2Body } = siteLinkLists

  // Social network links
  const allSocialNetworks = [
    facebook,
    youtube,
    instagram,
    snapchat,
    tiktok,
    pinterest,
  ]

  const availableSocialNetworks = allSocialNetworks.filter(
    socialNetwork =>
      intl.formatMessage({ id: socialNetwork.accountURLMessageId }) !== "#none"
  )

  const currentPageIsLanding = pageType === "Landing"
  const alternateURLS =
    alternateUrlsProp && currentPageIsLanding
      ? availableLocales.map(locale =>
          alternateUrlsProp.filter(
            (alternateUrl: { lang: PrismicLocale }) =>
              getLocaleFromPrismicLocale(alternateUrl.lang) === locale.id
          )
        )
      : alternateUrlsProp &&
        availableLocales.map(locale =>
          alternateUrlsProp.filter(
            (alternateUrl: { language: string }) =>
              alternateUrl.language === locale.id
          )
        )

  // Render
  return (
    <footer className={css.footer}>
      {/* Main section */}
      <div className={css.mainSection}>
        <img
          src={chefclubLogoImage}
          alt="Chefclub logo"
          className={css.logo}
          width={303}
          height={119}
        />

        <div className={css.storeBadges}>
          <AppDownloadButtons height={50} />
        </div>

        <div className={css.socialNetworks}>
          {availableSocialNetworks.map(socialNetwork => (
            <a
              className={css.socialNetwork}
              key={socialNetwork.nameMessageId}
              href={intl.formatMessage({
                id: socialNetwork.accountURLMessageId,
              })}
            >
              <ColorableSVG
                href={socialNetwork.colorableIcon}
                mainColor="white"
                width="26"
                height="26"
                title={intl.formatMessage({ id: socialNetwork.nameMessageId })}
              />
            </a>
          ))}
        </div>

        <FooterKlaviyoForm />
      </div>

      {/* Link lists */}
      <ul className={css.siteLinkListsSection}>
        <li className={css.siteLinkList}>
          <div className={css.listTitle}>
            <FormattedMessage id="footer/section:videos-and-recipes" />
          </div>
          <ul className={css.listContent}>
            {footerCol1Body.map((slice, i) => (
              <li key={i}>
                <a {...getPrismicLinkProps(slice.primary.link)}>
                  {slice.primary.text.text}
                </a>
              </li>
            ))}
          </ul>
        </li>

        <li className={css.siteLinkList}>
          <div className={css.listTitle}>
            <FormattedMessage id="footer/section:who-are-we" />
          </div>
          <ul className={css.listContent}>
            {footerCol2Body.map((slice, i) => (
              <li key={i}>
                <a {...getPrismicLinkProps(slice.primary.link)}>
                  {slice.primary.text.text}
                </a>
              </li>
            ))}
          </ul>
        </li>

        <li className={css.siteLinkList}>
          <div className={css.listTitle}>
            <FormattedMessage id="footer/section:our-languages" />
          </div>
          <ul className={css.listContent}>
            {availableLocales.map((locale, idx) => {
              // Matching locale with alternate language if existing
              return (
                <li key={locale.id}>
                  <Link
                    to={
                      alternateURLS &&
                      currentPageIsLanding &&
                      alternateURLS[idx][0]?.uid
                        ? `/${locale.id}/s/${alternateURLS[idx][0].uid}`
                        : alternateURLS && alternateURLS[idx][0]?.absolute_url
                        ? alternateURLS[idx][0]?.absolute_url
                        : `/${locale.id}/`
                    }
                    absoluteLink
                  >
                    <img
                      loading="lazy"
                      className={css.languageFlag}
                      src={flagImageForLanguageId(locale.id)}
                      alt=""
                      width="17px"
                      height="17px"
                    />
                    {locale.name}
                  </Link>
                </li>
              )
            })}
          </ul>
        </li>
      </ul>

      {/* Legal */}
      <div className={css.legalSection}>
        © Copyright {currentYear} Chefclub Network.{" "}
        <span className={css.allRightsReserved}>
          <Link to="legal-notices">
            <FormattedMessage id="text:all-rights-reserved" />
          </Link>
        </span>
        <div>
          <a href="https://www.iubenda.com/privacy-policy/48780379">
            Privacy Policy
          </a>
          {" • "}
          <a href="https://www.iubenda.com/privacy-policy/48780379/cookie-policy">
            Cookie Policy
          </a>
          {" • "}
          <a href={`/sitemap-${intl.locale.replace("-", "")}.xml`}>Sitemap</a>
        </div>
      </div>
    </footer>
  )
}
