import Vertical from "~/models/Vertical"
import { Colors, TextColor } from "~/types/global-types"

const verticalIds = Object.values(Vertical).map(vertical => vertical.id)
const grayColors = Array.from({ length: 7 }, (_, i) => `gray${i + 1}`) // => ["gray1", "gray2", ...]
const specialColors = [
  ...verticalIds,
  ...grayColors,
  "creators",
  "white",
  "inherit",
  "initial",
  "body",
  "success",
  "error",
]

export const formatColor = (color: Colors | TextColor): string => {
  const isSpecialColors = color && specialColors.includes(color)
  return !color ? "" : isSpecialColors ? `var(--${color}-color)` : color
}
