import { CreatePageCustomArguments } from "../types/global-types"
import isProduction from "../utils/isProduction"

export type Translator = (messageId: string) => string | null
export type PathResolver = ({
  route,
  translate,
  params,
}: {
  route: Route
  translate: Translator
  params: any
}) => string | null

export interface RouteOptions {
  id: string
  resolvePath?: PathResolver
  isOptional?: boolean
  disabledInProduction?: boolean
  disabledInDev?: boolean
  children?: Route[]
}

export default abstract class Route {
  id: string
  resolvePath: PathResolver | null
  isOptional: boolean
  children: Route[]
  parent: Route | null
  disabledInProduction: boolean
  disabledInDev: boolean

  constructor({
    id,
    resolvePath,
    isOptional = false,
    disabledInProduction = false,
    disabledInDev = false,
    children = [],
  }: RouteOptions) {
    this.id = id
    this.resolvePath = resolvePath ?? null
    this.isOptional = isOptional
    this.disabledInProduction = disabledInProduction
    this.disabledInDev = disabledInDev
    this.children = children
    this.parent = null

    this.children.forEach(child => (child.parent = this))
  }

  isRoot() {
    return this.parent === null
  }

  isLeaf() {
    return this.children.length === 0
  }

  getDescendantsAndSelf(): Route[] {
    const descendants = this.children.flatMap(child =>
      child.getDescendantsAndSelf()
    )

    return [this, ...descendants]
  }

  // Returns true if full path is completely literal
  isFullyLiteral(): boolean {
    const parentIsLiteral = this.parent ? this.parent.isFullyLiteral() : true

    return parentIsLiteral && this.resolvePath === null
  }

  getLocalPath(translate: Translator, params?: any): string | null {
    const localPath = this.resolvePath
      ? this.resolvePath({
          route: this,
          translate,
          params,
        })
      : this.id

    if (localPath === "") {
      // TODO: Also disallow empty local path for client-side routes. Will require rethinking their structure a bit.
      if (!(this as any).__allowEmptyPath) {
        throw new Error(
          `Route ${this.getFullIdString()} has an empty local path`
        )
      }
    }

    return localPath
  }

  getFullPath(translate: Translator, params?: any): string | null {
    const localPath = this.getLocalPath(translate, params)

    if (localPath == null) {
      return null
    }

    if (this.parent) {
      const parentPath = this.parent.getFullPath(translate, params)

      return parentPath ? `${parentPath}${localPath}/` : null
    }

    return localPath + "/"
  }

  getId(): string {
    return this.id
  }

  getFullId(): string[] {
    if (this.parent) {
      return [...this.parent.getFullId(), this.id]
    }
    return [this.id]
  }

  getFullIdString(): string {
    return "/" + this.getFullId().join("/")
  }

  // Whether this route is active in the current environment
  get isActive(): boolean {
    const isDisabled =
      (this.disabledInProduction && isProduction()) ||
      (this.disabledInDev && process.env.NODE_ENV === "development")
    return !isDisabled
  }

  abstract create({
    defaultContext,
    createPage,
  }: CreatePageCustomArguments): Promise<void>
}
