import React, { CSSProperties, HTMLProps, ReactNode } from "react"

import cn from "classnames"
import { FluidObject } from "gatsby-image"

import { BalanceText, Body, Heading } from "~/components/ui"

import css from "./SimpleBanner.module.scss"

interface SimpleBannerProps {
  linkProps?: HTMLProps<HTMLAnchorElement>
  title?: string
  description?: string
  buttonLabel?: string
  backgroundColor?: CSSProperties["color"]
  bodyColor?: CSSProperties["color"]
  layout?: "Image, text" | "Text, image"
  image?: {
    dimensions: {
      height: number
      width: number
    }
    fluid: null | FluidObject
  }
  imageAsChildren?: ReactNode
  center?: boolean
}

interface PropTypes extends SimpleBannerProps {
  isLeadingSlice?: boolean
}

function SimpleBanner({ isLeadingSlice, ...props }: PropTypes) {
  const bodyColor = props?.bodyColor || "#ffffff"
  const backgroundColor = props?.backgroundColor || "#74CDFF"
  return (
    <a
      data-nosnippet
      className={css.banner}
      {...props.linkProps}
      style={{
        backgroundColor,
        flexDirection: props.layout === "Image, text" ? "row" : "row-reverse",
      }}
    >
      {props.image?.fluid ? (
        <span
          className={css.icon}
          style={{
            backgroundImage: `url("${props.image.fluid?.src || ""}")`,
            ...props.image.dimensions,
          }}
        />
      ) : (
        <div className={css.iconWrap}>{props.imageAsChildren}</div>
      )}

      <div className={cn(css.content, { [css.center]: !!props.center })}>
        {!!props.title && (
          <Heading
            Tag={isLeadingSlice ? "h1" : undefined}
            className={css.title}
            variant="medium"
            color={bodyColor}
          >
            {props.title}
          </Heading>
        )}

        {!!props.description && (
          <BalanceText>
            {({ className }) => (
              <Body
                className={className}
                variant="body3"
                semiBold
                noMargin
                color={bodyColor}
              >
                {props.description}
              </Body>
            )}
          </BalanceText>
        )}

        {!!props.buttonLabel && (
          <Body variant="body3" className={css.link} color={bodyColor}>
            {props.buttonLabel}
          </Body>
        )}
      </div>
    </a>
  )
}

export default SimpleBanner
