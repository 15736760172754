import { createIntlCache } from "@formatjs/intl"
import { createIntl, IntlShape } from "react-intl"

import deMessages from "../data/locales/de.json"
import enGbMessages from "../data/locales/en-gb.json"
import enUsMessages from "../data/locales/en-us.json"
import esEsMessages from "../data/locales/es-es.json"
import esMxMessages from "../data/locales/es-mx.json"
import frMessages from "../data/locales/fr.json"
import itMessages from "../data/locales/it.json"
import ptBrMessages from "../data/locales/pt-br.json"

export type ChefclubLocale =
  | "fr"
  | "de"
  | "it"
  | "en-gb"
  | "en-us"
  | "es-es"
  | "es-mx"
  | "pt-br"

export type PrismicLocale =
  | "fr-fr"
  | "de-de"
  | "it-it"
  | "en-gb"
  | "en-us"
  | "es-es"
  | "es-mx"
  | "pt-br"

type LocaleAlias = { [key in ChefclubLocale]: PrismicLocale }

const localeCodeAlias: LocaleAlias = {
  fr: "fr-fr",
  de: "de-de",
  it: "it-it",
  "en-gb": "en-gb",
  "en-us": "en-us",
  "es-es": "es-es",
  "es-mx": "es-mx",
  "pt-br": "pt-br",
}

const messagesById = {
  de: deMessages,
  "en-gb": enGbMessages,
  "en-us": enUsMessages,
  "es-es": esEsMessages,
  "es-mx": esMxMessages,
  fr: frMessages,
  it: itMessages,
  "pt-br": ptBrMessages,
}

const cache = createIntlCache()

export function getIntl(locale: ChefclubLocale): IntlShape {
  return createIntl({ locale, messages: messagesById[locale] }, cache)
}

export function getPrismicLocaleFromLocale(
  chefclubLocale: ChefclubLocale
): PrismicLocale {
  const matchingPrismicLocale = Object.entries(localeCodeAlias).find(
    locale => locale[0] === chefclubLocale
  )

  if (!matchingPrismicLocale) {
    throw new Error(
      `utils/locales.ts: cannot find locale for ${chefclubLocale}`
    )
  }

  return matchingPrismicLocale[1] as PrismicLocale
}

export function getLocaleFromPrismicLocale(
  prismicLocale: PrismicLocale
): ChefclubLocale {
  const matchingLocale = Object.entries(localeCodeAlias).find(
    locale => locale[1] === prismicLocale
  )

  if (!matchingLocale) {
    return "fr"
  }

  return matchingLocale[0] as ChefclubLocale
}

export const allLocales: Map<ChefclubLocale, string> = new Map([
  ["fr", "Français"],
  ["de", "Deutsch"],
  ["it", "Italiano"],
  ["en-gb", "English (UK)"],
  ["en-us", "English (USA)"],
  ["es-es", "Español"],
  ["es-mx", "Mexicano"],
  ["pt-br", "Português (Brasil)"],
])
export const chefclubLocaleArray = [
  "fr",
  "de",
  "it",
  "en-us",
  "en-gb",
  "es-es",
  "es-mx",
  "pt-br",
]

export const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en-us"
  }

  let languages = navigator.languages ?? [navigator.language]
  languages = languages.map((lang: string) => lang.toLowerCase())

  if (!languages) return "en-us"

  let chefclubSuitableLanguage

  for (const lang of languages) {
    if (chefclubLocaleArray.includes(lang)) {
      chefclubSuitableLanguage = lang
      break
    } else if (chefclubLocaleArray.includes(lang.split("-")[0])) {
      chefclubSuitableLanguage = lang.split("-")[0]
      break
    } else if (lang.split("-")[0] == "es") {
      chefclubSuitableLanguage = "es-es"
      break
    }
  }

  return chefclubSuitableLanguage ?? "en-us"
}

export const getLocaleFromLocation = () => {
  const locale = window.location.pathname.split("/")[1] ?? "en-us"
  const chefclubLocale = chefclubLocaleArray.includes(locale) ? locale : "en-us"
  return chefclubLocale
}
