import { createApi } from "@reduxjs/toolkit/query/react"

import { getBaseQuery } from "../getBaseQuery"
import { CreatorTags } from "~/hooks/useCreators"
import { ChefclubLocale } from "~/utils/locales"

interface CreatorTagsRequest {
  lang: ChefclubLocale
}

const baseUrl = "/user-tags"

export const userTagsApi = createApi({
  reducerPath: "userTagsApi",
  baseQuery: getBaseQuery(""),
  endpoints: builder => ({
    getCreatorTags: builder.query<CreatorTags[] | [], CreatorTagsRequest>({
      query: ({ lang }) => ({
        url: `${baseUrl}?lang=${lang}`,
        method: "GET",
      }),
    }),
  }),
})

export const { useGetCreatorTagsQuery } = userTagsApi
