import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface PrismicShowsHomeQuery {
  appSettings: {
    shows_mobile_home_video_id: string
    shows_desktop_home_video_id: string
  }
}

export default async function createShowsHomePage(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, reporter, createPage, currentLocale, defaultContext } = args

  const result = await graphql<PrismicShowsHomeQuery>(
    `
      query createShowsHomePage($prismicLocale: String) {
        appSettings(lang: { eq: $prismicLocale }) {
          shows_mobile_home_video_id
          shows_desktop_home_video_id
        }
      }
    `,
    {
      prismicLocale: currentLocale.prismicCode,
    }
  )

  try {
    createPage({
      context: {
        ...defaultContext,
        mobileHomeVideoId: result.data?.appSettings.shows_mobile_home_video_id,
        desktopHomeVideoId:
          result.data?.appSettings.shows_desktop_home_video_id,
      },
    })
  } catch (e) {
    reporter.error(`Error creating the ${currentLocale.name} Shows homepage`)
  }
}
