import algoliasearch from "algoliasearch/lite"
import algoliasearchHelper, {
  SearchParameters,
  SearchResults,
} from "algoliasearch-helper"

import { Category } from "../../models/Category"
import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { AllCategories, getAllCategories } from "./utils/algoliaUtils"

type Ingredient = { slug: string; language: string; name: string }
export interface IngredientQuery {
  allIngredients: {
    nodes: Ingredient[]
  }
}

const GATSBY_ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY ?? ""
const GATSBY_ALGOLIA_APPLICATION_ID =
  process.env.GATSBY_ALGOLIA_APPLICATION_ID ?? ""
const GATSBY_ALGOLIA_INDEX = process.env.GATSBY_ALGOLIA_INDEX ?? ""

const searchClient = algoliasearch(
  GATSBY_ALGOLIA_APPLICATION_ID,
  GATSBY_ALGOLIA_API_KEY
)

export default async function createIngredientSitemapPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, defaultContext, createPage, currentLocale } = args

  const ingredientsOfLocaleQuery = await graphql<IngredientQuery>(
    `
      query getIngredients {
        allIngredients(filter: {locale: {eq: "${currentLocale.id}"}}){
          nodes {
            slug
            name
            language
          }
        }
      }
    `
  )

  const { categories } = await getAllCategories({
    graphql,
    currentLocale,
  })

  ingredientsOfLocaleQuery?.data?.allIngredients.nodes.length &&
    (await Promise.all(
      ingredientsOfLocaleQuery?.data?.allIngredients.nodes.map(
        async (ingredient: Ingredient) => {
          const fullPath = ingredient.slug

          const { content } = await getAlgoliaSearchResults(
            ingredient,
            categories,
            `language:${ingredient.language}`
          )

          const nbHits = content.hits.length

          const hitsByCategory: {
            [key: string]: { category: Category; hits: any[] }
          } = {}
          //Convert categories_tree from id to array of Category object
          //For each hit we parse the last category id and convert it to corresponding Category object
          content.hits.forEach(hit => {
            if (!Object.keys(hit.categories_tree).length) return
            const lastLvl =
              hit.categories_tree[
                `lvl${Object.keys(hit.categories_tree).length - 1}`
              ]

            const lastLvlIds = []
            Array.isArray(lastLvl)
              ? lastLvlIds.push(...lastLvl)
              : lastLvlIds.push(lastLvl)

            lastLvlIds.forEach(lastLvlId => {
              const levelLastId = lastLvlId.split(" > ").slice(-1)[0] // /!\ To change if we allow to have multiple categories
              const levelLastCategory = categories[levelLastId]
              //console.log("levelLastCategory", levelLastCategory)

              if (levelLastCategory) {
                if (!hitsByCategory[levelLastCategory.id]) {
                  hitsByCategory[levelLastCategory.id] = {
                    category: levelLastCategory,
                    hits: [],
                  }
                }
                hitsByCategory[levelLastCategory.id].hits.push(hit)
              }
            })
          })

          const context = {
            slug: fullPath,
            name: ingredient.name,
            ingredientValue: ingredient.slug,
            nbHits: nbHits,
            hitsByCategory: JSON.stringify(hitsByCategory),
          }

          if (fullPath != "") {
            createPage({
              routeParams: { ingredient: ingredient.slug },
              context: {
                ...defaultContext,
                ...context,
              },
            })
          }
        }
      )
    ))
}

const getAlgoliaSearchResults = (
  ingredient: Ingredient,
  categories: AllCategories,
  filters?: string
): Promise<{ content: SearchResults; state: SearchParameters }> => {
  const helper = algoliasearchHelper(searchClient, GATSBY_ALGOLIA_INDEX, {
    facets: ["star_ingredients.slug"],
    attributesToRetrieve: [
      "absolute_url",
      "web_cover_thumbnail",
      "title",
      "subtitle",
      "id",
      "author",
      "vertical",
      "categories_tree",
    ],
    hitsPerPage: 1000,
  })

  if (ingredient?.slug) {
    helper.addFacetRefinement("star_ingredients.slug", ingredient.slug)
  }

  return helper.searchOnce({ filters: filters })
}
