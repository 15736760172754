import React, { useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import LatestLandingsByTagsSmallCard from "./Cards/LatestLandingsByTagsSmallCard"
import FilterByTag from "~/components/FilterByTag/FilterByTag"
import Link from "~/components/Link/Link"
import { Col, Row } from "~/components/ui"
import { LatestLanding } from "~/templates/Landing/Landing"

import css from "./LatestLandingsByTags.module.scss"

interface tags {
  tag_color: string
  tag_name: string
}

export interface LatestLandingsByTagsProps {
  isDarkTheme?: boolean
  landings?: LatestLanding[]
  items?: tags[]
  primary: {
    allLandingsTagName: string
    allLandingsTagColor: string
    allFilteredBySelectedTags: boolean
    maxLandingsDisplayed?: number
    anchorId?: string
  }
  eagerImages?: boolean
}

function LatestLandingsByTags({
  landings,
  isDarkTheme,
  items,
  primary,
  eagerImages,
}: LatestLandingsByTagsProps) {
  const intl = useIntl()
  const [filteredLandings, setFilteredLandings] = useState(landings ?? [])

  const allTags =
    items?.map(tag => {
      return { translation: tag.tag_name, slug: tag.tag_name }
    }) ?? []

  // Filter landings by tag and omit those who don't have images
  // Trim function added to prevent bugs due to typo trailing spaces on Prismic
  const filterByTag = (landings: LatestLanding[], tag: string) => {
    return landings.filter(landing => {
      return landing.tags.includes(tag.trim())
    })
  }

  // Display Primic's maxLandingsDisplayed if filteredLandings.length exceeds it (to avoid incoherent display)
  const itemsCount =
    primary?.maxLandingsDisplayed &&
    filteredLandings &&
    filteredLandings.length > primary.maxLandingsDisplayed
      ? primary.maxLandingsDisplayed
      : filteredLandings?.length

  const landingsAmount = intl.formatMessage(
    {
      id: "tags/text:articles-amount",
    },
    {
      count: itemsCount,
    }
  )

  const landingsWithImages = landings?.filter(landing => {
    return landing.data.seoImage.gatsbyImageData
  })

  return (
    <div
      className={cn(css.root, {
        [css.darkTheme]: isDarkTheme,
      })}
    >
      <div className={css.filterContainer}>
        <FilterByTag
          tags={allTags}
          items={landingsWithImages ?? []}
          itemsCount={landingsAmount}
          setFilteredItems={setFilteredLandings}
          filteringLogic={filterByTag}
          isDarkTheme={isDarkTheme}
          allFilteredBySelectedTags={primary.allFilteredBySelectedTags}
        />
      </div>
      <Row>
        <Col width={[12, 12]} className={css.content}>
          {filteredLandings
            ?.slice(0, primary.maxLandingsDisplayed ?? 4)
            .map((landing, index) => {
              return (
                <div
                  key={landing.uid}
                  className={css.landingItem}
                  id={`landing-${index + 1}`}
                >
                  <Link to="s/landing" params={{ uid: landing.uid }}>
                    <LatestLandingsByTagsSmallCard
                      tag={landing.tags[0] ?? primary.allLandingsTagName}
                      landing={landing}
                      isDarkTheme={isDarkTheme}
                      tagColor={
                        items?.find(item => item.tag_name === landing.tags[0])
                          ?.tag_color ?? primary.allLandingsTagColor
                      }
                      eagerImages={eagerImages}
                    />
                  </Link>
                </div>
              )
            })}
        </Col>
      </Row>
    </div>
  )
}

export default LatestLandingsByTags
