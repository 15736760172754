import { PublicUser, Tag } from "./PublicUser"
import { UserTypeId } from "./UserType"

export interface User extends PublicUser {
  email: string
  firstName: string
  lastName: string
  lastModified: number
  isPremium: boolean
  countRecipesFavorites: number
  biography: string
  instagramProfile: string
  userTypes: UserTypeId[]
  chosen_lang?: string
  chosen_country?: string
}

// From django API
export interface ApiUser {
  id?: string
  user_id?: string
  email: string
  username?: string
  first_name: string
  last_name: string
  profile_picture: string
  tags?: Tag[]
  last_recipe_creation_date?: string | null
  last_modified: number
  cover_image?: string | null
  is_premium: boolean
  profile_pic: string
  posts_count: number
  followed_by_viewer: boolean
  followers_count: number
  follows_count: number
  count_recipes_favorites: number
  biography: string
  instagram_profile: string
  user_types: UserTypeId[]
  chosen_lang?: string
  chosen_country?: string
  authored_recipes_count: number
  tos_accepted?: string
  creators_t_c_accepted?: boolean | null
  // gender: null
  // phone_number: null
  // locale	"fr-fr"
  // chosen_lang	null
  // chosen_country	null
  // lang	"fr"
  // country	"fr"
  // created	"2020-01-27T14:34:56.320812Z"
  // is_premium	true
  // is_optin_newsletter	null
  // is_optin_newsletter_confirmed	null
  // email_validated	true
}

// backward compatibility with the Django frontend (localStorage)
export interface LegacyUser {
  id: string
  email: string
  firstname: string | null
  lastname: string | null
  profile_picture: string
  last_modified: number
  is_premium: boolean
}

export const apiUserToUser = ({
  id,
  user_id,
  first_name,
  last_name,
  username = "",
  email,
  profile_picture,
  profile_pic,
  last_modified,
  is_premium,
  posts_count,
  followed_by_viewer,
  followers_count,
  follows_count,
  count_recipes_favorites,
  biography = "",
  instagram_profile,
  user_types,
  chosen_lang,
  chosen_country,
  authored_recipes_count,
  cover_image,
  tags,
  last_recipe_creation_date,
}: ApiUser): User => ({
  id: id || user_id || "",
  lastName: last_name,
  firstName: first_name,
  username,
  email,
  profilePicture: profile_picture || profile_pic,
  lastModified: last_modified,
  isPremium: is_premium,
  postsCount: posts_count || 0,
  followedByViewer: followed_by_viewer,
  followersCount: followers_count || 0,
  followsCount: follows_count || 0,
  countRecipesFavorites: count_recipes_favorites || 0,
  biography,
  instagramProfile: instagram_profile || "",
  userTypes: user_types || [],
  authoredRecipeCount: authored_recipes_count,
  chosen_lang: chosen_lang,
  chosen_country: chosen_country,
  coverImage: cover_image,
  tags,
  lastRecipeCreationDate: last_recipe_creation_date,
})

export const userToLegacyUser = ({
  id,
  firstName,
  lastName,
  email,
  profilePicture,
  isPremium,
  lastModified,
}: User): LegacyUser => ({
  id,
  firstname: firstName,
  lastname: lastName,
  email,
  profile_picture: profilePicture ?? "",
  last_modified: lastModified,
  is_premium: isPremium,
})
