import { graphql, useStaticQuery } from "gatsby"
import { PrismicDate } from "prismic-reactjs"

import { findCurrentImage } from "~/components/Banner/utils"
import { PrismicLink } from "~/models/PrismicTypes"
import { PrismicLocale } from "~/utils/locales"

export type PrismicModals = {
  lang: string
  data: {
    bannerList?: PrismicModal[]
    defaultBannerLink: PrismicLink
    defaultDesktopBanner: { thumbnails: { Modal: { url: string } } }
    defaultMobileBanner: { thumbnails: { Modal: { url: string } } }
    defaultBannerAlt: string
  }
}

export type PrismicModal = {
  desktopImage: { thumbnails: { Modal: { url: string } } }
  mobileImage: { thumbnails: { Modal: { url: string } } }
  bannerAlt: string
  linkTo: PrismicLink
  fromDate: PrismicDate
  toDate: PrismicDate
}

export type formattedModal = {
  desktopImageUrl: string
  mobileImageUrl: string
  modalLink: PrismicLink
}

export const useModal = (locale: PrismicLocale): formattedModal | null => {
  const { modalList }: { modalList: { nodes: PrismicModals[] } | null } =
    useStaticQuery(graphql`
      {
        modalList: allPrismicBanner(
          filter: { data: { banner_type: { eq: "modal" } } }
        ) {
          nodes {
            lang
            data {
              defaultDesktopBanner: default_desktop_banner {
                thumbnails {
                  Modal {
                    url
                  }
                }
              }
              defaultMobileBanner: default_mobile_banner {
                thumbnails {
                  Modal {
                    url
                  }
                }
              }
              defaultBannerLink: default_banner_link {
                ...PrismicLink
              }
              bannerList: banner_list {
                desktopImage: desktop_image {
                  thumbnails {
                    Modal {
                      url
                    }
                  }
                }
                mobileImage: mobile_image {
                  thumbnails {
                    Modal {
                      url
                    }
                  }
                }
                linkTo: link_to {
                  ...PrismicLink
                }
                fromDate: from_date
                toDate: to_date
              }
            }
          }
        }
      }
    `)

  if (!modalList) return null
  const localeModal = modalList.nodes.find(node => node.lang === locale)
  if (!localeModal) return null

  const currentModal: PrismicModal = findCurrentImage(localeModal)
  if (currentModal)
    return {
      desktopImageUrl: currentModal.desktopImage.thumbnails.Modal.url,
      mobileImageUrl: currentModal.mobileImage.thumbnails.Modal.url,
      modalLink: currentModal.linkTo,
    }

  return {
    desktopImageUrl: localeModal.data.defaultDesktopBanner.thumbnails.Modal.url,
    mobileImageUrl: localeModal.data.defaultMobileBanner.thumbnails.Modal.url,
    modalLink: localeModal.data.defaultBannerLink,
  }
}
