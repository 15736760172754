import { NavigateOptions } from "@reach/router"
import { navigate as GatsbyNavigate } from "gatsby"

export const navigate = (
  to: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  options?: NavigateOptions<{}> | undefined
) => {
  if (/\?|\#/.test(to)) {
    window.location.href = to
  } else {
    GatsbyNavigate(to, options)
  }
}
