import React, { useEffect, useMemo, useState } from "react"

import algoliasearch from "algoliasearch/lite"
import { SearchState } from "react-instantsearch-core"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import { useIntl } from "react-intl"

import closeIcon from "./assets/close.svg"
import NoSearchResult from "./NoSearchResults/NoSearchResult"
import chefclubLogoImage from "~/components/Layout/chefclub-logo-fif-black.svg"
import SearchResultHits from "~/components/Layout/SearchLayout/Algolia/SearchResultsHits/SearchResultHits"
import SimpleSearchBox from "~/components/Layout/SearchLayout/Algolia/SimpleSearchBox/SimpleSearchBox"
import LegacyLink from "~/components/LegacyLink/LegacyLink"
import Separator from "~/components/Separator/Separator"
import { Body } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import { SearchSuggestions } from "~/gatsby/utils/getSearchSuggestions"
import { isDev, isProd } from "~/utils/general"

import css from "./SearchLayout.module.scss"

const GATSBY_ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY ?? ""
const GATSBY_ALGOLIA_APPLICATION_ID =
  process.env.GATSBY_ALGOLIA_APPLICATION_ID ?? ""
const GATSBY_ALGOLIA_INDEX = process.env.GATSBY_ALGOLIA_INDEX ?? ""

type propType = {
  isOpen: boolean
  close: () => void
  searchSuggestions: SearchSuggestions
}

const SearchLayout = ({ isOpen, close, searchSuggestions }: propType) => {
  const intl = useIntl()
  const logoColor = isDev
    ? "var(--daily-color)"
    : isProd
    ? "black"
    : "var(--original-color)"
  const searchClient = useMemo(
    () => algoliasearch(GATSBY_ALGOLIA_APPLICATION_ID, GATSBY_ALGOLIA_API_KEY),
    []
  )

  const [searchState, setSearchState] = useState<SearchState>({ query: "" })

  const [focusSearchBox, setFocusSearchBox] = useState(false)

  useEffect(() => {
    //On empèche le scroll du body
    const body = document.querySelector("body")
    if (body) {
      body.style.overflow = isOpen ? "hidden" : ""
    }

    if (searchState.query == "") {
      const urlParams = new URLSearchParams(window.location.search)
      const research = urlParams.get("q")
      if (research) {
        setSearchState({ query: research })
      }
    }
  }, [isOpen])

  const handleHitEnterKey = async () => {
    if (searchState.query) {
      const path = window.location.pathname
      await navigate(
        `/${intl.locale}/${intl.formatMessage({ id: "path:search" })}/?q=` +
          searchState.query
      )
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={GATSBY_ALGOLIA_INDEX}
      searchState={searchState}
      onSearchStateChange={setSearchState}
    >
      <Configure filters={"language:" + intl.locale} hitsPerPage={20} />
      <div className={css.searchRoot}>
        <div className={css.quitZone} onClick={close} />
        <div className={css.searchHeader}>
          <div className={css.logo}>
            <LegacyLink componentMessageIds={[]}>
              <img src={chefclubLogoImage} alt="Chefclub" />
            </LegacyLink>
          </div>
          <div className={css.searchInput}>
            <SimpleSearchBox
              onHitEnter={handleHitEnterKey}
              setOnFocus={setFocusSearchBox}
            />
            <div
              className={`${searchState.query === "" ? css.hidden : ""} ${
                css.resetBtn
              }`}
              onClick={() => setSearchState({ query: "" })}
            />
          </div>
          <div className={css.cancel} onClick={close}>
            <Body color="original" variant="body2" semiBold>
              {intl.formatMessage({
                id: "category/text:search-cancel",
              })}
            </Body>
            <img src={closeIcon} alt="close" />
          </div>
        </div>
        <Separator noMargin />
        <div className={css.resultsContainer}>
          <div className={css.results}>
            {!searchState?.query?.length ? (
              <NoSearchResult searchSuggestions={searchSuggestions} />
            ) : (
              <SearchResultHits
                query={searchState.query ?? ""}
                focusSearchBox={focusSearchBox}
              />
            )}
          </div>
        </div>
      </div>
    </InstantSearch>
  )
}

export default SearchLayout
