import React, { CSSProperties, HTMLProps } from "react"

import cn from "classnames"

import notConnectedIcon from "./notConnected.svg"
import { PartialPublicUser } from "~/models/PublicUser"
import { Colors } from "~/types/global-types"
import { formatColor } from "~/utils/colors"

import css from "./Avatar.module.scss"

type AvatarVariants =
  | "extraExtraSmall"
  | "extraSmall"
  | "small"
  | "medium"
  | "large"
  | "extraLarge"

type ResponsiveAvatarVariant = {
  mobile: AvatarVariants
  desktop: AvatarVariants
}

type Variant = AvatarVariants | ResponsiveAvatarVariant

const isResponsiveAvatarVariant = (
  arg: any
): arg is ResponsiveAvatarVariant => {
  return !!arg.mobile && !!arg.desktop
}

type VariantSize = { size: string; fontSize: string }
type ResponsiveVariantSize = { mobile: VariantSize; desktop: VariantSize }

const isResponsiveVariantSize = (arg: any): arg is ResponsiveVariantSize => {
  return !!arg.mobile && !!arg.desktop
}

const VARIANTS_SIZES: {
  [key in AvatarVariants]: VariantSize | ResponsiveVariantSize
} = {
  extraExtraSmall: { size: "24px", fontSize: "12px" },
  extraSmall: { size: "28px", fontSize: "14px" },
  small: { size: "40px", fontSize: "20px" },
  medium: { size: "48px", fontSize: "24px" },
  large: { size: "72px", fontSize: "36px" },
  extraLarge: {
    mobile: { size: "96px", fontSize: "32px" },
    desktop: { size: "192px", fontSize: "92px" },
  },
}

type BorderWidth = CSSProperties["borderWidth"]
type ResponsiveBorderWidth = { mobile: BorderWidth; desktop: BorderWidth }

const isResponsiveBorderWidth = (arg: any): arg is ResponsiveBorderWidth => {
  return !!arg.mobile && !!arg.desktop
}

type PropTypes = HTMLProps<HTMLSpanElement> & {
  user?: PartialPublicUser | null
  variant: Variant
  color?: Colors
  border?: {
    color: Colors
    width: BorderWidth | ResponsiveBorderWidth
  }
}

/*

=== INFO ===

This component should be responsive but as isCompact doesn't work in SSG we should do it with CSS only.
That's why we pass every size with css variables. To add/remove/modify size, please refer to VARIANTS_SIZES above

*/

const Avatar = ({
  user,
  variant,
  color,
  border,
  className,
  ...props
}: PropTypes) => {
  if (!user) {
    return <DisconnectedAvatar variant={variant} />
  }

  const styles: CSSProperties = user.profilePicture ? {} : { textIndent: 0 }

  if (color) {
    styles.backgroundColor = formatColor(color)
  }

  let sizeMobile = "24px"
  let fontSizeMobile = "24px"
  let borderWidthMobile: CSSProperties["borderWidth"] = "0px"
  let sizeDesktop = "12px"
  let fontSizeDesktop = "12px"
  let borderWidthDesktop: CSSProperties["borderWidth"] = "0px"

  const setMobileVariantSize = (variantSize: VariantSize) => {
    sizeMobile = variantSize.size
    fontSizeMobile = variantSize.fontSize
  }

  const setDesktopVariantSize = (variantSize: VariantSize) => {
    sizeDesktop = variantSize.size
    fontSizeDesktop = variantSize.fontSize
  }

  const setVariantSize = (variantSize: VariantSize | ResponsiveVariantSize) => {
    if (isResponsiveVariantSize(variantSize)) {
      setMobileVariantSize(variantSize.mobile)
      setDesktopVariantSize(variantSize.desktop)
    } else {
      setMobileVariantSize(variantSize)
      setDesktopVariantSize(variantSize)
    }
  }

  if (!isResponsiveAvatarVariant(variant)) {
    setVariantSize(VARIANTS_SIZES[variant])
  } else {
    const variantSizeMobile = VARIANTS_SIZES[variant.mobile]
    const variantSizeDesktop = VARIANTS_SIZES[variant.desktop]

    setMobileVariantSize(
      isResponsiveVariantSize(variantSizeMobile)
        ? variantSizeMobile.mobile
        : variantSizeMobile
    )
    setDesktopVariantSize(
      isResponsiveVariantSize(variantSizeDesktop)
        ? variantSizeDesktop.desktop
        : variantSizeDesktop
    )
  }

  if (border) {
    if (isResponsiveBorderWidth(border.width)) {
      borderWidthMobile = border.width.mobile
      borderWidthDesktop = border.width.desktop
    } else {
      borderWidthMobile = border.width
      borderWidthDesktop = border.width
    }
  }

  const responsiveStyles: CSSProperties = {
    ...styles,
    ...(border
      ? {
          "--avatar-border-width-desktop": borderWidthDesktop,
          "--avatar-border-width-mobile": borderWidthMobile,
          "--avatar-border-color": formatColor(border.color),
        }
      : {}),
    ...({
      "--avatar-size-mobile": sizeMobile,
      "--avatar-font-size-mobile": fontSizeMobile,
      "--avatar-size-desktop": sizeDesktop,
      "--avatar-font-size-desktop": fontSizeDesktop,
    } as CSSProperties),
  }

  const avatarClassName = cn(className, css.avatar, {
    [css.withBorder]: !!border,
    [css.hasImage]: !!user.profilePicture,
  })

  return user.profilePicture ? (
    <img
      alt={user.username}
      src={user.profilePicture}
      className={avatarClassName}
      style={responsiveStyles}
    />
  ) : (
    <span {...props} className={avatarClassName} style={responsiveStyles}>
      {!user.profilePicture && user?.username?.charAt(0)}
    </span>
  )
}

export const DisconnectedAvatar = ({ variant }: { variant: Variant }) => (
  <Avatar
    user={{
      username: "ChefClub",
      id: "ChefClub",
      profilePicture: notConnectedIcon,
      userTypes: [],
    }}
    variant={variant}
  />
)

export default Avatar
