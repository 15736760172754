import React, { useEffect, useState } from "react"

import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import Modal from "../Modal"
import nakedMauriceImage from "./naked-maurice.colorable.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import CloseIcon from "~/components/Icons/CloseIcon"
import { Body, Button, Col, Heading, Row } from "~/components/ui"
import useIsClient from "~/hooks/useIsClient"
import { useValidateEmailMutation } from "~/state/api/auth"
import { userSelector } from "~/state/modules/userInfo"

import css from "./EmailValidationModal.module.scss"

const EmailValidationModal = () => {
  const intl = useIntl()
  const [openModal, setOpenModal] = useState(false)

  const isClient = useIsClient()

  const user = useSelector(userSelector)

  const email = user?.email

  const [
    sendEmailValidation,
    { isSuccess: emailSent, isLoading: sendingEmail },
  ] = useValidateEmailMutation()

  // Close modal if successful API call
  useEffect(() => {
    if (emailSent) closeModal()
  }, [emailSent])

  const openEmailValidationModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const sendEmail = () => {
    email && sendEmailValidation({ email: email })
  }

  // Allows modal to be console-opened only if user is logged in and has email
  if (isClient && email) {
    window.openEmailValidationModal = openEmailValidationModal
  }

  // TODO: Replace CloseIcon and icons according to new design system once relevant PR is merged

  return (
    <Modal open={openModal} onClose={closeModal} unmountOnExit mode="fixed">
      {modalRef => (
        <div className={css.root} ref={modalRef}>
          <div className={css.closeButton} onClick={closeModal}>
            <CloseIcon width="26" height="26" />
          </div>

          <ColorableSVG
            className={css.nakedMaurice}
            href={nakedMauriceImage}
            mainColor={"var(--original-color)"} //Color of surrounding circle
            title={intl.formatMessage({
              id: "email-validation-modal/text:title",
            })}
            width={125}
            height={135}
          />

          <Row className={css.rowModal}>
            <Col width={12} className={css.titleContainer}>
              <Heading
                variant="modal"
                color="original"
                className={css.modalTitle}
              >
                {intl.formatMessage({
                  id: "email-validation-modal/text:title",
                })}
              </Heading>
            </Col>
            <Col width={12}>
              <Body variant="body1" color="black">
                {intl.formatMessage({
                  id: "email-validation-modal/text:description",
                })}
              </Body>
            </Col>

            <Button
              className={css.cta}
              variant="primary"
              fullWidth
              color="original"
              onClick={sendEmail}
              disabled={sendingEmail}
            >
              {intl.formatMessage({
                id: "email-validation-modal/button:resend-validation",
              })}
            </Button>
          </Row>
        </div>
      )}
    </Modal>
  )
}

export default EmailValidationModal
