import React, { useEffect } from "react"

import { loadPinterest } from "~/utils/pinterest"

export interface PinterestWidgetProps {
  accountId: string
  boardId?: string
}

interface PropTypes extends PinterestWidgetProps {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function PinterestWidget({ accountId, boardId }: PropTypes) {
  let type = "embedUser"
  let href = `https://www.pinterest.com/${accountId}/`
  if (boardId) {
    href += `${boardId}/`
    type = "embedBoard"
  }

  useEffect(() => {
    loadPinterest(document)
  }, [])

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {href && (
          <a
            href={href}
            data-pin-do={type}
            data-pin-board-width="400"
            data-pin-scale-height="320"
            data-pin-scale-width="80"
          />
        )}
      </div>
    </>
  )
}

export default PinterestWidget
