import React, { ButtonHTMLAttributes, FC } from "react"

import cn from "classnames"

import css from "./TimelineCardMediaSliderArrow.module.scss"

const arrows = {
  prev: {
    style: css.prev,
    label: "Previous image",
    content: `<`,
  },
  next: {
    style: css.next,
    label: "Next image",
    content: `>`,
  },
}

interface PropTypes extends ButtonHTMLAttributes<HTMLButtonElement> {
  direction: "next" | "prev"
}

const TimelineCardMediaSliderArrow: FC<PropTypes> = ({
  className,
  direction,
  onClick,
}) => {
  const { style, label, content } = arrows[direction]
  const classes = [style, className]

  if (className?.includes("slick-disabled")) {
    classes.push(css.disabled)
  }

  return (
    <button
      type="button"
      className={cn(classes)}
      aria-label={label}
      onClick={onClick}
    >
      {content}
    </button>
  )
}

export default TimelineCardMediaSliderArrow
