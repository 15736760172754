import { CreatePageCustomArguments } from "../types/global-types"
import Route, { RouteOptions } from "./Route"

export interface RedirectionRouteOptions extends RouteOptions {
  fromPath: string
  statusCode?: number
  toPath?: string
  absoluteToPath?: boolean
}

export default class RedirectionRoute extends Route {
  fromPath: string
  toPath: string
  absoluteToPath: boolean
  statusCode: number

  constructor({
    fromPath,
    statusCode,
    toPath,
    absoluteToPath,
    ...options
  }: RedirectionRouteOptions) {
    super(options)

    this.fromPath = fromPath
    this.statusCode = statusCode ?? 200
    this.toPath = toPath ?? "/loader"
    this.absoluteToPath = absoluteToPath as boolean
  }

  async create(args: CreatePageCustomArguments): Promise<void> {
    args.createRedirect({
      matchPath: this.fromPath,
      toPath: this.toPath,
      absoluteToPath: this.absoluteToPath,
      redirectInBrowser: false,
      statusCode: this.statusCode,
    })
  }
}
