/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import smoothscroll from "smoothscroll-polyfill"

import "./src/gatsby/browser-scripts/googleAnalyticsScript"
import { chefclubLocaleArray, getRedirectLanguage } from "./src/utils/locales"
import { wrapWithProviderAndGate } from "./wrap-with-provider"

export const wrapRootElement = wrapWithProviderAndGate

export const onClientEntry = () => {
  const isDebug = !!window.localStorage.getItem("debug_matchPath")

  const conditionnalDebug = (...args) => {
    if (isDebug) {
      console.log(...args)
    }
  }

  // IntersectionObserver polyfill
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }

  smoothscroll.polyfill()

  // Buffer polyfill (since webpack 5)
  window.global = window
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  window.Buffer = window.Buffer || require("buffer").Buffer

  /* MATCHPATH SYSTEM REPLACER */

  /*
  
  TODO : Try to remove with Gatsby 5+
  
  Get the window.chefclub__routeParams (see withTemplate HOC) if exist and add corresponding match if necessary
  https://github.com/gatsbyjs/gatsby/blob/6d8f0bfdcc1a2fe2d07f03583b19ea7934b0ccf4/packages/gatsby/cache-dir/production-app.js#L144-L153
  
  https://dev.to/alexandrefauchard/fix-gatsby-4-matchpath-issue-nfc

  */

  const { ___loader: loader, chefclub__routeParams } = window

  if (!loader) {
    return
  }

  //Original loadPage functions
  const originalLoadPage = loader.loadPage
  const originalLoadPageSync = loader.loadPageSync

  if (chefclub__routeParams) {
    //Executed at the page loading
    loader.loadPage = async rawPath => {
      conditionnalDebug("loadPage | rawPath", rawPath)
      //We get the locale from the url...
      let localeId = rawPath.split("/")[1] ?? ""
      const isLocalizedPath = chefclubLocaleArray.includes(localeId)
      if (!isLocalizedPath) {
        //...or from the browser config
        localeId = getRedirectLanguage()
      }
      conditionnalDebug("loadPage | localeId", localeId)

      //We replace potential "{locale}" by the locale in the path and matchPath
      const path = chefclub__routeParams.path.replace("{locale}", localeId)
      const matchPath = isLocalizedPath
        ? chefclub__routeParams.matchPath.replace("{locale}", localeId)
        : chefclub__routeParams.matchPath.replace("{locale}/", "")
      conditionnalDebug("loadPage | path", path)
      conditionnalDebug("loadPage | matchPath", matchPath)

      const isCSRPage = !!path
      const isLeavingCSRPage = //As this function is executed before the url change, we still detect a CSR page if we are leaving one so we need to know if we are leaving or not
        !!window.chefclub__lastActualPath &&
        window.chefclub__lastActualPath !== rawPath
      conditionnalDebug("loadPage | isCSRPage", isCSRPage)
      conditionnalDebug("loadPage | isLeavingCSRPage", isLeavingCSRPage)

      let pageResources
      //If we detect a CSR Page and we're not leaving we override the pagePath with the loader page path and we add a matchPath corresponding to the wanted page url with the last part replaced by *
      if (isCSRPage && !isLeavingCSRPage) {
        pageResources = await originalLoadPage(path)
        const rawParts = rawPath.split("/")
        rawParts.splice(rawPath.slice(-1) === "/" ? -2 : -1)
        pageResources.page.matchPath = matchPath ?? [...rawParts, "*"].join("/")
      } else {
        //If we detect a non-CSR page or leaving one
        pageResources = await originalLoadPage(rawPath)
      }
      conditionnalDebug("loadPage | pageResources", pageResources)

      window.chefclub__lastActualPath = rawPath
      window.chefclub__savedPageRessources = pageResources
      return pageResources
    }

    //The function is executed once the page is loaded for re-hydratation, in our case we load the same page if we are on a CSR page
    loader.loadPageSync = rawPath => {
      conditionnalDebug("loadPageSync")
      return (
        window.chefclub__savedPageRessources ?? originalLoadPageSync(rawPath)
      )
    }
  }
}

export const onInitialClientRender = () => {
  require("./src/gatsby/browser-scripts/iubendaScript")
  const headElement = document.querySelector("head")

  const iubendaExternalScriptElement2 = document.createElement("script")
  iubendaExternalScriptElement2.src =
    "https://cdn.iubenda.com/cs/tcf/stub-v2.js"
  iubendaExternalScriptElement2.type = "text/javascript"

  headElement.appendChild(iubendaExternalScriptElement2)

  const iubendaExternalScriptElement = document.createElement("script")
  iubendaExternalScriptElement.src = "https://cdn.iubenda.com/cs/iubenda_cs.js"
  iubendaExternalScriptElement.type = "text/javascript"
  iubendaExternalScriptElement.async = true
  iubendaExternalScriptElement.charset = "UTF-8"

  headElement.appendChild(iubendaExternalScriptElement)
}

// Overrides browser's default scroll restore when link has #anchor-id
export const shouldUpdateScroll = ({ routerProps: { location } }) =>
  !location.hash
