import React from "react"

import cn from "classnames"

import { responsiveCN, ResponsiveValues } from "~/utils/responsiveCN"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./Separator.module.scss"

interface PropTypes {
  noMargin?: ResponsiveValues<boolean>
  hideOnCompact?: boolean
  hideOnRegular?: boolean
}

const getResponsiveCN = responsiveCN(css)

const Separator = ({ noMargin, hideOnCompact, hideOnRegular }: PropTypes) => (
  <hr
    className={cn(css.root, getResponsiveCN("noMargin", noMargin), {
      [css.hideOnCompact]: hideOnCompact,
      [css.hideOnRegular]: hideOnRegular,
    })}
  />
)

export default Separator
