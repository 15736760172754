import React, { useContext, useEffect } from "react"

import { useIntl } from "react-intl"

import closeButton from "./closeButton.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import { Body, Button, Heading } from "~/components/ui"
import { CreatorsConditionsContext } from "~/context/CreatorsConditionsContext"
import { useUpdateDataMutation } from "~/state/api/profile"
import { ACCEPTED_CREATORS_LANGUAGES } from "~/utils/user"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./CreatorsConditionsPopup.module.scss"

const CreatorsConditionsPopUp = () => {
  const intl = useIntl()
  const acceptedLanguage = ACCEPTED_CREATORS_LANGUAGES.includes(intl.locale)

  const { hidePopup, refetch } = useContext(CreatorsConditionsContext)

  const [
    acceptCreatorsConditions,
    { isSuccess: patchQuerySuccess, isError, isLoading },
  ] = useUpdateDataMutation()

  const handleClick = () => {
    acceptCreatorsConditions({
      ["creators_t_c_accepted"]: true,
    })
  }

  const handleQuit = () => {
    if (hidePopup) hidePopup(true)
  }

  useEffect(() => {
    if (refetch) refetch()
  }, [patchQuerySuccess])
  return (
    <div className={css.root}>
      <div className={css.heading}>
        <Heading variant="small" semiBold>
          {intl.formatMessage({
            id: "creatorsconditions/popup/text:title-tos",
          })}
        </Heading>
        <ColorableSVG
          href={closeButton}
          width={24}
          height={24}
          className={css.icon}
          onClick={handleQuit}
        />
      </div>
      <Body variant="body3" noMargin>
        {intl.formatMessage({
          id: "creatorsconditions/popup/text:accept-tos",
        })}
      </Body>
      {isError ? (
        <Body variant="body1" semiBold>
          {intl.formatMessage({
            id: "profile/text:cancel_subscription_error",
          })}
        </Body>
      ) : (
        <div className={css.buttons}>
          <Button
            variant="secondary"
            color="original"
            size="small"
            onClick={handleQuit}
            fullWidth
          >
            <Link
              // TODO: Remove it when creators conditions will be translated in every languages
              to={
                acceptedLanguage
                  ? "legal-notices-creators"
                  : "/fr/mentions-legales-creators/"
              }
              absoluteLink={!acceptedLanguage}
            >
              {intl.formatMessage({
                id: "creatorsconditions/action:read-tos-button",
              })}
            </Link>
          </Button>
          <Button
            variant="primary"
            color="original"
            size="small"
            onClick={handleClick}
            disabled={patchQuerySuccess || isLoading}
            fullWidth
          >
            {intl.formatMessage({
              id: "creatorsconditions/action:accept-button",
            })}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CreatorsConditionsPopUp
