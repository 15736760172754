import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { deduplicate } from "../../utils/array"

interface PrismicWorkshop {
  prismicWorkshopPage: null | {
    data: {
      video_uid: null | string
      body: [
        {
          slice_type: "workshop"
          primary: {
            recipe_uid: null | string
          }
        }
      ]
    }
  }
}

export default async function createWorkshopPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const workshopId = currentLocale.messages?.["config:prismic-workshop-id"]

  const result = await graphql<PrismicWorkshop>(
    `
      query createWorkshopPagesQuery($lang: String, $workshopId: ID) {
        prismicWorkshopPage(
          lang: { eq: $lang }
          prismicId: { eq: $workshopId }
        ) {
          data {
            video_uid
            body {
              ... on PrismicWorkshopPageDataBodyWorkshop {
                slice_type
                primary {
                  recipe_uid
                }
              }
            }
          }
        }
      }
    `,
    { lang: currentLocale.prismicCode, workshopId }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data?.prismicWorkshopPage) {
    const { body, video_uid } = result.data.prismicWorkshopPage.data

    // Fetch linked Landing to get Linked Recipe ids
    const recipeIds = []

    // Gather all Recipe ids from Body slices
    recipeIds.push(
      ...body.flatMap(slice => {
        switch (slice.slice_type) {
          case "workshop":
            return slice.primary.recipe_uid
          default:
            return []
        }
      })
    )

    const videoIds = []

    if (video_uid) {
      videoIds.push(video_uid)
    }

    createPage({
      context: {
        ...defaultContext,
        lang: currentLocale.prismicCode,
        recipeIds: deduplicate(recipeIds),
        workshopId,
        videoIds,
      },
    })
  }
}
