import React from "react"

import KlaviyoForm from "./KlaviyoForm/KlaviyoForm"
import PinterestWidget from "./PinterestWidget/PinterestWidget"
import StoreLocator from "./StoreLocator/StoreLocator"
import { Row } from "~/components/ui"
import { isDev } from "~/utils/general"

export const externalIntegrationIds = [
  "klaviyoForm",
  "storeLocator",
  "pinterestWidget",
] as const
export type externalIntegrationIdsType = (typeof externalIntegrationIds)[number]

export interface ExternalIntegrationsProps {
  variation: externalIntegrationIdsType | string
  anchorId?: string
  className: string
  fullWidth?: boolean
  mapTitle?: string
  mapUrl: string
  accountId: string
  boardId?: string
}

interface PropTypes extends ExternalIntegrationsProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
  incrementH1Position?: () => void
}

function ExternalIntegrations({
  isDarkTheme,
  isPreview,
  isLeadingSlice,
  incrementH1Position,
  className,
  fullWidth,
  mapTitle,
  mapUrl,
  accountId,
  boardId,
  anchorId,
  variation,
  ...props
}: PropTypes) {
  switch (variation) {
    case "klaviyoForm":
      isLeadingSlice && incrementH1Position
      return (
        <KlaviyoForm
          className={className}
          fullWidth={fullWidth}
          {...{ ...props, isDarkTheme }}
        />
      )

    case "storeLocator":
      isLeadingSlice && incrementH1Position
      return (
        <StoreLocator
          mapTitle={mapTitle}
          mapUrl={mapUrl}
          {...{ ...props, isDarkTheme }}
        />
      )

    case "pinterestWidget":
      isLeadingSlice && incrementH1Position
      return (
        <PinterestWidget
          accountId={accountId}
          boardId={boardId}
          {...{ ...props, isDarkTheme }}
        />
      )

    default:
      return isDev ? (
        <Row justify="center">
          <h1>External Integrations Slice</h1>
          <p>Please choose an external slice on Prismic</p>
        </Row>
      ) : (
        <div data-slice="external-integration-error" />
      )
  }
}

export default ExternalIntegrations
