import React, { FC, useEffect, useState } from "react"

import cn from "classnames"

import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body } from "~/components/ui"
import { Tab, TabsProps } from "~/components/ui/Tabs/Tabs/Tab"

import css from "./TabsView.module.scss"

type TabsViewProps = {
  stickyTabs?: boolean
  selectedTabIndex?: number
  onSelectedTabChange?: (newIndex?: number) => void
  [key: string]: any
}

/*

USAGE :

<TabsView>
  <Tab>...</Tab>
  <Tab>...</Tab>
  <Tab>...</Tab>
</TabsView>

 */

export const TabsView: FC<TabsViewProps> = ({
  selectedTabIndex: selectedTabIndexProp,
  onSelectedTabChange,
  stickyTabs,
  children,
  ...props
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    selectedTabIndexProp ?? 0
  )

  const tabsInfo: TabsProps[] = [] // icon, label etc of the tabs for the header part
  const tabsContents: any[] = [] // content (children) of the <Tab> component

  useEffect(() => {
    if (selectedTabIndex !== selectedTabIndexProp) {
      setSelectedTabIndex(selectedTabIndexProp ?? 0)
    }
  }, [selectedTabIndexProp])

  //Change selected tab
  const selectTab = (tabIndex: number) => {
    const newIndex = Math.min(Math.max(tabIndex, 0), tabsInfo.length - 1)
    setSelectedTabIndex(newIndex)
    onSelectedTabChange && onSelectedTabChange(newIndex)
  }

  //Fill the tabsInfo and tabsContents array with the <Tab> component props and children
  const addTab = (node: { props: { children: any } & TabsProps }) => {
    //We extract children from the <Tab> component and place it in a div with the class and style of the <Tab> component
    tabsContents.push(
      <div className={node.props.className} style={node.props.style}>
        {node.props.children}
      </div>
    )
    //We extract icon and label <Tab> props to add it in tabsInfo
    tabsInfo.push({
      icon: node.props.icon,
      activeIcon: node.props.activeIcon,
      label: node.props.label,
    })
  }

  //If children is not an array it mean that we have only one child so the children object is directly this child
  if (Array.isArray(children)) {
    children.filter(node => node.type === Tab).forEach(node => addTab(node))
  } else if ((children as any).type === Tab) {
    addTab(children as any)
  }

  return (
    <div {...props}>
      <div
        className={cn(css.TabsViewHeader, { [css.stickyHeader]: stickyTabs })}
      >
        {/* We use extracted <Tab> props to generate the header part */}
        {tabsInfo.map((node, index) => (
          <div
            key={index}
            className={index === selectedTabIndex ? css.selected : undefined}
            onClick={() => selectTab(index)}
          >
            <ColorableSVG
              href={
                index === selectedTabIndex && node.activeIcon
                  ? node.activeIcon
                  : node.icon
              }
              className={css.tabIcon}
            />
            <Body variant="body4" semiBold>
              {node.label}
            </Body>
          </div>
        ))}
      </div>
      {/* We display the children prop of the <Tab> component corresponding to the selected one */}
      {tabsContents[selectedTabIndex]}
    </div>
  )
}
