import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import LatestRecipeCarousel, {
  LatestRecipesProps,
} from "~/components/slices/LatestRecipeCarousel"
import { PartialRecipe } from "~/models/PartialRecipe"

interface PropTypes extends PrismicLandingDataBodyLatestRecipes {
  recipes?: PartialRecipe[]
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function LandingBodyLatestRecipes({ recipes, ...props }: PropTypes) {
  if (!recipes) return null

  return (
    <SliceSection
      margin="small"
      noContainer
      anchorId={props.primary?.anchorId}
      {...props}
    >
      <LatestRecipeCarousel {...props} recipes={recipes} />
    </SliceSection>
  )
}

export default LandingBodyLatestRecipes

export interface PrismicLandingDataBodyLatestRecipes extends PrismicSlice {
  sliceType: "latest_recipes"
  primary: LatestRecipesProps["primary"]
}

export const query = graphql`
  fragment PrismicLandingDataBodyLatestRecipes on PrismicLandingDataBodyLatestRecipes {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      verticaleFilter: verticale_filter
      anchorId: id
    }
  }
`
