import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import List from "~/components/slices/List/List"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

interface PropTypes extends PrismicListDefault {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function PrismicNavMenuListDefault(props: PropTypes) {
  const content = props.items.map(item => {
    return {
      illustration: item.image.url,
      text: item.title,
      link: getPrismicLinkProps(item.link)?.href,
      tag: item.tag,
    }
  })
  return (
    <SliceSection {...props}>
      <List headerIsNotHx={true} content={content} {...props.primary} />
    </SliceSection>
  )
}

export default PrismicNavMenuListDefault

interface primary {
  title: PrismicStructuredText
  seeMoreLink: PrismicLink
  seeMoreLinkText: string
  imageForm: string
  horizontalInMobile: boolean
}

interface items {
  tag: string
  title: string
  image: {
    url: string
  }
  link: PrismicLink
}

export interface PrismicListDefault extends PrismicNavSlice {
  sliceType: "list"
  variation: "default"
  primary: primary
  items: items[]
}

export const query = graphql`
  fragment PrismicListDefault on PrismicListDefault {
    id
    sliceType: slice_type
    variation
    items {
      tag
      title
      image {
        url
      }
      link {
        ...PrismicLink
      }
    }
    primary {
      imageForm
      title {
        ...PrismicStructuredText
      }
      seeMoreLink {
        ...PrismicLink
      }
      seeMoreLinkText
      horizontalInMobile
    }
  }
`
