import { CSSProperties, FC } from "react"

export type TabsProps = {
  icon: string
  activeIcon?: string
  label: string
  className?: string
  style?: CSSProperties
}

export const Tab: FC<TabsProps> = () => {
  //Render is in the TabsView component
  return null
}
