import React from "react"

import loadable from "@loadable/component"
import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"

const RenderLottie = loadable(() => import("~/components/Lottie/Lottie"))

type PropTypes = PrismicLandingDataBodyFullWidthLottie

function LandingBodyFullWidthLottie(props: PropTypes) {
  const { url } = props.primary.lottie
  if (!url) return null

  return (
    <SliceSection margin="large" anchorId={props.primary.anchorId} {...props}>
      {({ isVisible }) => (
        <div style={{ display: "flex" }}>
          <RenderLottie url={url} play={isVisible} />
        </div>
      )}
    </SliceSection>
  )
}

export default LandingBodyFullWidthLottie

export interface PrismicLandingDataBodyFullWidthLottie extends PrismicSlice {
  sliceType: "full_width_lottie"
  primary: {
    lottie: {
      url: null | string
    }
    anchorId?: string
  }
}

export const query = graphql`
  fragment PrismicLandingDataBodyFullWidthLottie on PrismicLandingDataBodyFullWidthLottie {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      lottie {
        url
      }
      anchorId: id
    }
  }
`
