import React from "react"

import { graphql } from "gatsby"

import { LatestLanding } from "../Landing"
import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import LatestLandingsByTags from "~/components/slices/LatestLandingsByTags/LatestLandingsByTags"
import { LatestLandingsByTagsProps } from "~/components/slices/LatestLandingsByTags/LatestLandingsByTags"

interface PropTypes extends PrismicLandingDataBodyLatestLandingsByTags {
  landings?: LatestLanding[]
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function LandingBodyLatestLandingsByTags({ landings, ...props }: PropTypes) {
  const filterLandings = landings?.filter(
    landing => !landing.tags.includes("hidden") && landing.tags.length
  )
  return (
    <SliceSection margin="large" anchorId={props.primary.anchorId} {...props}>
      <LatestLandingsByTags landings={filterLandings} {...{ ...props }} />
    </SliceSection>
  )
}

export default LandingBodyLatestLandingsByTags

export interface PrismicLandingDataBodyLatestLandingsByTags
  extends PrismicSlice {
  sliceType: "latest_landings_by_tags"
  primary: LatestLandingsByTagsProps["primary"]
}

export const query = graphql`
  fragment PrismicLandingDataBodyLatestLandingsByTags on PrismicLandingDataBodyLatestLandingsByTags {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      allLandingsTagName: all_landings_tag_name
      allFilteredBySelectedTags: all_filtered_by_selected_tags
      allLandingsTagColor: all_landings_tag_color
      maxLandingsDisplayed: max_landings_displayed
      anchorId: id
    }
    items {
      tag_color
      tag_name
    }
  }
`
