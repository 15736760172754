import Bowser from "bowser"

/*
 * Check if the current browser in compatible with WebP
 * follow https://caniuse.com/webp
 */
export function hasWebpSupport(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false

  const browser = Bowser.getParser(window.navigator.userAgent)

  // Returns `undefined` when the browser is no described.
  const isValidBrowser = browser.satisfies({
    chrome: ">=32",
    firefox: ">=65",
    edge: ">=18",
    opera: ">=19",
    android: ">=4.2",
    //safari: ">=14",
  })
  // TODO: once Big Sur is released, uncomment safari (WebP is supported on Safari >= 14 on Big Sur only)
  return !!isValidBrowser
}

export function isAndroid(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false
  const browser = Bowser.getParser(window.navigator.userAgent)
  const os = browser.getOS()
  return os.name === "Android"
}

export function isIOS(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false
  const browser = Bowser.getParser(window.navigator.userAgent)
  const os = browser.getOS()
  return os.name === "iOS"
}

export function isMobile(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false
  const browser = Bowser.getParser(window.navigator.userAgent)
  const os = browser.getOS()
  return os.name === "iOS" || os.name === "Android"
}

export function isChrome(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false
  const browser = window.navigator.userAgent
  const vendor = window.navigator.vendor
  return /Chrome/.test(browser) && "Google Inc." === vendor
}

export function isFirefox(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false
  const browser = window.navigator.userAgent
  return /Mozilla/.test(browser) && /Firefox/.test(browser)
}

export function isSafari(): boolean {
  // Prevent SSR issues
  if (typeof window === "undefined") return false
  const vendor = window.navigator.vendor
  return "Apple Computer, Inc." === vendor
}
