import React, { useContext } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import { Body, Button, Heading } from "~/components/ui"
import { ModalLoginContext } from "~/context/ModalLoginContext"
import { VerticalId } from "~/models/Vertical"
import { kebabToCamelCase } from "~/utils/string"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./VideoPaywall.module.scss"

interface PropTypes {
  vertical: VerticalId
  show: boolean
}

function VideoPaywall({ vertical, show }: PropTypes) {
  const intl = useIntl()
  const { toggleModal } = useContext(ModalLoginContext)

  return (
    <div className={cn(css.root, { [css.hide]: !show })}>
      <div className={css.overlay} />
      <Heading className={css.legacyTitle} Tag="p">
        {intl.formatMessage({
          id: "recipe/section:register-for-free-to-see-the-recipe",
        })}
      </Heading>

      {/* The following adds an image using CSS from Vertical */}
      <div className={css[kebabToCamelCase(vertical)]} />

      <Button
        color="white"
        variant="secondary"
        onClick={() => {
          toggleModal && toggleModal("")
        }}
      >
        {intl.formatMessage({ id: "action:register" })}
      </Button>

      <Body
        className={css.link}
        onClick={() => {
          toggleModal && toggleModal("")
        }}
      >
        {intl.formatMessage({ id: "action:i-already-have-an-account" })}
      </Body>
    </div>
  )
}

export default VideoPaywall
