import React from "react"

import { RecipeCarousel } from "~/components/Carousel/Carousel"
import { Container, Heading } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"
import { PrismicStructuredText } from "~/models/PrismicTypes"

export interface PropTypes {
  title?: PrismicStructuredText
  recipes?: PartialRecipe[]
  isPreview?: boolean
  isDarkTheme?: boolean
  items: [{ recipeUid: string }]
  eagerImages?: boolean
}

function RecipeCarouselSlice({
  items,
  title,
  recipes,
  isPreview,
  isDarkTheme,
  eagerImages,
}: PropTypes) {
  if (!recipes) return null

  const mappedItems = items.map(
    item =>
      // If not found, return just the id
      recipes.find(recipe => recipe.id === item.recipeUid) || item.recipeUid
  )

  return (
    <>
      {title && (
        <Container>
          <Heading variant="large" color={isDarkTheme ? "white" : "body"}>
            {title.text}
          </Heading>
        </Container>
      )}
      <RecipeCarousel
        recipes={mappedItems}
        {...{ isPreview, isDarkTheme, eagerImages }}
      />
    </>
  )
}

export default RecipeCarouselSlice
