import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { PrismicStructuredText } from "~/models/PrismicTypes"

interface PrismicLegals {
  data: { id: string; data: { conditions: { text: string } } }
}

export default async function createLegalNoticesCreatorsPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const result = await graphql<PrismicLegals>(
    `
      query createPrismicLegalsPagesQuery($lang: String) {
        data: prismicLegals(
          lang: { eq: $lang }
          data: { type: { eq: "tos-creators" } }
        ) {
          id
          data {
            conditions {
              text
            }
          }
        }
      }
    `,
    { lang: currentLocale.prismicCode }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data?.data?.data?.conditions.text) {
    createPage({
      context: { ...defaultContext, lang: currentLocale.prismicCode },
    })
  }
}
