import { graphql, useStaticQuery } from "gatsby"

import { Creator, Tag } from "~/models/PublicUser"
import { ChefclubLocale } from "~/utils/locales"

export type CreatorTags = {
  slug: string
  translation: string
  users_count: number
  uuid: string
}

// Exported to be reused when hooks cannot be used
// Filter creators by slug
export const filteredBySlugCreators = (
  creators: Creator[],
  tagSlug: string
): Creator[] =>
  creators.filter(creator =>
    creator.tags.some((tag: Tag) => tag.slug === tagSlug)
  )

function useCreators(intlLocale?: ChefclubLocale, tagSlug?: string) {
  const creatorsQuery = useStaticQuery(graphql`
    {
      allCreator(sort: { fields: last_recipe_creation_date, order: DESC }) {
        nodes {
          lang
          username
          profilePicture: profile_picture
          coverImage: cover_image
          userTypes: user_types
          lastRecipeCreationDate: last_recipe_creation_date
          tags {
            slug
            translation
          }
        }
      }
    }
  `)

  const allCreators = creatorsQuery.allCreator.nodes

  // Return all creators if no locale nor slug is passed
  if (!intlLocale && !tagSlug) return allCreators

  const localisedCreators = allCreators.filter(
    (creator: Creator) => creator.lang === intlLocale
  )

  // Return all localised creators if no slug is passed
  if (intlLocale && !tagSlug) return localisedCreators

  // Return filtered-by-slug creators (localised or not depending on arg)
  if (tagSlug)
    return filteredBySlugCreators(
      intlLocale ? localisedCreators : allCreators,
      tagSlug
    )
}

export default useCreators
