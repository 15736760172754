import React from "react"

export const slugify = (label: string): string => {
  //If you modify this function please copy paste into sitemapUtils
  return label
    .normalize("NFD")
    .replace(/[^a-zA-Z ]/g, "")
    .replace(/\s/g, "-")
    .replace(/[\u0300-\u036f]/g, "")
}

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const kebabToCamelCase = (key: string): string => {
  return key.replace(/-(\w)/g, (_, letter) => letter.toUpperCase())
}

export const kebabToPascalCase = (key: string): string => {
  return capitalizeFirstLetter(kebabToCamelCase(key))
}

export interface CurrencyFormatterProps {
  amount: string | number
  currency: string
  locale: string
}

export function currencyFormatter({
  amount,
  currency,
  locale,
}: CurrencyFormatterProps): string {
  const style = "currency"
  return new Intl.NumberFormat(locale, { style, currency }).format(
    Number(amount)
  )
}

export function removeHTMLTags(str: string): string {
  if (typeof window !== "undefined" && window.DOMParser) {
    const doc = new DOMParser().parseFromString(str, "text/html")
    return doc.body.textContent || ""
  }
  return str.replace(/(<([^>]+)>)/gi, "")
}

export function parseMarkdownLinks(text: string): JSX.Element[] {
  const result: JSX.Element[] = []
  let remainingText = text

  while (remainingText.length > 0) {
    const linkMatch = /\[([^\]]+)\]\(([^)]+)\)/.exec(remainingText)

    if (linkMatch) {
      const [completeMatchString, linkText, linkURL] = linkMatch

      // Add remaining text up to link
      result.push(
        <React.Fragment key={result.length}>
          {remainingText.slice(0, linkMatch.index)}
        </React.Fragment>
      )

      // Add link
      result.push(
        <a key={result.length} href={linkURL}>
          {linkText}
        </a>
      )

      // Trim remaining text
      const linkMatchEndIndex = linkMatch.index + completeMatchString.length
      remainingText = remainingText.slice(linkMatchEndIndex)
    } else {
      // No link match: add all remaining text
      result.push(
        <React.Fragment key={result.length}>{remainingText}</React.Fragment>
      )
      remainingText = ""
    }
  }

  return result
}

export const getCursorFromUrl = (url?: string | null): string | undefined => {
  return url
    ? new URLSearchParams(new URL(url).searchParams).get("cursor") || undefined
    : undefined
}

export const isFirstLetterVowel = (string: string): boolean => {
  return ["a", "e", "i", "o", "u", "y"].includes(string[0].toLowerCase())
}

export function isString(value: any) {
  return typeof value === "string" || value instanceof String
}
