import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import { Body } from "../ui"

import css from "./FilterByTag.module.scss"

interface FilterBytagPropTypes<ItemType> {
  tags: { translation: string; slug: string }[]
  items: ItemType[] // Total unfiltered items
  itemsCount: string
  setFilteredItems: Dispatch<SetStateAction<ItemType[]>> // Hook used to update display of parent element
  filteringLogic: (item: ItemType[], tag: string) => ItemType[] // Data never has the same structure so the filtering logic would come from the parent component
  isDarkTheme?: boolean
  allFilteredBySelectedTags?: boolean
}

function FilterByTag<T>({
  tags,
  items,
  itemsCount,
  setFilteredItems,
  filteringLogic,
  isDarkTheme,
  allFilteredBySelectedTags,
}: FilterBytagPropTypes<T>) {
  const intl = useIntl()
  const [selectedFilter, setSelectedFilter] = useState(0)

  const isEnabled = selectedFilter !== 0

  // Tag including all creators
  const all = {
    slug: "All",
    translation: intl.formatMessage({
      id: "tags/text:all-tags-filter",
    }),
  }

  // Concatenate local "All" tag to API-fetched tags
  tags = [all, ...tags]

  useEffect(() => {
    if (!isEnabled) {
      if (allFilteredBySelectedTags) {
        const filteredItems: T[] = []

        tags.forEach(tag => {
          filteredItems.push(...filteringLogic(items, tag.slug))
        })

        setFilteredItems(filteredItems)
      } else {
        setFilteredItems(items)
      }
    }
  }, [isEnabled, allFilteredBySelectedTags])

  const toggleFilter = (index: number) => {
    // Skip if targeted filter is already on
    if (index === selectedFilter) return

    setSelectedFilter(index)

    // Filter by slugs if provided else filter by tags (depending on the parent component)
    tags[index]?.slug.length &&
      setFilteredItems(filteringLogic(items, tags[index].slug))
  }

  return (
    <div className={css.root}>
      {tags.length && (
        <div className={css.tagFilterContainer}>
          {tags.map((tag: { translation: string }, index: number) => (
            <Body
              key={index}
              className={cn(css.tagFilter, {
                [css.isSelected]: selectedFilter === index,
              })}
              color={selectedFilter === index ? "white" : "gray2"}
              semiBold
              variant="body2"
              onClick={() => toggleFilter(index)}
            >
              {tag.translation}
            </Body>
          ))}
        </div>
      )}
      <Body
        className={cn(css.filteredAmount, {
          [css.darkTheme]: isDarkTheme,
        })}
        variant="body3"
        semiBold
        color="gray2"
      >
        {itemsCount}
      </Body>
    </div>
  )
}

export default FilterByTag
