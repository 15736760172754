import { useEffect, useState } from "react"

export default function useDocument() {
  const [isClient, setClient] = useState<undefined | Document>(undefined)

  useEffect(() => {
    setClient(document)
  }, [])

  return isClient
}
