import { RichCase, RichEnum } from "../utils/RichEnum"
import { Colors } from "~/types/global-types"
import { assertNever } from "~/utils/general"

export type UserTypeId = "chefclub-staff" | "creator" | "animator" | "tester"

class UserTypeCase {
  id: UserTypeId
  color: Colors

  constructor(id: UserTypeId, color: Colors) {
    this.id = id
    this.color = color
  }

  get isStaff(): boolean {
    return this.id === "chefclub-staff"
  }

  get messageId(): string {
    switch (this.id) {
      case "chefclub-staff":
        return "profile/text:chefclub-staff"
      case "creator":
        return "profile/text:creator"
      case "animator":
        return "profile/text:animator"
      case "tester":
        return "profile/text:tester"
      default:
        assertNever(this.id)
    }
  }
}

const UserType = RichEnum({
  chefclubStaff: new UserTypeCase("chefclub-staff", "original"),
  creator: new UserTypeCase("creator", "creators"),
  animator: new UserTypeCase("animator", "original"),
  tester: new UserTypeCase("tester", "original"),
})
type UserType = RichCase<typeof UserType>

export default UserType

export function getUserTypeFromId(id: string): UserType | null {
  const userType = Object.values(UserType).find(p => p.id === id)
  if (!userType) return null
  return userType
}
