import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import fetch from "isomorphic-fetch"

export const sendInBlueApi = createApi({
  reducerPath: "sendInBlueApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://c781119c.sibforms.com/serve/",
    fetchFn: fetch,
    prepareHeaders: headers => {
      headers.set("Content-Type", "application/x-www-form-urlencoded")
      return headers
    },
  }),
  endpoints: builder => ({
    subscribeFooterNewsletterSIB: builder.mutation<
      { result: number },
      { body: string; urlParam: string }
    >({
      query: param => ({
        url: param.urlParam,
        method: "POST",
        body: param.body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          if (data) {
            window.dataLayer.push({
              event: "inscription_newsletter",
            })
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const { useSubscribeFooterNewsletterSIBMutation } = sendInBlueApi
/*
curl --location --request POST 'https://c781119c.sibforms.com/serve/MUIEABCScp20-mdUcNcBxzsodPtF6CTT0ymLiB04a_NgxV-oIqnhpeGw52gP-55kYmCU8ho26muWqbVmeuLzv51XfUKx2yMQWyNx-BjWoZbibDaM6fSRknz1H8XkG8AdwGow2moHbjCobMVhBIabk6SoXvTAHgAlIyLjSQ2wvDKNN1FqVorOV2CyUXNd0nV37HkCsTcedCbIyXF9' \
--form 'EMAIL="testnico50@yopmail.fr"' \
--form 'LOCALE="en-us"' \
--form 'LANGUAGE="en-us"'

*/
