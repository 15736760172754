import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import Timeline, { TimelineProps } from "~/components/Timeline/Timeline"

interface PropTypes extends PrismicLandingDataBodyTimeline {
  timelines?: TimelineProps[]
  isPreview?: boolean
  isDarkTheme?: boolean
}

function LandingBodyTimeline(props: PropTypes) {
  const currentTimeline =
    props.timelines &&
    props.timelines.find(
      timeline => timeline.prismicId === props.primary.timeline?.id
    )

  if (!currentTimeline) return null

  return (
    <SliceSection anchorId={props.primary.anchorId} {...props}>
      <Timeline {...currentTimeline.data} />
    </SliceSection>
  )
}

export default LandingBodyTimeline

export interface PrismicLandingDataBodyTimeline extends PrismicSlice {
  sliceType: "timeline"
  primary: {
    timeline: {
      id: null | string
    }
    anchorId?: string
  }
}

export const query = graphql`
  fragment PrismicLandingDataBodyTimeline on PrismicLandingDataBodyTimeline {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      timeline {
        id
        anchorId: id
      }
    }
  }
`
