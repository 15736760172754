import React, { forwardRef, HTMLProps } from "react"

import cn from "classnames"

import { responsiveCN, ResponsiveValues } from "~/utils/responsiveCN"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./container.module.scss"

type Tag = "div" | "section" | "header" | "footer" | "aside"

interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, "size"> {
  size?: "default" | "legacy"
  enable?: ResponsiveValues<boolean>
  Tag?: Tag
  noPaddingOnCompact?: boolean
}

const getResponsiveCN = responsiveCN(css)

export const Container = forwardRef<HTMLDivElement, PropTypes>(
  function Container(
    {
      className,
      size = "default",
      enable = true,
      Tag = "div",
      noPaddingOnCompact,
      ...props
    },
    ref
  ) {
    return (
      <Tag
        ref={ref}
        className={cn(className, getResponsiveCN("container", enable), {
          [css[size]]: size,
          [css.noPadding]: noPaddingOnCompact,
        })}
        {...props}
      />
    )
  }
)
