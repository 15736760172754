import React from "react"

import cn from "classnames"
import Image, { FluidObject } from "gatsby-image"

import { Col, Row } from "~/components/ui"
import { PrismicLink } from "~/models/PrismicTypes"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./ImageImageColumns.module.scss"

export interface ImageImageColumnsProps {
  leftImage: {
    alt: string | null
    fluid: FluidObject | null
  }
  leftLink: PrismicLink
  rightImage: {
    alt: string | null
    fluid: FluidObject | null
  }
  rightLink: PrismicLink
  anchorId?: string
}

interface PropTypes extends ImageImageColumnsProps {
  eagerImages?: boolean
}

function ImageImageColumns({
  leftImage,
  leftLink,
  rightImage,
  rightLink,
  eagerImages,
}: PropTypes) {
  return (
    <Row>
      <Col width={[12, 6]}>
        {leftImage.fluid?.src && (
          <a {...getPrismicLinkProps(leftLink)}>
            <Image
              className={cn(css.image, css.leftImage)}
              fadeIn={false}
              fluid={leftImage.fluid}
              alt={leftImage?.alt || ""}
              loading={eagerImages ? "eager" : undefined}
            />
          </a>
        )}
      </Col>
      <Col width={[12, 6]}>
        {rightImage.fluid?.src && (
          <a {...getPrismicLinkProps(rightLink)}>
            <Image
              className={css.image}
              fadeIn={false}
              fluid={rightImage.fluid}
              alt={rightImage?.alt || ""}
              loading={eagerImages ? "eager" : undefined}
            />
          </a>
        )}
      </Col>
    </Row>
  )
}

export default ImageImageColumns
