export function isNotNullish<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null
}

// from https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
export function assertNever(value: never): never {
  throw new Error("Unexpected object: " + value)
}

export function warnAboutUnexpectedSlice(
  prismicDocumentTypeName: string,
  sliceData: any
) {
  if (process.env.GATSBY_BUILD_WARNING_PRISMIC === "true") {
    const context = sliceData.__typename ?? sliceData

    console.warn(
      `Unexpected slice type in ${prismicDocumentTypeName} document:`,
      context
    )
  }
}

// TODO: use centralized env vars
const GATSBY_IS_PRODUCTION = process.env.GATSBY_IS_PRODUCTION ?? ""

/**
 * isProd means chefclub.tv, else others staging urls
 * isDev means localhost
 */
export const isProd =
  GATSBY_IS_PRODUCTION.toLowerCase() === "true" || GATSBY_IS_PRODUCTION === "1" // duplicates the computation done in environment.ts

export const isDev = process.env.NODE_ENV === "development"

/* Screen size */
export const mobileScreenWidth = "767px"
