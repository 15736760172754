/*
 *  GraphQL fragments for reuse in server-side code.
 */

export type verticalRecipe = { id: string }

export interface LatestVerticalRecipesQuery {
  originalRecipe: null | verticalRecipe
  lightAndFunRecipe: null | verticalRecipe
  dailyRecipe: null | verticalRecipe
  kidsRecipe: null | verticalRecipe
  cocktailsRecipe: null | verticalRecipe
}

export const getRecipeIdsFromVertical = (
  data: LatestVerticalRecipesQuery
): string[] => {
  const recipes = [
    data.originalRecipe,
    data.kidsRecipe,
    data.lightAndFunRecipe,
    data.dailyRecipe,
    data.cocktailsRecipe,
  ]

  return recipes
    .map(recipe => recipe?.id)
    .filter(recipe => !!recipe) as string[]
}

export const allVerticals = `
  originalRecipe: recipe(
    language: { eq: $language }
    vertical: { eq: "original" }
  ) {
    id
  }
  lightAndFunRecipe: recipe(
    language: { eq: $language }
    vertical: { eq: "light-and-fun" }
  ) {
    id
  }
  dailyRecipe: recipe(
    language: { eq: $language }
    vertical: { eq: "daily" }
  ) {
    id
  }
  kidsRecipe: recipe(language: { eq: $language }, vertical: { eq: "kids" }) {
    id
  }
  cocktailsRecipe: recipe(
    language: { eq: $language }
    vertical: { eq: "cocktails" }
  ) {
    id
  }
`
