import React from "react"

import StorybookColorableSVG from "./StorybookColorableSVG"
import { Colors } from "~/types/global-types"
import { formatColor } from "~/utils/colors"

interface PropTypes extends React.SVGProps<SVGSVGElement> {
  href: string
  title?: string | undefined
  mainColor?: Colors
}

const ColorableSVG = ({ href, mainColor, title, ...rest }: PropTypes) => {
  const isStorybook = process.env.IS_STORYBOOK

  return !isStorybook ? (
    <svg {...rest}>
      {title ? <title>{title}</title> : null}
      <use
        href={href + "#root"}
        style={mainColor ? { color: formatColor(mainColor) } : undefined}
      />
    </svg>
  ) : (
    <StorybookColorableSVG href={href} mainColor={mainColor} {...rest} />
  )
}
export default ColorableSVG
