import { useEffect } from "react"

import useIsClient from "./useIsClient"
import useSessionStorage from "./useSessionStorage"

export default function useIsMobileWebView() {
  const [mobileWebView, setMobileWebView, didReadLocalStorage] =
    useSessionStorage<boolean | null>("mobile-webview", null)
  const isClient = useIsClient()

  useEffect(() => {
    if (didReadLocalStorage && mobileWebView === null) {
      //Get the query string "view" from the url for the headless website
      const urlParams = isClient && new URLSearchParams(window.location.search)
      const view = urlParams && urlParams.get("view")
      const isMobileWebView = view === "alternate"
      setMobileWebView(isMobileWebView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileWebView, setMobileWebView])
  return mobileWebView
}
