import { CSSProperties } from "react"

import InitFromMap from "~/utils/InitFromMap"
import { RichCase, RichEnum } from "~/utils/RichEnum"

class HeaderSectionCase extends InitFromMap {
  messageId!: string
  urlMessageId!: string
  localePredicate!: (locale: string) => boolean
  subsections!: HeaderSubsection[]

  static defaultValues = {
    localePredicate: () => true,
  }
}

export class HeaderSubsection extends InitFromMap {
  id!: string
  messageId!: string
  urlMessageId!: string
  localePredicate!: (locale: string) => boolean
  color!: CSSProperties["color"]

  static defaultValues = {
    localePredicate: () => true,
  }
}

const HeaderSection = RichEnum({
  Recipes: new HeaderSectionCase({
    messageId: "header/section:recipes",
    urlMessageId: "url:recipes",
    subsections: [],
  }),
  Community: new HeaderSectionCase({
    messageId: "header/section:community",
    urlMessageId: "url:community",
    subsections: [],
  }),
  Kids: new HeaderSectionCase({
    messageId: "brand:kids",
    urlMessageId: "url:kids-home",
    subsections: [],
  }),
})
type HeaderSection = RichCase<typeof HeaderSection>

export default HeaderSection
