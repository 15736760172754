import { RefObject, useCallback, useEffect, useRef, useState } from "react"

import playVideo from "./playVideo"
import useEventListener from "~/hooks/useEventListener"
import useIntersectionObserver from "~/hooks/useIntersectionObserver"
import useInterval from "~/hooks/useInterval"
import useTimeout from "~/hooks/useTimeout"

const DURATION_BEFORE_PAYWALL = 5000

interface PlayerState {
  isFullscreen: boolean
  isLoaded: boolean
  percentTime: number
  isPlaying: boolean
  displayInitialPlayIcon: boolean
  isMuted: boolean
  loop: boolean
  autoPlay: boolean
  disableControls: boolean
  showPaywall: boolean
}
interface PlayerMethods {
  setFullscreen: (value: boolean) => void
  setMuted: (value: boolean) => void
  goBackward: () => void
  goForward: () => void
  play: () => void
  pause: () => void
  setTimeFromPercent: (value: number) => void
}

type UsePlayerControls = [
  RefObject<HTMLVideoElement>,
  PlayerState,
  PlayerMethods
]

export interface PlayerProps {
  src: string
  paywallEnabled: boolean
  disableControls?: boolean
  autoPlay?: boolean
  loop?: boolean
}

function usePlayerControls({
  src,
  paywallEnabled,
  ...props
}: PlayerProps): UsePlayerControls {
  const videoEl = useRef<HTMLVideoElement>(null)

  // Make optional fields to strict booleans or set a default value
  const disableControls = !!props.disableControls
  const loop = props.loop === undefined ? disableControls : props.loop
  const autoPlay =
    props.autoPlay === undefined ? disableControls : props.autoPlay

  // State
  const [isFullscreen, setFullscreen] = useState<boolean>(false)
  const [isMuted, setMuted] = useState<boolean>(autoPlay)
  const [isPlaying, setPlay] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [showPaywall, setShowPaywall] = useState<boolean>(false)
  const [percentTime, setPercentTime] = useState<number>(100)

  const videoObserver = useIntersectionObserver(videoEl.current, {
    rootMargin: "-50px",
    threshold: [0, 1],
  })

  // Display just play icon on page load if autoplay is falsy
  const displayInitialPlayIcon =
    !isPlaying && percentTime === 100 && !disableControls

  const play = async () => {
    // Optimistically update, thant will update UI
    setPlay(true)

    try {
      await videoEl.current?.play()

      setPlay(true)

      if (!isMuted) {
        // We dispatch a custom event so every players are notified
        window.dispatchEvent(
          new CustomEvent("video-started-playing", {
            detail: { videoId: src },
          })
        )
      }
    } catch (error) {
      console.error(error)
      setPlay(false)
    }
  }

  const pause = () => {
    if (videoEl.current) {
      videoEl.current.pause()
    } else {
      setIsLoaded(false)
    }

    setPlay(false)
  }

  const goForward = () => {
    if (videoEl.current) {
      videoEl.current.currentTime += 10
      updatePercentTime()
    }
  }

  const goBackward = () => {
    if (videoEl.current) {
      videoEl.current.currentTime -= 10
      updatePercentTime()
    }
  }

  useEffect(() => {
    const loadVideo = async () => {
      if (videoEl.current && videoEl.current.paused && !isLoaded) {
        try {
          await playVideo({
            src,
            player: videoEl.current,
            autoPlay,
          })
          setIsLoaded(true)
        } catch (e) {
          console.error(e)
        }
      }
    }

    loadVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoEl, src, isLoaded, showPaywall])

  // Update state when video plays/pauses
  const didStartPlaying = useCallback(() => {
    if (!isLoaded && src !== "") {
      setIsLoaded(true)
    }
  }, [isLoaded, src])
  useEventListener("play", didStartPlaying, videoEl || undefined)
  useEventListener("pause", () => setPlay(false), videoEl || undefined)

  // Stop the current player if another is started
  useEventListener(
    "video-started-playing",
    (event: CustomEvent<{ videoId: string }>) => {
      // Ignore if it's the current player
      if (event.detail.videoId === src) return

      if (isMuted) return

      pause()
    }
  )

  // Autoplay
  useEffect(() => {
    if (videoObserver?.isIntersecting && autoPlay) {
      play()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoEl.current, videoObserver?.isIntersecting])

  // Stop the video if the paywall is shown
  useTimeout(
    () => {
      if (!showPaywall) setShowPaywall(true)
      if (isPlaying) pause()
    },
    paywallEnabled ? DURATION_BEFORE_PAYWALL : null
  )

  //Restart video if user log in
  useEffect(() => {
    setShowPaywall(false)
  }, [paywallEnabled])

  // Mute the player
  useEffect(() => {
    if (videoEl.current) {
      videoEl.current.muted = isMuted
    }
  }, [isMuted])

  const updatePercentTime = () => {
    let percent = 100
    if (videoEl.current?.currentTime && videoEl.current?.duration) {
      const { currentTime, duration } = videoEl.current

      if (currentTime === duration) {
        // End of the video
        videoEl.current.currentTime = 0
        if (loop) {
          play()
        } else {
          pause()
        }
      } else {
        percent = (currentTime * 100) / duration
      }
    }
    setPercentTime(percent)
  }

  // Update the progress bar
  useInterval(updatePercentTime, isPlaying ? 200 : null)

  const setTimeFromPercent = (newTimeInPercent: number) => {
    if (!isPlaying) {
      play()
    }

    if (videoEl.current?.duration) {
      const { duration } = videoEl.current
      const newTimeInSeconds = (duration * newTimeInPercent) / 100

      videoEl.current.currentTime = newTimeInSeconds
      setPercentTime(newTimeInPercent)
    }
  }

  return [
    videoEl,
    {
      isFullscreen,
      isLoaded,
      percentTime,
      isPlaying,
      displayInitialPlayIcon,
      isMuted,
      loop,
      autoPlay,
      disableControls,
      showPaywall,
    },
    {
      setFullscreen,
      setMuted,
      goBackward,
      goForward,
      play,
      pause,
      setTimeFromPercent,
    },
  ]
}

export default usePlayerControls
