import React, { CSSProperties } from "react"

import { FluidObject } from "gatsby-image"

import SimpleBanner from "~/components/SimpleBanner/SimpleBanner"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

export interface FormattableTextProps {
  title: PrismicStructuredText
  text: PrismicStructuredText
  buttonLabel: PrismicStructuredText
  backgroundColor: null | CSSProperties["color"]
  textColor: null | CSSProperties["color"]
  layout: "Image, text" | "Text, image"
  image: {
    dimensions: {
      height: number
      width: number
    }
    fluid: null | FluidObject
  }
  link: PrismicLink
  isLeadingSlice?: boolean
  anchorId?: string
}

function FormattableText({
  title,
  text,
  buttonLabel,
  layout,
  image,
  link,
  isLeadingSlice,
  ...props
}: FormattableTextProps) {
  const linkProps = getPrismicLinkProps(link)

  return (
    <SimpleBanner
      isLeadingSlice={isLeadingSlice}
      linkProps={linkProps || undefined}
      backgroundColor={props?.backgroundColor || undefined}
      bodyColor={props?.textColor || undefined}
      image={image}
      title={title.text}
      description={text.text}
      buttonLabel={buttonLabel.text}
      center
    />
  )
}

export default FormattableText
