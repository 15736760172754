import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import List, { ListProps } from "~/components/slices/List/List"
import { PrismicStructuredText } from "~/models/PrismicTypes"

import css from "./PrismicNavMenuLayoutMobile2ColDesktop1Col.module.scss"

interface PropTypes extends PrismicLayoutMobile2ColDesktop1Col {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function PrismicNavMenuLayoutMobile2ColDesktop1Col(props: PropTypes) {
  return <div className={css.layoutMobile2ColDesktop1Col} />
}

export default PrismicNavMenuLayoutMobile2ColDesktop1Col

export interface PrismicLayoutMobile2ColDesktop1Col extends PrismicNavSlice {
  sliceType: "layout"
  variation: "mobile2ColDesktop1Col"
}

export const query = graphql`
  fragment PrismicLayoutMobile2ColDesktop1Col on PrismicLayoutMobile2ColDesktop1Col {
    id
    sliceType: slice_type
    variation
  }
`
