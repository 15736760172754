import facebookIcon from "./facebook.svg"
import instagramIcon from "./instagram.svg"
import pinterestIcon from "./pinterest.svg"
import snapchatIcon from "./snapchat.svg"
import tiktokIcon from "./tiktok.svg"
import youtubeIcon from "./youtube.svg"

export class SocialNetwork {
  nameMessageId: string
  accountURLMessageId: string
  colorableIcon: string

  constructor({
    nameMessageId,
    accountURLMessageId,
    colorableIcon,
  }: {
    nameMessageId: string
    accountURLMessageId: string
    colorableIcon: string
  }) {
    this.nameMessageId = nameMessageId
    this.accountURLMessageId = accountURLMessageId
    this.colorableIcon = colorableIcon
  }
}

export const facebook = new SocialNetwork({
  nameMessageId: "brand:facebook",
  accountURLMessageId: "url:facebook-account",
  colorableIcon: facebookIcon,
})

export const youtube = new SocialNetwork({
  nameMessageId: "brand:youtube",
  accountURLMessageId: "url:youtube-account",
  colorableIcon: youtubeIcon,
})

export const instagram = new SocialNetwork({
  nameMessageId: "brand:instagram",
  accountURLMessageId: "url:instagram-account",
  colorableIcon: instagramIcon,
})

export const snapchat = new SocialNetwork({
  nameMessageId: "brand:snapchat",
  accountURLMessageId: "url:snapchat-account",
  colorableIcon: snapchatIcon,
})

export const tiktok = new SocialNetwork({
  nameMessageId: "brand:tiktok",
  accountURLMessageId: "url:tiktok-account",
  colorableIcon: tiktokIcon,
})

export const pinterest = new SocialNetwork({
  nameMessageId: "brand:pinterest",
  accountURLMessageId: "url:pinterest-account",
  colorableIcon: pinterestIcon,
})
