import React from "react"

import { RichText } from "prismic-reactjs"
import { useIntl } from "react-intl"

import Link from "~/components/Link/Link"
import { Col, Heading, ImageCard, Row, Textarea } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import useIsClient from "~/hooks/useIsClient"
import { PartialRecipe } from "~/models/PartialRecipe"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import pathFromFullId from "~/routes/pathFromFullId"

import css from "./TextRecipeColumns.module.scss"

export interface TextRecipeColumnsProps {
  title: PrismicStructuredText
  subtitle: PrismicStructuredText
  text: PrismicStructuredText
  recipeUid: string
  layout: "Recipe, text" | "Text, recipe"
  anchorId?: string
}

export interface PropTypes extends TextRecipeColumnsProps {
  isDarkTheme?: boolean
  isPreview?: boolean
  currentRecipe?: PartialRecipe
  isLeadingSlice?: boolean
  eagerImages?: boolean
}

function TextRecipeColumns({
  title,
  subtitle,
  text,
  layout,
  recipeUid,
  isDarkTheme,
  isPreview,
  currentRecipe,
  isLeadingSlice,
  eagerImages,
}: PropTypes) {
  const intl = useIntl()
  const reverse = layout === "Text, recipe"
  const isClient = useIsClient()

  const recipeUrl = pathFromFullId("locale/recipes/recipe", intl, currentRecipe)

  // Hack: We have 3 links for the user (UX) but only one for Google (SEO)
  const goToLink = () => {
    if (isClient) {
      navigate(recipeUrl)
    }
  }
  return (
    <Row reverse={[false, reverse]} align={["initial", "middle"]}>
      <Col width={[12, 5]}>
        {currentRecipe ? (
          <ImageCard
            eagerImages={eagerImages}
            imageProps={{
              src: currentRecipe.webCoverThumbnail,
            }}
            overlay={{ type: "recipe" }}
            color={`var(--${currentRecipe.vertical}-color)`}
            onClick={goToLink}
          />
        ) : (
          <ImageCard
            eagerImages={eagerImages}
            overlay={{
              type: "not-found",
              payload: isPreview ? recipeUid : undefined,
            }}
          />
        )}
      </Col>
      <Col width={[12, 7]}>
        {title?.text && (
          <Heading
            Tag={isLeadingSlice ? "h1" : undefined}
            className={css.title}
            color={isDarkTheme ? "white" : "body"}
          >
            <Link to="recipes/recipe" params={currentRecipe} title="">
              {title.text}
            </Link>
          </Heading>
        )}

        {subtitle?.text && (
          <Heading color={currentRecipe?.vertical} className={css.subtitle}>
            {subtitle.text}
          </Heading>
        )}

        <Textarea
          className={css.textarea}
          color={isDarkTheme ? "white" : "body"}
        >
          <RichText render={text.raw} />{" "}
          <span className={css.cta} onClick={goToLink}>
            {intl.formatMessage({ id: "action:see-the-recipe" })}
          </span>
        </Textarea>
      </Col>
    </Row>
  )
}

export default TextRecipeColumns
