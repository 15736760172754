import React from "react"

import cn from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

import { Body, Heading, HrefButton, Row } from "~/components/ui"

import css from "./CreatorsHowItWorks.module.scss"

interface PropTypes {
  isPreview?: boolean
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
}

interface StepPropTypes {
  number: number
  title: string
  text: string
}

function Step({ title, text, number }: StepPropTypes) {
  return (
    <div className={css.step}>
      <div className={css.stepNumber}>
        <Heading variant="large" color="white" align="center">
          {number}
        </Heading>
      </div>
      <div className={css.stepText}>
        <Heading
          className={css.stepHeading}
          Tag="h4"
          variant="medium"
          align="left"
        >
          {title}
        </Heading>
        <Body align="left">{text}</Body>
      </div>
    </div>
  )
}

function CreatorsHowItWorks({ isLeadingSlice, isDarkTheme }: PropTypes) {
  const intl = useIntl()
  return (
    <Row justify="center">
      <div
        className={cn(css.root, {
          [css.darkTheme]: isDarkTheme,
        })}
      >
        <div className={css.heading}>
          <Heading
            Tag={isLeadingSlice ? "h1" : undefined}
            variant="large"
            align="center"
          >
            {intl.formatMessage({ id: "creators/text:how-it-works" })}
          </Heading>
        </div>
        <div className={css.timeline}>
          <div className={css.steps}>
            <div className={css.stepsVerticalLine} />
            <Step
              number={1}
              title={intl.formatMessage({ id: "creators/text:step-1-heading" })}
              text={intl.formatMessage({
                id: "creators/text:step-1-paragraph",
              })}
            />
            <Step
              number={2}
              title={intl.formatMessage({ id: "creators/text:step-2-heading" })}
              text={intl.formatMessage({
                id: "creators/text:step-2-paragraph",
              })}
            />
            <Step
              number={3}
              title={intl.formatMessage({ id: "creators/text:step-3-heading" })}
              text={intl.formatMessage({
                id: "creators/text:step-3-paragraph",
              })}
            />
          </div>

          <div className={css.subscribe}>
            <div className={css.subscribeVerticalLine} />
            <Heading
              variant="extraExtraLarge"
              uppercase
              color="white"
              className={css.subscribeHeading}
              Tag="h3"
            >
              {intl.formatMessage({ id: "creators/text:register" })}
            </Heading>
            <HrefButton
              href={intl.formatMessage({ id: "url:creators-form" })}
              variant="primary"
              size="medium"
              color="original"
            >
              <FormattedMessage id="creators/action:i-become-creator" />
            </HrefButton>
          </div>
        </div>
      </div>
    </Row>
  )
}

export default CreatorsHowItWorks
