import React from "react"

import { graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import { default as SlickSlider, Settings } from "react-slick"

import "~/composable-styles/vendors/slick.scss"
import TimelineCardMediaSliderArrow from "./TimelineCardMediaSliderArrow"
import { BasePrismicTimelineSlice } from "~/models/PrismicTypes"

import css from "./TimelineCardCarousel.module.scss"

type PropTypes = PrismicTimelineBodyCarousel

function TimelineCardCarousel({ items }: PropTypes) {
  const settings: Settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <TimelineCardMediaSliderArrow direction="next" />,
    prevArrow: <TimelineCardMediaSliderArrow direction="prev" />,
  }

  return (
    <span className={css.imageWrap}>
      <SlickSlider {...settings}>
        {items.map(
          ({ image }, i) =>
            image.fluid && (
              <Image
                key={i}
                fadeIn={false}
                fluid={image.fluid}
                alt={image?.alt || ""}
              />
            )
        )}
      </SlickSlider>
    </span>
  )
}

export default TimelineCardCarousel

export interface PrismicTimelineBodyCarousel extends BasePrismicTimelineSlice {
  sliceType: "carousel"
  items: Array<{
    image: {
      alt: string | null
      fluid: FluidObject | null
    }
  }>
}

export const query = graphql`
  fragment PrismicTimelineV2DataBodyCarousel on PrismicTimelineV2DataBodyCarousel {
    id
    __typename
    sliceType: slice_type
    primary {
      date(formatString: "MMMM YYYY", locale: $lang)
      title {
        ...PrismicStructuredText
      }
      description {
        ...PrismicStructuredText
      }
    }
    items {
      image {
        alt
        fluid(maxWidth: 716) {
          ...GatsbyImgixFluid_noBase64
        }
      }
    }
  }
`
