import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import fetch from "isomorphic-fetch"

export const klaviyoApi = createApi({
  reducerPath: "klaviyoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://a.klaviyo.com/api",
    fetchFn: fetch,
    prepareHeaders: headers => {
      headers.set("Content-Type", "application/x-www-form-urlencoded")
      headers.set("Accept", "text/html")
      return headers
    },
  }),
  endpoints: builder => ({
    subscribeFooterNewsletter: builder.mutation<
      { result: number },
      { apiKey: string; user: string }
    >({
      query: param => ({
        url: `/track`,
        method: "POST",
        body: `data={"token": "${param.apiKey}", "event": "Content - Subscribed to Newsletter via Site Footer", "customer_properties": {"$email": "${param.user}"}}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          console.log("data", data)
          if (data) {
            console.log("Pushing dataLayer")
            window.dataLayer.push({
              event: "inscription_newsletter",
            })
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const { useSubscribeFooterNewsletterMutation } = klaviyoApi
