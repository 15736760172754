import { useEffect, useState } from "react"

import {
  readFromSessionStorage,
  saveInSessionStorage,
} from "~/utils/sessionStorage"

export default function useSessionStorage<T>(
  key: string,
  initialValue: T
): [T, (newValue: T) => void, boolean] {
  const [didReadSessionStorage, setDidReadSessionStorage] = useState(false)
  const [storedValue, setStoredValue] = useState<T>(initialValue)

  const readValueFromSessionStorage = () => {
    const storedValue = readFromSessionStorage<T>(key)

    if (!didReadSessionStorage) {
      setDidReadSessionStorage(true)
    }

    if (storedValue !== undefined && storedValue !== null) {
      return storedValue
    }

    return initialValue
  }

  const writeValueToSessionStorage = (value: T) => {
    saveInSessionStorage(key, value)
  }

  useEffect(() => {
    setStoredValue(readValueFromSessionStorage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValueFromSessionStorage())
    }
    // this only works for other documents, not the current one
    window.addEventListener("storage", handleStorageChange)
    // this is a custom event, triggered in writeValueToSessionStorage
    window.addEventListener("session-storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
      window.removeEventListener("session-storage", handleStorageChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [storedValue, writeValueToSessionStorage, didReadSessionStorage]
}
