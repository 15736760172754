import React from "react"

import { FormattedMessage } from "react-intl"

import LandingsFullWidthCard from "../LatestLandings/Cards/LandingsFullWidthCard"
import LandingsSmallCard from "../LatestLandings/Cards/LandingsSmallCard"
import Link from "~/components/Link/Link"
import { Col, Heading, HrefButton, Row } from "~/components/ui"
import { LatestLanding } from "~/templates/Landing/Landing"

import css from "./ListWithHighlightedItem.module.scss"
export interface ListWithHighlightedItemProps {
  primary: {
    titleListWithHighlightedItem: string
    toButton: {
      url: string
    }
  }
  items: [
    {
      sourcePage: {
        uid: string
      }
    }
  ]
  allLandings: LatestLanding[] | undefined
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function ListWithHighlightedItem({
  primary,
  items,
  allLandings,
  isDarkTheme,
  eagerImages,
}: ListWithHighlightedItemProps) {
  const tagColor = "var(--gray5-color)"
  const temporaryLandings: LatestLanding[] = []
  items.forEach(item => {
    allLandings?.forEach(landing => {
      if (item.sourcePage.uid === landing.uid) {
        temporaryLandings.push(landing)
      }
    })
  })
  const selectedLandings = temporaryLandings.slice(0, 4)
  return (
    <>
      {selectedLandings.length && (
        <div className={css.content}>
          <div className={css.heading}>
            <Heading variant="large" color={isDarkTheme ? "white" : "body"}>
              {primary.titleListWithHighlightedItem}
            </Heading>
            <HrefButton
              className={css.seeMoreButton}
              variant="tertiary"
              href={primary.toButton?.url}
              color="original"
              size="medium"
              underline={false}
            >
              <FormattedMessage id="action:load-more" />
            </HrefButton>
          </div>
          <Row>
            <Col width={[12, 7]}>
              <Link to="s/landing" params={{ uid: selectedLandings[0].uid }}>
                <LandingsFullWidthCard
                  tag={selectedLandings[0].tags[0]}
                  landing={selectedLandings[0]}
                  isDarkTheme={isDarkTheme}
                  tagColor={tagColor}
                  eagerImages={eagerImages}
                />
              </Link>
            </Col>
            <Col width={[12, 5]} className={css.smallList}>
              {selectedLandings?.map((landing, index) =>
                index === 0 ? null : (
                  <Link
                    to="s/landing"
                    params={{ uid: landing.uid }}
                    key={index}
                  >
                    <LandingsSmallCard
                      tag={landing.tags[0]}
                      landing={landing}
                      isDarkTheme={isDarkTheme}
                      tagColor={tagColor}
                      eagerImages={eagerImages}
                    />
                  </Link>
                )
              )}
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default ListWithHighlightedItem
