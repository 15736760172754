import React, { CSSProperties, ReactNode } from "react"

import { CSSTransition } from "react-transition-group"
import { CSSTransitionProps } from "react-transition-group/CSSTransition"

import { deduplicate } from "~/utils/array"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./VisibilityTransition.module.scss"

// TODO: Actually we can't concat all CSS properties (e.g. transform)
// So, OneOrMore type may not be a good idea
// Being specific would probably be better, even if you lose the combination
type OneOrMore<T> = T | T[]

interface PropTypes {
  show?: boolean
  children?: ReactNode
  variant?: OneOrMore<"fade" | "scale" | "fromTop">
  duration?: number
  transitionProps?: Partial<CSSTransitionProps>
}

const propertiesByVariant = {
  fade: "opacity",
  scale: "transform",
  fromTop: "transform",
}

function VisibilityTransition({
  show = true,
  variant = "fade",
  duration = 1000,
  children,
  transitionProps,
}: PropTypes) {
  const variants = Array.isArray(variant) ? variant : [variant]
  const properties = deduplicate(variants.map(v => propertiesByVariant[v]))
  const cssTransitions = properties.map(p => `${p} ${duration}ms ease-out`)

  const classNames = variants.reduce<Record<string, string>>(
    (prevCN, currVariant) => ({
      ...prevCN,
      appear: `${prevCN["appear"]} ${css[`${currVariant}Appear`]}`,
      enter: `${prevCN["enter"]} ${css[`${currVariant}Enter`]}`,
      exit: `${prevCN["exit"]} ${css[`${currVariant}Exit`]}`,
    }),
    {
      appearActive: css.active,
      enterActive: css.active,
      exitActive: css.active,
    }
  )

  return (
    <div
      style={
        {
          "--transition": `${cssTransitions.join(", ")}`,
        } as CSSProperties
      }
    >
      <CSSTransition
        in={show}
        timeout={duration}
        unmountOnExit
        classNames={classNames}
        {...transitionProps}
      >
        <div>{children}</div>
      </CSSTransition>
    </div>
  )
}

export default VisibilityTransition
