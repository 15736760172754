/* eslint-disable */
import { isProd } from "~/utils/general"
;(function (w, d, s, l) {
  const GTMId = isProd ? "GTM-W5NC9L2" : "GTM-5W3HZHB"
  w[l] = w[l] || []
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  })
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : ""
  j.async = false
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + GTMId + dl
  f.parentNode.insertBefore(j, f)
})(window, document, "script", "dataLayer")

dataLayer.push({ "site stack": "gatsby" })
