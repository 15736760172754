import React from "react"

import { graphql } from "gatsby"

import Button, { ButtonProps } from "~/components/slices/Button/Button"
import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"

interface PropTypes extends PrismicLandingDataBodyButton {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function LandingBodyButton(props: PropTypes) {
  return (
    <SliceSection anchorId={props.primary.anchorId} {...props}>
      <Button {...props.primary} />
    </SliceSection>
  )
}

export default LandingBodyButton

export interface PrismicLandingDataBodyButton extends PrismicSlice {
  sliceType: "button"
  primary: ButtonProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyButton on PrismicLandingDataBodyButton {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      link {
        ...PrismicLink
      }
      label {
        ...PrismicStructuredText
      }
      anchorId: id
    }
  }
`
