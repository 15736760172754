import React, { useState } from "react"

import { useIntl } from "react-intl"

import CloseIcon from "~/components/Icons/CloseIcon"
import Modal from "~/components/Modals/Modal"
import { Col, Row } from "~/components/ui"
import useIsClient from "~/hooks/useIsClient"
import { useModal } from "~/hooks/useModal"
import { ChefclubLocale, getPrismicLocaleFromLocale } from "~/utils/locales"

import css from "./WelcomeModal.module.scss"

const WelcomeModal = () => {
  const intl = useIntl()
  const [openModal, setOpenModal] = useState(false)
  const isClient = useIsClient()

  const currentModal = useModal(
    getPrismicLocaleFromLocale(intl.locale as ChefclubLocale)
  )

  if (!currentModal) return null

  const openWelcomeModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  //Allows us to open the modal from the console
  if (isClient) {
    window.openChristmasModal = openWelcomeModal
  }
  return (
    <Modal open={openModal} onClose={closeModal} unmountOnExit mode="fixed">
      {modalRef => (
        <div className={css.root} ref={modalRef}>
          <div className={css.closeButton} onClick={closeModal}>
            {/* TODO : Icon design system */}
            <CloseIcon color="white" />
          </div>
          <a
            href={currentModal.modalLink.url}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              window.dataLayer.push({
                event: "popin_découvrir_boutique",
              })
            }
          >
            <Row className={css.rowModal}>
              <Col
                width={[0, 12]}
                className={css.desktopImageContainer}
                style={{
                  backgroundImage: `url(${currentModal.desktopImageUrl})`,
                }}
              />
              <Col
                width={[12, 0]}
                className={css.mobileImageContainer}
                style={{
                  backgroundImage: `url(${currentModal.mobileImageUrl})`,
                }}
              />
            </Row>
          </a>
        </div>
      )}
    </Modal>
  )
}

export default WelcomeModal
