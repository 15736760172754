import { createApi } from "@reduxjs/toolkit/query/react"

import { getBaseQuery } from "../getBaseQuery"
import { APIRecipe } from "~/models/Recipe"

interface FavoriteRequest {
  recipeId: string
}

interface APIFavoriteListResponse {
  next: string | null
  previous: string | null
  results: APIRecipe[]
}

export const favoriteApi = createApi({
  reducerPath: "favoriteApi",
  baseQuery: getBaseQuery("/buckets/default/collections/saved-recipes/records"),
  endpoints: builder => ({
    isFavorite: builder.query<any, FavoriteRequest>({
      query: params => ({
        url: `/${params.recipeId}`,
        method: "GET",
      }),
    }),

    addToFavorites: builder.mutation<any, FavoriteRequest>({
      query: params => ({
        url: `/${params.recipeId}`,
        method: "PUT",
      }),
    }),

    removeFromFavorites: builder.mutation<any, FavoriteRequest>({
      query: params => ({
        url: `/${params.recipeId}`,
        method: "DELETE",
      }),
    }),
  }),
})

export const fetchFavoriteApi = createApi({
  reducerPath: "fetchFavoriteApi",
  baseQuery: getBaseQuery("/saved-recipes"),
  endpoints: builder => ({
    getFavorite: builder.query<APIFavoriteListResponse, { [key: string]: any }>(
      {
        query: params => ({
          url: "",
          method: "GET",
          params,
        }),
      }
    ),
  }),
})

export const {
  useIsFavoriteQuery,
  useAddToFavoritesMutation,
  useRemoveFromFavoritesMutation,
} = favoriteApi

export const { useGetFavoriteQuery } = fetchFavoriteApi
