import React, { CSSProperties } from "react"

import loadable from "@loadable/component"
import cn from "classnames"
import contrast from "contrast"

import { SliceVideoControls } from "../common/SliceTypes"
import { getVideoControlsProps } from "../common/SliceUtils"
import {
  Button,
  Col,
  Container,
  Heading,
  PrismicTextarea,
  Row,
} from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import useIsClient from "~/hooks/useIsClient"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { Video } from "~/models/Video"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./VideoTextColumns.module.scss"

const VideoPlayer = loadable(
  () => import("~/components/VideoPlayer/VideoPlayer")
)

type PrismicVideoTextLayout = "Video, text" | "Text, video"

export interface VideoTextColumnsProps {
  layout: PrismicVideoTextLayout
  backgroundColor: CSSProperties["color"] | null
  title: PrismicStructuredText
  text: PrismicStructuredText
  buttonLabel: PrismicStructuredText
  buttonColor: null | string
  link: PrismicLink
  videoUid: null | string
  videoControls: SliceVideoControls
  anchorId?: string
}

export interface PropTypes extends VideoTextColumnsProps {
  isDarkTheme?: boolean
  isPreview?: boolean
  currentVideo: Video
  isLeadingSlice?: boolean
}

function getFontColor(color: string): "body" | "white" {
  return contrast(color) === "dark" ? "white" : "body"
}

function VideoTextColumns({
  title,
  text,
  buttonLabel,
  layout,
  link,
  isDarkTheme,
  buttonColor,
  currentVideo,
  backgroundColor,
  isLeadingSlice,
  ...props
}: PropTypes) {
  const reverse = layout === "Text, video"
  const isClient = useIsClient()

  const linkAttr = getPrismicLinkProps(link)
  const videoControls = getVideoControlsProps(props.videoControls)
  const textColor = backgroundColor
    ? getFontColor(backgroundColor)
    : isDarkTheme
    ? "white"
    : "body"

  // Hack: We have 3 links for the user (UX) but only one for Google (SEO)
  const goToLink = () => {
    if (linkAttr?.href && isClient) {
      navigate(linkAttr.href)
    }
  }

  const color = buttonColor || "black"
  return (
    <div
      className={cn(css.root, {
        [css.hasBackground]: !!backgroundColor,
      })}
      style={{
        backgroundColor: backgroundColor || "inherit",
      }}
    >
      <Container>
        <Row reverse={[false, reverse]} align={["initial", "middle"]}>
          <Col width={[12, 5]}>
            <VideoPlayer
              poster={currentVideo.videoPreview}
              src={currentVideo.hostedVideoUrl}
              verticalColor={color}
              format={"square"}
              videoFit="contain"
              {...videoControls}
            />
          </Col>
          <Col width={[12, 7]}>
            {title?.text && (
              <Heading
                Tag={isLeadingSlice ? "h1" : undefined}
                variant="large"
                className={css.title}
                color={textColor}
              >
                <a {...linkAttr} title="">
                  {title.text}
                </a>
              </Heading>
            )}

            <PrismicTextarea text={text} color={textColor} />

            {buttonLabel?.text && (
              <Button
                className={css.cta}
                onClick={goToLink}
                color={color}
                fullWidth={[true, false]}
              >
                {buttonLabel.text}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default VideoTextColumns
