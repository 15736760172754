import { graphql, useStaticQuery } from "gatsby"

import {
  ChefclubLocale,
  getPrismicLocaleFromLocale,
  PrismicLocale,
} from "~/utils/locales"

const SPECIALLANDINGS = {
  web_home_landing: "",
  web_home_community_landing: "path:community",
  web_home_kids_landing: "kids",
}

type allAppSettingsQuery = {
  allAppSettings: {
    nodes: {
      webHomeCommunityLanding: string | null
      webHomeKidsLanding: string | null
      webHomeLanding: string | null
      lang: PrismicLocale
    }[]
  }
}

export default function useSpecialLandingPath(
  currentLocale: ChefclubLocale,
  uid?: string
) {
  const allAppSettingsLandings: allAppSettingsQuery = useStaticQuery(graphql`
    query allAppSettingsQuery {
      allAppSettings {
        nodes {
          webHomeCommunityLanding: web_home_community_landing
          webHomeKidsLanding: web_home_kids_landing
          webHomeLanding: web_home_landing
          lang
        }
      }
    }
  `)
  if (!uid) return null
  const localeLandings = allAppSettingsLandings.allAppSettings.nodes.find(
    e => e.lang === getPrismicLocaleFromLocale(currentLocale)
  )

  const uidSpecialLandings = new Map([
    [localeLandings?.webHomeLanding, SPECIALLANDINGS.web_home_landing],
    [
      localeLandings?.webHomeCommunityLanding,
      SPECIALLANDINGS.web_home_community_landing,
    ],
    [localeLandings?.webHomeKidsLanding, SPECIALLANDINGS.web_home_kids_landing],
  ])
  return uidSpecialLandings.get(uid) ? uidSpecialLandings.get(uid) : null
}
