import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import TextImageColumnsV2, {
  TextImageColumnsV2Props,
} from "~/components/slices/TextImageColumnsV2/TextImageColumnsV2"

interface PropTypes extends PrismicLandingDataBodyTextImageColumnsV2 {
  isPreview?: boolean
  isDarkTheme?: boolean
  noMargin?: boolean
  isLeadingSlice?: boolean
  eagerImages?: boolean
}

function LandingBodyTextImageColumnsV2({ primary, ...props }: PropTypes) {
  return (
    <SliceSection
      tags={!!primary.backgroundColor ? "o-has-background" : ""}
      margin="large"
      noContainer
      anchorId={primary.anchorId}
      {...props}
    >
      {({ isVisible }) => (
        <TextImageColumnsV2 {...{ ...primary, ...props, isVisible }} />
      )}
    </SliceSection>
  )
}

export default LandingBodyTextImageColumnsV2

export interface PrismicLandingDataBodyTextImageColumnsV2 extends PrismicSlice {
  sliceType: "text_image_columns_v2"
  primary: TextImageColumnsV2Props
}

export const query = graphql`
  fragment PrismicLandingDataBodyTextImageColumnsV2 on PrismicLandingDataBodyTextImageColumnsV2 {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      layout
      backgroundColor: background_color
      backgroundIsFullWidth: background_is_full_width
      title {
        ...PrismicStructuredText
      }
      text {
        ...PrismicStructuredText
      }
      link {
        ...PrismicLink
      }
      linkLabel: link_label {
        ...PrismicStructuredText
      }
      linkStyle: link_style
      buttonColor: button_color
      secondLink: second_link {
        ...PrismicLink
      }
      secondLinkLabel: second_link_label {
        ...PrismicStructuredText
      }
      image {
        alt
        gatsbyImageData(width: 500, placeholder: BLURRED)
        fluid(maxWidth: 500) {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          Square {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            fluid {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
      imageSize: image_size
      lottie {
        url
      }
      anchorId: id
    }
  }
`
