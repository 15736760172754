import React, { CSSProperties, useCallback, useMemo } from "react"

import Link from "~/components/Link/Link"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import useVisualViewport from "~/hooks/useVisualViewport"

import css from "./CarouselComponent.module.scss"

const CarouselComponent = ({
  src,
  title,
  link,
  param,
  children,
  ratio = 1,
}: {
  src: string
  title?: string
  link?: string
  param?: string
  children?: React.ReactNode
  ratio?: number
}) => {
  const componentRef = React.useRef<HTMLDivElement | null>(null)
  const [scale, setScale] = React.useState(1)
  const { viewport } = useVisualViewport()
  const width = useMemo(() => viewport.width / ratio, [viewport])

  const isCompact = useIsOnCompact()

  const getBorderDistance = useCallback(() => {
    if (componentRef.current) {
      const carouselRect = componentRef.current.getBoundingClientRect()

      const calc =
        Math.abs(
          viewport.width / 2 - (carouselRect.left + carouselRect.width / 2)
        ) / viewport.width

      setScale(Math.max(0.7, 1 - calc))
    }
  }, [componentRef, viewport])

  componentRef?.current?.parentElement?.addEventListener(
    "scroll",
    getBorderDistance
  )

  const content = (
    <>
      <img
        src={src}
        alt={title}
        style={{ width: `100%` }}
        className={css.carouselImage}
      />
      <div style={isCompact && scale < 0.8 ? { opacity: "0%" } : {}}>
        {children}
      </div>
    </>
  )

  return (
    <div
      ref={componentRef}
      className={css.root}
      style={
        width
          ? ({
              width: `${width}px`,
              "--margin-inline": `${-width * 0.15}px`,
            } as CSSProperties)
          : {}
      }
    >
      <div
        className={css.container}
        style={{ "--scale-var": scale } as CSSProperties}
      >
        {link ? (
          <Link
            to={`shows/shows-listing`}
            params={{ slug: link }}
            hashParam={param}
          >
            {content}
          </Link>
        ) : (
          content
        )}
      </div>
    </div>
  )
}

export default CarouselComponent
