import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import ImageImageColumns, {
  ImageImageColumnsProps,
} from "~/components/slices/ImageImageColumns/ImageImageColumns"

interface PropTypes extends PrismicLandingDataBodyImageImageColumns {
  eagerImages?: boolean
}

function LandingBodyImageImageColumns(props: PropTypes) {
  return (
    <SliceSection margin="small" anchorId={props.primary.anchorId} {...props}>
      <ImageImageColumns {...props.primary} />
    </SliceSection>
  )
}

export default LandingBodyImageImageColumns

export interface PrismicLandingDataBodyImageImageColumns extends PrismicSlice {
  sliceType: "image_image_columns"
  primary: ImageImageColumnsProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyImageImageColumns on PrismicLandingDataBodyImageImageColumns {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      leftImage: left_image {
        fluid(maxWidth: 456) {
          ...GatsbyImgixFluid_noBase64
        }
      }
      leftLink: left_link {
        ...PrismicLink
      }
      rightImage: right_image {
        fluid(maxWidth: 456) {
          ...GatsbyImgixFluid_noBase64
        }
      }
      rightLink: right_link {
        ...PrismicLink
      }
      anchorId: id
    }
  }
`
