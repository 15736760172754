import React from "react"

import cn from "classnames"
import { RichText } from "prismic-reactjs"

import TimelineCardCarousel, {
  PrismicTimelineBodyCarousel,
} from "./TimelineCardCarousel/TimelineCardCarousel"
import TimelineCardImage, {
  PrismicTimelineBodyImage,
} from "./TimelineCardImage/TimelineCardImage"
import TimelineCardYoutube, {
  PrismicTimelineBodyYoutube,
} from "./TimelineCardYoutube/TimelineCardYoutube"
import { Body, Heading } from "~/components/ui"
import { warnAboutUnexpectedSlice } from "~/utils/general"

import css from "./TimelineCard.module.scss"

export type AnyPrismicTimelineSlice =
  | PrismicTimelineBodyImage
  | PrismicTimelineBodyCarousel
  | PrismicTimelineBodyYoutube

function Slice(props: AnyPrismicTimelineSlice) {
  switch (props.sliceType) {
    case "image":
      return <TimelineCardImage {...props} />
    case "carousel":
      return <TimelineCardCarousel {...props} />
    case "youtube":
      return <TimelineCardYoutube {...props} />
    default:
      warnAboutUnexpectedSlice("Timeline", props)
      return null
  }
}

type PropTypes = AnyPrismicTimelineSlice & {
  index: number
  isFirst: boolean
}

function TimelineCard({ index, isFirst, ...props }: PropTypes) {
  const { date, title, description } = props.primary
  const left = index % 2 === 0

  return (
    <article
      className={cn(css.card, left ? css.left : css.right)}
      id={isFirst ? "firstStory" : ""}
    >
      <Slice {...props} />

      <div className={css.content}>
        <Heading className={css.cardTitle} uppercase>
          <span className={css.cardDate}>{date}</span>
          <br />
          {title.text}
        </Heading>
        <Body Tag="div" className={css.description}>
          <RichText render={description.raw} />
        </Body>
      </div>
    </article>
  )
}

export default TimelineCard
