import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import List from "~/components/slices/List/List"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { PartialCreator } from "~/models/PublicUser"

interface PropTypes extends PrismicListLastCreators {
  isPreview?: boolean
  isDarkTheme?: boolean
  lastCreators?: PartialCreator[]
}

function PrismicNavMenuListFromLastCreators(props: PropTypes) {
  const content = props.lastCreators
    ?.map(creator => {
      return {
        illustration: creator.profilePicture,
        text: creator.username,
        link: `/fr/profil/u/${creator.username}`,
      }
    })
    .slice(0, props.primary.displayMax)

  return (
    <SliceSection {...props}>
      {content && (
        <List headerIsNotHx={true} content={content} {...props.primary} small />
      )}
    </SliceSection>
  )
}

export default PrismicNavMenuListFromLastCreators

interface primary {
  title: PrismicStructuredText
  displayMax: number
  seeMoreLinkText?: string
  seeMoreLink?: PrismicLink
  imageForm?: string
}

export interface PrismicListLastCreators extends PrismicNavSlice {
  sliceType: "list"
  variation: "lastCreators"
  primary: primary
}

export const query = graphql`
  fragment PrismicListLastCreators on PrismicListLastCreators {
    id
    sliceType: slice_type
    variation
    primary {
      displayMax
      imageForm
      title {
        ...PrismicStructuredText
      }
      seeMoreLinkText
      seeMoreLink {
        ...PrismicLink
      }
    }
  }
`
