import React, { useEffect, useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import * as yup from "yup"

import Link from "~/components/Link/Link"
import {
  Body,
  Button,
  Fieldset,
  PasswordInput,
  TextInput,
} from "~/components/ui"
import useAutoRedirect from "~/hooks/useAutoRedirect"
import pathFromFullId from "~/routes/pathFromFullId"
import { useSignUpMutation } from "~/state/api/auth"
import { authSelector } from "~/state/modules/auth"
import { ErrorResponse } from "~/types/global-types"
import getFormValidators from "~/utils/formValidation"
import { ChefclubLocale, getPrismicLocaleFromLocale } from "~/utils/locales"

import css from "./RegisterForm.module.scss"

interface RegisterFormProps {
  username: string
  email: string
  password: string
  newsletter: boolean
}

type PropsType = {
  onLogIn?: () => void
  handleLogInLink?: () => void
}

export function RegisterForm({ onLogIn, handleLogInLink }: PropsType) {
  const intl = useIntl()
  const [signUp, { isLoading, isSuccess, isError, error }] = useSignUpMutation()
  const { email, password, requiredString } = getFormValidators(intl)
  const redirectUrl = pathFromFullId("locale/profile", intl)
  const { isLoggedIn } = useSelector(authSelector)

  useAutoRedirect(redirectUrl, !onLogIn && (isSuccess || isLoggedIn))

  useEffect(() => {
    if ((isSuccess || isLoggedIn) && onLogIn) {
      onLogIn()
    }
  }, [isSuccess, isLoggedIn, onLogIn])

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        username: requiredString,
        email,
        password,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, ...form } = useForm<RegisterFormProps>({
    resolver: yupResolver(validationSchema),
  })

  const submit = form.handleSubmit((values: RegisterFormProps) => {
    signUp({
      ...values,
      is_optin_newsletter: values.newsletter,
      // send "fr-fr" format instead just "fr"
      locale: getPrismicLocaleFromLocale(intl.locale as ChefclubLocale),
    })
  })

  const { errors, isSubmitted } = form.formState

  return (
    <form className={css.root} onSubmit={submit} noValidate>
      {isError && isSubmitted && (
        <Body
          variant="body3"
          color="error"
          align="center"
          noMargin
          role="alert"
          className={css.errorMessage}
        >
          {/* This error message is displayed in english */}
          {(error as unknown as { data?: ErrorResponse })?.data?.details}
        </Body>
      )}

      <Fieldset error={errors.username?.message}>
        <TextInput
          {...register("username")}
          placeholder={intl.formatMessage({
            id: "form/text:label-username",
          })}
          errors={errors.username}
          className={css.input}
        />
      </Fieldset>

      <Fieldset error={errors.email?.message}>
        <TextInput
          {...register("email")}
          type="email"
          placeholder={intl.formatMessage({ id: "form/text:label-email" })}
          errors={errors.email}
          className={css.input}
        />
      </Fieldset>

      <Fieldset error={errors.password?.message}>
        <PasswordInput
          {...register("password")}
          placeholder={intl.formatMessage({ id: "form/text:label-password" })}
          errors={errors.password}
          className={css.input}
        />
      </Fieldset>
      <Fieldset>
        <div className={css.checkboxFieldset}>
          <label className={css.checkboxLabel} htmlFor="newsletter">
            <input
              id="newsletter"
              type="checkbox"
              className={css.checkbox}
              {...register("newsletter")}
            />
            <div
              style={{ display: "inline" }}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: "form/text:accept-newsletter",
                }),
              }}
            />
          </label>
        </div>
      </Fieldset>

      <Body align="center" variant="body3">
        <a
          href={
            "/" +
            intl.locale +
            "/" +
            intl.formatMessage({ id: "path:legal-notices" })
          }
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="profile/text:accept-the-terms-creating-an-account" />
        </a>
      </Body>

      <Button
        className={css.button}
        type="submit"
        disabled={isLoading}
        color="original"
      >
        <FormattedMessage id="profile/text:create-an-account" />
      </Button>

      <Button variant="tertiary" underline color="original" fullWidth>
        {handleLogInLink ? (
          <span onClick={handleLogInLink} className={css.clickableSpan}>
            <FormattedMessage id="profile/text:connect" />
          </span>
        ) : (
          <Link to="profile/login">
            <FormattedMessage id="profile/text:connect" />
          </Link>
        )}
      </Button>
    </form>
  )
}
