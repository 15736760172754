import React from "react"

import { FormattedMessage } from "react-intl"

import { RecipeCarousel } from "~/components/Carousel/Carousel"
import { Container, Heading } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"
import Vertical from "~/models/Vertical"
import { isNotNullish } from "~/utils/general"

export interface PropTypes {
  recipes: PartialRecipe[]
  isPreview?: boolean
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function AllVerticalCarousel({ eagerImages, ...props }: PropTypes) {
  const orderedRecipes = Object.values(Vertical)
    .map(vertical =>
      props.recipes.find(recipe => recipe.vertical === vertical.id)
    )
    .filter(isNotNullish)

  return (
    <>
      <Container>
        <Heading variant="large" color={props.isDarkTheme ? "white" : "body"}>
          <FormattedMessage id="text:the-universes" />
        </Heading>
      </Container>
      <RecipeCarousel
        {...props}
        recipes={orderedRecipes}
        isVertical
        eagerImages={eagerImages}
      />
    </>
  )
}

export default AllVerticalCarousel
