import React from "react"

import { useLocation } from "@reach/router"
import Helmet from "react-helmet"
import { useIntl } from "react-intl"

import useSiteMetadata from "~/hooks/useSiteMetadata"

export type SeoLink = {
  rel: string
  href: string
  hreflang?: string
}
interface PropTypes {
  title: string
  location?: Location
  description?: string
  image?: string
  lang?: string
  link?: SeoLink[]
  meta?: { name: string; content: string }[]
  needsCanonical?: boolean
}

export default function SEO({
  title,
  description = "",
  image = "",
  lang = undefined,
  link = [],
  meta = [],
  needsCanonical = true,
}: PropTypes) {
  const intl = useIntl()
  const { siteUrl, ...siteMetadata } = useSiteMetadata()
  const location = useLocation()

  if (!lang) lang = intl.locale

  const links: { rel: string; href: string; hreflang?: string }[] = [...link]
  const hasCanonical = link.find(el => el.rel === "canonical")
  if (!hasCanonical && needsCanonical) {
    links.push({
      rel: "canonical",
      href: siteUrl + location.pathname || "",
    })
  }

  const imageMeta = image
    ? [
        {
          property: "og:image",
          content: image,
        },
        {
          name: "twitter:image",
          content: image,
        },
      ]
    : []

  const metaDescription = description || siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      link={links}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `p:domain_verify`,
          content: "4d1ac52a5a8059bab67a3499b0a70e1f",
        },
      ].concat([...imageMeta, ...meta])}
    />
  )
}
