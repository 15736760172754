import { graphql } from "gatsby"

import {
  APIPartialRecipe,
  apiPartialRecipeToPartialRecipe,
  PartialRecipe,
} from "./PartialRecipe"
import { ApiUser, apiUserToUser, User } from "./User"
import { ChefclubLocale } from "~/utils/locales"

interface CommunityPostRecipe extends PartialRecipe {
  postsCount?: number
}

export enum UserReaction {
  like = "like",
  none = "none",
}

export interface CommunityPost {
  id: string
  title: string
  message: string
  likesCount: number
  isChallengeWinner: boolean
  challengeId: null | string
  createdTime: number
  userReaction?: UserReaction
  locale?: string

  attachments: {
    uuid: string
    media: string
    mediaThumbnail?: string
  }[]

  fromUser: User
  recipe: CommunityPostRecipe
}
/*
export const query = graphql`
  fragment CommunityPost on CommunityPost {
    id
    title
    message
    likesCount: likes_count
    isChallengeWinner: is_challenge_winner
    challengeId: challenge_id
    createdTime: created_time

    attachments {
      uuid
      media
      mediaThumbnail: media_thumbnail
    }
    fromUser: from_user {
      firstName: first_name
      lastName: last_name
      profilePicture: profile_picture
      id: user_id
      username
    }
    recipe {
      id: uuid
      title
      vertical
      webCover: web_cover
      webCoverThumbnail: web_cover_thumbnail
      language
      accessMode: access_mode
    }
  }
`
*/
export interface ApiAttachment {
  uuid: string
  media: string
  media_thumbnail: string
}

export interface ApiCommunityPost {
  uuid: string
  title: string
  message: string
  likes_count: number
  is_challenge_winner: boolean
  challenge_id: null | string
  created_time: number
  user_reaction: null | string
  locale: string

  attachments: ApiAttachment[]

  from_user: ApiUser
  recipe: APIPartialRecipe

  parentCommentId?: string

  // score: number
  // is_from_staff: false
  // language: string
  // last_modified: number
  // comments_count: number
  // country_code: string
  // auth_user: string
}

export interface APIRatingReasons {
  uuid: string
  translation: {
    uuid: string
    name: string
    language: ChefclubLocale
  }
  slug: string
  available_for_stars: number[]
}

export interface APIRating {
  uuid: string
  recipe_uuid: string
  rating_score: number
  user: string
  comment: string
  reasons: { uuid: string }[] | string[]
}

export const apiCommunityPostToPost = ({
  title,
  user_reaction,
  uuid,
  from_user,
  recipe,
  is_challenge_winner,
  likes_count,
  challenge_id,
  message,
  created_time,
  attachments,
  locale,
}: ApiCommunityPost): CommunityPost => ({
  id: uuid,
  title,
  message,
  likesCount: likes_count,
  isChallengeWinner: is_challenge_winner,
  challengeId: challenge_id || null,
  createdTime: created_time,
  userReaction: user_reaction as UserReaction,
  locale,

  attachments: attachments.map(({ media_thumbnail, ...attachment }) => ({
    ...attachment,
    mediaThumbnail: media_thumbnail,
  })),

  fromUser: apiUserToUser(from_user),
  recipe: apiPartialRecipeToPartialRecipe(recipe),
})
