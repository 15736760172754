import { createApi, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react"

import { getBaseQuery } from "../getBaseQuery"
import { APIRecipe } from "~/models/Recipe"

interface RecipesResponse {
  data: APIRecipe[]
  next?: string | null
}

interface GetRecipesParams {
  in_id?: string[] // uid1,uid2
  _limit?: number
  _token?: string
  author?: string
  language?: string
  allproperties?: string
  search?: string
  vertical?: string
  contains_categories?: string | null
  in_vertical?: string
  time?: null | string // To bypass API's cache
}

export const recipesApi = createApi({
  reducerPath: "recipesApi",
  baseQuery: getBaseQuery("/buckets/chefclub-v2/collections/recipes"),
  endpoints: builder => ({
    getRecipes: builder.query<RecipesResponse, GetRecipesParams>({
      query: params => ({ url: `/records`, method: "GET", params }),
      transformResponse: (
        rawResult: unknown,
        meta?: FetchBaseQueryMeta
      ): RecipesResponse | Promise<RecipesResponse> => {
        const url = meta?.response?.headers?.get("next-page")
        const next = url ? new URL(url).searchParams.get("_token") : undefined

        return { data: (rawResult as RecipesResponse)?.data || [], next }
      },
    }),
  }),
})

export const { useGetRecipesQuery } = recipesApi
