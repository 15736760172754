import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface PrismicCocreationPageQuery {
  allPrismicCocreationProject: {
    nodes: {
      id: string
      uid: string
      data: {
        body: {
          items: {
            video_id: string
          }[]
        }[]
      }
    }[]
  }
  appSettings: { klaviyo_form_class_name: string }
}

export default async function createCocreationPage(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args
  const result = await graphql<PrismicCocreationPageQuery>(
    `
      query createPrismicCocreationPageQuery($lang: String) {
        allPrismicCocreationProject(
          filter: { data: { is_staging: { eq: false } }, lang: { eq: $lang } }
        ) {
          nodes {
            id
            uid
            data {
              body {
                ... on PrismicCocreationProjectDataBodyCocreationProjectStep {
                  items {
                    video_id
                  }
                }
              }
            }
          }
        }
        appSettings(lang: { eq: $lang }) {
          klaviyo_form_class_name
        }
      }
    `,
    { lang: currentLocale.prismicCode }
  )

  if (result.errors) reporter.panic(result.errors)
  const allVideoIds: string[] = []
  result.data?.allPrismicCocreationProject.nodes.forEach(node => {
    node.data.body.forEach(uniqueBody => {
      uniqueBody.items.map(item => allVideoIds.push(item.video_id))
    })
  })

  result.data?.allPrismicCocreationProject.nodes.forEach(node => {
    createPage({
      routeParams: node,
      context: {
        ...defaultContext,
        lang: currentLocale.prismicCode,
        uid: node.uid,
        allVideoIds,
        klaviyoFormClassName: result.data?.appSettings.klaviyo_form_class_name,
      },
    })
  })
}
