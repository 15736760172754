import { CSSProperties } from "react"

import { graphql, useStaticQuery } from "gatsby"

import { AnyPrismicNavSlice } from "./NavSlices"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { PrismicLocale } from "~/utils/locales"

interface NavQuery {
  allPrismicAppSettings: {
    nodes: NavNode[]
  }
}

interface NavNode {
  data: {
    nav_items_web: NavItemWeb[]
  }
  lang: PrismicLocale
}

interface NavItemWeb {
  colorMobileCTA?: CSSProperties["color"]
  mobileCTA?: string
  link: PrismicLink
  label: PrismicStructuredText
  hover_content?: {
    document?: {
      lang: PrismicLocale
      data: {
        title: string
        body: AnyPrismicNavSlice[]
        body1: AnyPrismicNavSlice[]
        body2: AnyPrismicNavSlice[]
      }
    }
  }
}

export interface Nav {
  title: string
  link: PrismicLink
  mobileCTA?: string
  colorMobileCTA?: CSSProperties["color"]
  body?: AnyPrismicNavSlice[]
  body1?: AnyPrismicNavSlice[]
  body2?: AnyPrismicNavSlice[]
}

export const useNav = (locale: PrismicLocale): Nav[] => {
  const allPrismicNav = useStaticQuery(graphql`
    {
      allPrismicAppSettings(filter: { data: { is_staging: { eq: false } } }) {
        nodes {
          data {
            nav_items_web {
              hover_content {
                document {
                  ... on PrismicNavMenu {
                    prismicId
                    lang
                    data {
                      title
                      ...PrismicNavMenu
                    }
                  }
                }
              }
              label {
                text
              }
              link {
                ...PrismicLink
              }
              mobileCTA: mobile_cta
              colorMobileCTA: color_mobile_cta
            }
          }
          lang
        }
      }
    }
  `) as NavQuery

  const filterAllPrismicNav = allPrismicNav.allPrismicAppSettings.nodes.filter(
    (nav: NavNode) => nav.lang === locale
  )

  //Check if the nav is include in the appSettings
  const filterNav = filterAllPrismicNav[0].data.nav_items_web.map(
    (nav: NavItemWeb) => {
      return {
        title: nav.label.text,
        link: nav.link,
        mobileCTA: nav.mobileCTA,
        colorMobileCTA: nav.colorMobileCTA,
        body: nav.hover_content?.document?.data.body,
        body1: nav.hover_content?.document?.data.body1,
        body2: nav.hover_content?.document?.data.body2,
      }
    }
  )

  //TODO : Return an array and so allow to have any number of sections
  if (filterNav.length < 2) {
    throw new Error(
      `Not enough or too much nav menu found for ${locale}. Please add it to Prismic`
    )
  }

  return filterNav
}
