import React from "react"

import LandingsFullWidthCard from "./Cards/LandingsFullWidthCard"
import LandingsSmallCard from "./Cards/LandingsSmallCard"
import Link from "~/components/Link/Link"
import { Col, Heading, Row } from "~/components/ui"
import { LatestLanding } from "~/templates/Landing/Landing"

import css from "./LatestLandings.module.scss"

export interface LatestLandingProps {
  primary: {
    titleLatestLanding: string
    maxNumber: number
    tag: string
    tagColor?: string
    anchorId?: string
  }
  isDarkTheme?: boolean
  landings?: LatestLanding[]
  eagerImages?: boolean
}

function LatestLandings({
  primary,
  landings,
  isDarkTheme,
  eagerImages,
}: LatestLandingProps) {
  const filterLandings = landings
    ?.filter(
      landing =>
        (primary.tag ? landing.tags.includes(primary.tag) : true) &&
        landing.data.seoImage.gatsbyImageData &&
        landing.data.type === (null || "edito") &&
        !landing.tags.includes("hidden")
    )
    .slice(0, 4)
  return (
    <>
      {!!filterLandings?.length && (
        <div className={css.content}>
          <Heading variant="large" color={isDarkTheme ? "white" : "body"}>
            {primary.titleLatestLanding}
          </Heading>
          <Row>
            <Col width={[12, 7]}>
              <Link to="s/landing" params={{ uid: filterLandings[0].uid }}>
                <LandingsFullWidthCard
                  tag={primary.tag}
                  landing={filterLandings[0]}
                  isDarkTheme={isDarkTheme}
                  tagColor={primary.tagColor}
                  eagerImages={eagerImages}
                />
              </Link>
            </Col>
            <Col width={[12, 5]} className={css.smallList}>
              {filterLandings?.map((landing, index) => {
                if (index == 0) return null
                return (
                  <Link
                    to="s/landing"
                    params={{ uid: landing.uid }}
                    key={index}
                  >
                    <LandingsSmallCard
                      tag={primary.tag}
                      landing={landing}
                      isDarkTheme={isDarkTheme}
                      tagColor={primary.tagColor}
                      eagerImages={eagerImages}
                    />
                  </Link>
                )
              })}
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default LatestLandings
