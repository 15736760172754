import React, { CSSProperties, useEffect, useState } from "react"

import cn from "classnames"

import useIsClient from "~/hooks/useIsClient"
import useLocalStorage from "~/hooks/useLocalStorage"

import css from "./Tooltip.module.scss"

type DisplayProps =
  | { showOnce?: false; id?: string }
  | { showOnce: true; id: string }

// Tooltip will remain visible if no appearingDelay is set, and if showOnce is set to true then an id needs to be set
type PropTypes = DisplayProps & {
  appearingDelay?: number
  disappearingDelay?: number
  onClick?: () => void
  children: React.ReactNode
  bgColor?: string
  top?: number
  right?: number
  bottom?: number
  left?: number
}

export default function Tooltip({
  appearingDelay,
  disappearingDelay,
  onClick,
  children,
  showOnce,
  id,
  bgColor,
  top,
  right,
  bottom,
  left,
}: PropTypes) {
  const [showTooltip, setShowTooltip] = useState(!appearingDelay)
  const [alreadyShown, setAlreadyShown] = useLocalStorage<null | boolean>(
    id ? `tooltip-shown-${id}` : "",
    null
  )

  const isClient = useIsClient()
  const willAppear = isClient && appearingDelay && (!alreadyShown || !showOnce)

  useEffect(() => {
    if (willAppear) {
      const appearing = setTimeout(
        () => setShowTooltip(true),
        appearingDelay * 1000
      )
      const disappearing =
        disappearingDelay &&
        setTimeout(() => setShowTooltip(false), disappearingDelay * 1000)

      setAlreadyShown(true)
      return () => {
        clearTimeout(appearing)
        disappearing && clearTimeout(disappearing)
      }
    }
  }, [isClient])

  const handleClickOnBackground = () => {
    onClick && onClick()
    setShowTooltip(false)
  }

  return (
    <div
      className={cn(css.tooltip, showTooltip ? "" : css.hide)}
      style={
        {
          "--background-color": `${
            bgColor ? bgColor : "var(--original-color)"
          }`,
          right: `${right}px`,
        } as CSSProperties
      }
    >
      {onClick && (
        <div
          className={css.backgroundTooltip}
          onClick={handleClickOnBackground}
        />
      )}
      <div
        className={css.contentTooltip}
        style={{
          top: `${top}px`,
          bottom: `${bottom}px`,
          left: `${left}px`,
        }}
      >
        {children}
      </div>
    </div>
  )
}
