export type Units = {
  slug: string
  is_volume: boolean
  conversion_to_ml: number | null
  display_for_liquids: boolean
  display_for_solids: boolean
  fr: string
  fr_plural: string
  en: string
  en_plural: string
  de: string
  de_plural: string
  es: string
  es_plural: string
  pt: string
  pt_plural: string
  it: string
  it_plural: string
}
export const units = [
  {
    slug: "gram",
    is_volume: false,
    conversion_to_ml: null,
    display_for_liquids: false,
    display_for_solids: true,
    fr: "Gr",
    fr_plural: "Gr",
    en: "Gr",
    en_plural: "Gr",
    de: "Gr",
    de_plural: "Gr",
    es: "Gr",
    es_plural: "Gr",
    pt: "Gr",
    pt_plural: "Gr",
    it: "Gr",
    it_plural: "Gr",
  },
  {
    slug: "centiliter",
    is_volume: true,
    conversion_to_ml: 10,
    display_for_liquids: true,
    display_for_solids: false,
    fr: "cl",
    fr_plural: "cl",
    en: "cl",
    en_plural: "cl",
    de: "cl",
    de_plural: "cl",
    es: "cl",
    es_plural: "cl",
    pt: "cl",
    pt_plural: "cl",
    it: "cl",
    it_plural: "cl",
  },
  {
    slug: "milliliters",
    is_volume: true,
    conversion_to_ml: 1,
    display_for_liquids: true,
    display_for_solids: false,
    fr: "ml",
    fr_plural: "ml",
    en: "ml",
    en_plural: "ml",
    de: "ml",
    de_plural: "ml",
    es: "ml",
    es_plural: "ml",
    pt: "ml",
    pt_plural: "ml",
    it: "ml",
    it_plural: "ml",
  },
  {
    slug: "cup",
    is_volume: true,
    conversion_to_ml: 250,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "",
    fr_plural: "",
    en: "Cup",
    en_plural: "Cups",
    de: "",
    de_plural: "Cup",
    es: "Cups",
    es_plural: "",
    pt: "",
    pt_plural: "",
    it: "",
    it_plural: "",
  },
  {
    slug: "halfcup",
    is_volume: true,
    conversion_to_ml: 125,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "",
    fr_plural: "",
    en: "½ cup",
    en_plural: "½ cups",
    de: "",
    de_plural: "",
    es: "",
    es_plural: "",
    pt: "",
    pt_plural: "",
    it: "",
    it_plural: "",
  },
  {
    slug: "thirdcup",
    is_volume: true,
    conversion_to_ml: 80,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "",
    fr_plural: "",
    en: "⅓ cup",
    en_plural: "⅓ cups",
    de: "",
    de_plural: "",
    es: "",
    es_plural: "",
    pt: "",
    pt_plural: "",
    it: "",
    it_plural: "",
  },
  {
    slug: "quartercup",
    is_volume: true,
    conversion_to_ml: 60,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "",
    fr_plural: "",
    en: "¼ cup",
    en_plural: "¼ cups",
    de: "",
    de_plural: "",
    es: "",
    es_plural: "",
    pt: "",
    pt_plural: "",
    it: "",
    it_plural: "",
  },
  {
    slug: "tablespoon",
    is_volume: true,
    conversion_to_ml: 15,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "c. à s.",
    fr_plural: "c. à s.",
    en: "tbsp",
    en_plural: "tbsp",
    de: "EL",
    de_plural: "EL",
    es: "cda",
    es_plural: "cda",
    pt: "colher de sopa",
    pt_plural: "colher de sopa",
    it: "tbsp",
    it_plural: "tbsp",
  },
  {
    slug: "teaspoon",
    is_volume: true,
    conversion_to_ml: 5,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "c. à c.",
    fr_plural: "c. à c.",
    en: "tsp",
    en_plural: "tsp",
    de: "TL",
    de_plural: "TL",
    es: "cdta",
    es_plural: "cdta",
    pt: "colher de chá",
    pt_plural: "colher de chá",
    it: "tsp",
    it_plural: "tsp",
  },
  {
    slug: "oz",
    is_volume: true,
    conversion_to_ml: 30,
    display_for_liquids: true,
    display_for_solids: true,
    fr: "",
    fr_plural: "",
    en: "oz",
    en_plural: "oz",
    de: "",
    de_plural: "",
    es: "",
    es_plural: "",
    pt: "",
    pt_plural: "",
    it: "",
    it_plural: "",
  },
]
