import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist"
import { PersistConfig } from "redux-persist/es/types"
import storage from "redux-persist/lib/storage"

import { authApi, validationApi } from "./api/auth"
import { bookApi } from "./api/book"
import { communityApi } from "./api/community"
import { communityRecipeCategoriesApi } from "./api/communityRecipe/communityRecipeCategories"
import { communityRecipeDietsApi } from "./api/communityRecipe/communityRecipeDiets"
import { communityRecipeIngredientsApi } from "./api/communityRecipe/communityRecipeIngredients"
import { communityRecipeKitchenwaresApi } from "./api/communityRecipe/communityRecipeKitchenwares"
import { communityRecipeStepsApi } from "./api/communityRecipe/communityRecipeSteps"
import { communityRecipesApi } from "./api/communityRecipes"
import { klaviyoApi } from "./api/externalapi/klaviyo"
import { sendInBlueApi } from "./api/externalapi/sendInBlue"
import { favoriteApi, fetchFavoriteApi } from "./api/favorite"
import { profileApi } from "./api/profile"
import { publicProfileApi } from "./api/publicProfile"
import { recipesApi } from "./api/recipesApi"
import { userTagsApi } from "./api/userTags"
import { viewsApi } from "./api/viewsApi"
import auth from "./modules/auth"
import community from "./modules/community"
import recipesViews from "./modules/recipesViews"
import userInfo from "./modules/userInfo"
import { subscriptionsApi } from "~/state/api/subscriptions"
import subscriptionsInfo from "~/state/modules/subscriptionsInfo"

const rootReducer = combineReducers({
  user: combineReducers({
    auth,
    info: userInfo,
    recipesViews,
  }),
  community,
  subscriptions: subscriptionsInfo,
  [authApi.reducerPath]: authApi.reducer,
  [validationApi.reducerPath]: validationApi.reducer,
  [klaviyoApi.reducerPath]: klaviyoApi.reducer,
  [sendInBlueApi.reducerPath]: sendInBlueApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [publicProfileApi.reducerPath]: publicProfileApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [communityRecipesApi.reducerPath]: communityRecipesApi.reducer,
  [favoriteApi.reducerPath]: favoriteApi.reducer,
  [bookApi.reducerPath]: bookApi.reducer,
  [fetchFavoriteApi.reducerPath]: fetchFavoriteApi.reducer,
  [viewsApi.reducerPath]: viewsApi.reducer,
  [recipesApi.reducerPath]: recipesApi.reducer,
  [userTagsApi.reducerPath]: userTagsApi.reducer,
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [communityRecipeDietsApi.reducerPath]: communityRecipeDietsApi.reducer,
  [communityRecipeStepsApi.reducerPath]: communityRecipeStepsApi.reducer,
  [communityRecipeCategoriesApi.reducerPath]:
    communityRecipeCategoriesApi.reducer,
  [communityRecipeKitchenwaresApi.reducerPath]:
    communityRecipeKitchenwaresApi.reducer,
  [communityRecipeIngredientsApi.reducerPath]:
    communityRecipeIngredientsApi.reducer,
})

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  whitelist: ["user"],
  version: 0,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// TODO: Add django token here? (can reduce Layout renders)
function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      validationApi.middleware,
      klaviyoApi.middleware,
      sendInBlueApi.middleware,
      profileApi.middleware,
      publicProfileApi.middleware,
      communityApi.middleware,
      communityRecipesApi.middleware,
      favoriteApi.middleware,
      bookApi.middleware,
      fetchFavoriteApi.middleware,
      viewsApi.middleware,
      recipesApi.middleware,
      userTagsApi.middleware,
      subscriptionsApi.middleware,
      communityRecipeDietsApi.middleware,
      communityRecipeStepsApi.middleware,
      communityRecipeCategoriesApi.middleware,
      communityRecipeKitchenwaresApi.middleware,
      communityRecipeIngredientsApi.middleware
    ),
  })

  const persistor = persistStore(store)
  return { store, persistor }
}

export type RootState = ReturnType<typeof rootReducer>

export default createStore
