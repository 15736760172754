import React from "react"

import { graphql } from "gatsby"

import TimelineCard, {
  AnyPrismicTimelineSlice,
} from "./TimelineCard/TimelineCard"
import { PrismicStructuredText } from "~/models/PrismicTypes"

import css from "./timeline.module.scss"

export interface TimelineProps {
  prismicId: string
  data: {
    timelineTitle: PrismicStructuredText
    body: AnyPrismicTimelineSlice[]
  }
}

function Timeline({ body }: TimelineProps["data"]) {
  return (
    <div className={css.timeline}>
      {body.map((props, i) => (
        <TimelineCard
          key={props.id}
          index={i}
          isFirst={body.length - 1 === i}
          {...props}
        />
      ))}

      <div className={css.line} />
    </div>
  )
}

export default Timeline

export const query = graphql`
  fragment PrismicTimelineV2 on PrismicTimelineV2 {
    prismicId
    data {
      timelineTitle: timeline_title {
        ...PrismicStructuredText
      }
      body {
        ...PrismicTimelineV2DataBodyImage
        ...PrismicTimelineV2DataBodyCarousel
        ...PrismicTimelineV2DataBodyYoutube
      }
    }
  }
`
