import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { getPrismicLocaleFromLocale } from "../../utils/locales"
import createLandingPages from "./createLandingPages"

interface PrismicAppSettingsCocreationQuery {
  appSettings: {
    cocreation_landing: string
  }
}

export default async function createCocreationLandingPage(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql } = args

  const result = await graphql<PrismicAppSettingsCocreationQuery>(
    `
      query appSettingsQuery($lang: String) {
        appSettings(lang: { eq: $lang }) {
          cocreation_landing
        }
      }
    `,
    {
      lang: getPrismicLocaleFromLocale(args.currentLocale.id),
    }
  )
  let landingId = ""
  if (result.data?.appSettings.cocreation_landing) {
    landingId = result.data?.appSettings.cocreation_landing
  }

  if (landingId.length) {
    createLandingPages(args, landingId as string)
  }
}
