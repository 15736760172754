import { useEffect, useRef } from "react"

function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Allow changing callback without resetting timeout.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}

export default useTimeout
