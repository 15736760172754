import React, { useEffect, useState } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import AccountModalLayout from "./AccountModalLayout"
import chefclubLogoImage from "./assets/chefclub-logo.svg"
import modalImageDesktop from "./assets/modal-image-desktop.jpg"
import modalImageMobile from "./assets/modal-image-mobile.jpg"
import questionMarkIcon from "./assets/question-mark.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import {
  LoginForm,
  RegisterForm,
  SendResetPasswordForm,
} from "~/components/Forms"
import { ResendResetPassword } from "~/components/Forms/AccountSettingsForm/ResendResetPassword/ResendResetPassword"
import CloseIcon from "~/components/Icons/CloseIcon"
import Modal from "~/components/Modals/Modal"
import { Body, Button, Heading } from "~/components/ui"
import Tooltip from "~/components/ui/Tooltip/Tooltip"
import { userSelector } from "~/state/modules/userInfo"

import css from "./AccountModal.module.scss"

export type AccountModalPagesIds =
  | "log-in"
  | "register"
  | "forgot-password"
  | "registration-done"
  | "forgot-password-done"

type AccountModalProps = {
  open?: boolean
  onClose?: () => void
  onLogIn?: () => void
  initialPage?: AccountModalPagesIds
  from?: string
}

const AccountModal = ({
  open,
  onClose,
  onLogIn,
  initialPage,
  from,
}: AccountModalProps) => {
  const [displayedPage, setDisplayedPage] = useState<AccountModalPagesIds>(
    initialPage ?? "log-in"
  )
  const [emailToSend, setEmailToSend] = useState("")
  const [questionTooltip, setQuestionTooltip] = useState(false)
  const user = useSelector(userSelector)

  const questionMarkIconColor = questionTooltip
    ? "var(--original-color)"
    : "black"

  const intl = useIntl()
  useEffect(() => {
    if (initialPage) {
      setDisplayedPage(initialPage)
    }
  }, [initialPage])

  const handleLogIn = () => {
    if (onLogIn) {
      onLogIn()
    }
    closeModal()
  }

  const handleRegister = () => {
    setDisplayedPage("registration-done")
  }

  const closeModal = () => {
    onClose && onClose()
    setDisplayedPage("log-in")
  }

  return (
    <Modal open={open} onClose={onClose} unmountOnExit mode="fixed">
      {modalRef => (
        <div className={css.root} ref={modalRef}>
          <div className={css.closeButton} onClick={closeModal}>
            <CloseIcon />
          </div>
          <div className={css.questionButton}>
            <ColorableSVG
              href={questionMarkIcon}
              mainColor={questionMarkIconColor}
              width="24"
              height="24"
              title="Chefclub"
              onClick={() => setQuestionTooltip(!questionTooltip)}
            />
            {questionTooltip && (
              <Tooltip onClick={() => setQuestionTooltip(false)}>
                <Body variant="body2" color="white">
                  <FormattedMessage id="form/action:a-problem" />
                  <a
                    href={intl.formatMessage({
                      id: "url:django-contact-us",
                    })}
                  >
                    <FormattedMessage id="form/action:contact-chefclub" />
                  </a>
                </Body>
              </Tooltip>
            )}
          </div>
          {displayedPage === "log-in" ? (
            <AccountModalLayout
              imageDesktop={modalImageDesktop}
              imageMobile={modalImageMobile}
            >
              <div className={css.login}>
                <Heading
                  variant="modal"
                  uppercase
                  color="original"
                  className={css.loginHeading}
                >
                  <FormattedMessage id="profile/text:to-login" />
                </Heading>
                <LoginForm
                  onLogIn={handleLogIn}
                  handleForgotPasswordLink={() =>
                    setDisplayedPage("forgot-password")
                  }
                  handleRegisterLink={() => setDisplayedPage("register")}
                />
              </div>
            </AccountModalLayout>
          ) : displayedPage === "register" ? (
            <AccountModalLayout
              imageDesktop={modalImageDesktop}
              imageMobile={modalImageMobile}
            >
              <div className={css.register}>
                <Heading variant="modal" uppercase color="original">
                  <FormattedMessage id="profile/form:register-header" />
                </Heading>
                {from && (
                  <div className={css.registerAlinea}>
                    <Body variant="body1" className={css.lastAlinea}>
                      {intl
                        .formatMessage({ id: "form/text:registration-alinea" })
                        .replace("%s", from ?? "")}
                    </Body>
                  </div>
                )}
                <RegisterForm
                  onLogIn={handleRegister}
                  handleLogInLink={() => setDisplayedPage("log-in")}
                />
              </div>
            </AccountModalLayout>
          ) : displayedPage === "registration-done" ? (
            <AccountModalLayout
              imageDesktop={modalImageDesktop}
              imageMobile={modalImageMobile}
            >
              <div className={css.registerDone}>
                <Heading variant="modal" uppercase color="original">
                  {(user && user.username) ?? (
                    <FormattedMessage id="profile/text:create-an-account" />
                  )}
                </Heading>
                <div className={css.registerDoneAlinea}>
                  <Body variant="body1">
                    <FormattedMessage id="form/text:welcome-in-community" />
                  </Body>
                  <ColorableSVG href={chefclubLogoImage} title="Chefclub" />
                </div>
                <Button variant="tertiary" underline color="original">
                  <span
                    onClick={() => setDisplayedPage("log-in")}
                    className={css.clickableSpan}
                  >
                    <FormattedMessage id="form/action:go-back-to-previous-page" />
                  </span>
                </Button>
              </div>
            </AccountModalLayout>
          ) : displayedPage === "forgot-password" ? (
            <AccountModalLayout
              imageDesktop={modalImageDesktop}
              imageMobile={modalImageMobile}
            >
              <div className={css.forgotPassword}>
                <div className={css.forgotPasswordAlinea}>
                  <Heading variant="modal" uppercase color="original">
                    <FormattedMessage id="form/text:reset-my-password" />
                  </Heading>
                  <Body variant="body1" className={css.lastAlinea}>
                    <FormattedMessage id="form/text:reset-password-alinea" />
                  </Body>
                </div>
                <SendResetPasswordForm
                  onResetPasswordSend={(email: string) => {
                    setEmailToSend(email)
                    setDisplayedPage("forgot-password-done")
                  }}
                />
                <Button
                  variant="tertiary"
                  underline
                  color="original"
                  className={css.clickableSpan}
                  onClick={() => setDisplayedPage("log-in")}
                >
                  <FormattedMessage id="profile/text:remember-password" />
                </Button>
              </div>
            </AccountModalLayout>
          ) : (
            <AccountModalLayout
              imageDesktop={modalImageDesktop}
              imageMobile={modalImageMobile}
            >
              <>
                <Heading variant="modal" uppercase color="original">
                  <FormattedMessage id="form/text:reset-password-done" />
                </Heading>
                <Body variant="body1">
                  <FormattedMessage id="form/text:reset-password-done-alinea" />
                </Body>
                <Button
                  color="original"
                  onClick={() => setDisplayedPage("log-in")}
                  className={css.ctaButton}
                >
                  <FormattedMessage id="form/text:reset-password-go-back-connexion-page" />
                </Button>
                <ResendResetPassword email={emailToSend} />
              </>
            </AccountModalLayout>
          )}
        </div>
      )}
    </Modal>
  )
}

export default AccountModal
