import { SearchParameters, SearchResults } from "algoliasearch-helper"

import { Category } from "~/models/Category"
import { IngredientFacet } from "~/models/IngredientFacet"
import { RecipeAlgoliaHit } from "~/models/Recipe"
import { LocaleWithMessages } from "~/types/global-types"

export type AlgoliaResult = { content: SearchResults; state: SearchParameters }

export const BASE_PARAMS = {
  hierarchicalFacets: [
    {
      name: "categories_tree",
      attributes: [
        "categories_tree.lvl0",
        "categories_tree.lvl1",
        "categories_tree.lvl2",
        "categories_tree.lvl3",
      ],
    },
  ],
  facets: ["vertical", "star_ingredients.slug"],
  attributesToRetrieve: [
    "absolute_url",
    "web_cover_thumbnail",
    "web_cover",
    "web_cover_hd",
    "title",
    "subtitle",
    "id",
    "author",
    "vertical",
    "created_time",
    "published_date",
    "categories",
    "ratings_average",
    "ratings_count",
  ],
  hitsPerPage: 24,
}

export const categoryQuery = `
  id
  name
  slug
  title
  language
  categoryId
  description
  parentId
  parentSlug
`

export interface AllCategories {
  [key: string]: Category
}

export interface CategoriesQuery {
  allCategory: {
    nodes: Category[]
  }
}

export const convertData = (
  result: AlgoliaResult,
  categories: AllCategories
) => {
  //Convert hierarchicalFacets to the good format
  const hierarchicalFacets = convertCategoriesData(
    result.content.hierarchicalFacets[0],
    categories
  )

  //Convert Vertical to the good format
  const verticalFacets = convertVerticalData(result)

  //Convert Star ingredients to the good format
  const starIngredientsFacets = convertStarIngredientData(result)

  return { hierarchicalFacets, verticalFacets, starIngredientsFacets }
}

export const convertCategoriesData = (
  hierarchicalFacets: any,
  categories: AllCategories
) => {
  if (hierarchicalFacets) {
    return {
      label: categories[hierarchicalFacets.name]?.name,
      value: hierarchicalFacets.name,
      items: hierarchicalFacets.data
        ? hierarchicalFacets.data.map((child: any) =>
            convertCategoriesData(child, categories)
          )
        : null,
      isRefined: hierarchicalFacets.isRefined,
      count: hierarchicalFacets.count,
    }
  } else {
    return undefined
  }
}

export const convertVerticalData = (result: AlgoliaResult) => {
  const verticalFacetsRawData: any =
    result.content.facets.filter(
      (facet: SearchResults.Facet) => facet.name === "vertical"
    )[0]?.data ?? []
  return Object.keys(verticalFacetsRawData).map(key => {
    return {
      label: key,
      isRefined: false,
      count: verticalFacetsRawData[key],
      value: [key],
    }
  })
}

export const convertStarIngredientData = (
  result: AlgoliaResult
): IngredientFacet[] => {
  const starIngredientsFacetsRawData: any =
    result.content.facets.filter(
      (facet: SearchResults.Facet) => facet.name === "star_ingredients.slug"
    )[0]?.data ?? []
  return Object.keys(starIngredientsFacetsRawData).map(key => {
    return {
      label: key,
      isRefined: false,
      count: starIngredientsFacetsRawData[key],
      value: [key],
    }
  })
}

export const generateContext = ({
  fullPath,
  result,
  categories,
  pageTitle,
  description,
}: {
  fullPath: string
  result: AlgoliaResult
  categories: AllCategories
  pageTitle?: string
  description: string
}) => {
  const searchState = result.state

  const emptyResult = {
    absolute_url: "#",
    web_cover_thumbnail:
      "https://www.chefclub.tv/static/chefclub-logo.colorable-48d14fb737706f9520acc1d7a15dcab6.svg#root",
    title: "NONE",
    subtitle: "NONE",
    id: "",
    ratings_average: 0,
    ratings_count: 0,
  }

  const imageLinks = result.content.hits.map((hit: RecipeAlgoliaHit) => ({
    link: hit.web_cover_thumbnail,
    id: hit.id,
  }))

  const { hierarchicalFacets, verticalFacets, starIngredientsFacets } =
    convertData(result, categories)

  return {
    context: {
      slug: fullPath,
      searchState: JSON.stringify(searchState),
      hierarchicalFacets: JSON.stringify(hierarchicalFacets),
      verticalRefinementList: JSON.stringify(verticalFacets),
      starIngredientsRefinementList: JSON.stringify(
        starIngredientsFacets.filter((item: any) => item.count >= 5)
      ),
      hits: result.content.hits || emptyResult,
      nbHits: result.content.nbHits,
      pageTitle: pageTitle,
      description: description, //category.description,
      imageLinks: imageLinks,
    },
    hierarchicalFacets,
    verticalFacets,
    starIngredientsFacets,
  }
}

export type getAllCategoriesType = {
  graphql<TData, TVariables = any>(
    query: string,
    variables?: TVariables
  ): Promise<{
    errors?: any
    data?: TData
  }>
  currentLocale: LocaleWithMessages
}

export const getAllCategories = async ({
  graphql,
  currentLocale,
}: getAllCategoriesType): Promise<{
  categories: AllCategories
  baseCategories: Category[]
}> => {
  const categoriesOfLocaleQuery = await graphql<CategoriesQuery>(
    `
      query ourCategories {
        allCategory(filter: {language: {eq: "${currentLocale.id}"}}){
          nodes {
            ${categoryQuery}
          }
        }
      }
    `
  )

  const categories: AllCategories = {}
  const baseCategories = categoriesOfLocaleQuery.data?.allCategory.nodes
  if (baseCategories) {
    for (const category of baseCategories) {
      categories[category.categoryId] = {
        ...category,
        id: category.categoryId,
      } as Category
    }

    return { baseCategories, categories }
  }
  return { baseCategories: [], categories }
}
