import React, { RefObject } from "react"

import { useIntl } from "react-intl"

import { KiddozEquivalence } from "../KidsConverterV2"
import { formatIngredient, getIngredientFromSlug, kiddozIcons } from "../utils"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import ChefclubLogoIcon from "~/components/Layout/chefclub-logo-fif-black.svg"
import Separator from "~/components/Separator/Separator"
import { Heading } from "~/components/ui"
import { capitalizeFirstLetter } from "~/utils/string"

import css from "./PrintConversions.module.scss"

interface PropTypes {
  kiddoz: KiddozEquivalence[] | undefined
}

const PrintConversions = React.forwardRef(({ kiddoz }: PropTypes, ref) => {
  const intl = useIntl()
  return (
    <div className={css.root} ref={ref as RefObject<HTMLDivElement>}>
      <Heading
        variant="extraLarge"
        color="kids"
        uppercase
        className={css.heading}
      >
        {intl.formatMessage({
          id: "kids-converter/your-recipe",
        })}
      </Heading>
      {kiddoz?.map((kiddoz, index) => (
        <div key={index}>
          <div className={css.conversion}>
            <div className={css.headers}>
              <Heading variant="small">
                {formatIngredient(kiddoz.ingredient, intl)}
              </Heading>
              <Heading variant="extraSmall">
                &nbsp; (
                {`${kiddoz.initialValue.quantity} ${capitalizeFirstLetter(
                  getIngredientFromSlug(kiddoz.ingredient)[
                    "fr"
                  ].toLowerCase() ?? ""
                )}`}
                )
              </Heading>
            </div>
            <div className={css.kiddozIcons}>
              {kiddoz.kiddoz.map((kiddoz, index) => (
                <React.Fragment key={index}>
                  {kiddoz.kiddoz && (
                    <img src={kiddozIcons.get(kiddoz.kiddoz)} />
                  )}
                </React.Fragment>
              ))}
              {getIngredientFromSlug(kiddoz.ingredient).icon}
            </div>
          </div>
          <Separator />
        </div>
      ))}
      <div className={css.footer}>
        <img
          src={ChefclubLogoIcon}
          alt="Chefclub logo"
          width={102}
          height={40}
        />
      </div>
    </div>
  )
})

export default PrintConversions
