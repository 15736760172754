import React from "react"

import CoCreationProjects from "./CoCreationProjects/CoCreationProjects"
import ShowsSlice from "./ShowsSlice/ShowsSlice"
import CreatorsHowItWorks from "~/components/slices/CustomContents/CreatorsHowItWorks/CreatorsHowItWorks"
import CreatorsListing from "~/components/slices/CustomContents/CreatorsListing/CreatorsListing"
import { Row } from "~/components/ui"
import { CoCreationProject } from "~/models/CoCreationProjects"
import { Episode, Show } from "~/models/Show"
import { Video } from "~/models/Video"
import KidsConverterV2 from "~/templates/KidsConverter/KidsConverterV2/KidsConverterV2"
import { isDev } from "~/utils/general"

export const customSlicesIds = [
  "creators_how_it_works",
  "creators_listing",
  "co-creation_projects",
] as const
export type customSlicesIdsType = (typeof customSlicesIds)[number]

export interface CustomContentsProps {
  customSlice: customSlicesIdsType | string
  anchorId?: string
  coCreationProjects: CoCreationProject[]
  allShowsEpisodes: {
    nodes: Episode[]
  }
  allShows: {
    nodes: Show[]
  }
  showsMobileHomeVideo: Video
  showsDesktopHomeVideo: Video
}

interface PropTypes extends CustomContentsProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
  incrementH1Position?: () => void
}

function CustomContents({
  isDarkTheme,
  isPreview,
  isLeadingSlice,
  incrementH1Position,
  customSlice,
  coCreationProjects,
  allShowsEpisodes,
  allShows,
  showsMobileHomeVideo,
  showsDesktopHomeVideo,
  ...props
}: PropTypes) {
  switch (customSlice) {
    case "creators_how_it_works":
      return (
        <CreatorsHowItWorks {...{ ...props, isDarkTheme, isLeadingSlice }} />
      )
    case "creators_listing":
      isLeadingSlice && incrementH1Position
      return <CreatorsListing {...{ ...props, isDarkTheme }} />

    case "co-creation_projects":
      isLeadingSlice && incrementH1Position
      return (
        <CoCreationProjects
          {...{ ...props, isDarkTheme, coCreationProjects }}
        />
      )

    case "shows":
      return (
        <ShowsSlice
          episodes={allShowsEpisodes}
          publishedShows={allShows}
          mobileHomeVideo={showsMobileHomeVideo}
          desktopHomeVideo={showsDesktopHomeVideo}
        />
      )

    case "kids_converter":
      return <KidsConverterV2 />

    default:
      return isDev ? (
        <Row justify="center">
          <h1>Custom Contents Slice</h1>
          <p>Please choose a custom slice on Prismic</p>
        </Row>
      ) : null
  }
}

export default CustomContents
