import React, { CSSProperties, useContext } from "react"

import cn from "classnames"

import Separator from "~/components/Separator/Separator"
import { Col, Heading, HrefButton, PrismicTextarea, Row } from "~/components/ui"
import { ThemeContext } from "~/context/ThemeContext"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./Text.module.scss"

export interface TextProps {
  textAlign: "left" | "center"
  title: PrismicStructuredText
  titleColor: CSSProperties["color"] | undefined
  subtitle: PrismicStructuredText
  text: PrismicStructuredText
  hasSeparator: boolean
  buttonLink: PrismicLink | null
  buttonLabel: PrismicStructuredText
  buttonStyle: "Prominent" | "Prominent (secondary)" | "Inline"
  buttonColor: CSSProperties["color"] | null
  anchorId?: string
}

interface PropTypes extends TextProps {
  isPreview?: boolean
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
}

function Text({
  title,
  titleColor,
  subtitle,
  text,
  textAlign,
  isDarkTheme,
  isLeadingSlice,
  hasSeparator,
  buttonLink,
  buttonLabel,
  buttonStyle,
  buttonColor,
}: PropTypes) {
  const theme = useContext(ThemeContext)
  const isKidsTheme = theme === "kids"

  const titleVariant = isKidsTheme ? "extraLargeCampingHoliday" : "extraLarge"
  const defaultTitleColor = isKidsTheme
    ? "#7d5bc6"
    : isDarkTheme
    ? "white"
    : "body"

  const actualTitleColor = titleColor ?? defaultTitleColor

  const isCentered = textAlign === "center"

  const linkProps = buttonLink && getPrismicLinkProps(buttonLink)

  const hasButton = !!buttonLabel.text && !!buttonLink?.url
  const buttonVariant =
    buttonStyle === "Prominent"
      ? "primary"
      : buttonStyle === "Prominent (secondary)"
      ? "secondary"
      : "tertiary"

  return (
    <Row justify="center" className={css.root}>
      <Col width={[12, isCentered ? 10 : 12]}>
        {title?.text && (
          <Heading
            Tag={isLeadingSlice ? "h1" : "h2"}
            variant={titleVariant}
            align={textAlign}
            color={actualTitleColor}
            className={css.title}
          >
            {title.text}
          </Heading>
        )}

        {subtitle?.text && (
          <Heading
            Tag="h3"
            variant="extraSmall"
            align={textAlign}
            color={isDarkTheme ? "white" : "body"}
          >
            {subtitle.text}
          </Heading>
        )}
      </Col>
      <Col width={[12, isCentered ? 8 : 12]}>
        {text?.text && (
          <PrismicTextarea
            color={isDarkTheme ? "white" : "body"}
            align={textAlign}
            text={text}
          />
        )}
      </Col>

      {hasButton && (
        <Col
          width={[12, isCentered ? 10 : 12]}
          className={cn(css.buttonContainer, { [css.center]: isCentered })}
        >
          <HrefButton
            {...linkProps}
            fullWidth={[true, false]}
            variant={buttonVariant}
            color={buttonColor}
          >
            {buttonLabel.text}
          </HrefButton>
        </Col>
      )}
      {hasSeparator && (
        <Col width={[12, isCentered ? 10 : 12]}>
          <Separator />
        </Col>
      )}
    </Row>
  )
}

export default Text
