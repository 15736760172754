import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import List from "~/components/slices/List/List"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"
import { PartialEpisode } from "~/models/Show"

interface PropTypes extends PrismicListLastShowEpisodes {
  isPreview?: boolean
  isDarkTheme?: boolean
  lastShowEpisodes?: PartialEpisode[]
}

function PrismicNavMenuListFromLastShowEpisodes(props: PropTypes) {
  const content = props.lastShowEpisodes
    ?.map(episode => {
      return {
        illustration: (episode as any)["video_poster_thumbnail"],
        text: episode.title,
        link: `/shows/${episode.showSlug}/episodes/${episode.slug}`,
        tag: episode.showTitle?.toUpperCase() || "",
      }
    })
    .slice(0, props.primary.displayMax || 3)

  return (
    <SliceSection {...props}>
      {content && (
        <List headerIsNotHx={true} content={content} {...props.primary} />
      )}
    </SliceSection>
  )
}

export default PrismicNavMenuListFromLastShowEpisodes

interface primary {
  title: PrismicStructuredText
  displayMax: number
  seeMoreLinkText?: string
  seeMoreLink?: PrismicLink
  imageForm?: string
}

export interface PrismicListLastShowEpisodes extends PrismicNavSlice {
  sliceType: "list"
  variation: "lastShowEpisodes"
  primary: primary
}

export const query = graphql`
  fragment PrismicListLastShowEpisodes on PrismicListLastShowEpisodes {
    id
    sliceType: slice_type
    variation
    primary {
      displayMax
      imageForm
      title {
        ...PrismicStructuredText
      }
      seeMoreLinkText
      seeMoreLink {
        ...PrismicLink
      }
    }
  }
`
