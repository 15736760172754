import algoliasearch from "algoliasearch/lite"
import algoliasearchHelper, {
  SearchParameters,
  SearchResults,
} from "algoliasearch-helper"

import {
  AllCategories,
  BASE_PARAMS,
  generateContext,
  getAllCategories,
} from "./utils/algoliaUtils"
import { Category } from "~/models/Category"
import { CreatePageCustomScriptArguments } from "~/types/global-types"

const GATSBY_ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY ?? ""
const GATSBY_ALGOLIA_APPLICATION_ID =
  process.env.GATSBY_ALGOLIA_APPLICATION_ID ?? ""
const GATSBY_ALGOLIA_INDEX = process.env.GATSBY_ALGOLIA_INDEX ?? ""

const searchClient = algoliasearch(
  GATSBY_ALGOLIA_APPLICATION_ID,
  GATSBY_ALGOLIA_API_KEY
)

const getAlgoliaSearchResults = (
  vertical: Category,
  categories: AllCategories,
  filters?: string
): Promise<{ content: SearchResults; state: SearchParameters }> => {
  const helper = algoliasearchHelper(
    searchClient,
    GATSBY_ALGOLIA_INDEX,
    BASE_PARAMS
  )

  if (vertical?.slug) {
    helper.addFacetRefinement("vertical", vertical.slug)
  }

  return helper.searchOnce({ filters: filters })
}

export default async function createVerticalPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, defaultContext, createPage, currentLocale, translate } = args
  const verticales = ["original", "kids", "light-and-fun", "daily", "cocktails"]

  const { categories } = await getAllCategories({
    graphql,
    currentLocale,
  })

  const baseVerticales: Category[] = []

  for (const vertical of verticales) {
    const baseVerticale = {
      id: "base",
      name: " Toutes les recettes",
      slug: vertical,
      title: vertical,
      language: currentLocale.id,
      categoryId: "base",
      description:
        translate("category/text:default-description") ??
        "Plus de 2000 recettes à découvrir avec des filtres pour tous les goûts ! ",
      parentId: null,
    }
    baseVerticales.push(baseVerticale)
  }

  const slugCategories: any[] = []

  // GENERATION DES PAGES PRÉ-RENDUES
  baseVerticales &&
    (await Promise.all(
      baseVerticales.map(async vertical => {
        //Check for duplication
        if (slugCategories.includes(vertical.slug)) {
          console.warn(
            "DUPLICATE PAGE PATH: ",
            currentLocale.id,
            vertical.categoryId,
            vertical.slug
          )
          return
        }
        slugCategories.push(vertical.slug)

        const algoliaFilter = `language:${vertical.language}`

        const result: any = await getAlgoliaSearchResults(
          vertical,
          categories,
          algoliaFilter
        )

        const fullPath = vertical.slug

        const { context } = generateContext({
          fullPath: fullPath,
          result: result,
          categories: categories,
          description: vertical.description,
          pageTitle: vertical.title,
        })

        createPage({
          routeParams: { fullPath },
          context: {
            ...defaultContext,
            ...context,
          },
        })
      })
    ))
}
