import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface PrismicLegals {
  prismicLegals: { id: string }
}

export default async function createLegalNoticesPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const result = await graphql<PrismicLegals>(
    `
      query createPrismicLegalsPagesQuery($lang: String) {
        prismicLegals(lang: { eq: $lang }) {
          id
        }
      }
    `,
    { lang: currentLocale.prismicCode }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data?.prismicLegals) {
    createPage({
      context: { ...defaultContext, lang: currentLocale.prismicCode },
    })
  }
}
