import React from "react"
import { CSSProperties } from "react"

import { FormattedMessage, useIntl } from "react-intl"

import Link from "~/components/Link/Link"
import { Button, Heading, ImageCard } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"
import { getVerticalFromId } from "~/models/Vertical"
import { ChefclubLocale } from "~/utils/locales"
import { capitalizeFirstLetter } from "~/utils/string"

import css from "./CarouselRecipeCard.module.scss"

export interface ViewMore {
  color: CSSProperties["color"]
  link: string
  isGatsbyLink: boolean
}

interface PropTypes {
  recipe: PartialRecipe | string
  isPreview?: boolean
  isDarkTheme?: boolean
  isVertical?: boolean
  viewMore?: ViewMore
  eagerImages?: boolean
}

function CarouselRecipeCard({
  recipe,
  isPreview,
  isDarkTheme,
  isVertical,
  viewMore,
  eagerImages,
}: PropTypes) {
  const intl = useIntl()

  // Recipe not found
  if (typeof recipe === "string" || !recipe) {
    if (process.env.GATSBY_BUILD_WARNING_PRISMIC === "true") {
      console.warn(`Recipe not found`, { id: recipe })
    }
    return (
      <div className={css.slide}>
        <ImageCard
          eagerImages={eagerImages}
          overlay={{
            type: "not-found",
            payload: isPreview ? recipe : undefined,
          }}
        />
      </div>
    )
  }

  const currentVertical = getVerticalFromId(recipe.vertical)

  const Content = (
    <>
      <ImageCard
        imageProps={{
          src: recipe.webCoverThumbnail,
        }}
        overlay={
          isVertical
            ? { type: "vertical", payload: currentVertical.name }
            : { type: "recipe" }
        }
        color={currentVertical.color}
        eagerImages={eagerImages}
      />

      {!isVertical ? (
        <Heading
          className={css.slideTitle}
          color={isDarkTheme ? "white" : "body"}
          Tag="h3"
          variant="extraSmall"
        >
          {recipe.title}
        </Heading>
      ) : (
        <span className={css.verticalGap} />
      )}
    </>
  )

  if (!!viewMore) {
    const ViewMore = (
      <div
        className={css.viewMore}
        style={{
          backgroundColor: viewMore?.color,
        }}
      >
        <Button Tag="span" color="white" variant="tertiary">
          <FormattedMessage id="action:load-more" />
        </Button>
      </div>
    )

    return viewMore.isGatsbyLink ? (
      <Link
        to={viewMore.link}
        className={css.slide}
        title={intl.formatMessage({ id: "action:load-more" })}
      >
        {ViewMore}
      </Link>
    ) : (
      <a
        href={viewMore.link}
        className={css.slide}
        title={intl.formatMessage({ id: "action:load-more" })}
      >
        {ViewMore}
      </a>
    )
  }

  return isVertical ? (
    // TODO: Replace <a> by GatsbyLink when theses pages will be in Gatsby
    <a
      className={css.slide}
      title={capitalizeFirstLetter(recipe.vertical)}
      href={intl.formatMessage({
        id: currentVertical.urlMessageId,
      })}
    >
      {Content}
    </a>
  ) : (
    <Link
      className={css.slide}
      title={recipe.title}
      to="recipes/recipe"
      params={recipe}
      lang={recipe.language as ChefclubLocale}
    >
      {Content}
    </Link>
  )
}

export default CarouselRecipeCard
