import { createApi } from "@reduxjs/toolkit/query/react"

import { getBaseQuery } from "../getBaseQuery"
import { RecipeView, updateHistory } from "../modules/recipesViews"

export interface APIView {
  id: string
  last_view: number
}

interface ViewsResponse {
  data: APIView[]
}

const APIViewToView = ({ id, last_view }: APIView): RecipeView => ({
  id,
  lastView: last_view,
})

// We can maybe call it "historyAPI" ?
export const viewsApi = createApi({
  reducerPath: "viewsApi",
  baseQuery: getBaseQuery("/views/recipes"),
  endpoints: builder => ({
    getViewedRecipes: builder.query<ViewsResponse, undefined>({
      query: () => ({ url: "", method: "GET" }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          if (data && data.data) {
            const views = data.data.map(APIViewToView)
            dispatch(updateHistory(views))
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),

    addViewedRecipe: builder.mutation<ViewsResponse, string>({
      query: recipeId => ({ url: `/${recipeId}`, method: "PUT" }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          if (data && data.data) {
            const views = data.data.map(APIViewToView)
            dispatch(updateHistory(views))
          }
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const { useAddViewedRecipeMutation, useGetViewedRecipesQuery } = viewsApi
