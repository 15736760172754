import React, { useState } from "react"
import { forwardRef, HTMLProps } from "react"

import cn from "classnames"

import errorIcon from "./error.svg"
import successIcon from "./success.svg"
import { Body } from "~/components/ui"

import css from "./FormInputs.module.scss"

export interface FieldsetProps extends HTMLProps<HTMLFieldSetElement> {
  error?: string
  valid?: string
}

export function Fieldset({ children, error, valid }: FieldsetProps) {
  return (
    <fieldset className={css.fieldset}>
      {children}
      {error && (
        <div className={css.messageWrap}>
          <img loading="lazy" src={errorIcon} alt="" />
          <Body variant="body3" color="original" noMargin>
            {error}
          </Body>
        </div>
      )}
      {valid && (
        <div className={css.messageWrap}>
          <img loading="lazy" src={successIcon} alt="" />
          <Body variant="body3" color="success" noMargin>
            {valid}
          </Body>
        </div>
      )}
    </fieldset>
  )
}

export interface TextInputProps extends HTMLProps<HTMLInputElement> {
  errors?: unknown
}
export interface GetInputProps {
  inputProps: TextInputProps
  wrapProps: HTMLProps<HTMLDivElement>
}

function getInputProps({ errors, ...props }: TextInputProps): GetInputProps {
  return {
    inputProps: {
      ...props,
      type: props.type || "text",
      className: css.input,
      ["aria-invalid"]: !!errors ? "true" : "false",
    },
    wrapProps: {
      className: cn(css.inputWrap, props.className, {
        [css.error]: !!errors,
      }),
    },
  }
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props, ref) {
    const { inputProps, wrapProps } = getInputProps(props)

    return (
      <div {...wrapProps}>
        {props.prefix && <span className={css.prefix}>{props.prefix}</span>}
        <input
          {...inputProps}
          ref={ref}
          className={`${css.input} ${props.prefix && css.hasPrefix}`}
        />
      </div>
    )
  }
)

export const PasswordInput = forwardRef<HTMLInputElement, TextInputProps>(
  function PasswordInput(props, ref) {
    const [revealPassword, setRevealPassword] = useState<boolean>(false)
    const inputType = revealPassword ? "text" : "password"
    const { inputProps, wrapProps } = getInputProps(props)
    const { className, ...wrapperProps } = wrapProps

    const toggleVisibility = () => {
      setRevealPassword(reveal => !reveal)
    }

    return (
      <div {...wrapperProps} className={cn(css.passwordWrap, className)}>
        <input {...inputProps} type={inputType} ref={ref} />
        <button
          className={cn(css.passwordToggle, {
            [css.hide]: inputType === "text",
          })}
          type="button"
          onClick={toggleVisibility}
        >
          Show/Hide password
        </button>
      </div>
    )
  }
)

export interface TextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  errors?: unknown
}
export interface GetTextAreaProps {
  textAreaProps: TextAreaProps
  wrapProps: HTMLProps<HTMLDivElement>
}

function getTextAreaProps({
  errors,
  ...props
}: TextAreaProps): GetTextAreaProps {
  return {
    textAreaProps: {
      ...props,
      type: props.type || "text",
      className: css.input,
      ["aria-invalid"]: !!errors ? "true" : "false",
    },
    wrapProps: {
      className: cn(css.inputWrap, css.textAreaWrap, props.className, {
        [css.error]: !!errors,
      }),
    },
  }
}

export const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextAreaInput(props, ref) {
    const { textAreaProps, wrapProps } = getTextAreaProps(props)

    return (
      <div {...wrapProps}>
        <textarea {...textAreaProps} ref={ref} />
      </div>
    )
  }
)

export const Select = forwardRef<
  HTMLSelectElement,
  HTMLProps<HTMLSelectElement>
>(function Select(props, ref) {
  return (
    <div className={css.selectWrap}>
      <select {...props} ref={ref} />
    </div>
  )
})
