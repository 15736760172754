import { useEffect, useState } from "react"

export default function useIsOnScreen(ref: any, rootMargin = "0px"): boolean {
  const [isOnScreen, setIsOnScreen] = useState<boolean>(true)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    const currentElement = ref?.current

    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      observer.unobserve(currentElement)
    }
  }, [])

  return isOnScreen
}
