import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import TextRecipeColumns, {
  TextRecipeColumnsProps,
} from "~/components/slices/TextRecipeColumns/TextRecipeColumns"
import { PartialRecipe } from "~/models/PartialRecipe"

interface PropTypes extends PrismicLandingDataBodyTextRecipeColumns {
  recipes?: PartialRecipe[]
  isPreview?: boolean
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
  eagerImages?: boolean
}

function LandingBodyTextRecipeColumns({
  primary,
  recipes,
  isPreview,
  isDarkTheme,
  ...props
}: PropTypes) {
  const currentRecipe =
    recipes && recipes.find(recipe => recipe.id === primary.recipeUid)

  if (!currentRecipe && process.env.GATSBY_BUILD_WARNING_PRISMIC === "true") {
    console.warn(`Recipe not found`, { id: primary.recipeUid })
  }

  return (
    <SliceSection margin="large" anchorId={primary.anchorId} {...props}>
      <TextRecipeColumns
        {...primary}
        isDarkTheme={isDarkTheme}
        isPreview={isPreview}
        currentRecipe={currentRecipe}
      />
    </SliceSection>
  )
}

export default LandingBodyTextRecipeColumns

export interface PrismicLandingDataBodyTextRecipeColumns extends PrismicSlice {
  sliceType: "text_recipe_columns"
  primary: TextRecipeColumnsProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyTextRecipeColumns on PrismicLandingDataBodyTextRecipeColumns {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      title {
        ...PrismicStructuredText
      }
      subtitle {
        ...PrismicStructuredText
      }
      text {
        ...PrismicStructuredText
      }
      recipeUid: recipe_uid
      layout
      anchorId: id
    }
  }
`
