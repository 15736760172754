import React, { useMemo } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import Link from "~/components/Link/Link"
import { Body, Button, Fieldset, PasswordInput } from "~/components/ui"
import useAutoRedirect from "~/hooks/useAutoRedirect"
import pathFromFullId from "~/routes/pathFromFullId"
import { useResetPasswordMutation } from "~/state/api/auth"
import getFormValidators from "~/utils/formValidation"

import css from "./ResetPasswordForm.module.scss"

interface ResetPasswordProps {
  password: string
  confirmPassword: string
}

export function ResetPasswordForm({ userToken }: { userToken: string }) {
  const intl = useIntl()
  const { password, confirmPassword } = getFormValidators(intl)
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation()

  const redirectUrl = pathFromFullId("locale/profile/login", intl)

  useAutoRedirect(redirectUrl, isSuccess)

  const validationSchema = useMemo(
    () => yup.object().shape({ password, confirmPassword }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, ...form } = useForm<ResetPasswordProps>({
    resolver: yupResolver(validationSchema),
  })

  const submit = form.handleSubmit(async (values: ResetPasswordProps) => {
    const password = values.password
    resetPassword({ password, userToken })
  })

  const { errors } = form.formState

  return (
    <form className={css.root} onSubmit={submit} noValidate>
      <Fieldset error={errors.password?.message}>
        <PasswordInput
          {...register("password")}
          placeholder={intl.formatMessage({
            id: "form/text:label-new-password",
          })}
          errors={errors.password}
        />
      </Fieldset>

      <Fieldset error={errors.confirmPassword?.message}>
        <PasswordInput
          {...register("confirmPassword")}
          placeholder={intl.formatMessage({
            id: "form/text:label-new-password-confirm",
          })}
          errors={errors.confirmPassword}
        />
      </Fieldset>

      <Button
        className={css.button}
        type="submit"
        disabled={isLoading}
        color="original"
      >
        <FormattedMessage id="form/text:choose-new-password" />
      </Button>

      <Body color="black" variant="body3" className={css.contactText}>
        <FormattedMessage id="form/action:a-problem" />
        <a href={intl.formatMessage({ id: "url:django-contact-us" })}>
          <FormattedMessage id="form/action:contact-chefclub" />
        </a>
      </Body>
    </form>
  )
}
