import algoliasearch from "algoliasearch/lite"
import algoliasearchHelper, {
  SearchParameters,
  SearchResults,
} from "algoliasearch-helper"

import { getIdBreadcrumbById } from "../../utils/createPages/categoriesUtils"
import { promiseAllLimit } from "../../utils/PromiseAllLimit"
import {
  AllCategories,
  BASE_PARAMS,
  generateContext,
  getAllCategories,
} from "./utils/algoliaUtils"
import { Category } from "~/models/Category"
import { IngredientFacet } from "~/models/IngredientFacet"
import { CreatePageCustomScriptArguments } from "~/types/global-types"

const GATSBY_ALGOLIA_API_KEY = process.env.GATSBY_ALGOLIA_API_KEY ?? ""
const GATSBY_ALGOLIA_APPLICATION_ID =
  process.env.GATSBY_ALGOLIA_APPLICATION_ID ?? ""
const GATSBY_ALGOLIA_INDEX = process.env.GATSBY_ALGOLIA_INDEX ?? ""

// /!\ NB_PARALLEL_CATEGORY_GENERATIONS * NB_PARALLEL_INGREDIENT_GENERATIONS < 100 /!\
const NB_PARALLEL_CATEGORY_GENERATIONS = 11
const NB_PARALLEL_INGREDIENT_GENERATIONS = 9

const searchClient = algoliasearch(
  GATSBY_ALGOLIA_APPLICATION_ID,
  GATSBY_ALGOLIA_API_KEY
)

const getAlgoliaSearchResults = (
  category: Category,
  categories: AllCategories,
  filters?: string,
  ingredient?: IngredientFacet
): Promise<{ content: SearchResults; state: SearchParameters }> => {
  const helper = algoliasearchHelper(
    searchClient,
    GATSBY_ALGOLIA_INDEX,
    BASE_PARAMS
  )
  const IdBreadcrumb = getIdBreadcrumbById(
    category.categoryId,
    ">",
    0,
    0,
    categories
  )

  if (category?.id) {
    helper.addHierarchicalFacetRefinement("categories_tree", IdBreadcrumb)
  }

  if (ingredient?.value?.length) {
    helper.addFacetRefinement("star_ingredients.slug", ingredient.value[0])
  }

  return helper.searchOnce({ filters: filters })
}

export default async function createCategoryPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, defaultContext, createPage, currentLocale, translate } = args

  const { categories, baseCategories } = await getAllCategories({
    graphql,
    currentLocale,
  })

  const baseTitle =
    translate("category/text:all-recipes-chefclub")?.replace("%s", "") ??
    "Recipes"

  const baseWithoutCategory = {
    id: "base",
    name: " Toutes les recettes",
    slug: translate("path:recipes") ?? "recipes",
    title: baseTitle,
    language: currentLocale.id,
    categoryId: translate("path:recipes") ?? "recipes",
    description:
      translate("category/text:default-description") ??
      "Plus de 2000 recettes à découvrir avec des filtres pour tous les goûts ! ",
    parentId: null,
  }

  const slugCategories: any[] = []
  baseCategories?.push(baseWithoutCategory)

  // GENERATION DES PAGES PRÉ-RENDUES
  baseCategories &&
    (await promiseAllLimit(
      NB_PARALLEL_CATEGORY_GENERATIONS,
      baseCategories.map(category => async () => {
        //Check for duplication
        if (slugCategories.includes(category.slug)) {
          console.warn(
            "DUPLICATE PAGE PATH: ",
            currentLocale.id,
            category.categoryId,
            category.slug
          )
          return
        }
        slugCategories.push(category.slug)

        const algoliaFilter = `language:${category.language}`

        //Get algolia results
        const result: any = await getAlgoliaSearchResults(
          category,
          categories,
          algoliaFilter
        )

        const fullPath = category.slug

        const { context, starIngredientsFacets } = generateContext({
          fullPath: fullPath,
          result: result,
          categories: categories,
          description: category.description,
        })

        await generateAssociatedIngredientsPages({
          starIngredients: starIngredientsFacets,
          basePath: fullPath,
          algoliaParams: {
            category: category,
            categories: categories,
            filters: algoliaFilter,
          },
          args: args,
        })

        createPage({
          routeParams: { category: fullPath },
          context: {
            ...defaultContext,
            ...context,
          },
        })
      })
    ))
}

const generateAssociatedIngredientsPages = async ({
  starIngredients,
  ...params
}: {
  basePath: string
  starIngredients: IngredientFacet[]
  algoliaParams: {
    category: Category
    categories: AllCategories
    filters?: string
  }
  args: CreatePageCustomScriptArguments
}) => {
  await promiseAllLimit(
    NB_PARALLEL_INGREDIENT_GENERATIONS,
    starIngredients.map(
      ingredient => () => generateIngredientPages({ ingredient, ...params })
    )
  )
}

const generateIngredientPages = async ({
  basePath,
  ingredient,
  algoliaParams: { category, categories, filters },
  args,
}: {
  basePath: string
  ingredient: IngredientFacet
  algoliaParams: {
    category: Category
    categories: AllCategories
    filters?: string
  }
  args: CreatePageCustomScriptArguments
}) => {
  const { defaultContext, createPage } = args

  const result: any = await getAlgoliaSearchResults(
    category,
    categories,
    filters,
    ingredient
  )

  //If less than 5 recipes we do not generate the page
  if (result.content.hits.length < 5) {
    return
  }

  const fullPath = `${basePath}/${ingredient.value[0]}`

  const { context } = generateContext({
    fullPath: fullPath,
    result: result,
    categories: categories,
    description: category.description,
  })

  createPage({
    routeParams: {
      category: basePath,
      ingredient: ingredient.value[0],
    },
    context: {
      ...defaultContext,
      ...context,
    },
  })
}
