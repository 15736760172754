import { useEffect, useState } from "react"

function useIsTouchScreen(): boolean {
  const [isTouchable, setIsTouchable] = useState<boolean>(false)

  useEffect(() => {
    setIsTouchable("ontouchstart" in window || !!navigator.maxTouchPoints)
  }, [])

  return isTouchable
}

export default useIsTouchScreen
