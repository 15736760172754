import React from "react"

import { useIntl } from "react-intl"

import CarouselComponent from "../CarouselComponent/CarouselComponent"
import { HomeShowsQuery } from "../HomeShows"
import Carousel from "~/components/Carousel/Carousel"
import DotsTagsList from "~/components/DotsTagsList/DotsTagsList"
import chefclubShowLogo from "~/components/Layout/chefclub-logo-fif-white.svg"
import { Body, Heading } from "~/components/ui"
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer"
import useIsOnCompact from "~/hooks/useIsOnCompact"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./Shows.module.scss"

const Shows = ({
  episodes,
  publishedShows,
  teaserShows,
  mobileHomeVideo,
  desktopHomeVideo,
}: HomeShowsQuery) => {
  const isCompact = useIsOnCompact()

  const intl = useIntl()

  const videoUrl = isCompact
    ? mobileHomeVideo?.hostedVideoUrl
    : desktopHomeVideo?.hostedVideoUrl
  const posterUrl = isCompact
    ? mobileHomeVideo?.videoPreviewThumbnail
    : desktopHomeVideo?.videoPreviewThumbnail
  return (
    <div className={css.root}>
      <div className={css.header}>
        <div className={css.headerVideo}>
          <VideoPlayer
            src={videoUrl}
            poster={posterUrl}
            autoPlay
            verticalColor="white"
            format={{ mobile: "square", desktop: "other" }}
            ratio={400 / 1280}
            videoFit="cover"
            disableControls
            loop
          />
        </div>
        <div className={css.overlay}></div>
        <h1 className={css.logo}>
          <img
            src={chefclubShowLogo}
            alt={intl.formatMessage({ id: "show/text:home-title" })}
          />
        </h1>
      </div>
      <section className={css.newShows}>
        <Heading variant="large" color="white" className={css.carouselHeading}>
          {intl.formatMessage({ id: "show/text:newepisodes" })}
        </Heading>
        <Carousel
          beginAtIndex={3}
          topArrowPosition={172}
          itemsPerPage={isCompact ? 3 : 4}
          infiniteScroll
        >
          {episodes.nodes.map((episode, index) => {
            const show = publishedShows.nodes.find(
              show => show.id === episode.show
            )
            return (
              <CarouselComponent
                key={index}
                src={episode.videoPosterThumbnail}
                title={`${show?.title} - ${intl.formatMessage({
                  id: "show/text:season",
                })} ${episode.seasonNumber} ${intl.formatMessage({
                  id: "show/text:episode",
                })} - ${episode.episodeNumber} - ${episode.title}`}
                link={show?.slug}
                param={episode.slug}
                ratio={2.34}
              >
                <Body
                  variant="body4"
                  semiBold
                  uppercase
                  color="var(--gray3-color)"
                  className={css.episodeShowName}
                >
                  {show?.title}
                </Body>
                <Heading
                  variant="extraSmall"
                  color="white"
                  className={css.episodeName}
                >
                  {episode.title}
                </Heading>
              </CarouselComponent>
            )
          })}
        </Carousel>
      </section>
      <section className={css.allShows}>
        <Heading variant="large" color="white" className={css.carouselHeading}>
          {intl.formatMessage({ id: "show/text:allshows" })}
        </Heading>
        <Carousel
          beginAtIndex={1}
          topArrowPosition={172}
          itemsPerPage={isCompact ? 3 : 4}
          infiniteScroll
        >
          {publishedShows.nodes.map((show, index) => (
            <CarouselComponent
              key={index}
              src={show.showPoster}
              title={show.title}
              link={show.slug}
              ratio={1.51}
            >
              <div className={css.details}>
                <img src={show.showLogo} className={css.showLogo} />
                <DotsTagsList
                  tags={show.tags}
                  mainColor={show.mainColor || undefined}
                />
                <Body
                  variant="body4"
                  color="white"
                  align="center"
                  className={css.synopsis}
                >
                  {show.synopsis}
                </Body>
              </div>
            </CarouselComponent>
          ))}
        </Carousel>
      </section>
      {teaserShows?.nodes && teaserShows?.nodes.length > 0 && (
        <section className={css.allShows}>
          <Heading
            variant="large"
            color="white"
            className={css.carouselHeading}
          >
            {intl.formatMessage({ id: "show/text:teasershows" })}
          </Heading>
          <Carousel
            beginAtIndex={1}
            topArrowPosition={172}
            itemsPerPage={isCompact ? 3 : 4}
            infiniteScroll
          >
            {teaserShows?.nodes.map((show, index) => (
              <CarouselComponent
                key={index}
                src={show.showPoster}
                title={show.title}
                ratio={1.51}
              />
            ))}
          </Carousel>
        </section>
      )}
    </div>
  )
}

export default Shows
