import React, { useContext } from "react"

import mauriceImage from "./maurice.svg"
import Carousel from "~/components/Carousel/Carousel"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import StarRating from "~/components/StarRating/StarRating"
import { Body, Container, Heading } from "~/components/ui"
import { ThemeContext } from "~/context/ThemeContext"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PrismicStructuredText } from "~/models/PrismicTypes"
import { getTextColor } from "~/utils/themes"

import css from "./Reviews.module.scss"

export interface ReviewsProps {
  items: {
    reviewerName: PrismicStructuredText
    text: PrismicStructuredText
    score: number
  }[]
  primary: {
    backgroundColor: string
    starsColor: string
    anchorId?: string
  }
}

interface PropTypes extends ReviewsProps {
  isDarkTheme?: boolean
}

const defaultBackgroundColor = "#ffbd38"
const defaultStarsColor = "#b93b84"

function Reviews({ items, primary, isDarkTheme }: PropTypes) {
  const isCompact = useIsOnCompact()

  const { backgroundColor, starsColor } = primary

  const theme = useContext(ThemeContext)

  const [isKidsTheme, isFoodTheme] = [theme === "kids", theme === "food"]

  const textColor = getTextColor(backgroundColor, isFoodTheme, isDarkTheme)

  const backgroundStyle = {
    background: backgroundColor ?? defaultBackgroundColor,
  }

  return (
    <div style={backgroundStyle}>
      <Container className={css.container}>
        <div className={css.content}>
          <Heading
            Tag="h2"
            color={isKidsTheme ? "kids" : textColor}
            align="center"
          >
            Leurs avis ! {/* TODO Internationalise this heading */}
          </Heading>

          <div className={css.carouselWrapper}>
            <Carousel
              width={isCompact ? 238 : 458}
              itemsPerPage={1}
              dotsColor={"var(--white-color)"}
              scrollLoop={true}
            >
              {items.map(item => (
                <div className={css.review} key={item.reviewerName.text}>
                  <StarRating
                    score={item.score}
                    color={starsColor ?? defaultStarsColor}
                    size={isCompact ? "medium" : "large"}
                  />
                  <Body className={css.reviewText} color={textColor}>
                    “{item.text.text}”
                  </Body>
                  <Body color={textColor}>{item.reviewerName.text}</Body>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <ColorableSVG className={css.mauriceIllustration} href={mauriceImage} />
      </Container>
    </div>
  )
}

export default Reviews
