import { IGatsbyImageData, withArtDirection } from "gatsby-plugin-image"
import { PrismicDate } from "prismic-reactjs"

import { PrismicModal, PrismicModals } from "~/hooks/useModal"
import { PrismicLink } from "~/models/PrismicTypes"

type Banner = {
  backgroundColor: string
  bannerAlt: string
  fromDate?: PrismicDate
  toDate?: PrismicDate
  linkTo: PrismicLink
  desktopImage: { gatsbyImageData: IGatsbyImageData }
  mobileImage: { gatsbyImageData: IGatsbyImageData }
}

export type FormattedBanner = {
  backgroundColor: string
  bannerAlt: string
  linkTo: PrismicLink
  banner: IGatsbyImageData
}

export type PrismicBanners = {
  data: {
    bannerList?: Banner[]
    defaultBannerLink: PrismicLink
    defaultDesktopBanner: { gatsbyImageData: IGatsbyImageData }
    defaultMobileBanner: { gatsbyImageData: IGatsbyImageData }
    defaultBannerAlt: string
    defaultBannerBackgroundColor: string
  }
}

export const findCurrentImage = (images: PrismicBanners | PrismicModals) => {
  const today = new Date()
  return (images.data.bannerList as any[])?.find(
    (image: Banner | PrismicModal) => {
      if (image.fromDate && image.toDate) {
        const fromDate = image.fromDate?.toString().split("-")
        const formattedFromDate = new Date(
          parseInt(fromDate[0]),
          parseInt(fromDate[1]) - 1,
          parseInt(fromDate[2])
        )
        const toDate = image.toDate?.toString().split("-")
        const formattedToDate = new Date(
          parseInt(toDate[0]),
          parseInt(toDate[1]) - 1,
          parseInt(toDate[2])
        )

        if (
          formattedToDate.getTime() > today.getTime() &&
          formattedFromDate.getTime() < today.getTime()
        ) {
          return image
        }
      }
    }
  )
}
//TODO : replace the magic strings
export const formatBannerToDisplay: (
  banners: PrismicBanners
) => FormattedBanner | null = banners => {
  const currentBanner: Banner = findCurrentImage(banners)
  if (currentBanner)
    return {
      backgroundColor: currentBanner.backgroundColor,
      bannerAlt: currentBanner.bannerAlt,
      linkTo: currentBanner.linkTo,
      banner: withArtDirection(currentBanner.desktopImage.gatsbyImageData, [
        {
          media: `(max-width: 767px)`,
          image: currentBanner.mobileImage.gatsbyImageData,
        },
      ]),
    }

  return {
    backgroundColor: banners.data.defaultBannerBackgroundColor,
    bannerAlt: banners.data.defaultBannerAlt,
    linkTo: banners.data.defaultBannerLink,
    banner: withArtDirection(
      banners.data.defaultDesktopBanner.gatsbyImageData,
      [
        {
          media: `(max-width: 767px)`,
          image: banners.data.defaultMobileBanner.gatsbyImageData,
        },
      ]
    ),
  }
}
