import React, { CSSProperties } from "react"

import Avatar from "~/components/Avatar/Avatar"
import Link from "~/components/Link/Link"
import { Body, Heading } from "~/components/ui"
import { Tag } from "~/models/PublicUser"

import css from "./Card.module.scss"

interface CardPropTypes {
  userId: string
  username: string
  userTypes: string[]
  profileBackground?: string | null
  profilePicture: string | null
  tags?: Tag[]
  eagerImages?: boolean
  className?: string
}

export default function Card({
  userId,
  username,
  userTypes,
  profileBackground,
  profilePicture,
  tags,
  eagerImages,
  className,
}: CardPropTypes) {
  const defaultBgColor = "var(--gray4-color)"
  const maxNumberOfTags = 3

  // Applies default color if profileBackground doesn't exist
  const backgroundStyles: CSSProperties = {
    backgroundColor: defaultBgColor,
  }

  return (
    <Link
      to={`profile/u`}
      params={{ id: username }}
      title={username}
      className={className}
    >
      <div className={css.card}>
        <div className={css.cardHead}>
          {profileBackground ? (
            <img
              loading={eagerImages ? "eager" : "lazy"}
              src={profileBackground}
              alt=""
              className={css.cardBackground}
            />
          ) : (
            <div className={css.cardBackground} style={backgroundStyles} />
          )}

          <Avatar
            className={css.cardImage}
            user={{
              username,
              id: userId,
              profilePicture,
              userTypes,
            }}
            color={profilePicture ? undefined : "var(--creators-color)"}
            variant="large"
          />
        </div>

        <div className={css.cardBody}>
          <Heading
            className={css.cardHeading}
            variant="extraSmall"
            align="center"
          >
            {username}
          </Heading>

          <div className={css.cardCategories}>
            {tags &&
              tags.slice(0, maxNumberOfTags).map((tag, idx) => (
                <Body
                  key={idx}
                  className={css.cardCategory}
                  variant={"body4"}
                  align="center"
                  title={tag.translation}
                >
                  {tag.translation}
                </Body>
              ))}
          </div>
        </div>
      </div>
    </Link>
  )
}
