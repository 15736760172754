import React, { CSSProperties } from "react"

import { FormattedMessage } from "react-intl"

import css from "./AppDownloadButtons.module.scss"

const APP_STORE_LINK = "https://url.chefclub.tv/chefce79fa"
const GOOGLE_PLAY_LINK = "https://url.chefclub.tv/chefca09df"

interface PropTypes {
  height?: number
}

function AppDownloadButtons({ height = 40 }: PropTypes) {
  return (
    <div
      className={css.storeBadges}
      style={
        {
          "--app-download-button-height": `${height}px`,
        } as CSSProperties
      }
    >
      <a className={css.appStore} href={APP_STORE_LINK}>
        <FormattedMessage id="action:get-on-app-store" />
      </a>
      <a className={css.playStore} href={GOOGLE_PLAY_LINK}>
        <FormattedMessage id="action:get-on-play-store" />
      </a>
    </div>
  )
}

export default AppDownloadButtons
