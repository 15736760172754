export function addZero(number: number): string {
  return number < 10 ? `0${number}` : `${number}`
}

export function formatTimestamp(timestamp: number): string {
  const roundedTimestamp = Math.round(timestamp)
  const hours = Math.floor(roundedTimestamp / 3600)
  const minutes = Math.floor(roundedTimestamp / 60) - hours * 60
  const seconds = roundedTimestamp % 60
  if (hours) {
    return `${hours}:${addZero(minutes)}:${addZero(seconds)}`
  }
  return `${minutes}:${addZero(seconds)}`
}

export function minutesToHHMM(minutes: number): {
  hours: number
  minutes: number
} {
  const hours = Math.floor(minutes / 60)
  const minutesLeft = minutes % 60
  return { hours: hours, minutes: minutesLeft }
}
