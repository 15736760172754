import React from "react"

import cn from "classnames"
import { FormattedMessage } from "react-intl"

import chefclubLogoImage from "../assets/chefclubAuthorPicture.svg"
import Avatar from "~/components/Avatar/Avatar"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import { Body, Heading } from "~/components/ui"
import { PartialPublicUser } from "~/models/PublicUser"
import { getUserTypeFromId } from "~/models/UserType"

import css from "./SearchResult.module.scss"

type propType = {
  absolute_url: string
  title: string
  author?: PartialPublicUser
  isChefclub?: boolean
  chefclubColor?: string
  pictureSrc: string
  multilineTitles?: boolean
  vertical?: string
}

const SearchResult = ({
  absolute_url,
  title,
  author,
  isChefclub,
  chefclubColor,
  pictureSrc,
  multilineTitles = false,
  vertical,
}: propType) => {
  const userType =
    author?.userTypes && author.userTypes?.length > 0
      ? getUserTypeFromId(author.userTypes[0])
      : null

  const isKidsVertical = vertical === "kids"

  const isCreator = userType?.id === "creator"

  const hasAvatar = author?.profilePicture?.length

  const hasUsername = author?.username?.length

  return (
    <Link to={absolute_url} absoluteLink className={css.resultRoot}>
      <div className={css.imagePart}>
        <img src={pictureSrc} alt="" />
      </div>
      <div className={css.info}>
        <div
          className={cn(css.recipeTitle, {
            [css.multilineTitles]: multilineTitles,
          })}
        >
          <Heading variant="extraSmall">{title}</Heading>
        </div>
        {author && !isChefclub && (
          <div className={css.authorLine}>
            <Avatar
              user={author}
              variant={"extraExtraSmall"}
              color={isCreator ? "var(--creators-color)" : "var(--gray4-color)"}
            />
            <Body variant="body4" semiBold>
              {`${author.username}${
                author.userTypes && author.userTypes.length > 0 ? " - " : ""
              }`}
            </Body>
            {userType && (
              <Body variant="body4" semiBold color="gray2">
                <FormattedMessage id={userType.messageId} />
              </Body>
            )}
          </div>
        )}
        {isChefclub && (
          <div className={css.authorLine}>
            {hasAvatar ? (
              <Avatar
                user={author}
                variant={"extraExtraSmall"}
                color={
                  isCreator ? "var(--creators-color)" : "var(--gray4-color)"
                }
              />
            ) : (
              <ColorableSVG
                className={css.logoChefclub}
                href={chefclubLogoImage}
                mainColor={chefclubColor ?? "#F5184D"}
                width="24"
                height="24"
                title="Chefclub"
              />
            )}
            <Body variant="body4" semiBold>
              {!hasUsername ? "Team -" : `${author.username} -`}
            </Body>
            <Body variant="body4" semiBold color="gray2">
              {!userType ? (
                `Chefclub ${isKidsVertical ? "Kids" : ""}`
              ) : (
                <FormattedMessage id={userType.messageId} />
              )}
            </Body>
          </div>
        )}
      </div>
    </Link>
  )
}

export default SearchResult
