import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import ExternalIntegrations, {
  ExternalIntegrationsProps,
} from "~/components/slices/ExternalIntegrations/ExternalIntegrations"

interface PropTypes extends PrismicExternalIntegration {
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
}

function LandingBodyExternalIntegration({
  primary,
  isDarkTheme,
  ...props
}: PropTypes) {
  return (
    <SliceSection {...props}>
      <ExternalIntegrations
        anchorId={primary.anchorId}
        {...{ ...props, ...primary }}
      />
    </SliceSection>
  )
}

export default LandingBodyExternalIntegration

export interface PrismicExternalIntegration extends PrismicSlice {
  sliceType: "external_integration"
  primary: ExternalIntegrationsProps
}

export const query = graphql`
  fragment PrismicExternalIntegrationKlaviyoForm on PrismicExternalIntegrationKlaviyoForm {
    id
    variation
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      className
      fullWidth
    }
  }
  fragment PrismicExternalIntegrationStoreLocator on PrismicExternalIntegrationStoreLocator {
    id
    variation
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      mapTitle: title
      mapUrl: url
    }
  }
  fragment PrismicExternalIntegrationPinterestWidget on PrismicExternalIntegrationPinterestWidget {
    id
    variation
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      accountId
      boardId
    }
  }
`
