import React from "react"

import { graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"

import { BasePrismicTimelineSlice, PrismicLink } from "~/models/PrismicTypes"
import { getPrismicLinkProps } from "~/utils/PrismicLink"

import css from "./TimelineCardImage.module.scss"

type PropTypes = PrismicTimelineBodyImage

function TimelineCardImage({ primary }: PropTypes) {
  const { image, link } = primary

  if (!image.fluid) return null

  const Component = (
    <Image fadeIn={false} fluid={image.fluid} alt={image?.alt || ""} />
  )

  return link?.url ? (
    <a {...getPrismicLinkProps(link)} className={css.imageWrap}>
      {Component}
    </a>
  ) : (
    <span className={css.imageWrap}>{Component}</span>
  )
}

export default TimelineCardImage

export interface PrismicTimelineBodyImage extends BasePrismicTimelineSlice {
  sliceType: "image"
  primary: BasePrismicTimelineSlice["primary"] & {
    link: PrismicLink
    image: {
      alt: string | null
      fluid: FluidObject | null
    }
  }
}

export const query = graphql`
  fragment PrismicTimelineV2DataBodyImage on PrismicTimelineV2DataBodyImage {
    id
    __typename
    sliceType: slice_type
    primary {
      date(formatString: "MMMM YYYY", locale: $lang)
      title {
        ...PrismicStructuredText
      }
      description {
        ...PrismicStructuredText
      }
      link {
        ...PrismicLink
      }
      image {
        alt
        fluid(maxWidth: 716) {
          ...GatsbyImgixFluid_noBase64
        }
      }
    }
  }
`
