import { graphql, useStaticQuery } from "gatsby"

export interface SiteMetadata {
  title: string
  description: string
  siteUrl: string
}

function useSiteMetadata(): SiteMetadata {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  return data.site.siteMetadata
}

export default useSiteMetadata
