import React from "react"

import { RecipeCarousel } from "~/components/Carousel/Carousel"
import Link from "~/components/Link/Link"
import { Container, Heading } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"
import { PartialLanding } from "~/templates/Landing/Landing"

import css from "./LandingCarousel.module.scss"

export interface LandingCarouselProps {
  landingId: string
  recipes: PartialRecipe[]
  landings: PartialLanding[]
  isPreview?: boolean
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function LandingCarousel({
  landingId,
  landings,
  recipes,
  isDarkTheme,
  eagerImages,
}: LandingCarouselProps) {
  // Get the current Landing
  const currentLanding = landings.find(landing => landing.uid === landingId)
  if (!currentLanding?.data.body) return null

  const { title, body } = currentLanding.data

  // From This landing, Gather all recipe ids
  // TODO: The following code is duplicated from CreateLandingPages: Refactor
  const recipeIds = body.flatMap<string>(slice => {
    switch (slice.sliceType) {
      case "recipe_carousel":
        return slice.items.map(item => item.recipeUid)
      case "recipe_video":
      case "text_recipe_columns":
        return slice.primary?.recipeUid ? [slice.primary.recipeUid] : []
      default:
        return []
    }
  })

  if (!recipeIds) return null

  // Get recipes from recipe ids
  const mappedItems = recipeIds.map(
    recipeId =>
      // If not found, return just the id
      recipes.find(recipe => recipe.id === recipeId) || recipeId
  )

  if (!mappedItems) return null

  return (
    <>
      {title && (
        <Container>
          <Heading variant="large" color={isDarkTheme ? "white" : "body"}>
            <Link
              className={css.hiddenLink}
              to="s/landing"
              params={currentLanding}
            >
              {title.text}
            </Link>
          </Heading>
        </Container>
      )}
      <RecipeCarousel recipes={mappedItems} {...{ isDarkTheme, eagerImages }} />
    </>
  )
}

export default LandingCarousel
