import Hls from "hls.js"

import { isIOS } from "~/utils/detector"

interface PlayerOptions {
  src: string
  player: HTMLVideoElement
  autoPlay?: boolean
}

export default function play({ src, player, autoPlay }: PlayerOptions) {
  const isAlreadyPlaying = !player.paused

  if (isAlreadyPlaying) return

  if (isIOS()) {
    return autoPlay && player.play()
  }
  if (Hls.isSupported()) {
    const config = {
      capLevelToPlayerSize: true,
      maxMaxBufferLength: 10,
    }
    const hls = new Hls(config)
    hls.loadSource(src)
    hls.attachMedia(player)
    // fly high from the start
    hls.startLevel = 2
    // The below line creates an error on safari, lets use a timeout instead.
    //hls.on(Hls.Events.MANIFEST_PARSED, function()
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        autoPlay &&
          player
            .play()
            .then(() => resolve())
            .catch(e => reject(e))
      }, 100)
    })
  } else {
    throw new Error("This browser does not support HLS")
  }
}
