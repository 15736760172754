import React, { CSSProperties } from "react"

import { Colors } from "~/types/global-types"
import { formatColor } from "~/utils/colors"

import css from "./Loader.module.scss"

interface PropTypes {
  color?: Colors
}

export function Loader({ color }: PropTypes) {
  const styling = {
    borderTop: color && `8px solid ${formatColor(color)}`,
  } as CSSProperties
  return (
    <div className={css.root}>
      <div className="o-loader" style={styling} />
    </div>
  )
}
