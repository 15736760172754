import React, { FC, Fragment } from "react"

import { Link } from "gatsby"
import { useIntl } from "react-intl"

import BreadcrumbSEO, { LinkItem } from "./BreadcrumbSEO"
import { Body, Container } from "~/components/ui"
import pathFromFullId from "~/routes/pathFromFullId"
import { capitalizeFirstLetter } from "~/utils/string"

import css from "./Breadcrumb.module.scss"

interface PropTypes {
  pageTitle: string
  parent?: LinkItem | null
  hide?: boolean // Don't display the Breadcrumb but print LDJSON for SEO
  template?: "Recipe" | "Profile"
  multipleParents?: LinkItem[] | null
}

const BreadcrumbWrapper: FC = ({ children }) => (
  <div className={css.wrapper}>
    <Container>{children}</Container>
  </div>
)

function Breadcrumb({
  parent, //Param for one parent -> Use in recipe page & profile
  pageTitle,
  hide,
  template,
  multipleParents, //Param when page has multiple parents -> Use in landing page
}: PropTypes) {
  const intl = useIntl()

  // Build links array
  const items: LinkItem[] = []

  switch (template) {
    case "Recipe":
      items.push({
        label: intl.formatMessage({ id: "breadcrumb/section:all-recipes" }),
        link: intl.formatMessage({ id: "url:recipes" }),
      })
      break

    case "Profile":
      items.push({
        label: intl.formatMessage({ id: "brand:my-chefclub" }),
        link: pathFromFullId("locale/profile", intl),
      })
      break

    default:
      break
  }

  if (multipleParents) {
    items.push(...multipleParents)
  }

  if (parent && parent.label && parent.link) {
    if (parent.parentSlug) {
      const grandParents = parent.parentSlug.split("/")
      const unnormalizedGrandParents = parent.unnormalizedParentSlug?.split("/")
      for (let i = 0; i < grandParents.length; i++) {
        const grandParent = grandParents[i]
        const unnormalizedGrandParent = unnormalizedGrandParents?.[i]
        items.push({
          label: unnormalizedGrandParent
            ? capitalizeFirstLetter(unnormalizedGrandParent)
            : capitalizeFirstLetter(grandParent),
          link: `/${intl.locale}/c/${grandParent}`,
        })
      }
    }
    items.push(parent)
  }

  items.push({ label: pageTitle })

  return (
    <>
      <BreadcrumbSEO items={items} />

      {!hide && (
        <BreadcrumbWrapper>
          <Body className={css.breadcrumb}>
            {items.map(({ label, link }, i) => {
              const isLast = i + 1 === items.length
              const className = isLast ? css.currentPage : ""
              const isInternal = link ? /^\/(?!\/)/.test(link) : false
              const linkWithTrailingSlash =
                link && (link.slice(-1) == "/" ? link : link + "/")
              return (
                <Fragment key={i}>
                  {linkWithTrailingSlash ? (
                    isInternal ? (
                      <Link
                        className={className}
                        to={linkWithTrailingSlash}
                        title={label}
                      >
                        {label}
                      </Link>
                    ) : (
                      <a
                        className={className}
                        href={linkWithTrailingSlash}
                        title={label}
                      >
                        {label}
                      </a>
                    )
                  ) : (
                    <span className={className}>{label}</span>
                  )}

                  {!isLast && <span className={css.arrow}>/</span>}
                </Fragment>
              )
            })}
          </Body>
        </BreadcrumbWrapper>
      )}
    </>
  )
}

export default Breadcrumb
