import "normalize.css"

import React, { CSSProperties, useEffect, useState } from "react"

import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"

import "./fonts.scss"
import "./global.scss"
import AccountModal, {
  AccountModalPagesIds,
} from "../Modals/AccountModal/AccountModal"
import EmailValidationModal from "../Modals/EmailValidationModal/EmailValidationModal"
import WelcomeModal from "../Modals/WelcomeModal/WelcomeModal"
import Footer from "./Footer/Footer"
import Header from "./Header/Header"
import HeaderSection from "./Header/HeaderSection"
import SearchLayout from "~/components/Layout/SearchLayout/SearchLayout"
import { CreatorsConditionsContext } from "~/context/CreatorsConditionsContext"
import { ModalLoginContext } from "~/context/ModalLoginContext"
import { SearchSuggestions } from "~/gatsby/utils/getSearchSuggestions"
import { SiteLinkLists } from "~/gatsby/utils/getSiteLinks"
import useIsClient from "~/hooks/useIsClient"
import useIsMobileWebView from "~/hooks/useIsMobileWebView"
import useSessionStorage from "~/hooks/useSessionStorage"
import { PartialCreator } from "~/models/PublicUser"
import { PartialEpisode } from "~/models/Show"
import Vertical from "~/models/Vertical"
import pathFromFullId from "~/routes/pathFromFullId"
import { profileApi, useProfileQuery } from "~/state/api/profile"
import { saveDjangoUserTokenInLocalStorage } from "~/state/django-compatibility"
import { authSelector, logout, setUserToken } from "~/state/modules/auth"
import { userSelector } from "~/state/modules/userInfo"
import CreatorsConditionsPopUp from "~/templates/Account/CreatorsConditions/CreatorsConditionsPopup/CreatorsConditionsPopup"
import { Locale } from "~/types/global-types"
import { readFromLocalStorage } from "~/utils/localStorage"

import css from "./Layout.module.scss"

interface PropTypes {
  children: JSX.Element
  locales: Locale[]
  siteLinkLists: SiteLinkLists
  location: Location
  options: LayoutOptions
  searchSuggestions: SearchSuggestions
  lastCreators?: PartialCreator[]
  lastShowEpisodes?: PartialEpisode[]
  shopUrl?: string
}

export interface LayoutOptions {
  forceSelectedSection?: HeaderSection
}

function useSyncWithDjangoAuth(paramToken: string | null) {
  const dispatch = useDispatch()
  if (paramToken?.length) {
    dispatch(setUserToken(paramToken))
    saveDjangoUserTokenInLocalStorage(paramToken)
    profileApi.endpoints.profile.initiate(paramToken)
  }

  const { token, isLoggedIn } = useSelector(authSelector)

  const user = useSelector(userSelector)

  // Fetch user data when token is added.
  useProfileQuery(token, {
    // Fetch profile data from API only if
    // - token is defined: (from Django or redux hydration) and not SRR
    // - User profile is not here yet
    skip: !isLoggedIn || !!user?.id,
  })

  // The django token is used as the only source of truth for the auth,
  // ... but is not defined in SSR
  useEffect(() => {
    const djangoUserToken = readFromLocalStorage<string>(
      "chefclub-token",
      false
    )

    if (djangoUserToken) {
      if (!token) dispatch(setUserToken(djangoUserToken))
    } else {
      // Do side effect (remove token from localStorage)
      dispatch(logout())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default function Layout({
  children,
  locales,
  siteLinkLists,
  location,
  options,
  searchSuggestions,
  lastCreators,
  lastShowEpisodes,
  shopUrl,
}: PropTypes) {
  const intl = useIntl()
  const [isSearchOpen, setSearchOpen] = useState<boolean>(false)
  let paramToken = null
  const isClient = useIsClient()
  if (isClient) {
    const urlParams = new URLSearchParams(window.location.search)
    paramToken = urlParams.get("token")
  }

  useSyncWithDjangoAuth(paramToken)

  const data = children.props.data
  const appSettings = data && data.appSettings

  const prismicID = children.props.pageContext.prismicId

  const [currentPageIsMainHome, currentPageIsKidsHome] = prismicID
    ? [
        prismicID === appSettings?.web_home_landing,
        prismicID === appSettings?.web_home_kids_landing,
      ]
    : [false, false]

  const currentPageIsLanding =
    children?.props.data && children?.props.data.prismicLanding

  const pageType =
    currentPageIsMainHome || currentPageIsKidsHome
      ? "Home"
      : currentPageIsLanding
      ? "Landing"
      : "Other"

  const alternateUrls =
    pageType === "Landing"
      ? children?.props.data.prismicLanding.alternateLanguages
      : pageType === "Other"
      ? children?.props?.data?.alternatesUrl?.nodes
      : null

  const isMobileWebView = useIsMobileWebView()

  let currentSection: HeaderSection | undefined

  let setThemeColor: CSSProperties = {}

  // Select section and subsection based on current URL
  const allSections = Object.values(HeaderSection)

  // // Manual override for the Recipes section
  if (isPrefixOfLocation(pathFromFullId("locale/recipes", intl), location)) {
    currentSection = HeaderSection.Recipes
  }

  // // Iterate through sections and subsections, find a matching one
  for (const section of allSections) {
    // Select section if its URL matches
    const sectionURL = intl.formatMessage({ id: section.urlMessageId })
    if (isPrefixOfLocation(sectionURL, location)) {
      currentSection = section
    }

    // Select section and subsection if subsection URL matches
    for (const subsection of section.subsections) {
      const subsectionURL = intl.formatMessage({ id: subsection.urlMessageId })
      if (isPrefixOfLocation(subsectionURL, location)) {
        currentSection = section
      }
    }
  }

  // // Manual override for kids-related pages
  const kidsVerticalPageCompletePath =
    pathFromFullId("locale/recipes", intl) + "kids"

  if (isPrefixOfLocation(kidsVerticalPageCompletePath, location)) {
    const kidsVerticalPageRelativePath = getRelativePath(
      pathFromFullId("locale/recipes", intl) + "kids"
    ) as string
    const locationSuffix = location.pathname.split(
      kidsVerticalPageRelativePath
    )[1]
    const isOnKidsVerticalPage = locationSuffix === "/"

    if (isOnKidsVerticalPage) {
      // Kids vertical page: must select Kids
      currentSection = HeaderSection.Kids
    } else if (currentSection === HeaderSection.Kids) {
      // Kids recipe page: must select Recipes instead of Kids
      currentSection = HeaderSection.Recipes
    }
  }

  // // Manual override for search-recipes-related pages
  const searchPath = pathFromFullId("locale/search", intl)

  if (isPrefixOfLocation(searchPath, location)) {
    // Search-recipes pages: must select Recipes
    currentSection = HeaderSection.Recipes
  }

  // Forced selected section?
  if (options.forceSelectedSection) {
    currentSection = options.forceSelectedSection as HeaderSection
  }

  // Get the current vertical from URL
  for (const vertical of Object.values(Vertical)) {
    const verticalURL = intl.formatMessage({ id: vertical.urlMessageId })
    if (isPrefixOfLocation(verticalURL, location)) {
      setThemeColor = {
        "--current-theme-color": vertical.color,
      } as CSSProperties
    }
  }

  const [openModal, setopenModal] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [fromModal, setFromModal] = useState("")
  const [toModal, setToModal] = useState<AccountModalPagesIds>("log-in")

  const user = useSelector(userSelector)
  const { token } = useSelector(authSelector)

  const isCreator = user?.userTypes.some(ut => ut === "creator")
  const [creatorsPopupHidden, setCreatorsPopupHidden] = useSessionStorage(
    "creatorsPopupHidden",
    false
  )

  const {
    data: profileData,
    isSuccess: getQuerySuccess,
    refetch,
  } = useProfileQuery(token, { skip: !isCreator })
  const creatorsConditionsIsAccepted = profileData?.data.creators_t_c_accepted

  return (
    <ModalLoginContext.Provider
      value={{
        isOpen: openModal,
        toggleModal: (from: string, to?: AccountModalPagesIds) => {
          setopenModal(!openModal)
          setFromModal(from)
          to && setToModal(to)
        },
        isLogin: isLogin,
      }}
    >
      <CreatorsConditionsContext.Provider
        value={{
          hidePopup: setCreatorsPopupHidden,
          isCreator: isCreator,
          creatorsConditionsIsAccepted: creatorsConditionsIsAccepted,
          refetch: refetch,
          getQuerySuccess: getQuerySuccess,
        }}
      >
        <div className={css.root}>
          {!isMobileWebView && (
            <>
              <Header
                openSearch={() => setSearchOpen(true)}
                lastCreators={lastCreators}
                lastShowEpisodes={lastShowEpisodes}
                shopUrl={shopUrl}
              />
              <SearchLayout
                isOpen={isSearchOpen}
                close={() => setSearchOpen(false)}
                searchSuggestions={searchSuggestions}
              />
            </>
          )}
          <main className={css.main} style={setThemeColor}>
            {children}
          </main>
          {!isMobileWebView && (
            <Footer
              availableLocales={locales}
              siteLinkLists={siteLinkLists}
              alternateUrls={alternateUrls}
              pageType={pageType}
            />
          )}
        </div>
        <AccountModal
          open={openModal}
          onClose={() => {
            setopenModal(false)
          }}
          onLogIn={() => setIsLogin(!isLogin)}
          from={fromModal}
          initialPage={toModal}
        />
        <WelcomeModal />
        <EmailValidationModal />
        {isCreator &&
          !creatorsConditionsIsAccepted &&
          !creatorsPopupHidden &&
          getQuerySuccess && <CreatorsConditionsPopUp />}
      </CreatorsConditionsContext.Provider>
    </ModalLoginContext.Provider>
  )
}

function getRelativePath(path: string): string | null {
  const relativePathRegex = /^(https?:\/\/(www\.)?chefclub.tv)?(.*)/
  const relativePathComponents = relativePathRegex.exec(path)

  if (!relativePathComponents) return null

  return relativePathComponents[3]
}

function isPrefixOfLocation(path: string, location: Location): boolean {
  const relativePath = getRelativePath(path)
  if (relativePath === null) return false

  return location.pathname.indexOf(relativePath) === 0
}
