import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../store"

export type RecipeView = {
  id: string
  lastView: number
}

const initialState: RecipeView[] = []

// We can maybe call it "history" ?
const slice = createSlice({
  name: "recipesViews",
  initialState,
  reducers: {
    updateHistory(state, action: PayloadAction<RecipeView[]>) {
      state = action.payload.sort((a, b) => b.lastView - a.lastView)
      return state
    },
  },
})

export const recipesViewsSelector = (state: RootState) =>
  state.user.recipesViews

export const { updateHistory } = slice.actions

export default slice.reducer
