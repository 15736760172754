import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { logoutDjangoUserInLocalStorage } from "../django-compatibility"
import { RootState } from "../store"
import { removeUser } from "./userInfo"

export interface AuthState {
  token: string | null
  isLoggedIn: boolean
}

const initialState: AuthState = {
  token: null,
  isLoggedIn: false,
}

// Thunks are used for async logic or side-effect
export const logout = createAsyncThunk("auth/logout", (_, thunkAPI) => {
  logoutDjangoUserInLocalStorage()
  thunkAPI.dispatch(removeUser())
})

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserToken(state, action: PayloadAction<string>) {
      state.token = action.payload
      state.isLoggedIn = !!action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(logout.fulfilled, state => {
      state.isLoggedIn = false
      state.token = null
    })
  },
})

export const authSelector = (state: RootState) => state.user.auth

export const { setUserToken } = slice.actions

export default slice.reducer
