import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { RootState } from "../store"
import { User } from "~/models/User"

const initialState: User | null = null

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state = { ...Object(state), ...action.payload }
      return state
    },
    removeUser(state) {
      state = initialState
      return state
    },
  },
})

export const userSelector = (state: RootState): User | null => state.user.info

export const { setUser, removeUser } = slice.actions

export default slice.reducer
