import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { getPrismicLocaleFromLocale } from "../../utils/locales"
import createLandingPages from "./createLandingPages"

interface PrismicAppSettingsKidsQuery {
  appSettings: {
    web_home_kids_landing: string
  }
}

export default async function createKidsHomeLandingPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql } = args
  let landingId =
    args.currentLocale.messages?.["config:prismic-kids-home-landing-id"]

  //Use, if exists, appSettings key instead of lingohub key
  const result = await graphql<PrismicAppSettingsKidsQuery>(
    `
      query appSettingsQuery($lang: String) {
        appSettings(lang: { eq: $lang }) {
          web_home_kids_landing
        }
      }
    `,
    {
      lang: getPrismicLocaleFromLocale(args.currentLocale.id),
    }
  )

  if (result.data?.appSettings.web_home_kids_landing) {
    landingId = result.data?.appSettings.web_home_kids_landing
  }

  if (landingId?.length) {
    createLandingPages(args, landingId as string)
  }
}
