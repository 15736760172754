import { SliceVideoControls } from "./SliceTypes"
import { PlayerProps } from "~/components/VideoPlayer/usePlayerControls"

export const getVideoControlsProps = (
  videoControls: SliceVideoControls
): Partial<Pick<PlayerProps, "disableControls" | "autoPlay">> => {
  switch (videoControls) {
    case "Hide controls and auto-play":
      return { disableControls: true, autoPlay: true }
    case "Show controls and auto-play":
      return { disableControls: false, autoPlay: true }
    default:
      return { disableControls: false, autoPlay: false }
  }
}
