import React, { useEffect, useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"
import { TransitionGroup } from "react-transition-group"

import Card from "./Cards/Card"
import FilterByTag from "~/components/FilterByTag/FilterByTag"
import { Body, Button, Col, Heading, Loader, Row } from "~/components/ui"
import useCreators, { filteredBySlugCreators } from "~/hooks/useCreators"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PublicUser } from "~/models/PublicUser"
import { useGetCreatorTagsQuery } from "~/state/api/userTags"
import { ChefclubLocale } from "~/utils/locales"

import css from "./CreatorsListing.module.scss"

interface PropTypes {
  isPreview?: boolean
  isDarkTheme?: boolean
  eagerImages?: boolean
}

const LOADING_THRESHOLD = 9

// Eager-loads images/backgrounds from first 5 creators if mobile, or first 9 creators if desktop
const [MOBILE_EAGERLOAD_THRESHOLD, DESKTOP_EAGERLOAD_THRESHOLD] = [5, 9]

function CreatorsListing({ isDarkTheme, eagerImages }: PropTypes) {
  const intl = useIntl()
  const isCompact = useIsOnCompact()

  // Creators filtered by locale
  const creators = useCreators(intl.locale as ChefclubLocale)

  const [showButton, setShowButton] = useState<boolean>(false)
  const [filteredCreators, setFilteredCreators] = useState(creators)
  const [shownCreatorsAmount, setShownCreatorsAmount] =
    useState<number>(LOADING_THRESHOLD)

  const {
    data,
    isSuccess: isTagsLoaded,
    isLoading: isTagsLoading,
  } = useGetCreatorTagsQuery({
    lang: intl.locale as ChefclubLocale,
  })

  // If filter is off, account for all creators
  const filteredCreatorsCount = filteredCreators.length

  // Show Load-more button only if there are more creators to show
  const moreCreatorsToShow = filteredCreatorsCount > shownCreatorsAmount

  const showMore = () => {
    if (!moreCreatorsToShow) return
    setShownCreatorsAmount(shownCreatorsAmount + LOADING_THRESHOLD)
  }

  useEffect(() => {
    setShowButton(moreCreatorsToShow)
  }, [moreCreatorsToShow])

  const isEagerLoadable = (idx: number) => {
    return isCompact
      ? idx < MOBILE_EAGERLOAD_THRESHOLD
      : idx < DESKTOP_EAGERLOAD_THRESHOLD
  }

  const isFullWidth = (idx: number) => {
    return (idx + 1) % 5 === 0
  }

  // Get tag translations & slugs to pass it down to FilterByTag component
  const allTags =
    data?.map(tag => {
      return { translation: tag.translation, slug: tag.slug }
    }) ?? []

  const creatorsAmount = intl.formatMessage(
    {
      id: "creators/text:creators-amount",
    },
    {
      count: filteredCreatorsCount,
    }
  )

  return (
    <div
      className={cn(css.root, {
        [css.darkTheme]: isDarkTheme,
      })}
    >
      {!creators.length ? (
        // If empty Creators list
        <Heading variant="medium" align="center">
          {intl.formatMessage({
            id: "creators/text:no-available-data",
          })}
        </Heading>
      ) : (
        // If non-empty Creators list
        <>
          {isTagsLoading ? (
            // If tags API-fetch is in progress
            <div className={css.loaderContainer}>
              <Loader />
            </div>
          ) : isTagsLoaded && data?.length ? (
            // If tags successfully fetched
            <div className={css.filterContainer}>
              <FilterByTag
                tags={allTags}
                items={creators}
                itemsCount={creatorsAmount}
                setFilteredItems={setFilteredCreators}
                filteringLogic={filteredBySlugCreators}
              />
            </div>
          ) : isTagsLoaded && !data?.length ? (
            <Body variant="body2" semiBold align="center">
              {intl.formatMessage({
                id: "category/text:no-results",
              })}
            </Body>
          ) : (
            // If failed API call
            <Body variant="body2" semiBold align="center">
              {intl.formatMessage({
                id: "creators/text:error-when-loading",
              })}
            </Body>
          )}
          <TransitionGroup>
            <Row>
              {filteredCreators
                ?.slice(0, shownCreatorsAmount)
                .map((creator: PublicUser, idx: number) => (
                  <Col
                    key={idx}
                    width={[isFullWidth(idx) ? 12 : 6, 4, 4]}
                    className={cn(css.cardContainer, {
                      [css.fullWidth]: isFullWidth(idx),
                    })}
                  >
                    <Card
                      userId={creator.id}
                      username={creator.username}
                      userTypes={creator.userTypes}
                      profileBackground={creator.coverImage}
                      profilePicture={creator.profilePicture}
                      tags={creator.tags}
                      eagerImages={
                        isEagerLoadable(idx) ? eagerImages : undefined
                      }
                      className={css.card}
                    />
                  </Col>
                ))}
            </Row>
          </TransitionGroup>
        </>
      )}
      {showButton && (
        <div className={css.buttonContainer}>
          <Button
            fullWidth={[true, false]}
            variant="secondary"
            size="medium"
            color="original"
            onClick={showMore}
          >
            {intl.formatMessage({
              id: "creators/action:show-more-creators",
            })}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CreatorsListing
