import useEventListener from "./useEventListener"
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect"

function useLockedBody(locked = false) {
  useEventListener("scroll", () => {
    document.body.style.setProperty("--scroll-y", `${window.scrollY}px`)
  })

  // Do the side effect before render
  useIsomorphicLayoutEffect(() => {
    if (!locked) {
      return
    }

    const body = document.body
    const root = document.getElementById("___gatsby")!
    const scrollY = body.style.getPropertyValue("--scroll-y")
    const scrollBarWidth = window.innerWidth - body.scrollWidth

    // Avoid width reflow
    if (scrollBarWidth) {
      root.style.paddingRight = `${scrollBarWidth}px`
    }

    root.style.position = "fixed" // Block scrollY (includes iOS)
    root.style.top = `-${scrollY}` // Keep scrollY position
    root.style.width = "100vw" // Block resizing of the window after removing the scrollbar

    return () => {
      if (scrollBarWidth) {
        root.style.paddingRight = ""
      }

      const scrollY = root.style.top

      root.style.position = ""
      root.style.top = ""
      root.style.width = ""

      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }
  }, [locked])
}

export default useLockedBody
