import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { getPrismicLocaleFromLocale } from "../../utils/locales"
import createLandingPages from "./createLandingPages"

interface PrismicAppSettingsKidsQuery {
  appSettings: {
    web_kids_converter: string
  }
}

async function createKidsConverterPages(args: CreatePageCustomScriptArguments) {
  const { reporter, currentLocale, graphql } = args

  //Use, if exists, appSettings key instead of lingohub key
  const result = await graphql<PrismicAppSettingsKidsQuery>(
    `
      query appSettingsQuery($lang: String) {
        appSettings(lang: { eq: $lang }) {
          web_kids_converter
        }
      }
    `,
    {
      lang: getPrismicLocaleFromLocale(currentLocale.id),
    }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data?.appSettings.web_kids_converter) {
    const landingId = result.data?.appSettings.web_kids_converter
    createLandingPages(args, landingId as string)
  }
}

export default createKidsConverterPages
