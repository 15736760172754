import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import RecipeCarouselSlice from "~/components/slices/RecipeCarousel"
import { PartialRecipe } from "~/models/PartialRecipe"
import { PrismicStructuredText } from "~/models/PrismicTypes"

interface PropTypes extends PrismicLandingDataBodyRecipeCarousel {
  recipes?: PartialRecipe[]
  isPreview?: boolean
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
  eagerImages?: boolean
}

function LandingBodyRecipeCarousel({ primary, ...props }: PropTypes) {
  return (
    <SliceSection
      margin="small"
      noContainer
      anchorId={primary.anchorId}
      {...props}
    >
      <RecipeCarouselSlice title={primary.title1} {...props} />
    </SliceSection>
  )
}

export default LandingBodyRecipeCarousel

export interface PrismicLandingDataBodyRecipeCarousel extends PrismicSlice {
  sliceType: "recipe_carousel"
  primary: {
    title1: PrismicStructuredText
    anchorId?: string
  }
  items: [{ recipeUid: string }]
}

export const query = graphql`
  fragment PrismicLandingDataBodyRecipeCarousel on PrismicLandingDataBodyRecipeCarousel {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      title1 {
        ...PrismicStructuredText
      }
      anchorId: id
    }
    items {
      recipeUid: recipe_uid
    }
  }
`
