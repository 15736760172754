import React from "react"

import cn from "classnames"
import { Helmet } from "react-helmet"

import useIsClient from "~/hooks/useIsClient"

import css from "./KlaviyoForm.module.scss"
export interface KlaviyoFormProps {
  className: string
  fullWidth?: boolean
}

interface PropTypes extends KlaviyoFormProps {
  isPreview?: boolean
  isDarkTheme?: boolean
}

const Klaviyo = React.memo(function Klaviyo({
  className,
  fullWidth,
}: PropTypes) {
  return (
    <>
      <Helmet>
        <script
          src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=UeE32u"
          type="text/javascript"
        />
      </Helmet>
      <div className={cn(className, { [css.fullWidth]: fullWidth })}></div>
    </>
  )
})

function KlaviyoForm({ className, fullWidth, isDarkTheme }: PropTypes) {
  const isClient = useIsClient()
  return (
    <div className={css.center}>
      {isClient && <Klaviyo className={className} fullWidth={fullWidth} />}
    </div>
  )
}

export default KlaviyoForm
