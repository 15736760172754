import React, { createContext, CSSProperties } from "react"

export type Theme = "default" | "kids" | "food" | "creators" | "nav"

export const ThemeContext = createContext<Theme>("default")

export const StorybookPrismicSlicesBackgroundContext = React.createContext({
  changeColor: (newColor: CSSProperties["color"]) => {
    console.log(newColor)
  },
  setState: (newState: boolean) => {
    console.log(newState)
  },
  setPaddingTopState: (newState: boolean) => {
    console.log(newState)
  },
  setContainerState: (newState: boolean) => {
    console.log(newState)
  },
})
