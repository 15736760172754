import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { CommunityPostsResponse } from "../api/community"
import { RootState } from "../store"
import { apiCommunityPostToPost, CommunityPost } from "~/models/CommunityPost"

export interface CommunityState {
  posts: CommunityPost[]
  nextUrl: null | string // Used to save full next url
  nextCursor: null | string // Used to trigger refetch
}

const initialState: CommunityState = {
  posts: [],
  nextUrl: null,
  nextCursor: null,
}

const slice = createSlice({
  name: "community",
  initialState,
  reducers: {
    onReceivePosts(state, action: PayloadAction<CommunityPostsResponse>) {
      const formattedPosts = action.payload.results.map(apiCommunityPostToPost)
      state.posts.push(...formattedPosts)
      state.nextUrl = action.payload.next || null
    },
    nextPage(state) {
      if (state.nextUrl) {
        const urlParams = new URLSearchParams(
          new URL(state.nextUrl).searchParams
        )
        const cursor = urlParams.get("cursor")

        state.nextCursor = cursor
      }
    },
  },
})

export const communitySelector = (state: RootState) => state.community

export const { onReceivePosts, nextPage } = slice.actions

export default slice.reducer
