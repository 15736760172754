import React, { Fragment } from "react"

import { graphql } from "gatsby"

import PrismicNavMenuLayoutMobile2ColDesktop1Col, {
  PrismicLayoutMobile2ColDesktop1Col,
} from "./Slices/Layout/PrismicNavMenuLayoutMobile2ColDesktop1Col"
import PrismicNavMenuListDefault, {
  PrismicListDefault,
} from "./Slices/List/PrismicNavMenuListDefault"
import PrismicNavMenuListFromDiets, {
  PrismicListFromDiets,
} from "./Slices/List/PrismicNavMenuListFromDiets"
import PrismicNavMenuListFromLastCreators, {
  PrismicListLastCreators,
} from "./Slices/List/PrismicNavMenuListFromLastCreators"
import PrismicNavMenuListFromSelectedLandings, {
  PrismicListFromSelectedLandings,
} from "./Slices/List/PrismicNavMenuListFromSelectedLandings"
import PrismicNavMenuTilesDefault, {
  PrismicTilesDefault,
} from "./Slices/Tiles/PrismicNavMenuTilesDefault"
import PrismicNavMenuListFromLastShowEpisodes, {
  PrismicListLastShowEpisodes,
} from "~/components/Layout/Header/Slices/List/PrismicNavMenuListFromLastShowEpisodes"
import { PartialRecipe } from "~/models/PartialRecipe"
import { PartialCreator } from "~/models/PublicUser"
import { PartialEpisode } from "~/models/Show"

import css from "./NavSlices.module.scss"

export type AnyPrismicNavSlice = {
  //for all slices
} & (
  | PrismicListFromDiets
  | PrismicListFromSelectedLandings
  | PrismicListLastCreators
  | PrismicListLastShowEpisodes
  | PrismicListDefault
  | PrismicTilesDefault
  | PrismicLayoutMobile2ColDesktop1Col
)
// | anyElseSlice

interface NavSliceAdditionalData {
  lastRecipes?: PartialRecipe[]
  lastCreators?: PartialCreator[]
  lastShowEpisodes?: PartialEpisode[]
}

function slices(
  props: AnyPrismicNavSlice,
  { lastRecipes, lastCreators, lastShowEpisodes }: NavSliceAdditionalData
) {
  switch (props.sliceType) {
    case "list":
      switch (props.variation) {
        case "fromDiets":
          return <PrismicNavMenuListFromDiets key={props.id} {...props} />
        case "fromSelectedLandings":
          return (
            <PrismicNavMenuListFromSelectedLandings key={props.id} {...props} />
          )
        case "lastCreators":
          return (
            <PrismicNavMenuListFromLastCreators
              key={props.id}
              lastCreators={lastCreators}
              {...props}
            />
          )
        case "lastShowEpisodes":
          if (lastShowEpisodes) {
            return (
              <PrismicNavMenuListFromLastShowEpisodes
                key={props.id}
                lastShowEpisodes={lastShowEpisodes}
                {...props}
              />
            )
          } else {
            return null
          }
        case "default":
          return <PrismicNavMenuListDefault key={props.id} {...props} />
        default:
          null
      }
    case "tiles":
      switch (props.variation) {
        case "default":
          return <PrismicNavMenuTilesDefault key={props.id} {...props} />
        default:
          return null
      }
    case "layout":
      switch (props.variation) {
        case "mobile2ColDesktop1Col":
          return (
            <PrismicNavMenuLayoutMobile2ColDesktop1Col
              key={props.id}
              {...props}
            />
          )
        default:
          return null
      }
    default:
      return null
  }
}

interface PropTypes extends NavSliceAdditionalData {
  body?: AnyPrismicNavSlice[]
}

function NavSlices({
  body,
  lastRecipes,
  lastCreators,
  lastShowEpisodes,
  ...props
}: PropTypes) {
  return (
    <div className={css.root}>
      {body?.map((slice, index) => (
        <>
          {slices(
            {
              ...slice,
              ...props,
            },
            {
              lastRecipes,
              lastCreators,
              lastShowEpisodes,
            }
          )}
        </>
      ))}
    </div>
  )
}

export default NavSlices

export const query = graphql`
  fragment PrismicNavMenu on PrismicNavMenuDataType {
    body {
      ...PrismicListDefault
      ...PrismicListFromDiets
      ...PrismicListFromSelectedLandings
      ...PrismicListLastCreators
      ...PrismicListLastShowEpisodes
      ...PrismicTilesDefault
      ...PrismicLayoutMobile2ColDesktop1Col
    }
    body1 {
      ...PrismicListDefault
      ...PrismicListFromDiets
      ...PrismicListFromSelectedLandings
      ...PrismicListLastCreators
      ...PrismicListLastShowEpisodes
      ...PrismicTilesDefault
      ...PrismicLayoutMobile2ColDesktop1Col
    }
    body2 {
      ...PrismicListDefault
      ...PrismicListFromDiets
      ...PrismicListFromSelectedLandings
      ...PrismicListLastCreators
      ...PrismicListLastShowEpisodes
      ...PrismicTilesDefault
      ...PrismicLayoutMobile2ColDesktop1Col
    }
  }
`
