import React from "react"

import { FormattedMessage } from "react-intl"

import { Button } from "~/components/ui"
import { useSendResetPasswordMutation } from "~/state/api/auth"

import css from "./ResendResetPassword.module.scss"

type PropsType = {
  email: string
  onSubmit?: () => void
}

export function ResendResetPassword({ onSubmit, email }: PropsType) {
  const [resetPassword, { isLoading, isSuccess }] =
    useSendResetPasswordMutation()

  const submit = () => {
    resetPassword({ email: email })
  }

  return (
    <div className={css.root}>
      <Button
        color="original"
        variant="tertiary"
        underline
        className={css.button}
        type="submit"
        disabled={isLoading}
        onClick={() => submit()}
      >
        <FormattedMessage id="form/action:resend-email" />
      </Button>
    </div>
  )
}
