import React from "react"

import { graphql } from "gatsby"

import { PartialLanding } from "../Landing"
import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import LandingCarousel from "~/components/slices/LandingCarousel/LandingCarousel"
import { PartialRecipe } from "~/models/PartialRecipe"

interface PropTypes extends PrismicLandingDataBodyLandingCarousel {
  recipes?: PartialRecipe[]
  landings?: PartialLanding[]
  eagerImages?: boolean
}

function LandingBodyLandingCarousel({
  primary,
  recipes,
  landings,
  ...props
}: PropTypes) {
  const landingId = primary.source.uid
  if (!landingId || !landings || !recipes) return null

  return (
    <SliceSection
      margin="small"
      noContainer
      anchorId={primary.anchorId}
      {...props}
    >
      <LandingCarousel {...{ landingId, landings, recipes, ...props }} />
    </SliceSection>
  )
}

export default LandingBodyLandingCarousel

export interface PrismicLandingDataBodyLandingCarousel extends PrismicSlice {
  sliceType: "landing_carousel"
  primary: { source: { uid: string }; anchorId?: string }
}

export const query = graphql`
  fragment PrismicLandingDataBodyLandingCarousel on PrismicLandingDataBodyLandingCarousel {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      source {
        uid
      }
      anchorId: id
    }
  }
`
