import React from "react"

interface ICreatorsConditionsContext {
  isCreator: boolean | undefined
  hidePopup?: (newValue: boolean) => void
  creatorsConditionsIsAccepted: boolean | null | undefined
  refetch?: () => void
  getQuerySuccess: boolean
}

const defaultState = {
  isCreator: false,
  creatorsConditionsIsAccepted: false,
  getQuerySuccess: false,
}

export const CreatorsConditionsContext =
  React.createContext<ICreatorsConditionsContext>(defaultState)
