import { User, userToLegacyUser } from "~/models/User"
import {
  removeFromLocalStorage,
  saveInLocalStorage,
} from "~/utils/localStorage"

/*
  backward compatibility :
  - [User Token]: In the Django website, the user's token is saved in localStorage
    using the "chefclub-token" key.
    We do the same

  - [User Data]: In the Django website, the user's first name is stored as "firstname"
    (in local storage) whereas the API returns it as "first_name".
    We do the same for last name.
    Also, last_name and first_name may be null, if it's the case, we set
    them to "" (empty string) after login, so the types are consistent.
*/

export function saveDjangoUserTokenInLocalStorage(token: string): void {
  saveInLocalStorage("chefclub-token", token, false)
}

export function saveDjangoUserInLocalStorage(user: User): void {
  saveInLocalStorage("chefclub-profile", userToLegacyUser(user))
}

export function logoutDjangoUserInLocalStorage(): void {
  removeFromLocalStorage("chefclub-token")
  removeFromLocalStorage("chefclub-profile")
}
