import { CreatePageCustomScriptArguments } from "../../types/global-types"

export interface LandingPagePreviewQuery {
  allPrismicLanding: {
    nodes: [
      {
        id: string
        uid: string
      }
    ]
  }
}

export default async function createLandingPagePreview(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, reporter, defaultContext, createPage } = args

  const placeholderContentDocumentUid = "landing-preview-placeholder-content"

  const result = await graphql<LandingPagePreviewQuery>(
    `
      query createLandingPagePreviewQuery($uid: String) {
        allPrismicLanding(filter: { uid: { eq: $uid } }) {
          nodes {
            id
            uid
          }
        }
      }
    `,
    { uid: placeholderContentDocumentUid }
  )

  if (result.errors) reporter.panic(result.errors)

  const placeholderContentLanding = result.data!.allPrismicLanding.nodes[0]

  if (!placeholderContentLanding) {
    reporter.panic(
      "createLandingPagePreview.ts: no result when querying for Landing placeholder content document"
    )
  }

  createPage({
    context: {
      ...defaultContext,
      id: placeholderContentLanding.id,
      lang: "fr-fr",
    },
  })
}
