import React, { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import * as yup from "yup"

import { Body, Button, Fieldset, TextInput } from "~/components/ui"
import { useSendResetPasswordMutation } from "~/state/api/auth"
import getFormValidators from "~/utils/formValidation"

import css from "./SendResetPasswordForm.module.scss"

type PropsType = {
  onResetPasswordSend?: (email: string) => void
}

export function SendResetPasswordForm({ onResetPasswordSend }: PropsType) {
  const intl = useIntl()
  const { email } = getFormValidators(intl)
  const [resetPassword, { isLoading, isSuccess }] =
    useSendResetPasswordMutation()
  const [emailToSend, setEmailToSend] = useState("")

  useEffect(() => {
    if (isSuccess && onResetPasswordSend) {
      onResetPasswordSend(emailToSend)
    }
  }, [isSuccess, onResetPasswordSend])

  const validationSchema = useMemo(
    () => yup.object().shape({ email }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { register, ...form } = useForm<{ email: string }>({
    resolver: yupResolver(validationSchema),
  })

  const submit = form.handleSubmit((values: { email: string }) => {
    setEmailToSend(values.email)
    resetPassword(values)
  })

  const { errors, isSubmitted } = form.formState

  return (
    <form className={css.root} onSubmit={submit} noValidate>
      {isSubmitted && (
        <Body
          variant="body3"
          color="success"
          align="center"
          noMargin
          role="alert"
          className={css.errorMessage}
        >
          <FormattedMessage id="form/text:send-reset-password-success-message" />
        </Body>
      )}

      <Fieldset error={errors.email?.message}>
        <TextInput
          type="email"
          {...register("email")}
          placeholder={intl.formatMessage({ id: "form/text:label-email" })}
          errors={errors.email}
          className={css.input}
        />
      </Fieldset>

      <Button
        className={css.button}
        type="submit"
        disabled={isLoading}
        color="original"
      >
        <FormattedMessage id="form/action:reset-my-password" />
      </Button>
    </form>
  )
}
