import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface HubPagesQuery {
  allCategory: {
    nodes: {
      slug: string
      language: string
      childrensCategories: string[]
    }[]
  }
}

export default async function createHubPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const result = await graphql<HubPagesQuery>(
    `
      query hubPagesQuery($lang: String) {
        allCategory(
          filter: { parentSlug: { eq: "" }, language: { eq: $lang } }
        ) {
          nodes {
            slug
            language
            childrensCategories
          }
        }
      }
    `,
    { lang: currentLocale.id }
  )

  if (result.data) {
    for (const category of result.data.allCategory.nodes) {
      try {
        createPage({
          routeParams: category,
          context: {
            ...defaultContext,
            childrensCategories: category.childrensCategories,
            slug: category.slug,
            lang: category.language,
          },
        })
      } catch (e) {
        console.log("ERROR", e)
      }
    }
  }
}
