import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import Text, { TextProps } from "~/components/slices/Text/Text"

interface PropTypes extends PrismicLandingDataBodyText {
  isDarkTheme?: boolean
  isLeadingSlice?: boolean
}

function LandingBodyText({ primary, isDarkTheme, ...props }: PropTypes) {
  const usedAsTitle = !primary.text.text
  return (
    <SliceSection
      margin={usedAsTitle ? "none" : "large"}
      anchorId={primary.anchorId}
      {...props}
    >
      <Text {...{ ...props, ...primary, isDarkTheme }} />
    </SliceSection>
  )
}

export default LandingBodyText

export interface PrismicLandingDataBodyText extends PrismicSlice {
  sliceType: "text"
  primary: TextProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyText on PrismicLandingDataBodyText {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      textAlign: text_align
      title {
        ...PrismicStructuredText
      }
      titleColor: title_color
      subtitle {
        ...PrismicStructuredText
      }
      text {
        ...PrismicStructuredText
      }
      hasSeparator: has_separator
      buttonLink: button_link {
        ...PrismicLink
      }
      buttonLabel: button_label {
        ...PrismicStructuredText
      }
      buttonStyle: button_style
      buttonColor: button_color
      anchorId: id
    }
  }
`
