import React from "react"

import { RecipeCarousel as Carousel } from "~/components/Carousel/Carousel"
import Link from "~/components/Link/Link"
import { Button, Container, Heading, HrefButton } from "~/components/ui"
import { PartialRecipe } from "~/models/PartialRecipe"

import css from "./RecipeCarousel.module.scss"

interface PropTypes {
  title?: string
  subtitle?: string
  recipes: PartialRecipe[]
  button?: null | {
    label: string
    link: string
  }
  fullWidthClickable?: boolean
  isDarkTheme?: boolean
  isKidsTheme?: boolean
  underline?: boolean
}

function RecipeCarousel({
  title,
  subtitle,
  recipes,
  button,
  underline = true, // Underlined by default
  fullWidthClickable,
  isDarkTheme,
  isKidsTheme,
}: PropTypes) {
  if (recipes.length < 1) {
    return null
  }

  const TopContainerFullWidthLink = ({
    children,
  }: {
    children: React.ReactNode
  }) =>
    button && fullWidthClickable ? (
      <Link to={button.link} className={css.topRowLink} absoluteLink>
        {children}
      </Link>
    ) : (
      <>{children}</>
    )

  return (
    <div className={css.root}>
      <TopContainerFullWidthLink>
        <Container className={css.topRow}>
          {title && (
            <Heading
              variant="large"
              className={css.titlesContainer}
              color={isDarkTheme ? "white" : "body"}
            >
              <span>{title}</span>
              {subtitle && (
                <>
                  <span className={css.separator}>-</span>
                  <span>{subtitle}</span>
                </>
              )}
            </Heading>
          )}
          {button &&
            (fullWidthClickable ? (
              <Button
                className={css.button}
                variant="tertiary"
                underline={underline}
                title={button.label}
                color={
                  isKidsTheme ? "kids" : isDarkTheme ? "white" : "original"
                }
              >
                {button.label}
              </Button>
            ) : (
              <HrefButton
                href={button.link}
                className={css.button}
                variant="tertiary"
                underline={underline}
                title={button.label}
                color={
                  isKidsTheme ? "kids" : isDarkTheme ? "white" : "original"
                }
              >
                {button.label}
              </HrefButton>
            ))}
        </Container>
      </TopContainerFullWidthLink>

      <Carousel recipes={recipes} />
    </div>
  )
}

export default RecipeCarousel
