import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface createHomeHubPagesQuery {
  allCategory: {
    nodes: {
      slug: string
      language: string
      childrensCategories: string[]
    }[]
  }
}

export default async function createHomeHubPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const result = await graphql<createHomeHubPagesQuery>(
    `
      query hubPagesQuery($lang: String) {
        allCategory(
          filter: { parentSlug: { eq: "" }, language: { eq: $lang } }
        ) {
          nodes {
            slug
            language
            childrensCategories
          }
        }
      }
    `,
    { lang: currentLocale.id }
  )

  if (result.data) {
    try {
      createPage({
        context: {
          ...defaultContext,
        },
      })
    } catch (e) {
      console.log("ERROR")
    }
  }
}
