import React, { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import cn from "classnames"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import Select from "react-select"
import * as yup from "yup"

import plusIcon from "../assets/plus.svg"
import { Ingredient, ingredients } from "../data/Ingredients"
import { Units, units } from "../data/Units"
import { KiddozEquivalence } from "../KidsConverterV2"
import { getUnitBySlug, Kiddoz, KiddozName, kiddozValues } from "../model"
import {
  convertFood2ml,
  convertQuantityToKiddoz,
  getIngredientFromSlug,
} from "../utils"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Button, Row, Select as Select2, TextInput } from "~/components/ui"
import useDocument from "~/hooks/useDocument"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import getFormValidators from "~/utils/formValidation"
import { capitalizeFirstLetter } from "~/utils/string"

import css from "./KiddozConversionForm.module.scss"

interface KidsFormProps {
  ingredient: string
  quantity: number
  unity: string
}

interface PropTypes {
  action: (kiddoz: KiddozEquivalence) => void
  cancel?: () => void
  prefill?: KidsFormProps
}
const KiddozConversionForm = ({
  action,
  cancel,
  prefill,
  ...rest
}: PropTypes) => {
  const intl = useIntl()
  const simpleLocale = intl.locale.split("-")[0]
  const isMobile = useIsOnCompact()

  const { requiredString, requiredPositiveIntegerNumber, requiredKinds } =
    getFormValidators(intl)

  const isModify = !!cancel

  const [unitsOptions, setUnitsOptions] = useState(units)

  const doc = useDocument()

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ingredient: requiredKinds,
        quantity: requiredPositiveIntegerNumber,
        unity: requiredString,
      }),
    [requiredPositiveIntegerNumber, requiredString, requiredKinds]
  )

  const { register, ...form } = useForm<KidsFormProps>({
    resolver: yupResolver(validationSchema),
    defaultValues: prefill,
  })

  const submit = (values: KidsFormProps) => {
    const ingredient = getIngredientFromSlug(values.ingredient)
    const unit = getUnitBySlug(values.unity)
    if (!unit) {
      return
    }

    let resultsInKiddoz: {
      kiddoz: KiddozName | undefined
    }[]

    if (unit.slug === "gram") {
      const kiddozGramsValues = [
        ingredient.poussin,
        ingredient.poule,
        ingredient.souris,
        ingredient.chat,
        ingredient.cochon,
        ingredient.chef,
      ]
      resultsInKiddoz = convertQuantityToKiddoz(
        [...kiddozGramsValues],
        values.quantity
      ).path.map(path => {
        return {
          kiddoz: Kiddoz.get(path),
        }
      })
    } else {
      resultsInKiddoz = convertQuantityToKiddoz(
        [...kiddozValues],
        convertFood2ml({
          unit: unit,
          value: values.quantity,
        })
      ).path.map(path => {
        return {
          kiddoz: Kiddoz.get(path),
        }
      })
    }

    const KiddozEquivalence = {
      kiddoz: resultsInKiddoz,
      ingredient: values.ingredient,
      initialValue: {
        units: unit,
        quantity: values.quantity,
      },
    }
    action(KiddozEquivalence)
  }

  //memo translated kinds
  const kindsTranslatedSorted = useMemo(
    () =>
      ingredients.sort((kindA: Ingredient, kindB: Ingredient) => {
        const kindATranslated = (
          kindA[simpleLocale as keyof Ingredient] as string
        )
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
        const kindBTranslated = (
          kindB[simpleLocale as keyof Ingredient] as string
        )
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
        if (kindATranslated < kindBTranslated) {
          return -1
        }
        if (kindATranslated > kindBTranslated) {
          return 1
        }
        return 0
      }),
    [intl]
  )

  const ingredientOptions = useMemo(
    () =>
      kindsTranslatedSorted.map((kind: Ingredient) => {
        return {
          value: kind.slug,
          label: capitalizeFirstLetter(
            (kind[simpleLocale as keyof Ingredient] as string).toLowerCase()
          ),
        }
      }),
    [kindsTranslatedSorted, simpleLocale]
  )

  const filterUnits = () =>
    units.filter(unit => {
      if (unit[simpleLocale as keyof Units] === "") {
        return false
      }
      let keepUnit = false
      const selectedIngredientSlug = form.getValues().ingredient
      const ingredientIsLiquid =
        selectedIngredientSlug &&
        getIngredientFromSlug(selectedIngredientSlug).is_liquid === true

      const ingredientIsSolid =
        selectedIngredientSlug &&
        getIngredientFromSlug(selectedIngredientSlug).is_liquid === false

      if (ingredientIsLiquid && unit.display_for_liquids === true) {
        keepUnit = true
      }
      if (ingredientIsSolid && unit.display_for_solids === true) {
        keepUnit = true
      }
      return keepUnit
    })

  useEffect(() => {
    setUnitsOptions(filterUnits())
  }, [])

  return (
    <div className={css.root}>
      <form onSubmit={form.handleSubmit(submit)} noValidate>
        <Row
          className={cn(css.calculator, { [css.modifyCalculator]: isModify })}
        >
          <div
            className={cn(css.ingredient, {
              [css.smallSelect]: !isModify,
            })}
          >
            <Controller
              control={form.control}
              name="ingredient"
              render={({ field: { onChange, value, name, ref } }) => (
                <Select
                  ref={ref}
                  options={ingredientOptions}
                  value={ingredientOptions.find(i => i.value === value)}
                  onChange={option => {
                    onChange(option?.value)
                    setUnitsOptions(filterUnits())
                  }}
                  placeholder={intl.formatMessage({
                    id: `kids-converter/text:form-placeholder-ingredient`,
                  })}
                  menuPortalTarget={doc?.body}
                  noOptionsMessage={() =>
                    intl.formatMessage({
                      id: `kids-converter/text:form-no-ingredient`,
                    })
                  }
                  styles={{
                    control: base => ({
                      ...base,
                      fontSize: "14px",
                      zIndex: 10,
                      borderRadius: "6px",
                      border: "1px solid #ff6b00",
                      height: isMobile ? "56px" : "auto",
                      fontFamily: "Open Sans",
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                    option: base => ({
                      ...base,
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                    }),
                    noOptionsMessage: base => ({
                      ...base,
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                    }),
                  }}
                />
              )}
            />
          </div>

          <TextInput
            {...register("quantity")}
            placeholder={intl.formatMessage({
              id: `kids-converter/text:form-placeholder-quantity`,
            })}
            className={cn(css.inputText, {
              [css.fullwidth]: isModify,
              [css.small]: !isModify,
            })}
          />
          <div
            className={cn(css.units, {
              [css.fullwidth]: isModify,
              [css.small]: !isModify,
            })}
          >
            <Select2
              {...register("unity")}
              placeholder={intl.formatMessage({
                id: `kids-converter/text:form-placeholder-unit`,
              })}
              className={css.select}
              required
            >
              <option value="" disabled selected hidden>
                {intl.formatMessage({
                  id: `kids-converter/text:form-placeholder-unit`,
                })}
              </option>
              {unitsOptions.map((unit, index) => (
                <option key={index} value={unit.slug}>
                  {unit[simpleLocale as keyof Units]}
                </option>
              ))}
            </Select2>
          </div>
          {!isModify ? (
            <Button className={css.submit} color="white" type="submit">
              <ColorableSVG
                href={plusIcon}
                height={14}
                width={14}
                color="black"
                className={css.plusIcon}
              />
            </Button>
          ) : (
            <div className={css.submitButtons}>
              <Button variant="tertiary" color="gray2" onClick={() => cancel()}>
                {intl.formatMessage({ id: "form/action:cancel" })}
              </Button>
              <Button
                variant="secondary"
                color="kids"
                type="submit"
                size="small"
              >
                {intl.formatMessage({ id: "form/action:submit" })}
              </Button>
            </div>
          )}
        </Row>
      </form>
    </div>
  )
}

export default KiddozConversionForm
