import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { deduplicate } from "../../utils/array"
import {
  allVerticals,
  getRecipeIdsFromVertical,
  LatestVerticalRecipesQuery,
} from "../utils/getVerticalRecipes"
import {
  AnyPrismicSlice,
  getLandingIdsFromSlices,
  getRecipeIdsFromSlices,
  getTimelineIdsFromSlices,
  getVerticalRecipesIdsFromSlices,
  getVideoIdsFromSlices,
} from "./commonSlices"

export interface PrismicLandingQuery extends LatestVerticalRecipesQuery {
  allPrismicLanding: {
    nodes: [
      {
        id: string
        uid: string
        prismicId: string
        data: { body: AnyPrismicSlice[] }
      }
    ]
  }
}
interface PrismicShowsHomeQuery {
  appSettings: {
    shows_mobile_home_video_id: string
    shows_desktop_home_video_id: string
  }
}

export const landingQuery = `
  id
  uid
  prismicId
  data {
    body {
      ... on PrismicLandingDataBodyTextRecipeColumns {
        slice_type
        primary {
          recipe_uid
        }
      }
      ... on PrismicLandingDataBodyRecipeCarousel {
        slice_type
        items {
          recipe_uid
        }
      }
      ... on PrismicLandingDataBodyVideoTextColumns {
        slice_type
        primary {
          video_uid
        }
      }
      ... on PrismicLandingDataBodyLandingCarousel {
        slice_type
        primary {
          source {
            uid
          }
        }
      }
      ... on PrismicLandingDataBodyVideo {
        slice_type
        primary {
          mobile_video_uid
          desktop_video_uid
        }
      }
      ... on PrismicLandingDataBodyFullWidthBanner {
        slice_type
        primary {
          mobile_video_uid
          desktop_video_uid
        }
      }
      ... on PrismicLandingDataBodyRecipeVideo {
        slice_type
        primary {
          recipe_uid
        }
      }
      ... on PrismicLandingDataBodyTimeline {
        slice_type
        primary {
          timeline {
            id
          }
        }
      }
      ... on PrismicLandingDataBodyLatestRecipes {
        slice_type
        primary {
          verticale_filter
        }
      }
    }
  }
`

export default async function createLandingPages(
  args: CreatePageCustomScriptArguments,
  filterLandingId?: string,
  homeShows?: boolean
): Promise<void> {
  const { graphql, reporter, currentLocale, defaultContext, createPage } = args

  let result

  if (filterLandingId) {
    result = await graphql<PrismicLandingQuery>(
      `
        query createLandingPagesQuery($lang: String, $language: String, $landingId: ID) {
          allPrismicLanding(filter: {
            lang: { eq: $lang },
            prismicId: { eq: $landingId }
          }) {
            nodes {
              ${landingQuery}
            }
          }
          ${allVerticals}
        }
      `,
      {
        lang: currentLocale.prismicCode,
        language: currentLocale.id,
        landingId: filterLandingId,
      }
    )
  } else {
    result = await graphql<PrismicLandingQuery>(
      `
      query createLandingPagesQuery($lang: String, $language: String) {
        allPrismicLanding(filter: { lang: { eq: $lang } }) {
          nodes {
            ${landingQuery}
          }
        }
        ${allVerticals}
      }
    `,
      { lang: currentLocale.prismicCode, language: currentLocale.id }
    )
  }

  let showsVideos = {
    shows_mobile_home_video_id: "",
    shows_desktop_home_video_id: "",
  }
  const resultShowsVideos = await graphql<PrismicShowsHomeQuery>(
    `
      query createShowsHomePage($prismicLocale: String) {
        appSettings(lang: { eq: $prismicLocale }) {
          shows_mobile_home_video_id
          shows_desktop_home_video_id
        }
      }
    `,
    {
      prismicLocale: currentLocale.prismicCode,
    }
  )
  if (resultShowsVideos.data?.appSettings) {
    showsVideos = resultShowsVideos.data?.appSettings
  }

  if (result.errors) reporter.panic(result.errors)

  if (result.data) {
    for (const prismicLanding of result.data.allPrismicLanding.nodes) {
      const uid = prismicLanding.uid

      // Gather all recipe ids
      const recipeIds = getRecipeIdsFromSlices(prismicLanding.data.body)

      // Gather all Landing ids
      const landingIds = getLandingIdsFromSlices(prismicLanding.data.body)

      // For each nested landing selection
      const nestedLandings = result.data.allPrismicLanding.nodes.filter(
        landing => landingIds.includes(landing.uid)
      )

      nestedLandings.forEach(nestedLanding => {
        recipeIds.push(...getRecipeIdsFromSlices(nestedLanding.data.body))
      })

      const allVideoIds = getVideoIdsFromSlices(prismicLanding.data.body)

      createPage({
        routeParams: { uid },
        context: {
          ...defaultContext,
          id: prismicLanding.id,
          prismicId: prismicLanding.prismicId,
          lang: currentLocale.prismicCode,
          recipeIds: deduplicate(recipeIds),
          landingIds,
          verticalRecipesIds: getRecipeIdsFromVertical(result.data),
          videoIds: allVideoIds,
          timelineIds: getTimelineIdsFromSlices(prismicLanding.data.body),
          verticaleIdsLatestRecipes: getVerticalRecipesIdsFromSlices(
            prismicLanding.data.body
          ),
          showsMobileHomeVideoId: showsVideos.shows_mobile_home_video_id,
          showsDesktopHomeVideoId: showsVideos.shows_desktop_home_video_id,
        },
      })
    }
  }
}
