import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import VideoTextColumns, {
  VideoTextColumnsProps,
} from "~/components/slices/VideoTextColumns/VideoTextColumns"
import { Video } from "~/models/Video"

interface PropTypes extends PrismicLandingDataBodyVideoTextColumns {
  videos?: Video[]
  isLeadingSlice?: boolean
}

// Copied from LandingBodyTextImageColumns
// TODO: If we will have change, keep separate code, else extract component
function LandingBodyVideoTextColumns({ videos, primary, ...props }: PropTypes) {
  const currentVideo =
    videos && videos.find(video => video.id === primary.videoUid)

  if (!currentVideo) {
    return null
  }

  return (
    <SliceSection
      tags={!!primary.backgroundColor ? "o-has-background" : ""}
      margin="large"
      noContainer
      anchorId={primary.anchorId}
      {...props}
    >
      <VideoTextColumns {...props} {...primary} currentVideo={currentVideo} />
    </SliceSection>
  )
}

export default LandingBodyVideoTextColumns

export interface PrismicLandingDataBodyVideoTextColumns extends PrismicSlice {
  sliceType: "video_text_columns"
  primary: VideoTextColumnsProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyVideoTextColumns on PrismicLandingDataBodyVideoTextColumns {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      backgroundColor: background_color
      title {
        ...PrismicStructuredText
      }
      text {
        ...PrismicStructuredText
      }
      link {
        ...PrismicLink
      }
      layout

      buttonLabel: button_label {
        ...PrismicStructuredText
      }
      buttonColor: button_color
      videoUid: video_uid
      videoControls: video_controls
      anchorId: id
    }
  }
`
