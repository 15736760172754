import React, { ComponentPropsWithoutRef, FC } from "react"

import almondIcon from "./ingredients/almond.svg"
import apricotJamIcon from "./ingredients/apricot_jam.svg"
import bakingPowderIcon from "./ingredients/baking_powder.svg"
import balsamicCreamIcon from "./ingredients/balsamic_cream.svg"
import balsamicVinegarIcon from "./ingredients/balsamic_vinegar.svg"
import bechamelIcon from "./ingredients/bechamel.svg"
import beetInCubesIcon from "./ingredients/beet_in_cubes.svg"
import bicarbonateIcon from "./ingredients/bicarbonate.svg"
import blackOliveIcon from "./ingredients/black_olive.svg"
import blueberryIcon from "./ingredients/blueberry.svg"
import bottureBuardsIcon from "./ingredients/botture_buards.svg"
import breadcrumbsIcon from "./ingredients/breadcrumbs.svg"
import brownSugarIcon from "./ingredients/brown_sugar.svg"
import butterIcon from "./ingredients/butter.svg"
import butterCubesIcon from "./ingredients/butter_cubes.svg"
import caramelIcon from "./ingredients/caramel.svg"
import cashewNutIcon from "./ingredients/cashew_nut.svg"
import chiaSeedIcon from "./ingredients/chia_seed.svg"
import chickenDiceIcon from "./ingredients/chicken_dice.svg"
import chickpeaIcon from "./ingredients/chickpea.svg"
import chocolateChipsIcon from "./ingredients/chocolate_chips.svg"
import chocolateInPiecesIcon from "./ingredients/chocolate_in_pieces.svg"
import chocolateSauceIcon from "./ingredients/chocolate_sauce.svg"
import cinnamonIcon from "./ingredients/cinnamon.svg"
import cocoIcon from "./ingredients/coco.svg"
import cocoaPowderIcon from "./ingredients/cocoa_powder.svg"
import coconutMilkIcon from "./ingredients/coconut_milk.svg"
import coconutOilIcon from "./ingredients/coconut_oil.svg"
import cookedEggNoodleIcon from "./ingredients/cooked_egg_noodle.svg"
import cookedShellsIcon from "./ingredients/cooked_shells.svg"
import corailLensIcon from "./ingredients/corail_lens.svg"
import cornIcon from "./ingredients/corn.svg"
import cornFlourIcon from "./ingredients/cornflour.svg"
import croutonIcon from "./ingredients/crouton.svg"
import crushedHazelnutsIcon from "./ingredients/crushed_hazelnuts.svg"
import cuminIcon from "./ingredients/cumin.svg"
import currantIcon from "./ingredients/currant.svg"
import curryIcon from "./ingredients/curry.svg"
import drivenCherryIcon from "./ingredients/driven_cherry.svg"
import eightyIcon from "./ingredients/eighty.svg"
import fetaCheeseIcon from "./ingredients/feta_cheese.svg"
import flourIcon from "./ingredients/flour.svg"
import freshPeaIcon from "./ingredients/fresh_pea.svg"
import fruitCoulisIcon from "./ingredients/fruit_coulis.svg"
import gratedCheeseIcon from "./ingredients/grated_cheese.svg"
import gratedCoconutIcon from "./ingredients/grated_coconut.svg"
import greekYogurtIcon from "./ingredients/greek_yogurt.svg"
import groundAlmondsIcon from "./ingredients/ground_almonds.svg"
import groundBeefIcon from "./ingredients/ground_beef.svg"
import hamIcon from "./ingredients/ham.svg"
import hamDiceIcon from "./ingredients/ham_dice.svg"
import hazelnutsIcon from "./ingredients/hazelnuts.svg"
import honeyIcon from "./ingredients/honey.svg"
import icingSugarIcon from "./ingredients/icing_sugar.svg"
import jamIcon from "./ingredients/jam.svg"
import ketchupIcon from "./ingredients/ketchup.svg"
import ladonIcon from "./ingredients/ladon.svg"
import lemonJuiceIcon from "./ingredients/lemon_juice.svg"
import linseedIcon from "./ingredients/linseed.svg"
import liquidCreamIcon from "./ingredients/liquid_cream.svg"
import liquidFoodColoringIcon from "./ingredients/liquid_food_coloring.svg"
import liquidsIcon from "./ingredients/liquids.svg"
import mapleSyrupIcon from "./ingredients/maple_syrup.svg"
import mascarponeIcon from "./ingredients/mascarpone.svg"
import meltedButterIcon from "./ingredients/melted_butter.svg"
import milkIcon from "./ingredients/milk.svg"
import mincedMushroomIcon from "./ingredients/minced_mushroom.svg"
import mincedPorkIcon from "./ingredients/minced_pork.svg"
import mozzarellaRapeeIcon from "./ingredients/mozzarella_rapee.svg"
import multicoloredVermicelliIcon from "./ingredients/multicolored_vermicelli.svg"
import mustardIcon from "./ingredients/mustard.svg"
import nutmegIcon from "./ingredients/nutmeg.svg"
import oatMealIcon from "./ingredients/oat_meal.svg"
import oilIcon from "./ingredients/oil.svg"
import oliveOilIcon from "./ingredients/olive_oil.svg"
import orangeJuiceIcon from "./ingredients/orange_juice.svg"
import orangeTreeFlowerWaterIcon from "./ingredients/orange_tree_flower_water.svg"
import oreganoIcon from "./ingredients/oregano.svg"
import otherLiquidsIcon from "./ingredients/other_liquids.svg"
import paprikaIcon from "./ingredients/paprika.svg"
import pestoIcon from "./ingredients/pesto.svg"
import pistachioIcon from "./ingredients/pistachio.svg"
import poppySeedIcon from "./ingredients/poppy_seed.svg"
import powderedMilkIcon from "./ingredients/powdered_milk.svg"
import powderedSugarIcon from "./ingredients/powdered_sugar.svg"
import puffedRiceIcon from "./ingredients/puffed_rice.svg"
import raisinIcon from "./ingredients/raisin.svg"
import raspberryIcon from "./ingredients/raspberry.svg"
import rawShellsIcon from "./ingredients/raw_shells.svg"
import rawSpinachIcon from "./ingredients/raw_spinach.svg"
import riceIcon from "./ingredients/rice.svg"
import ricottaIcon from "./ingredients/ricotta.svg"
import saltIcon from "./ingredients/salt.svg"
import sesameOilIcon from "./ingredients/sesame_oil.svg"
import sesamePureeIcon from "./ingredients/sesame_puree.svg"
import sesameSeedIcon from "./ingredients/sesame_seed.svg"
import softButterIcon from "./ingredients/soft_butter.svg"
import sourCreamIcon from "./ingredients/sour_cream.svg"
import soyaSauceIcon from "./ingredients/soya_sauce.svg"
import spinachIcon from "./ingredients/spinach.svg"
import spreadIcon from "./ingredients/spread.svg"
import steakIcon from "./ingredients/steak.svg"
import strawberryIcon from "./ingredients/strawberry.svg"
import strawberryCoulisIcon from "./ingredients/strawberry_coulis.svg"
import strawberryJamIcon from "./ingredients/strawberry_jam.svg"
import strawberryJuiceIcon from "./ingredients/strawberry_juice.svg"
import sugarIcon from "./ingredients/sugar.svg"
import sunflowerOilIcon from "./ingredients/sunflower_oil.svg"
import sweetenedCondensedMilkIcon from "./ingredients/sweetened_condensed_milk.svg"
import syrupIcon from "./ingredients/syrup.svg"
import tomatoSauceIcon from "./ingredients/tomato_sauce.svg"
import tumericIcon from "./ingredients/tumeric.svg"
import vanillaExtractIcon from "./ingredients/vanilla_extract.svg"
import vanillaSugarIcon from "./ingredients/vanilla_sugar.svg"
import vegetableOilIcon from "./ingredients/vegetable_oil.svg"
import vergeoiseIcon from "./ingredients/vergeoise.svg"
import whiteChocolateIcon from "./ingredients/white_chocolate.svg"
import worcestershireSauceIcon from "./ingredients/worcestershire_sauce.svg"
import yogurtIcon from "./ingredients/yogurt.svg"

const ingredientsIcons: { [key: string]: { icon: string; slug: string } } = {
  almond: { icon: almondIcon, slug: "almond" },
  apricot_jam: { icon: apricotJamIcon, slug: "apricot_jam" },
  balsamic_cream: { icon: balsamicCreamIcon, slug: "balsamic_cream" },
  balsamic_vinegar: { icon: balsamicVinegarIcon, slug: "balsamic_vinegar" },
  bechamel: { icon: bechamelIcon, slug: "bechamel" },
  beet_in_cubes: { icon: beetInCubesIcon, slug: "beet_in_cubes" },
  black_olive: { icon: blackOliveIcon, slug: "black_olive" },
  blueberry: { icon: blueberryIcon, slug: "blueberry" },
  botture_buards: { icon: bottureBuardsIcon, slug: "botture_buards" },
  breadcrumbs: { icon: breadcrumbsIcon, slug: "breadcrumbs" },
  brown_sugar: { icon: brownSugarIcon, slug: "brown_sugar" },
  butter: { icon: softButterIcon, slug: "butter" },
  coco: { icon: cocoIcon, slug: "coco" },
  caramel: { icon: caramelIcon, slug: "caramel" },
  cashew_nut: { icon: cashewNutIcon, slug: "cashew_nut" },
  chia_seed: { icon: chiaSeedIcon, slug: "chia_seed" },
  chicken_dice: { icon: chickenDiceIcon, slug: "chicken_dice" },
  chickpea: { icon: chickpeaIcon, slug: "chickpea" },
  chocolate: { icon: chocolateChipsIcon, slug: "chocolate" },
  chocolate_chips: { icon: chocolateChipsIcon, slug: "chocolate_chips" },
  chocolate_in_pieces: {
    icon: chocolateInPiecesIcon,
    slug: "chocolate_in_pieces",
  },
  chocolate_sauce: { icon: chocolateSauceIcon, slug: "chocolate_sauce" },
  chocolate_vermicellian: {
    icon: chocolateInPiecesIcon,
    slug: "chocolate_vermicellian",
  },
  cinnamon: { icon: cinnamonIcon, slug: "cinnamon" },
  cocoa_powder: { icon: cocoaPowderIcon, slug: "cocoa_powder" },
  coconut_milk: { icon: coconutMilkIcon, slug: "coconut_milk" },
  coconut_oil: { icon: coconutOilIcon, slug: "coconut_oil" },
  cold_butter: { icon: butterIcon, slug: "cold_butter" },
  cold_butter_in_cubes: { icon: butterCubesIcon, slug: "cold_butter_in_cubes" },
  cooked_egg_noodle: { icon: cookedEggNoodleIcon, slug: "cooked_egg_noodle" },
  cooked_shells: { icon: cookedShellsIcon, slug: "cooked_shells" },
  corail_lens: { icon: corailLensIcon, slug: "corail_lens" },
  corn: { icon: cornIcon, slug: "corn" },
  cornflour: { icon: cornFlourIcon, slug: "cornflour" },
  cream_cheese: { icon: mascarponeIcon, slug: "cream_cheese" },
  crouton: { icon: croutonIcon, slug: "crouton" },
  crushed_hazelnuts: { icon: crushedHazelnutsIcon, slug: "crushed_hazelnuts" },
  cumin: { icon: cuminIcon, slug: "cumin" },
  currant: { icon: currantIcon, slug: "currant" },
  curry: { icon: curryIcon, slug: "curry" },
  dark_chocolate: { icon: chocolateChipsIcon, slug: "dark_chocolate" },
  driven_cherry: { icon: drivenCherryIcon, slug: "driven_cherry" },
  eighty: { icon: eightyIcon, slug: "eighty" },
  feta_cheese: { icon: fetaCheeseIcon, slug: "feta_cheese" },
  food_bicarbonate: { icon: bicarbonateIcon, slug: "food_bicarbonate" },
  fresh_pea: { icon: freshPeaIcon, slug: "fresh_pea" },
  fruit_coulis: { icon: fruitCoulisIcon, slug: "fruit_coulis" },
  grated_cheddar: { icon: gratedCheeseIcon, slug: "grated_cheddar" },
  grated_cheese: { icon: gratedCheeseIcon, slug: "grated_cheese" },
  grated_mozzarella: { icon: gratedCheeseIcon, slug: "grated_mozzarella" },
  grated_coconut: { icon: gratedCoconutIcon, slug: "grated_coconut" },
  grated_parmesan: { icon: gratedCheeseIcon, slug: "grated_parmesan" },
  greek_yogurt: { icon: greekYogurtIcon, slug: "greek_yogurt" },
  ground_beef: { icon: groundBeefIcon, slug: "ground_beef" },
  ham_dice: { icon: hamDiceIcon, slug: "ham_dice" },
  ham_match: { icon: hamIcon, slug: "ham_match" },
  hazelnuts: { icon: hazelnutsIcon, slug: "hazelnuts" },
  honey: { icon: honeyIcon, slug: "honey" },
  icing_sugar: { icon: icingSugarIcon, slug: "icing_sugar" },
  jam: { icon: jamIcon, slug: "jam" },
  ketchup: { icon: ketchupIcon, slug: "ketchup" },
  ladon: { icon: ladonIcon, slug: "ladon" },
  lemon_juice: { icon: lemonJuiceIcon, slug: "lemon_juice" },
  linseed: { icon: linseedIcon, slug: "linseed" },
  liquid_cream: { icon: liquidCreamIcon, slug: "liquid_cream" },
  liquid_food_coloring: {
    icon: liquidFoodColoringIcon,
    slug: "liquid_food_coloring",
  },
  maple_syrup: { icon: mapleSyrupIcon, slug: "maple_syrup" },
  mascarpone: { icon: mascarponeIcon, slug: "mascarpone" },
  melted_butter: { icon: meltedButterIcon, slug: "melted_butter" },
  milk: { icon: milkIcon, slug: "milk" },
  milk_chocolate: { icon: chocolateChipsIcon, slug: "milk_chocolate" },
  minced_mushroom: { icon: mincedMushroomIcon, slug: "minced_mushroom" },
  minced_pork: { icon: mincedPorkIcon, slug: "minced_pork" },
  mozzarella_rapee: { icon: mozzarellaRapeeIcon, slug: "mozzarella_rapee" },
  multicolored_vermicelli: {
    icon: multicoloredVermicelliIcon,
    slug: "multicolored_vermicelli",
  },
  mustard: { icon: mustardIcon, slug: "mustard" },
  nutmeg: { icon: nutmegIcon, slug: "nutmeg" },
  oatmeal: { icon: oatMealIcon, slug: "oatmeal" },
  oil: { icon: oilIcon, slug: "oil" },
  olive_oil: { icon: oliveOilIcon, slug: "olive_oil" },
  orange_juice: { icon: orangeJuiceIcon, slug: "orange_juice" },
  orange_tree_flower_water: {
    icon: orangeTreeFlowerWaterIcon,
    slug: "orange_tree_flower_water",
  },
  oregano: { icon: oreganoIcon, slug: "oregano" },
  other_liquids: { icon: otherLiquidsIcon, slug: "other_liquids" },
  paprika: { icon: paprikaIcon, slug: "paprika" },
  pesto: { icon: pestoIcon, slug: "pesto" },
  pistachio: { icon: pistachioIcon, slug: "pistachio" },
  plain_flour: { icon: flourIcon, slug: "plain_flour" },
  poppy_seed: { icon: poppySeedIcon, slug: "poppy_seed" },
  powdered_milk: { icon: powderedMilkIcon, slug: "powdered_milk" },
  powdered_sugar: { icon: powderedSugarIcon, slug: "powdered_sugar" },
  puffed_rice: { icon: puffedRiceIcon, slug: "puffed_rice" },
  raisin: { icon: raisinIcon, slug: "raisin" },
  raspberry: { icon: raspberryIcon, slug: "raspberry" },
  raspberry_jam: { icon: jamIcon, slug: "raspberry_jam" },
  raw_shells: { icon: rawShellsIcon, slug: "raw_shells" },
  raw_spinach: { icon: rawSpinachIcon, slug: "raw_spinach" },
  rice: { icon: riceIcon, slug: "rice" },
  ricotta: { icon: ricottaIcon, slug: "ricotta" },
  salt: { icon: saltIcon, slug: "salt" },
  sesame_oil: { icon: sesameOilIcon, slug: "sesame_oil" },
  sesame_puree: { icon: sesamePureeIcon, slug: "sesame_puree" },
  sesame_seed: { icon: sesameSeedIcon, slug: "sesame_seed" },
  soft_butter: { icon: softButterIcon, slug: "soft_butter" },
  sour_cream: { icon: sourCreamIcon, slug: "sour_cream" },
  soya_sauce: { icon: soyaSauceIcon, slug: "soya_sauce" },
  spinach: { icon: spinachIcon, slug: "spinach" },
  spread: { icon: spreadIcon, slug: "spread" },
  steak: { icon: steakIcon, slug: "steak" },
  strawberry: { icon: strawberryIcon, slug: "strawberry" },
  strawberry_coulis: { icon: strawberryCoulisIcon, slug: "strawberry_coulis" },
  strawberry_jam: { icon: strawberryJamIcon, slug: "strawberry_jam" },
  strawberry_juice: { icon: strawberryJuiceIcon, slug: "strawberry_juice" },
  sugar: { icon: sugarIcon, slug: "sugar" },
  sugar_pearl: { icon: sugarIcon, slug: "sugar_pearl" },
  sunflower_oil: { icon: sunflowerOilIcon, slug: "sunflower_oil" },
  sweetened_condensed_milk: {
    icon: sweetenedCondensedMilkIcon,
    slug: "sweetened_condensed_milk",
  },
  syrup: { icon: syrupIcon, slug: "syrup" },
  tapered_almond: { icon: groundAlmondsIcon, slug: "tapered_almond" },
  tomato_sauce: { icon: tomatoSauceIcon, slug: "tomato_sauce" },
  turmeric: { icon: tumericIcon, slug: "turmeric" },
  vanilla_extract: { icon: vanillaExtractIcon, slug: "vanilla_extract" },
  vanilla_sugar: { icon: vanillaSugarIcon, slug: "vanilla_sugar" },
  vegetable_oil: { icon: vegetableOilIcon, slug: "vegetable_oil" },
  vergeoise: { icon: vergeoiseIcon, slug: "vergeoise" },
  water: { icon: liquidsIcon, slug: "water" },
  white_chocolate: { icon: whiteChocolateIcon, slug: "white_chocolate" },
  worcestershire_sauce: {
    icon: worcestershireSauceIcon,
    slug: "worcestershire_sauce",
  },
  yeast: { icon: bakingPowderIcon, slug: "yeast" },
  yogurt: { icon: yogurtIcon, slug: "yogurt" },
}

const IngredientIcon: FC<
  ComponentPropsWithoutRef<"img"> & { slug: string }
> = ({ slug, ...props }) => {
  return ingredientsIcons?.[slug]?.icon ? (
    <img src={ingredientsIcons[slug].icon} {...props} />
  ) : (
    <p>{slug}</p>
  )
}

export default IngredientIcon
