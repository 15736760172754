import { useEffect, useState } from "react"

import useEventListener from "./useEventListener"

export default function useIsOnCompact(): boolean {
  const [isCompact, setIsCompact] = useState<boolean>(true)

  const updateMatchQuery = () => {
    setIsCompact(matchMedia("(max-width: 767px)").matches)
  }

  // Trigger on mount
  useEffect(() => updateMatchQuery(), [])

  useEventListener("resize", updateMatchQuery)

  return isCompact
}
