import React, { ChangeEvent, CSSProperties } from "react"

import cn from "classnames"

import css from "./PlayerProgressBar.module.scss"

interface PropTypes {
  percentTime: number
  verticalColor: CSSProperties["color"]
  onPercentTimeChange: (percentTime: number) => void
  className?: string
}

function PlayerProgressBar({
  percentTime,
  verticalColor,
  onPercentTimeChange: updateTime,
  className,
}: PropTypes) {
  const isRunning = percentTime < 99.5

  const handleChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    updateTime(Number(event.target.value) / 10)
  }

  return (
    <>
      <div
        className={cn(css.root, className)}
        style={
          {
            "--progress-bar-color": verticalColor,
            "--progress-bar-percent": `${percentTime}%`,
          } as CSSProperties
        }
      >
        <input
          onChange={handleChangeTime}
          type="range"
          min="1"
          max="1000"
          className={cn(css.progressBar, {
            [css["isRunning"]]: isRunning,
          })}
          value={percentTime * 10}
        />
      </div>
    </>
  )
}

export default PlayerProgressBar
