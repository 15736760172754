import React from "react"

import { useIntl } from "react-intl"

import Carlton from "../assets/carlton.svg"
import Colette from "../assets/colette.svg"
import Elvis from "../assets/elvis.svg"
import Kikitos from "../assets/kikitos.svg"
import Lola from "../assets/lola.svg"
import Maurice from "../assets/maurice.svg"
import { Body, Heading } from "~/components/ui"

import css from "./IndicationsCard.module.scss"

const IndicationsCard = () => {
  const intl = useIntl()
  return (
    <div className={css.root}>
      <div className={css.container}>
        <Heading variant="small" color="#7D5BC6" noMargin>
          {intl.formatMessage({ id: "kids-converter/text:indication-title" })}
        </Heading>
        <Body variant="body3">
          {intl.formatMessage({
            id: "kids-converter/text:indication-description",
          })}
        </Body>
        <div className={css.icons}>
          <img src={Maurice} />
          <img src={Elvis} />
          <img src={Carlton} />
          <img src={Lola} />
          <img src={Colette} />
          <img src={Kikitos} />
        </div>
      </div>
    </div>
  )
}

export default IndicationsCard
