import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import FreeCarousel, {
  FreeCarouselProps,
  ItemProps,
} from "~/components/slices/FreeCarousel/FreeCarousel"

interface PropTypes extends PrismicLandingDataBodyFreeCarousel {
  isPreview?: boolean
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function LandingBodyFreeCarousel(props: PropTypes) {
  return (
    <SliceSection
      margin="default"
      noContainer
      anchorId={props.primary.anchorId}
      {...props}
    >
      <FreeCarousel {...props} />
    </SliceSection>
  )
}

export default LandingBodyFreeCarousel

export interface PrismicLandingDataBodyFreeCarousel extends PrismicSlice {
  sliceType: "free_carousel"
  items: ItemProps[]
  primary: FreeCarouselProps["primary"]
}

export const query = graphql`
  fragment PrismicLandingDataBodyFreeCarousel on PrismicLandingDataBodyFreeCarousel {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      title {
        ...PrismicStructuredText
      }
      carouselLink: carousel_link {
        ...PrismicLink
      }
      carouselLinkLabel: carousel_link_label
      itemStyle: item_style
      itemSize: item_size
      textAlign: text_align
      anchorId: id
    }
    items {
      title {
        ...PrismicStructuredText
      }
      link {
        ...PrismicLink
      }
      image {
        gatsbyImageData(width: 296, placeholder: BLURRED)
        fluid(maxWidth: 168) {
          ...GatsbyImgixFluid_noBase64
        }
        dimensions {
          width
        }
        thumbnails {
          full_width_mobile {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            fluid(maxWidth: 500) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
        }
      }
      wideImage: wide_image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        fluid(maxWidth: 1280) {
          ...GatsbyPrismicImageFluid_noBase64
        }
      }
      color
    }
  }
`
