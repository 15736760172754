import React from "react"

import { Col, Row } from "~/components/ui"

import css from "./AccountModalLayout.module.scss"

export type AccountModalPagesIds = "log-in" | "register" | "forgot-password"

type AccountModalLayoutProps = {
  children: JSX.Element
  imageMobile: string
  imageDesktop: string
}

const AccountModalLayout = ({
  children,
  imageMobile,
  imageDesktop,
}: AccountModalLayoutProps) => {
  return (
    <Row className={css.rowModal}>
      <Col
        width={[0, 6]}
        className={css.desktopImageContainer}
        style={{ backgroundImage: `url(${imageDesktop})` }}
      />
      <Col
        width={[12, 0]}
        className={css.mobileImageContainer}
        style={{ backgroundImage: `url(${imageMobile})` }}
      />
      <Col width={[12, 6]} className={css.content}>
        {children}
      </Col>
    </Row>
  )
}

export default AccountModalLayout
