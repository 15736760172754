import React, { ReactNode, useEffect, useState } from "react"

import cn from "classnames"

import { SliceLabel } from "../SliceTypes"
import { Col, ColProps } from "~/components/ui"
import useIntersectionObserver from "~/hooks/useIntersectionObserver"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { isChrome, isSafari } from "~/utils/detector"
import { isDev } from "~/utils/general"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SliceSection.module.scss"

export interface SliceChildrenProps {
  isVisible: boolean
}
export type SliceChildren = (props: SliceChildrenProps) => ReactNode

export interface SliceSectionProps extends ColProps {
  noContainer?: boolean
  sliceId?: string
  sliceType?: string
  __typename?: string
  sliceLabel?: SliceLabel
  margin?: "none" | "small" | "default" | "large"
  tags?: string
  children: ReactNode | SliceChildren
  anchorId?: string
}

const SliceSection = ({
  children,
  noContainer,
  sliceId,
  sliceType,
  __typename,
  sliceLabel,
  margin = "default",
  width = 12,
  offset = 0,
  tags = "",
  anchorId,
}: SliceSectionProps) => {
  const isCompact = useIsOnCompact()
  const [sliceRef, setSliceRef] = useState<HTMLElement | null>(null)
  const sliceObserver = useIntersectionObserver(sliceRef, {
    rootMargin: "-50px",
    threshold: [0, 1],
  })
  const isVisible = !!sliceObserver?.isIntersecting
  // Hacky way to anchor scroll if on Chrome browser
  // TODO: Remove this when Chrome would better support anchor tags
  useEffect(() => {
    if (`#${anchorId}` === window.location.hash) {
      const scrolling = setTimeout(() => {
        if (isChrome()) {
          const hash = location.hash
          window.location.hash = ""
          window.location.hash = hash
        } else if (isSafari()) {
          window.location.href = window.location.href
        } else {
          window.location.hash = window.location.hash
        }
      }, 500)

      return () => {
        clearTimeout(scrolling)
      }
    }
  }, [])

  if (sliceLabel === "app_only") return null

  if (isCompact && sliceLabel === "large_screen_only") {
    return null
  }

  if (sliceLabel === "hidden" && !isDev) {
    return null
  }

  if (
    !isCompact &&
    sliceLabel &&
    ["small_screen_only", "web_small_screen_only"].includes(sliceLabel)
  ) {
    return null
  }

  const props = {
    ref: setSliceRef,
    width,
    offset,
    className: cn(css.sliceSection, tags, "o-slice", {
      [`o-slice-${sliceType}`]: sliceType,
      [`o-slice-${sliceId}`]: sliceId,
      [css[margin]]: true,
      [css.noContainer]: noContainer,
    }),
    "data-slice": sliceType,
    "data-typename": __typename,
    "data-margin": margin,
    style: { display: "block" },
    children:
      typeof children === "function" ? children({ isVisible }) : children,
  }

  return noContainer ? (
    <section id={anchorId} {...props} />
  ) : (
    <Col id={anchorId} Tag="section" {...props} />
  )
}

export default SliceSection
