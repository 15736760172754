import React from "react"

import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby"
import { useIntl } from "react-intl"

import pathFromFullId from "~/routes/pathFromFullId"
import { domainRemover, isExternalLink } from "~/utils/link"
import { ChefclubLocale, getIntl } from "~/utils/locales"

export interface LinkPropTypes<T>
  extends Omit<
    GatsbyLinkProps<T>,
    "ref"
  > /*React.HTMLProps<HTMLAnchorElement>*/ {
  to: string
  children?: React.ReactNode
  noLocale?: boolean // if set, do not append locale_id/ before the route
  absoluteLink?: boolean // if set, do not modify link in any way
  params?: any // Used for dynamic routes
  queryParams?: Record<string, string> // Appended at the end of the url like ?param=x
  hashParam?: string
  lang?: ChefclubLocale
  fallback?: boolean
}

function Link<T>({
  to,
  children,
  noLocale = false,
  absoluteLink = false,
  params = {},
  queryParams,
  lang,
  fallback,
  hashParam,
  ...rest
}: LinkPropTypes<T>) {
  const defaultIntl = useIntl()
  if (isExternalLink(to)) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    )
  }
  const intl =
    lang !== undefined && lang !== defaultIntl.locale
      ? getIntl(lang)
      : defaultIntl

  let path = absoluteLink
    ? domainRemover(to || "")
    : pathFromFullId(noLocale ? to : `locale/${to}`, intl, params)

  if (hashParam) {
    path += `#${hashParam}`
  }

  if (queryParams) {
    const paramsAsString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&")

    path += `?${paramsAsString}`
  }
  //If the path contains param do not add trailing slash
  let addTrailingSlash = true
  const hash = path.split("#").length > 1
  const query = path.split("?").length > 1
  if (hash || query) {
    addTrailingSlash = false
  }

  path =
    path.slice(-1) == "/" || queryParams || !addTrailingSlash
      ? path
      : path + "/"

  if (fallback) {
    return (
      <a href={path} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={path} {...rest}>
      {children}
    </GatsbyLink>
  )
}

export default Link
