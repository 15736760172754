export interface Subscription {
  product_id: string
  expires_date: Date
  expires_date_ms: string
  extra?: {
    hosted_invoice_url?: string
    last4?: string
    [key: string]: any
  } | null
  platform?: string
  cancellation_date?: Date
  cancellation_date_ms?: string
}

// From django API
export interface ApiSubscription {
  product_id: string
  expires_date: string
  expires_date_ms?: string
  extra?: {
    [key: string]: any
  } | null
  platform?: string
  cancellation_date?: string
  cancellation_date_ms?: string
}

export const apiSubscriptionToSubscription = ({
  product_id,
  expires_date: expires_date_string,
  expires_date_ms,
  extra,
  platform,
  cancellation_date: cancellation_date_string,
  cancellation_date_ms,
}: ApiSubscription): Subscription => {
  const expires_date: Date = new Date(expires_date_string)
  const cancellation_date: Date | undefined = cancellation_date_string
    ? new Date(cancellation_date_string)
    : undefined
  cancellation_date_ms =
    cancellation_date_ms ??
    (cancellation_date ? `${cancellation_date.getTime()}` : undefined)

  return {
    product_id,
    expires_date,
    expires_date_ms: expires_date_ms ?? `${expires_date.getTime()}`,
    extra,
    platform,
    cancellation_date: cancellation_date,
    cancellation_date_ms,
  }
}

export const getLastActiveSubscription = (
  subscriptions: Subscription[]
): Subscription => {
  let newerSubscription: Subscription = subscriptions[0]
  for (let index = 1; index < subscriptions.length; index++) {
    if (newerSubscription.expires_date < subscriptions[index].expires_date) {
      newerSubscription = subscriptions[index]
    }
  }
  return newerSubscription
}
