import InitFromMap from "../utils/InitFromMap"
import { RichCase, RichEnum } from "../utils/RichEnum"

export const Verticals = [
  "original",
  "daily",
  "light-and-fun",
  "kids",
  "cocktails",
] as const

export type VerticalId = (typeof Verticals)[number]

class VerticalCase extends InitFromMap {
  id!: VerticalId
  name!: string
  messageId!: string
  urlMessageId!: string
  color!: string
}

const Vertical = RichEnum({
  Kids: new VerticalCase({
    id: "kids",
    name: "Kids",
    messageId: "brand:kids",
    urlMessageId: "url:django-vertical-kids",
    color: "#ff6b00",
  }),
  LightAndFun: new VerticalCase({
    id: "light-and-fun",
    name: "Light & fun",
    messageId: "brand:light-and-fun",
    urlMessageId: "url:django-vertical-light-and-fun",
    color: "#00d1ff",
  }),
  Daily: new VerticalCase({
    id: "daily",
    name: "Daily",
    messageId: "brand:daily",
    urlMessageId: "url:django-vertical-daily",
    color: "#0179e9",
  }),
  Original: new VerticalCase({
    id: "original",
    name: "Original",
    messageId: "brand:original",
    urlMessageId: "url:django-vertical-original",
    color: "#f5184d",
  }),
  Cocktails: new VerticalCase({
    id: "cocktails",
    name: "Cocktails",
    messageId: "brand:cocktails",
    urlMessageId: "url:django-vertical-cocktails",
    color: "#ffd600",
  }),
})
type Vertical = RichCase<typeof Vertical>

export default Vertical

export function getVerticalFromId(id: string) {
  const vertical = Object.values(Vertical).find(p => p.id === id)
  if (!vertical) return Vertical.Original
  return vertical
}
