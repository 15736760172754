import { IntlShape } from "react-intl"

import Route from "./Route"
import routes from "./routes"
/*
  computes exact translated path for a given route (as string) and locale.
  Example if fullRouteId = "locale/profile/login" and current intl locale is
  french, returns "/fr/profile/connexion"
*/
export default function pathFromFullId(
  fullRouteId: string,
  intl: IntlShape,
  params: any = {}
): string {
  const translate = (messageId: string) => {
    // we prefix it with "path:", and check that the translation exists
    if (!intl.messages[messageId]) {
      throw new Error(
        `Missing translation for route (messageId=${messageId} and locale=${intl.locale})`
      )
    }
    return intl.formatMessage({ id: messageId })
  }

  const leafRoute = fullRouteId
    .split("/")
    .reduce<Route | undefined>((foundRoute, currentPath, index) => {
      const candidates =
        index === 0 ? routes : foundRoute && foundRoute.children
      return candidates && candidates.find(route => route.id === currentPath)
    }, undefined)

  const fullPath = leafRoute?.getFullPath(translate, params)
  if (!fullPath) throw new Error(`Cannot find route ${fullRouteId}`)
  return "/" + fullPath
}
