import React from "react"

import IngredientIcon from "../assets/IngredientIcon"
import ingredientsInfo from "../assets/ingredients.json"

export type Ingredient = {
  icon: JSX.Element
  is_liquid?: boolean
  slug: string
  fr: string
  en: string
  de: string
  es: string
  it: string
  pt: string
  chef: number
  cochon: number
  chat: number
  souris: number
  poule: number
  poussin: number
}

export const ingredients: Ingredient[] = Array.isArray(ingredientsInfo)
  ? ingredientsInfo.map(ing => ({
      ...ing,
      icon: (
        <IngredientIcon height={56} width={56} slug={ing.slug} alt={ing.slug} />
      ),
    }))
  : []
