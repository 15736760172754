/*
  return true is GATSBY_IS_PRODUCTION is explicitly set to true
*/

// TODO: use centralized env vars
export default function isProduction() {
  return (
    process.env.GATSBY_IS_PRODUCTION?.toLowerCase() === "true" ||
    process.env.GATSBY_IS_PRODUCTION === "1"
  )
}
