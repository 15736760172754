import React, { useEffect, useMemo, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import * as yup from "yup"

import validationIcon from "../assets/validationIcon.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Body, Button, TextInput } from "~/components/ui"
import useNewsletterApiKeys from "~/hooks/useNewsletterApiKeys"
import { useSubscribeFooterNewsletterMutation } from "~/state/api/externalapi/klaviyo"
import { useSubscribeFooterNewsletterSIBMutation } from "~/state/api/externalapi/sendInBlue"
import getFormValidators from "~/utils/formValidation"
import { ChefclubLocale } from "~/utils/locales"

import css from "./FooterKlaviyoForm.module.scss"

const FooterKlaviyoForm = () => {
  const intl = useIntl()

  const [error, setError] = useState<null | string>(null)

  const { email } = getFormValidators(intl)

  const allNewsletterFormAPIKeys = useNewsletterApiKeys(
    intl.locale as ChefclubLocale
  )

  const sendinBlueFooterFormUrl =
    allNewsletterFormAPIKeys?.sendinBlueFooterFormId

  const klaviyoApiKey = allNewsletterFormAPIKeys?.klaviyoPublicApiKey

  const sendinBlueFooterFormId = sendinBlueFooterFormUrl?.substring(
    sendinBlueFooterFormUrl.lastIndexOf("/") + 1
  )

  const aKeyIsAvailable = useMemo(() => {
    return !!(klaviyoApiKey || sendinBlueFooterFormId)
  }, [klaviyoApiKey, sendinBlueFooterFormId])

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { register, ...form } = useForm<{ email: string }>({
    resolver: yupResolver(validationSchema),
  })
  const [subscribeNewsletter, result] = useSubscribeFooterNewsletterMutation()
  const [subscribeNewsletterSIB, resultSIB] =
    useSubscribeFooterNewsletterSIBMutation()

  const onSubmit = (values: { email: string }) => {
    if (!klaviyoApiKey && !sendinBlueFooterFormId) return
    if (klaviyoApiKey) {
      subscribeNewsletter({
        apiKey: klaviyoApiKey,
        user: values.email,
      }).then(res => form.reset())
    } else if (sendinBlueFooterFormId) {
      const urlSearchParams = new URLSearchParams()
      urlSearchParams.append("EMAIL", values.email)
      urlSearchParams.append("LOCALE", intl.locale)
      urlSearchParams.append("LANGUAGE", intl.locale)

      subscribeNewsletterSIB({
        body: urlSearchParams.toString(),
        urlParam: sendinBlueFooterFormId,
      }).then(res => form.reset())
    }
  }

  useEffect(() => {
    if (form.formState.errors.email?.message)
      setError(form.formState.errors.email.message)
  }, [form.formState.errors])

  return (
    <div className={css.root}>
      {aKeyIsAvailable && (
        <div className={css.newsletter}>
          <div>
            <Body
              variant="body2"
              bold
              className={css.newsletterTitle}
              uppercase
            >
              {intl.formatMessage({ id: "footer/newsletter/text:title" })}
            </Body>
            {result.data ? (
              <div className={css.klaviyoValidation}>
                <ColorableSVG href={validationIcon} height={16} width={16} />
                <Body variant="body3" noMargin>
                  {intl.formatMessage({
                    id: "footer/newsletter/text:inscription-succeed",
                  })}
                </Body>
              </div>
            ) : (
              <div className={css.klaviyoValidation}>
                <Body variant="body3" noMargin>
                  {error
                    ? error
                    : intl.formatMessage({
                        id: "footer/newsletter/text:description",
                      })}
                </Body>
              </div>
            )}
          </div>
          <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
            <TextInput
              {...register("email")}
              className={css.textField}
              placeholder={intl.formatMessage({
                id: "footer/newsletter/text:placeholder",
              })}
              type="email"
            />
            <Button
              color={"var(--original-color)"}
              className={css.button}
              autoWidth
              type="submit"
              disabled={!!result.data}
            >
              {intl.formatMessage({
                id: "footer/newsletter/action:subscribe",
              })}
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}

export default FooterKlaviyoForm
