import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface PrismicOurStoryPageV2 {
  prismicOurStoryPageV2: { id: string }
}

export default async function createOurStoryPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const result = await graphql<PrismicOurStoryPageV2>(
    `
      query createOurStoryPagesQuery($lang: String) {
        prismicOurStoryPageV2(lang: { eq: $lang }) {
          id
        }
      }
    `,
    { lang: currentLocale.prismicCode }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data?.prismicOurStoryPageV2) {
    createPage({
      context: { ...defaultContext, lang: currentLocale.prismicCode },
    })
  }
}
