import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import ImageBanner, {
  ImageBannerProps,
} from "~/components/slices/ImageBanner/ImageBanner"

interface PropTypes extends PrismicLandingDataBodyImageBanner {
  eagerImages?: boolean
}

function LandingBodyImageBanner({ primary, ...props }: PropTypes) {
  return (
    <SliceSection margin="small" anchorId={primary.anchorId} {...props}>
      <ImageBanner {...{ ...primary, ...props }} />
    </SliceSection>
  )
}

export default LandingBodyImageBanner

export interface PrismicLandingDataBodyImageBanner extends PrismicSlice {
  sliceType: "image_banner"
  primary: ImageBannerProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyImageBanner on PrismicLandingDataBodyImageBanner {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      link {
        ...PrismicLink
      }
      bannerHeight: banner_height
      desktopImage: desktop_image {
        gatsbyImageData(width: 1000, placeholder: BLURRED)
        fluid(maxWidth: 1000) {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          High {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
            fluid(maxWidth: 1000) {
              ...GatsbyImgixFluid_noBase64
            }
          }
          SixteenByNine {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
            fluid(maxWidth: 1000) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
      mobileImage: mobile_image {
        gatsbyImageData(width: 500, placeholder: BLURRED)
        fluid(maxWidth: 728) {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          High {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            fluid(maxWidth: 728) {
              ...GatsbyImgixFluid_noBase64
            }
          }
          SixteenByNine {
            gatsbyImageData(width: 500, placeholder: BLURRED)
            fluid(maxWidth: 728) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
      anchorId: id
    }
  }
`
