import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  CSSProperties,
  FC,
  forwardRef,
} from "react"

import cn from "classnames"
import { GatsbyLinkProps } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import { ReactScrollLinkProps } from "react-scroll/modules/components/Link"

import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link, { LinkPropTypes } from "~/components/Link/Link"
import { Colors } from "~/types/global-types"
import { formatColor } from "~/utils/colors"
import { responsiveCN, ResponsiveValues } from "~/utils/responsiveCN"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./button.module.scss"

type HTMLButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "color" | "size"
>
type HTMLLinkProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  "color" | "size"
>

interface CommonProps {
  fullWidth?: ResponsiveValues<boolean>
  color?: Colors
  // disabled?: boolean // Use native HTML prop
  variant?: "primary" | "secondary" | "tertiary"
  size?: "medium" | "small"
  uppercase?: ResponsiveValues<boolean>
  underline?: boolean
  disabled?: boolean
  autoWidth?: boolean
  autoHeight?: boolean
}

interface GetClassesProps extends CommonProps {
  className?: string
}

const getResponsiveCN = responsiveCN(css)

interface StyleProps {
  className: string
  style: CSSProperties
}

const getStyleProps = ({
  className,
  variant = "primary",
  color = "black",
  fullWidth = false,
  size = "medium",
  uppercase = true,
  underline = false,
  disabled = false,
  autoWidth = false,
  autoHeight = false,
}: GetClassesProps): StyleProps => {
  return {
    className: cn(
      className,
      css[variant],
      { [css.underline]: underline },
      getResponsiveCN("fullWidth", fullWidth),
      getResponsiveCN("uppercase", uppercase),
      css[size],
      { [css.autoWidth]: autoWidth, [css.autoHeight]: autoHeight }
    ),
    style: disabled
      ? { color: "white" }
      : color === null
      ? {}
      : {
          backgroundColor:
            variant === "primary" ? formatColor(color) : "transparent",
          borderColor: formatColor(color),
          color:
            variant === "primary" ? "var(--white-color)" : formatColor(color),
        },
  }
}

export interface ButtonProps extends CommonProps, HTMLButtonProps {
  Tag?: "button" | "span"
  icon?: string
  iconClassName?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      Tag = "button",
      className,
      color,
      fullWidth,
      variant,
      size,
      uppercase,
      underline,
      icon,
      iconClassName,
      disabled,
      autoWidth,
      autoHeight,
      children,
      ...props
    },
    ref
  ) {
    return (
      <Tag
        {...props}
        disabled={disabled}
        {...getStyleProps({
          className,
          color,
          fullWidth,
          variant,
          size,
          uppercase,
          underline,
          disabled,
          autoWidth,
          autoHeight,
        })}
        ref={ref}
      >
        {icon && (
          <ColorableSVG
            href={icon}
            className={cn(
              iconClassName,
              variant === "tertiary" ? css.iconTertiary : css.iconDefault
            )}
          />
        )}
        {children}
      </Tag>
    )
  }
)

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function IconButton(
    { icon, iconClassName, autoWidth, autoHeight, children, ...props },
    ref
  ) {
    return (
      <Button
        icon={icon}
        iconClassName={cn(iconClassName, css.noMarginIcon)}
        autoWidth
        autoHeight
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

export interface HrefButtonProps extends CommonProps, HTMLLinkProps {
  Tag?: "a"
}

export const HrefButton = forwardRef<HTMLAnchorElement, HrefButtonProps>(
  function HrefButton(
    {
      className,
      color,
      fullWidth,
      variant,
      size,
      uppercase,
      underline,
      disabled,
      autoWidth,
      autoHeight,
      ...props
    },
    ref
  ) {
    return (
      <a
        {...props}
        {...getStyleProps({
          className,
          color,
          fullWidth,
          variant,
          size,
          uppercase,
          underline,
          disabled,
          autoWidth,
          autoHeight,
        })}
        ref={ref}
      />
    )
  }
)

export interface LinkButtonProps<T>
  extends CommonProps,
    Omit<LinkPropTypes<T>, "color"> {}

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps<any>>(
  function LinkButton(
    {
      className,
      color,
      fullWidth,
      variant,
      size,
      uppercase,
      underline,
      disabled,
      autoWidth,
      autoHeight,
      ...props
    },
    ref
  ) {
    return (
      <Link
        {...props}
        {...getStyleProps({
          className,
          color,
          fullWidth,
          variant,
          size,
          uppercase,
          underline,
          disabled,
          autoWidth,
          autoHeight,
        })}
      />
    )
  }
)

export type ScrollButtonProps = Omit<ReactScrollLinkProps, "size"> &
  CommonProps &
  Omit<HTMLButtonProps, "size">

export const ScrollButton: FC<ScrollButtonProps> = ({
  className,
  color,
  fullWidth,
  variant,
  size,
  uppercase,
  underline,
  ...props
}) => (
  <ScrollLink
    smooth
    {...getStyleProps({
      className,
      color,
      fullWidth,
      variant,
      size,
      uppercase,
      underline,
    })}
    {...props}
  />
)

export interface PrimaryButtonProps
  extends Omit<CommonProps, "color" | "size" | "variant" | "uppercase">,
    HTMLButtonProps {
  primary: string
  secondary: string
}

export const PremiumButton = ({
  primary,
  secondary,
  className,
  ...props
}: PrimaryButtonProps) => (
  <Button className={cn(className, css.premium)} color={null} {...props}>
    <span>{primary}</span>
    <span className={css.small}>{secondary}</span>
  </Button>
)
