import React, { useContext } from "react"

import { useIntl } from "react-intl"

import { ThemeContext } from "~/context/ThemeContext"
import { PartialRecipe } from "~/models/PartialRecipe"
import Vertical from "~/models/Vertical"
import RecipeCarousel from "~/templates/Recipe/RecipeCarousel/RecipeCarousel"
import { getVerticaleLink } from "~/utils/link"
import { ChefclubLocale } from "~/utils/locales"

export interface LatestRecipesProps {
  primary?: {
    verticaleFilter?: Vertical
    anchorId?: string
  }
  recipes: PartialRecipe[]
  isPreview?: boolean
  isDarkTheme?: boolean
  eagerImages?: boolean
}

function LatestRecipeCarousel(props: LatestRecipesProps) {
  const intl = useIntl()
  const verticale = props.primary?.verticaleFilter ?? null
  const theme = useContext(ThemeContext)
  const isKidsTheme = theme === "kids"

  return (
    <div style={{ marginTop: "-16px", marginBottom: "-40px" }}>
      <RecipeCarousel
        {...props}
        title={intl.formatMessage({
          id: "text:discover-last-recipes",
        })}
        button={{
          label: intl.formatMessage({ id: "action:load-more" }),
          link: getVerticaleLink(
            intl.locale as ChefclubLocale,
            intl.formatMessage({ id: "path:recipes" }),
            verticale
          ),
        }}
        isKidsTheme={isKidsTheme}
        underline={false}
      />
    </div>
  )
}

export default LatestRecipeCarousel
