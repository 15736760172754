import React, { Fragment, useState } from "react"

import { graphql } from "gatsby"

import { LatestLanding, PartialLanding } from "./Landing"
import LandingBodyAllVerticals, {
  PrismicLandingDataBodyAllVerticals,
} from "./slices/LandingBodyAllVerticals"
// TODO: Using dynamic imports
// I wanted dynamic import like this https://loadable-components.com/docs/dynamic-import/
// There is a gatsby plugin which implements SSR support
// See plugin: https://www.gatsbyjs.com/plugins/gatsby-plugin-loadable-components-ssr/
// But today, there is a bug, dynamic import doesn't work properly with SSR
// See issue: https://github.com/hector-del-rio/gatsby-plugin-loadable-components-ssr/issues/4
import LandingBodyButton, {
  PrismicLandingDataBodyButton,
} from "./slices/LandingBodyButton"
import LandingBodyCustomContents, {
  PrismicLandingDataBodyCustomContents,
} from "./slices/LandingBodyCustomContents"
import LandingBodyExternalIntegration, {
  PrismicExternalIntegration,
} from "./slices/LandingBodyExternalIntegration"
import LandingBodyFlipTiles, {
  PrismicLandingDataBodyFlipTiles,
} from "./slices/LandingBodyFlipTiles"
import LandingBodyFormattableText, {
  PrismicLandingDataBodyFormattableText,
} from "./slices/LandingBodyFormattableText"
import LandingBodyFreeCarousel, {
  PrismicLandingDataBodyFreeCarousel,
} from "./slices/LandingBodyFreeCarousel"
import LandingBodyFullWidthBanner, {
  PrismicLandingDataBodyFullWidthBanner,
} from "./slices/LandingBodyFullWidthBanner"
import LandingBodyFullWidthLottie, {
  PrismicLandingDataBodyFullWidthLottie,
} from "./slices/LandingBodyFullWidthLottie"
import LandingBodyImageBanner, {
  PrismicLandingDataBodyImageBanner,
} from "./slices/LandingBodyImageBanner"
import LandingBodyImageImageColumns, {
  PrismicLandingDataBodyImageImageColumns,
} from "./slices/LandingBodyImageImageColumns"
import LandingBodyLandingCarousel, {
  PrismicLandingDataBodyLandingCarousel,
} from "./slices/LandingBodyLandingCarousel"
import LandingBodyLatestLandings, {
  PrismicLandingDataBodyLatestLandings,
} from "./slices/LandingBodyLatestLandings"
import LandingBodyLatestLandingsByTags, {
  PrismicLandingDataBodyLatestLandingsByTags,
} from "./slices/LandingBodyLatestLandingsByTags"
import LandingBodyLatestRecipes, {
  PrismicLandingDataBodyLatestRecipes,
} from "./slices/LandingBodyLatestRecipes"
import LandingBodyListWithHighlightedItem, {
  PrismicLandingDataBodyListWithHighlightedItem,
} from "./slices/LandingBodyListWithHighlightedItem"
import LandingBodyRecipeCarousel, {
  PrismicLandingDataBodyRecipeCarousel,
} from "./slices/LandingBodyRecipeCarousel"
import LandingBodyRecipeVideo, {
  PrismicLandingDataBodyRecipeVideo,
} from "./slices/LandingBodyRecipeVideo"
import LandingBodyReviews, {
  PrismicLandingDataBodyReviews,
} from "./slices/LandingBodyReviews"
import LandingBodyText, {
  PrismicLandingDataBodyText,
} from "./slices/LandingBodyText"
import LandingBodyTextImageColumnsV2, {
  PrismicLandingDataBodyTextImageColumnsV2,
} from "./slices/LandingBodyTextImageColumnsV2"
import LandingBodyTextRecipeColumns, {
  PrismicLandingDataBodyTextRecipeColumns,
} from "./slices/LandingBodyTextRecipeColumns"
import LandingBodyTimeline, {
  PrismicLandingDataBodyTimeline,
} from "./slices/LandingBodyTimeline"
import LandingBodyVideo, {
  PrismicLandingDataBodyVideo,
} from "./slices/LandingBodyVideo"
import LandingBodyVideoTextColumns, {
  PrismicLandingDataBodyVideoTextColumns,
} from "./slices/LandingBodyVideoTextColumns"
import { TimelineProps } from "~/components/Timeline/Timeline"
import { CoCreationProject } from "~/models/CoCreationProjects"
import { PartialRecipe, PartialRecipeWithVideo } from "~/models/PartialRecipe"
import { Episode, Show } from "~/models/Show"
import { Video } from "~/models/Video"
import { warnAboutUnexpectedSlice } from "~/utils/general"

export type AnyPrismicLandingSlice = {
  isPreview?: boolean
  isDarkTheme?: boolean
  setPositionH1: (value: number) => void
  positionH1: number
  position: number
  hasHeader: boolean
} & (
  | PrismicLandingDataBodyRecipeCarousel
  | PrismicLandingDataBodyTextRecipeColumns
  | PrismicLandingDataBodyText
  | PrismicLandingDataBodyButton
  | PrismicLandingDataBodyVideoTextColumns
  | PrismicLandingDataBodyImageImageColumns
  | PrismicLandingDataBodyFormattableText
  | PrismicLandingDataBodyAllVerticals
  | PrismicLandingDataBodyLatestRecipes
  | PrismicLandingDataBodyFreeCarousel
  | PrismicLandingDataBodyLandingCarousel
  | PrismicLandingDataBodyRecipeVideo
  | PrismicLandingDataBodyVideo
  | PrismicLandingDataBodyImageBanner
  | PrismicLandingDataBodyTimeline
  | PrismicLandingDataBodyTextImageColumnsV2
  | PrismicLandingDataBodyListWithHighlightedItem
  | PrismicLandingDataBodyFullWidthBanner
  | PrismicLandingDataBodyFlipTiles
  | PrismicLandingDataBodyLatestLandings
  | PrismicLandingDataBodyLatestLandingsByTags
  | PrismicLandingDataBodyReviews
  | PrismicLandingDataBodyFullWidthLottie
  | PrismicLandingDataBodyCustomContents
  | PrismicExternalIntegration
)

interface LandingSliceAdditionalData {
  recipes?: PartialRecipeWithVideo[]
  landings?: PartialLanding[]
  orderedLandings?: LatestLanding[]
  lastRecipes?: PartialRecipe[]
  verticalRecipes?: PartialRecipe[]
  videos?: Video[]
  timelines?: TimelineProps[]
  lastVerticalRecipes?: PartialRecipe[]
  coCreationProjects?: CoCreationProject[]

  allShowsEpisodes: {
    nodes: Episode[]
  }
  allShows: {
    nodes: Show[]
  }
  showsMobileHomeVideo: Video
  showsDesktopHomeVideo: Video
}

// Images in 3 leading slices will be eager-loaded
const EAGER_LOAD_THRESHOLD = 3

function slices(
  props: AnyPrismicLandingSlice,
  {
    recipes,
    landings,
    orderedLandings,
    lastRecipes,
    verticalRecipes,
    videos,
    timelines,
    lastVerticalRecipes,
    allShows,
    allShowsEpisodes,
    showsMobileHomeVideo,
    showsDesktopHomeVideo,
  }: LandingSliceAdditionalData
) {
  // Slices that are incompatible with h1 are ones without textual content below their heading (buttons, carousels...etc.)
  // Even if those are the first slice of the landing they won't have any h1.
  // As a result: the isLeadingSlice prop won't be passed to those.
  const isPositionH1Compatible =
    props.positionH1 === props.position && !props.hasHeader

  const incrementH1Position = (): void =>
    props.setPositionH1(props.positionH1 + 1)

  const shouldEagerLoad = props.position < EAGER_LOAD_THRESHOLD

  switch (props.sliceType) {
    case "recipe_carousel":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyRecipeCarousel
          key={props.id}
          {...props}
          recipes={recipes}
          eagerImages={shouldEagerLoad}
        />
      )
    case "list_with_highlighted_item":
      return (
        <LandingBodyListWithHighlightedItem
          key={props.id}
          allLandings={orderedLandings}
          {...props}
        />
      )
    case "text_recipe_columns":
      return (
        <LandingBodyTextRecipeColumns
          key={props.id}
          {...props}
          recipes={recipes}
          isLeadingSlice={isPositionH1Compatible}
          eagerImages={shouldEagerLoad}
        />
      )
    case "latest_recipes":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyLatestRecipes
          key={props.id}
          {...props}
          recipes={
            lastVerticalRecipes?.length ? lastVerticalRecipes : lastRecipes
          }
          eagerImages={shouldEagerLoad}
        />
      )
    case "all_verticals":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyAllVerticals
          key={props.id}
          {...props}
          recipes={verticalRecipes}
          eagerImages={shouldEagerLoad}
        />
      )
    case "landing_carousel":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyLandingCarousel
          key={props.id}
          {...props}
          recipes={recipes}
          landings={landings}
          eagerImages={shouldEagerLoad}
        />
      )
    case "free_carousel":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyFreeCarousel
          key={props.id}
          {...props}
          eagerImages={shouldEagerLoad}
        />
      )
    case "text":
      return (
        <LandingBodyText
          key={props.id}
          {...props}
          isLeadingSlice={isPositionH1Compatible}
        />
      )
    case "external_integration":
      return <LandingBodyExternalIntegration key={props.id} {...props} />
    case "button":
      isPositionH1Compatible && incrementH1Position()
      return <LandingBodyButton key={props.id} {...props} />
    case "text_image_columns_v2":
      return (
        <LandingBodyTextImageColumnsV2
          key={props.id}
          {...props}
          isLeadingSlice={isPositionH1Compatible}
          eagerImages={shouldEagerLoad}
        />
      )
    case "video_text_columns":
      return (
        <LandingBodyVideoTextColumns
          key={props.id}
          {...props}
          videos={videos}
          isLeadingSlice={isPositionH1Compatible}
        />
      )
    case "image_banner":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyImageBanner
          key={props.id}
          {...props}
          eagerImages={shouldEagerLoad}
        />
      )
    case "video":
      isPositionH1Compatible && incrementH1Position()
      return <LandingBodyVideo key={props.id} {...props} videos={videos} />
    case "recipe_video":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyRecipeVideo key={props.id} {...props} recipes={recipes} />
      )
    case "full_width_lottie":
      isPositionH1Compatible && incrementH1Position()
      return <LandingBodyFullWidthLottie key={props.id} {...props} />
    case "formattable_text":
      return (
        <LandingBodyFormattableText
          key={props.id}
          {...props}
          isLeadingSlice={isPositionH1Compatible}
        />
      )
    case "image_image_columns":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyImageImageColumns
          key={props.id}
          {...props}
          eagerImages={shouldEagerLoad}
        />
      )
    case "timeline":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyTimeline key={props.id} {...props} timelines={timelines} />
      )
    case "full_width_banner":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyFullWidthBanner
          key={props.id}
          {...props}
          eagerImages={shouldEagerLoad}
          videos={videos}
        />
      )
    case "flip_tiles":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyFlipTiles
          key={props.id}
          {...props}
          eagerImages={shouldEagerLoad}
        />
      )
    case "latest_landings":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyLatestLandings
          key={props.id}
          {...props}
          landings={orderedLandings}
          eagerImages={shouldEagerLoad}
        />
      )
    case "latest_landings_by_tags":
      isPositionH1Compatible && incrementH1Position()
      return (
        <LandingBodyLatestLandingsByTags
          key={props.id}
          {...props}
          landings={orderedLandings}
          eagerImages={shouldEagerLoad}
        />
      )
    case "reviews":
      isPositionH1Compatible && incrementH1Position()
      return <LandingBodyReviews key={props.id} {...props} />
    case "custom_contents":
      return (
        <LandingBodyCustomContents
          key={props.id}
          {...props}
          isLeadingSlice={isPositionH1Compatible}
          incrementH1Position={incrementH1Position}
          allShows={allShows}
          allShowsEpisodes={allShowsEpisodes}
          showsDesktopHomeVideo={showsDesktopHomeVideo}
          showsMobileHomeVideo={showsMobileHomeVideo}
        />
      )
    default:
      warnAboutUnexpectedSlice("Landing", props)
      return null
  }
}

interface PropTypes extends LandingSliceAdditionalData {
  body: AnyPrismicLandingSlice[]
  hasHeader: boolean
  isPreview?: boolean
  isDarkTheme?: boolean
  landingUID?: string
}

function LandingSlice({
  body,
  recipes,
  landings,
  lastRecipes,
  orderedLandings,
  verticalRecipes,
  videos,
  timelines,
  lastVerticalRecipes,
  hasHeader,
  allShows,
  allShowsEpisodes,
  showsDesktopHomeVideo,
  showsMobileHomeVideo,
  ...props
}: PropTypes) {
  const [positionH1, setPositionH1] = useState(0)
  return (
    <>
      {body.map((slice, index) => (
        <Fragment key={slice.id}>
          {slices(
            {
              ...slice,
              position: index,
              positionH1,
              setPositionH1,
              hasHeader,
              ...props,
            },
            {
              recipes,
              landings,
              lastRecipes,
              orderedLandings,
              verticalRecipes,
              videos,
              timelines,
              lastVerticalRecipes,
              allShows,
              allShowsEpisodes,
              showsDesktopHomeVideo,
              showsMobileHomeVideo,
            }
          )}
        </Fragment>
      ))}
    </>
  )
}

export default LandingSlice

export const query = graphql`
  fragment PrismicLandingBody on PrismicLandingDataType {
    body {
      ...PrismicLandingDataBodyRecipeCarousel
      ...PrismicLandingDataBodyTextRecipeColumns
      ...PrismicLandingDataBodyText
      ...PrismicExternalIntegrationKlaviyoForm
      ...PrismicExternalIntegrationStoreLocator
      ...PrismicExternalIntegrationPinterestWidget
      ...PrismicLandingDataBodyButton
      ...PrismicLandingDataBodyVideoTextColumns
      ...PrismicLandingDataBodyImageImageColumns
      ...PrismicLandingDataBodyFormattableText
      ...PrismicLandingDataBodyAllVerticals
      ...PrismicLandingDataBodyLatestRecipes
      ...PrismicLandingDataBodyFreeCarousel
      ...PrismicLandingDataBodyLandingCarousel
      ...PrismicLandingDataBodyRecipeVideo
      ...PrismicLandingDataBodyVideo
      ...PrismicLandingDataBodyImageBanner
      ...PrismicLandingDataBodyTimeline
      ...PrismicLandingDataBodyTextImageColumnsV2
      ...PrismicLandingDataBodyListWithHighlightedItem
      ...PrismicLandingDataBodyFullWidthBanner
      ...PrismicLandingDataBodyFlipTiles
      ...PrismicLandingDataBodyLatestLandings
      ...PrismicLandingDataBodyLatestLandingsByTags
      ...PrismicLandingDataBodyReviews
      ...PrismicLandingDataBodyFullWidthLottie
      ...PrismicLandingDataBodyCustomContents
    }
  }
`
