import { IntlShape } from "react-intl"

import Carlton from "./assets/carlton.svg"
import Colette from "./assets/colette.svg"
import Elvis from "./assets/elvis.svg"
import Kikitos from "./assets/kikitos.svg"
import Lola from "./assets/lola.svg"
import Maurice from "./assets/maurice.svg"
import { Ingredient, ingredients } from "./data/Ingredients"
import { KiddozName, KiddozValues, Quantity } from "./model"
import { capitalizeFirstLetter } from "~/utils/string"

const POURCENTAGE_ALLOWED_MARGIN = 0.1

export function convertQuantityToKiddoz(
  kiddozValues: number[], //ML or Grams values for kiddoz
  amountUnit: number // Value we want
): { path: KiddozValues[]; kiddozUsed: number } {
  const minKiddozValue = Math.min(...kiddozValues)
  const margin = amountUnit * POURCENTAGE_ALLOWED_MARGIN
  // We build a matrix of acceptable values
  // The index in the matrix is the value in grams or ML
  // We will remove values under the minValue later (in order for index to equals value)
  // The path represents the kiddoz values necessary to reach value (ie index)
  // kiddozUsed represent the number of kiddoz spoons to use
  const matrixSize = Math.round(amountUnit + margin)
  const minValue = Math.round(amountUnit)
  const change: { kiddozUsed: number; path: number[] }[] = Array(
    matrixSize
  ).fill({ kiddozUsed: 9999, path: [5] })

  change.map((currentWeight, index) => {
    if (index < minKiddozValue) {
      // if (index < margin || index < minKiddozValue) {
      // We set to 0 for values small the minKiddozValue are those cannot be made with our value set
      change[index] = { kiddozUsed: 0, path: [] }
    } else {
      kiddozValues.forEach(kiddozValue => {
        const valueDifference = index - kiddozValue
        if (valueDifference == 0) {
          // If we have a perfect match we use 1 of the kiddozValue
          change[index] = { kiddozUsed: 1, path: [kiddozValue] }
        } else if (valueDifference > 0) {
          // If it's not a perfect match we sum with the previously calculated kiddozUsed for the difference
          // If it smaller than any value we could currently have (for another kiddozValue)
          if (
            change[valueDifference]?.kiddozUsed + 1 <
            change[index]?.kiddozUsed
          ) {
            change[index] = {
              kiddozUsed: change[valueDifference].kiddozUsed + 1,
              path: [kiddozValue, ...change[valueDifference].path],
            }
          }
        }
      })
    }
  })
  // Now we remove the value to small to be a proper result
  // and select the one with the least kiddozused
  const minPathWithMargin = change.slice(minValue).reduce(
    (acc, current) => {
      if (current.kiddozUsed < acc.kiddozUsed) {
        return current
      }
      return acc
    },
    { kiddozUsed: 99999, path: [] }
  )

  const path = getEquivalentPathFromKiddozValues(
    kiddozValues,
    minPathWithMargin.path
  )

  return {
    path,
    kiddozUsed: minPathWithMargin.kiddozUsed,
  }
}

const getEquivalentPathFromKiddozValues = (
  kiddozValues: number[],
  path: number[]
): KiddozValues[] => {
  const possiblesValues: KiddozValues[] = [5, 15, 60, 80, 125, 250]

  return path.map(value => possiblesValues[kiddozValues.indexOf(value)])
}

export const kiddozIcons = new Map<KiddozName, any>([
  ["Poule", Colette],
  ["Poussin", Kikitos],
  ["Souris", Lola],
  ["Chat", Carlton],
  ["Cochon", Elvis],
  ["Chef", Maurice],
])

export const formatIngredient = (
  ingredient: string,
  intl: IntlShape
): string => {
  const simpleLocale = intl.locale.split("-")[0]
  return capitalizeFirstLetter(
    (
      getIngredientFromSlug(ingredient)[
        simpleLocale as keyof Ingredient
      ] as string
    ).toLowerCase()
  )
}

export const getIngredientFromSlug = (InitialSlug: string): Ingredient => {
  const ingredient = ingredients.find(({ slug }) => slug === InitialSlug)
  if (ingredient === undefined) {
    throw new Error(`No conversion for ${InitialSlug}`)
  }
  return ingredient
}

export const convertFood2ml = (quantity: Quantity): number => {
  console.log(quantity.unit)
  console.log(quantity)
  return quantity.value * (quantity.unit.conversion_to_ml ?? 1)
}
