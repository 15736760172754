import React from "react"

import { HomeShowsQuery } from "~/templates/Shows/HomeShows/HomeShows"
import Shows from "~/templates/Shows/HomeShows/Shows/Shows"

// eslint-disable-next-line css-modules/no-unused-class
import css from "./ShowsSlice.module.scss"

const ShowsSlice = ({
  episodes,
  publishedShows,
  mobileHomeVideo,
  desktopHomeVideo,
}: HomeShowsQuery) => {
  return (
    <div className={css.root}>
      <Shows
        episodes={episodes}
        publishedShows={publishedShows}
        mobileHomeVideo={mobileHomeVideo}
        desktopHomeVideo={desktopHomeVideo}
      />
    </div>
  )
}

export default ShowsSlice
