/* eslint-disable @typescript-eslint/ban-ts-comment */
export const loadPinterest = function (d: Document) {
  const f = d.getElementsByTagName("SCRIPT")[0],
    p = d.createElement("SCRIPT")
  // @ts-ignore
  p.type = "text/javascript"
  // @ts-ignore
  p.async = true
  // @ts-ignore
  p.src = "//assets.pinterest.com/js/pinit.js"
  // @ts-ignore
  f.parentNode.insertBefore(p, f)
}
