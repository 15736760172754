import React, { ReactNode } from "react"

import balanceText from "balance-text"

import useIsomorphicLayoutEffect from "~/hooks/useIsomorphicLayoutEffect"

interface PropTypes {
  children: (props: { className: string }) => ReactNode
}

export const BalanceText = ({ children }: PropTypes) => {
  const className = "balance-text"

  useIsomorphicLayoutEffect(() => {
    balanceText(`.${className}`, { watch: true })
  }, [])

  return <div>{children({ className })}</div>
}
