import React from "react"

import Helmet from "react-helmet"

export interface LinkItem {
  label: string
  link?: string
  parentSlug?: string
  unnormalizedParentSlug?: string
}

export interface LdJsonItem {
  "@type": "ListItem"
  position: number
  name: string
  item?: string
}

interface PropTypes {
  items: LinkItem[]
}

function BreadcrumbSEO({ items }: PropTypes) {
  const itemListElement = items.map((item, i) => {
    let output: LdJsonItem = {
      "@type": "ListItem",
      position: i + 1,
      name: item.label,
    }

    if (item?.link) {
      const isInternal = /^\/(?!\/)/.test(item.link)
      const baseUrl = "https://www.chefclub.tv"

      output = {
        ...output,
        item: isInternal ? `${baseUrl}${item.link}` : item.link,
      }
    }
    return output
  })

  const ldJsonBreadcrumb = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement,
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJsonBreadcrumb)}
      </script>
    </Helmet>
  )
}

export default BreadcrumbSEO
