import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface VideoPages {
  allVideo: {
    nodes: {
      id: string
    }[]
  }
}

export default async function createVideoPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, createPage, currentLocale, defaultContext, reporter } = args

  const result = await graphql<VideoPages>(
    `
      query createVideoPagesQuery($language: String) {
        allVideo(filter: { language: { eq: $language } }) {
          nodes {
            id
          }
        }
      }
    `,
    { language: currentLocale.id }
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data?.allVideo) {
    for (const video of result.data?.allVideo.nodes) {
      try {
        createPage({
          routeParams: video,
          context: {
            ...defaultContext,
            videoId: video.id,
          },
        })
      } catch (e) {
        console.log("ERROR")
      }
    }
  }
}
