import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicNavSlice } from "~/components/slices/common/SliceTypes"
import List from "~/components/slices/List/List"
import { PrismicLink, PrismicStructuredText } from "~/models/PrismicTypes"

interface PropTypes extends PrismicListFromSelectedLandings {
  isPreview?: boolean
  isDarkTheme?: boolean
}

function PrismicNavMenuListFromSelectedLandings(props: PropTypes) {
  const content = props.items.map((item: landing) => {
    return {
      text: item.landing?.document?.data?.metaTitle,
      illustration: item.landing?.document?.data?.seoImage.url,
      link: `/fr/s/${item.landing?.document?.uid}`,
      tag: item.tag,
    }
  })
  return (
    <SliceSection {...props}>
      <List headerIsNotHx={true} content={content} {...props.primary} />
    </SliceSection>
  )
}

export default PrismicNavMenuListFromSelectedLandings

interface primary {
  title: PrismicStructuredText
  imageForm: string
  seeMoreLink?: PrismicLink
  seeMoreLinkText?: string
}
interface landing {
  landing: {
    document: {
      uid: string
      data: {
        metaTitle: string
        seoImage: {
          url: string
        }
      }
    }
  }
  tag: string
}

export interface PrismicListFromSelectedLandings extends PrismicNavSlice {
  sliceType: "list"
  variation: "fromSelectedLandings"
  primary: primary
  items: landing[]
}

export const query = graphql`
  fragment PrismicListFromSelectedLandings on PrismicListFromSelectedLandings {
    id
    sliceType: slice_type
    variation
    primary {
      imageForm
      title {
        ...PrismicStructuredText
      }
      seeMoreLink: seeMoreItemLink {
        ...PrismicLink
      }
      seeMoreLinkText: seeMoreItemLinkText
    }
    items {
      tag
      landing {
        document {
          ... on PrismicLanding {
            uid
            data {
              seoImage: seo_image {
                url
              }
              metaTitle: meta_title
            }
          }
        }
      }
    }
  }
`
