import { IntlShape } from "react-intl"
import * as yup from "yup"

import { kinds } from "~/templates/KidsConverter/KidsConverterV2/model"

function getFormValidators(intl: IntlShape) {
  const requiredMessage = intl.formatMessage({
    id: "form/text:field-is-required",
  })

  return {
    requiredString: yup.string().required(requiredMessage),
    email: yup
      .string()
      .email(intl.formatMessage({ id: "form/text:email-format-incorrect" }))
      .required(requiredMessage),

    // Currently: We don't enforce secure passwords
    passwordLite: yup.string().required(requiredMessage),

    // Strong password validation
    password: yup
      .string()
      .min(
        8,
        intl.formatMessage(
          { id: "form/text:field-requires-at-least-x-chars" },
          { length: 8 }
        )
      )
      .matches(
        /.*[a-z]/,
        intl.formatMessage({
          id: "form/text:field-requires-lowercase",
        })
      )
      .matches(
        /.*[A-Z]/,
        intl.formatMessage({
          id: "form/text:field-requires-uppercase",
        })
      )
      .matches(
        /.*[0-9]/,
        intl.formatMessage({ id: "form/text:field-requires-number" })
      )
      .matches(
        /.*[-+_!@#$%^&*., ?]/,
        intl.formatMessage({
          id: "form/text:field-requires-special-char",
        })
      )
      .required(requiredMessage),

    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        intl.formatMessage({ id: "form/text:passwords-must-match" })
      )
      .required(requiredMessage),

    requiredPositiveIntegerNumber: yup
      .number()
      .required(requiredMessage)
      .positive()
      .integer(),

    requiredKinds: yup
      .string()
      .oneOf(Object.values(kinds))
      .required(requiredMessage),
  }
}

export default getFormValidators
