import React from "react"

import Img, { FluidObject } from "gatsby-image"

import { Col, Heading, PrismicTextarea, Row } from "~/components/ui"
import { PrismicStructuredText } from "~/models/PrismicTypes"

import css from "./LandingHeader.module.scss"

interface PropTypes {
  title: PrismicStructuredText
  description: PrismicStructuredText
  isDarkTheme?: boolean
  layout?: "image" | "centered"
  image?: {
    alt: string | null
    fluid: FluidObject | null
  }
}

function InnerLandingCenteredHeader(props: PropTypes) {
  const { isDarkTheme, title, description } = props
  return (
    <Col offset={[0, 1]} width={[12, 10]}>
      <Heading
        Tag="h1"
        color={isDarkTheme ? "white" : "body"}
        variant="extraLarge"
        align="center"
      >
        {title.text}
      </Heading>

      <PrismicTextarea
        color={isDarkTheme ? "white" : "body"}
        text={description}
        align="center"
      />
    </Col>
  )
}
function InnerLandingImageHeader(props: PropTypes) {
  const { isDarkTheme, title, description, image } = props
  return (
    <div className={css.flex}>
      <div className={css.wrapper}>
        <Heading
          Tag="h1"
          color={isDarkTheme ? "white" : "body"}
          variant="extraLarge"
        >
          {title.text}
        </Heading>

        <div className={css.imageWrapper}>
          {image?.fluid && (
            <Img
              className={css.image}
              fluid={image.fluid}
              alt={image.alt || ""}
            />
          )}
        </div>

        <PrismicTextarea
          color={isDarkTheme ? "white" : "body"}
          text={description}
        />
      </div>

      {/* Used in CSS to ensure the correct height */}
      <div className={css.artificialHeight} />
    </div>
  )
}

function LandingHeader({ layout = "image", ...props }: PropTypes) {
  return (
    <Col
      Tag="header"
      className="o-landing-header"
      width={12}
      data-layout={layout}
    >
      <Row>
        {layout === "image" ? (
          <InnerLandingImageHeader {...props} />
        ) : (
          <InnerLandingCenteredHeader {...props} />
        )}
      </Row>
    </Col>
  )
}

export default LandingHeader
