import { ingredients } from "./data/Ingredients"
import { Units, units } from "./data/Units"

export const getUnitBySlug = (slug: string): Units | undefined => {
  return units.find(unit => unit.slug === slug)
}

export const kiddozNames = [
  "Poule",
  "Poussin",
  "Souris",
  "Chat",
  "Cochon",
  "Chef",
] as const

export type KiddozName = (typeof kiddozNames)[number]

export const kiddozValues = [5, 15, 60, 80, 125, 250] as const

export type KiddozValues = (typeof kiddozValues)[number]

export const Kiddoz = new Map<KiddozValues, KiddozName>([
  [5, "Poussin"],
  [15, "Poule"],
  [60, "Souris"],
  [80, "Chat"],
  [125, "Cochon"],
  [250, "Chef"],
])

export type Quantity = {
  unit: Units
  value: number
}

export type Kinds = (typeof ingredients)[number]["slug"]

export const kinds = ingredients.map(({ slug }) => slug)
