//This function allow you to limit the number of parallel running functions in a PromiseAll
//Source : https://gist.github.com/jcouyang/632709f30e12a7879a73e9e132c0d56b

export const promiseAllLimit = async <T>(
  n: number,
  list: (() => Promise<T>)[]
) => {
  const head = list.slice(0, n) // N first promises
  const tail = list.slice(n) // Rest of the promises
  const results: T[] = []

  const execute = async (
    promise: () => Promise<T>,
    index: number,
    runNext: () => Promise<void>
  ) => {
    results[index] = await promise()
    await runNext()
  }

  const runNext = async () => {
    const index = list.length - tail.length
    const promise = tail.shift()
    if (promise !== undefined) {
      await execute(promise, index, runNext)
    }
  }

  await Promise.all(head.map((promise, i) => execute(promise, i, runNext)))

  return results
}
