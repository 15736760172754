import React, { CSSProperties, ImgHTMLAttributes, useState } from "react"

import cn from "classnames"
import { FormattedMessage } from "react-intl"

import closeIcon from "./assets/close-icon.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Modal from "~/components/Modals/Modal"
import { Body, Heading } from "~/components/ui"
import { cancelScrollOnClose } from "~/utils/modals"

import css from "./ImageCard.module.scss"
export type ImageCardOverlay =
  | { type: "default" }
  | { type: "recipe" }
  | { type: "vertical"; payload: string }
  | { type: "premium" }
  | { type: "branded" }
  | { type: "play" }
  | { type: "reproduction"; payload: string }
  | { type: "workshop"; payload: "full" | "completed" }
  | { type: "not-found"; payload?: string }

function Stickers(props: ImageCardOverlay) {
  switch (props.type) {
    case "recipe":
      return null
    case "branded":
      return (
        <>
          <span className={css.logo} />
        </>
      )

    case "vertical":
      return (
        <span className={cn(css.bottomStripe, css.isVertical)}>
          <Heading
            className={css.vertical}
            Tag="span"
            color="white"
            align="center"
            uppercase
          >
            {props.payload}
          </Heading>
        </span>
      )

    case "reproduction":
      return (
        <div className={css.instagramWrapper}>
          <span className={css.instagramIcon} />
          <Body variant="body3" semiBold color="white" align="center">
            {props.payload}
          </Body>
        </div>
      )

    case "premium":
      return (
        <>
          <span className={css.premium} />
        </>
      )

    case "workshop":
      return (
        <div className={css.blackOverlay}>
          <Body color="white" uppercase variant="body1">
            {props.payload === "full" ? (
              <FormattedMessage id="text:full" />
            ) : (
              <FormattedMessage id="text:ended" />
            )}
          </Body>
        </div>
      )

    case "not-found":
      return (
        <div className={css.notFound}>
          <Body>
            <code>{props.payload}</code>
          </Body>
        </div>
      )

    case "play":
      return <span className={css.play} />

    default:
      return null
  }
}

type ImageProps = ImgHTMLAttributes<HTMLImageElement>

interface PropTypes {
  imageProps?: ImageProps
  overlay?: ImageCardOverlay
  onClick?: () => void
  color?: CSSProperties["color"]
  disableHover?: boolean
  eagerImages?: boolean
  openingModal?: boolean
}

export function ImageCard({
  overlay = { type: "default" },
  onClick,
  color,
  disableHover,
  eagerImages,
  openingModal = false,
  ...props
}: PropTypes) {
  const imageProps: ImageProps = {
    src: "",
    alt: "",
    title: "",
    ...props.imageProps,
  }

  const notFound = !imageProps.src || overlay.type === "not-found"
  const [open, setOpen] = useState(false)
  const onClose = () => {
    cancelScrollOnClose()

    setOpen(false)
  }

  const openModal = () => {
    openingModal && setOpen(true)
  }
  return (
    <>
      <div
        className={cn(css.root, {
          [css.hasHover]: !disableHover && !notFound,
        })}
        style={
          color
            ? ({
                "--current-theme-color": color,
              } as CSSProperties)
            : {}
        }
        onClick={onClick ? onClick : openModal}
      >
        {!notFound && (
          <img loading={eagerImages ? "eager" : "lazy"} {...imageProps} />
        )}

        {overlay && <Stickers {...overlay} />}
      </div>
      {openingModal && (
        <Modal open={open} onClose={onClose} unmountOnExit mode="fixed">
          {modalRef => (
            <div className={css.modal} ref={modalRef}>
              <img
                className={css.modalImage}
                loading={eagerImages ? "eager" : "lazy"}
                {...imageProps}
              />
              <ColorableSVG
                className={css.closeIcon}
                href={closeIcon}
                height={24}
                width={24}
                onClick={onClose}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  )
}
