module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeLinkHeaders":false,"mergeCachingHeaders":false,"headers":{"/*":["Referrer-Policy: strict-origin-when-cross-origin"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"chefclub","imageImgixParams":{"auto":"compress,format","fit":"max","q":90},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":50},"promptForAccessToken":true,"apiEndpoint":"https://chefclub.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chefclub","start_url":"/en-us/","lang":"en-US","scope":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon.svg","cache_busting_mode":"none","theme_color_in_head":false,"localize":[{"name":"Chefclub","start_url":"/fr/","lang":"fr"},{"name":"Chefclub","start_url":"/en-gb/","lang":"en-GB"},{"name":"Chefclub","start_url":"/de/","lang":"de"},{"name":"Chefclub","start_url":"/es-es/","lang":"es-ES"},{"name":"Chefclub","start_url":"/es-mx/","lang":"es-MX"},{"name":"Chefclub","start_url":"/it/","lang":"it"},{"name":"Chefclub","start_url":"/pt-br/","lang":"pt-BR"}],"legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog-rum/gatsby-browser.js'),
      options: {"plugins":[],"applicationId":"6166a922-7a1a-451a-ac0e-7991ebeded9f","clientToken":"pub2d5c9758e1b92291d64b1e79d800e2ed","site":"datadoghq.com","service":"chefclub-rum-js","env":"prod","version":"","sampleRate":20,"premiumSampleRate":100,"trackInteractions":true,"defaultPrivacyLevel":"mask-user-input","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
