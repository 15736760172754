import { graphql, useStaticQuery } from "gatsby"

import {
  ChefclubLocale,
  getPrismicLocaleFromLocale,
  PrismicLocale,
} from "~/utils/locales"

type allAppSettingsQuery = {
  allAppSettings: {
    nodes: {
      sendinBlueFooterFormId?: string | null
      klaviyoPublicApiKey?: string | null
      lang: PrismicLocale
    }[]
  }
}

export default function useNewsletterApiKeys(currentLocale: ChefclubLocale) {
  const allAppSettings: allAppSettingsQuery = useStaticQuery(graphql`
    query allSendinBlueUrl {
      allAppSettings {
        nodes {
          sendinBlueFooterFormId: sendinblue_footer_form_id
          klaviyoPublicApiKey: klaviyo_public_api_key
          lang
        }
      }
    }
  `)
  const localeSendinBlue = allAppSettings.allAppSettings.nodes.find(
    e => e.lang === getPrismicLocaleFromLocale(currentLocale)
  )

  return localeSendinBlue
}
