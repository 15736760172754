import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import FullWidthBanner, {
  FullWidthBannerProps,
} from "~/components/slices/FullWidthBanner/FullWidthBanner"
import { Video as IVideo } from "~/models/Video"

interface PropTypes extends PrismicLandingDataBodyFullWidthBanner {
  eagerImages?: boolean
  videos?: IVideo[]
}

function LandingBodyFullWidthBanner({ primary, ...props }: PropTypes) {
  return (
    <SliceSection
      margin="small"
      noContainer
      anchorId={primary.anchorId}
      {...props}
    >
      <FullWidthBanner {...{ ...primary, ...props }} />
    </SliceSection>
  )
}

export default LandingBodyFullWidthBanner

export interface PrismicLandingDataBodyFullWidthBanner extends PrismicSlice {
  sliceType: "full_width_banner"
  primary: FullWidthBannerProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyFullWidthBanner on PrismicLandingDataBodyFullWidthBanner {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      bannerHeight: banner_height
      image {
        fluid(maxWidth: 1000) {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          Mob_375x280px {
            fluid(maxWidth: 1900) {
              ...GatsbyImgixFluid_noBase64
            }
          }
          Mob_375x400px {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
          Mob_375x614px {
            fluid(maxWidth: 1900) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
      mobileImage: mobile_image {
        alt
        fluid {
          ...GatsbyImgixFluid_noBase64
        }
        thumbnails {
          Mob_375x280px {
            fluid(maxWidth: 500) {
              ...GatsbyImgixFluid_noBase64
            }
          }
          Mob_375x400px {
            fluid(maxWidth: 500) {
              ...GatsbyPrismicImageFluid_noBase64
            }
          }
          Mob_375x614px {
            fluid(maxWidth: 500) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
      }
      title1 {
        ...PrismicStructuredText
      }
      scrollArrowButton: scroll_arrow_button
      specialFeature: special_feature
      lottieDesktop: lottie {
        url
      }
      lottieMobile: lottie_mobile {
        url
      }
      mobileVideoUid: mobile_video_uid
      desktopVideoUid: desktop_video_uid
      anchorId: id
    }
  }
`
