import React from "react"

import { Colors } from "~/types/global-types"
import { formatColor } from "~/utils/colors"

interface PropTypes extends React.SVGProps<SVGSVGElement> {
  href: string
  title?: string | undefined
  mainColor?: Colors
}

const StorybookColorableSVG = ({ href, mainColor, className }: PropTypes) => {
  href.replace("<svg ", `<svg color="${formatColor(mainColor)}"  `)

  console.log(href)

  return (
    <div dangerouslySetInnerHTML={{ __html: href }} className={className} />
  )
}
export default StorybookColorableSVG
