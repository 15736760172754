import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import RecipeVideo, { RecipeVideoProps } from "~/components/slices/RecipeVideo"
import { PartialRecipeWithVideo } from "~/models/PartialRecipe"

interface PropTypes extends PrismicLandingDataBodyRecipeVideo {
  recipes?: PartialRecipeWithVideo[]
}

function LandingBodyRecipeVideo({ primary, ...props }: PropTypes) {
  return (
    <SliceSection anchorId={primary.anchorId} {...props}>
      <RecipeVideo {...{ ...props, ...primary }} />
    </SliceSection>
  )
}

export default LandingBodyRecipeVideo

export interface PrismicLandingDataBodyRecipeVideo extends PrismicSlice {
  sliceType: "recipe_video"
  primary: RecipeVideoProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyRecipeVideo on PrismicLandingDataBodyRecipeVideo {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      recipeUid: recipe_uid
      videoControls: video_controls
      anchorId: id
    }
  }
`
