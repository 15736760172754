import { CreatePageCustomScriptArguments } from "../../types/global-types"

interface CreatorsQuery {
  allCreator: {
    totalCount: number
    nodes: [
      {
        user_id: string
        username: string
      }
    ]
  }
}

export default async function createCreatorPages(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql, reporter, createPage, defaultContext, currentLocale } = args

  //TODO : Generate only in the good locale when API available. For now, every profile is in /fr/. Also do the changes into src/template/Profile/PublicProfile/PublicProfile.tsx
  if (currentLocale.id !== "fr") {
    return
  }

  const result = await graphql<CreatorsQuery>(
    `
      query createRecipePagesQuery {
        allCreator {
          nodes {
            user_id
            username
          }
        }
      }
    `
  )

  if (result.errors) reporter.panic(result.errors)

  if (result.data) {
    for (const creator of result.data.allCreator.nodes) {
      try {
        createPage({
          routeParams: creator,
          context: {
            ...defaultContext,
            username: creator.username,
            userId: creator.user_id,
          },
        })
      } catch (e) {
        console.log("ERROR")
      }
    }
  }
}
