import { CreatePageCustomScriptArguments } from "../../types/global-types"
import { getPrismicLocaleFromLocale } from "../../utils/locales"
import createLandingPages from "./createLandingPages"

interface PrismicAppSettingsCommunityQuery {
  appSettings: {
    web_home_community_landing: string
  }
}

export default async function createCommunityHomeLandingPage(
  args: CreatePageCustomScriptArguments
): Promise<void> {
  const { graphql } = args
  let landingId = ""

  const result = await graphql<PrismicAppSettingsCommunityQuery>(
    `
      query appSettingsQuery($lang: String) {
        appSettings(lang: { eq: $lang }) {
          web_home_community_landing
        }
      }
    `,
    {
      lang: getPrismicLocaleFromLocale(args.currentLocale.id),
    }
  )

  if (result.data?.appSettings.web_home_community_landing) {
    landingId = result.data?.appSettings.web_home_community_landing
  }

  if (landingId.length) {
    createLandingPages(args, landingId as string)
  }
}
