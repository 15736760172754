/**
 * UI common Types and Utilities
 */
import cn from "classnames"

import { capitalizeFirstLetter } from "./string"

export type ResponsiveValues<T> = T | [T | undefined, T, T?]

export type Breakpoint = "Compact" | "Regular" | "Wide"

export const BREAKPOINT_NAMES: Breakpoint[] = ["Compact", "Regular", "Wide"]

function buildClassName<T>(
  prefix: string,
  value: T,
  breakpointName?: Breakpoint
): string {
  let className = `${prefix}`

  if (typeof value === `string`) {
    className += capitalizeFirstLetter(value)
  }
  if (typeof value === `number`) {
    className += value.toString()
  }
  if (typeof breakpointName !== `undefined`) {
    className += breakpointName
  }
  return className
}

// Use-case: 0 is a value
const shouldEnableClassName = (value: any): boolean => {
  if (value === 0) {
    return true
  }
  return !!value
}

export const responsiveCN =
  (css: any) =>
  <T>(classNamePrefix: string, value?: ResponsiveValues<T>): string => {
    let classNames: string

    if (Array.isArray(value)) {
      classNames = cn(
        BREAKPOINT_NAMES.map((breakpointName, index) => {
          const className = buildClassName(
            classNamePrefix,
            value[index],
            breakpointName
          )
          return { [css[className]]: shouldEnableClassName(value[index]) }
        })
      )
    } else {
      const className = buildClassName(classNamePrefix, value)
      classNames = cn({
        [css[className]]: shouldEnableClassName(value),
      })
    }

    return classNames
  }
