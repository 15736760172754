export const cancelScrollOnClose = () => {
  const body = document.querySelector("body")
  const html = document.querySelector("html")

  if (body) {
    const baseScrollBehaviorBody = body.style.scrollBehavior
    body.style.scrollBehavior = "auto"
    setTimeout(() => {
      body.style.scrollBehavior = baseScrollBehaviorBody
    }, 200)
  }
  if (html) {
    const baseScrollBehaviorHtml = html.style.scrollBehavior
    html.style.scrollBehavior = "auto"
    setTimeout(() => {
      html.style.scrollBehavior = baseScrollBehaviorHtml
    }, 200)
  }
}
