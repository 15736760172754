import React from "react"

import { AccountModalPagesIds } from "~/components/Modals/AccountModal/AccountModalLayout"

interface IModalLoginContext {
  isOpen: boolean
  toggleModal?: (from: string, to?: AccountModalPagesIds) => void
  isLogin: boolean
}

const defaultState = {
  isOpen: false,
  isLogin: false,
}

export const ModalLoginContext =
  React.createContext<IModalLoginContext>(defaultState)
