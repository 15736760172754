import { isNotNullish } from "./general"

export function deduplicate<T>(array: T[]): T[] {
  const set = new Set(array)
  return [...set]
}

export function compact<T>(array: (T | undefined | null)[]): T[] {
  return array.filter(isNotNullish)
}
