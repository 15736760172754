/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */

/*type categories = {
  [id: string]: {
    name: string
    parent: string | null | false
  }
}*/

/*type item =
  | false
  | {
      [id: string]: {
        name: string
        parent: string | null | false
      }
    }*/

import { AllCategories } from "~/hooks/useCategories"
import { Category } from "~/models/Category"

export const getCategoryById = (id: string, CATEGORIES: AllCategories) => {
  if (CATEGORIES && CATEGORIES.hasOwnProperty(id)) {
    return { ...CATEGORIES[id], id }
  }
  return null
}

//In locale "fr", the slug "bouchees" return ["sale", "aperos", "bouchees"]
export const getCategoriesFacetsBySlug = (
  slug: string,
  CATEGORIES: AllCategories
) => {
  const actualCategoryNormalizedSlug = slug.normalize().toUpperCase()

  const matchedCategoriesIds = []
  let actualCategoryId: string | null | undefined

  for (const idCat of Object.keys(CATEGORIES)) {
    if (
      actualCategoryNormalizedSlug ===
      CATEGORIES[idCat]?.slug.normalize().toUpperCase()
    ) {
      matchedCategoriesIds.push(idCat)
      actualCategoryId = CATEGORIES[idCat].parentId

      //On remonte jusqu'au plus haut parent
      while (actualCategoryId) {
        matchedCategoriesIds.push(actualCategoryId)
        actualCategoryId = CATEGORIES[actualCategoryId]?.parentId
      }
    }
  }
  return matchedCategoriesIds
}

export const getCategoriesBySlug = (
  slug: string,
  CATEGORIES: AllCategories
) => {
  const normalizedName = slug.normalize().toUpperCase()
  const matchedCategories = []

  for (const idCat in Object.keys(CATEGORIES)) {
    if (
      CATEGORIES[idCat] &&
      normalizedName === CATEGORIES[idCat].slug.normalize().toUpperCase()
    ) {
      matchedCategories.push({ ...CATEGORIES[idCat], id: idCat })
    }
  }

  return matchedCategories
}

export const getCategoriesByName = (
  name: string,
  CATEGORIES: AllCategories
): Category[] => {
  const normalizedName = name.normalize().toUpperCase()
  const matchedCategories: Category[] = []

  for (const idCat in CATEGORIES) {
    if (normalizedName === CATEGORIES[idCat].name.normalize().toUpperCase()) {
      matchedCategories.push({ ...CATEGORIES[idCat], id: idCat })
    }
  }

  return matchedCategories
}

export const getSubCategoriesById = (id: any, CATEGORIES: AllCategories) => {
  const subCategories = []

  for (const idCat in CATEGORIES) {
    if (id === CATEGORIES[idCat].parentId) {
      subCategories.push({ ...CATEGORIES[idCat], id: idCat })
    }
  }

  return subCategories
}

export const getCategoryPathById = (
  id: any,
  startCrop = 0,
  endCrop = 0,
  CATEGORIES: AllCategories
) => {
  let actualCategory = getCategoryById(id, CATEGORIES)

  let path = [actualCategory]

  while (actualCategory && actualCategory.parentId) {
    actualCategory = getCategoryById(actualCategory.parentId, CATEGORIES)
    path.unshift(actualCategory)
  }
  path = path
    .slice(startCrop)
    .slice(-endCrop)
    .filter(e => !!e)
  return path
}

const getBreadcrumbById = (
  id: any,
  attribute: string,
  separator = ">",
  CATEGORIES: AllCategories,
  startCrop = 0,
  endCrop = 0
) => {
  const path = getCategoryPathById(id, startCrop, endCrop, CATEGORIES)
  try {
    return path.map((item: any) => item[attribute]).join(` ${separator} `)
  } catch (e) {
    throw "Error for id : " + id + " path : " + JSON.stringify(path)
  }
}

export const getNameBreadcrumbById = (
  id: any,
  separator = ">",
  startCrop = 0,
  endCrop = 0,
  CATEGORIES: AllCategories
) => getBreadcrumbById(id, "name", separator, CATEGORIES, startCrop, endCrop)

export const getIdBreadcrumbById = (
  id: any,
  separator = ">",
  startCrop = 0,
  endCrop = 0,
  CATEGORIES: AllCategories
) => getBreadcrumbById(id, "id", separator, CATEGORIES, startCrop, endCrop)

export const getCategoriesTreeValueById = (
  id: any,
  CATEGORIES: AllCategories
) => {
  const path = getCategoryPathById(id, 1, 0, CATEGORIES).map(item => item?.id)

  if (path.length > 0) {
    const categoriesTree: any = {}

    for (let i = 0; i < path.length; i++) {
      categoriesTree[`lvl${i}`] = getIdBreadcrumbById(
        path[i],
        ">",
        1,
        0,
        CATEGORIES
      )
    }

    return categoriesTree
  }

  return false
}

export const recursiveCategoriesRenaming: any = (
  items: any[],
  CATEGORIES: AllCategories
) => {
  return items.map((item: { label: any; items: any }) => ({
    ...item,
    label: getCategoryById(item.label, CATEGORIES)?.name,
    items: item.items
      ? recursiveCategoriesRenaming(item.items, CATEGORIES)
      : null,
  }))
}
