import React, { useContext, useRef, useState } from "react"

import { useLocation } from "@reach/router"
import cn from "classnames"
import debounce from "lodash/debounce"
import { Helmet } from "react-helmet"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"

import chefclubLogoImage from "../chefclub-logo-fif-black.svg"
import arrowMobileCta from "./assets/arrow.svg"
import kidsLogoImage from "./assets/kids-logo.svg"
import kidsLogoActiveImage from "./assets/kids-logo-active.svg"
import NavSlices from "./NavSlices"
import { Nav, useNav } from "./useNav"
import Avatar from "~/components/Avatar/Avatar"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { Body, Col, HrefButton, Row } from "~/components/ui"
import { ModalLoginContext } from "~/context/ModalLoginContext"
import { ThemeContext } from "~/context/ThemeContext"
import useEventListener from "~/hooks/useEventListener"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { PartialCreator } from "~/models/PublicUser"
import { PartialEpisode } from "~/models/Show"
import { User } from "~/models/User"
import { userSelector } from "~/state/modules/userInfo"
import { isDev, isProd } from "~/utils/general"
import { domainRemover } from "~/utils/link"
import { ChefclubLocale, getPrismicLocaleFromLocale } from "~/utils/locales"

import css from "./Header.module.scss"

interface PropTypes {
  openSearch: () => void
  lastCreators?: PartialCreator[]
  lastShowEpisodes?: PartialEpisode[]
  shopUrl?: string
}

export default function Header({
  openSearch,
  lastCreators,
  lastShowEpisodes,
  shopUrl,
}: PropTypes) {
  const intl = useIntl()

  const nav = useNav(getPrismicLocaleFromLocale(intl.locale as ChefclubLocale)) // TODO : Remove hardcoded locale when documents in all locales will be available

  const [currentHover, setCurrentHover] = useState<number | null>(null)

  const navs = new Map()

  nav.map((nav: Nav, index) => {
    navs.set(index, nav)
  })

  const user = useSelector(userSelector)

  const backgroundRef = useRef<HTMLDivElement | null>(null)
  const rootRef = useRef<HTMLDivElement | null>(null)

  const handleScroll = debounce(() => {
    const clientRect = backgroundRef.current?.getBoundingClientRect()
    if (clientRect?.top && clientRect?.top < 0) {
      setCurrentHover(null)
    }
  }, 100)

  useEventListener("scroll", handleScroll, rootRef)

  //TODO : change icons -> design system
  const preloadedLinks = [
    { rel: "preload", as: "image", href: chefclubLogoImage },
    {
      rel: "preload",
      as: "image",
      href: kidsLogoActiveImage,
    },
    {
      rel: "preload",
      as: "image",
      href: kidsLogoImage,
    },
  ]
  const isCompact = useIsOnCompact()
  const location = useLocation()
  const logoColor = isDev
    ? "var(--daily-color)"
    : isProd
    ? "black"
    : "var(--original-color)"

  const LoggedInButton = ({ user }: { user: User }) => (
    <Link to="profile" className={cn(css.iconNavItem, css.accountLoggedIn)}>
      <Avatar user={user} color="#5700cf" variant="extraExtraSmall" />
    </Link>
  )
  const { isOpen, toggleModal } = useContext(ModalLoginContext)

  const handleModal = () => {
    !isOpen && toggleModal && toggleModal("")
  }

  const LoggedOutButton = () => (
    <div
      className={cn(css.iconNavItem, css.accountLoggedOut)}
      onClick={handleModal}
    >
      <FormattedMessage id="section:account" />
    </div>
  )

  return (
    <ThemeContext.Provider value={"nav"}>
      <header className={css.root}>
        {/* Navigation row */}
        <div className={css.navRow}>
          <Helmet link={preloadedLinks} />
          <Link to={`/${intl.locale}/`} className={css.logo} absoluteLink>
            {/*TODO : change icons -> design system*/}
            <>
              <img
                src={chefclubLogoImage}
                alt="Chefclub logo"
                className={css.desktopLogo}
                width={150}
                height={59}
              />
              <img
                src={chefclubLogoImage}
                alt="Chefclub logo"
                className={css.mobileLogo}
                width={109}
                height={43}
              />
            </>
          </Link>
          {/* Section row */}
          <ul className={css.sectionRow}>
            {Array.from(navs).map(([key, value], index, arr) => {
              const hasDropdwonContent =
                !!value.body ?? !!value.body1 ?? !!value.body2

              const isSelected =
                location.pathname === domainRemover(value.link.url)

              const isCurrentHover = currentHover === index

              return (
                <React.Fragment key={index}>
                  <li
                    className={cn(
                      css.sectionWrapper,
                      value.title === "kids" && css.kids
                    )}
                    key={value.title}
                    onMouseEnter={() => {
                      if (isCompact) {
                      } else {
                        setCurrentHover(index)
                      }
                    }}
                    onMouseLeave={() => {
                      if (isCompact) {
                      } else {
                        setCurrentHover(null)
                      }
                    }}
                    onClick={() => {
                      if (isCompact) {
                        setCurrentHover((prevValue: number | null) =>
                          prevValue === index ? null : index
                        )
                      }
                    }}
                  >
                    <Link
                      className={cn({
                        [css.section]: true,
                        [css.selected]: isSelected && !isCurrentHover,
                      })}
                      to={value.link.url}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        if (isCompact && hasDropdwonContent) {
                          event.preventDefault()
                        }
                      }}
                      absoluteLink
                    >
                      {value.title === "Kids" ? (
                        <>
                          {!isCurrentHover ? (
                            <img
                              src={
                                isSelected ? kidsLogoActiveImage : kidsLogoImage
                              }
                              alt="Kids"
                            />
                          ) : (
                            <>
                              <img
                                src={kidsLogoImage}
                                className={css.kidsLogo}
                                alt="Kids"
                              />
                              <img
                                src={kidsLogoActiveImage}
                                className={css.kidsLogoActive}
                                alt="Kids"
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <FormattedMessage id={value.title} />
                      )}
                    </Link>
                    <div
                      className={css.dropdownOverlay}
                      style={
                        hasDropdwonContent && isCurrentHover
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className={css.dropdownContent}>
                        <Row>
                          {value.mobileCTA && (
                            <Col
                              width={[12, 0]}
                              className={cn(css.column, css.mobileCta)}
                            >
                              <SliceSection margin="none">
                                <Link
                                  to={value.link.url}
                                  absoluteLink
                                  onClick={() => {
                                    setCurrentHover(null)
                                  }}
                                  className={css.mobileCtaLink}
                                >
                                  <Body
                                    variant="body3"
                                    color={value.colorMobileCTA ?? "original"}
                                    semiBold
                                  >
                                    {value.mobileCTA}
                                  </Body>{" "}
                                  <ColorableSVG
                                    href={arrowMobileCta}
                                    color={value.colorMobileCTA ?? "original"}
                                    height="10px"
                                    width="6px"
                                  />
                                </Link>
                              </SliceSection>
                            </Col>
                          )}
                          <Col width={[12, 4]} className={css.column}>
                            <NavSlices
                              body={value.body}
                              lastCreators={lastCreators}
                              lastShowEpisodes={lastShowEpisodes}
                            />
                          </Col>
                          <Col
                            width={[12, 4]}
                            className={cn(css.column, css.centerNavCol)}
                          >
                            <NavSlices
                              body={value.body1}
                              lastCreators={lastCreators}
                              lastShowEpisodes={lastShowEpisodes}
                            />
                          </Col>
                          <Col width={[12, 4]} className={css.column}>
                            <NavSlices
                              body={value.body2}
                              lastCreators={lastCreators}
                              lastShowEpisodes={lastShowEpisodes}
                            />
                          </Col>
                        </Row>
                        {isCurrentHover && hasDropdwonContent && (
                          <div
                            className={css.background}
                            onTouchStart={() => {
                              setCurrentHover(null)
                            }}
                            style={
                              isCurrentHover && hasDropdwonContent
                                ? {}
                                : { display: "none" }
                            }
                            ref={backgroundRef}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
          <ul className={css.navItems}>
            <li className={css.navItemWrapper}>
              <div
                className={cn(css.iconNavItem, css.search)}
                onClick={openSearch}
              >
                <FormattedMessage id="action:search" />
              </div>
            </li>
            <li className={cn(css.navItemWrapper, css.loggedButton)}>
              {user ? <LoggedInButton user={user} /> : <LoggedOutButton />}
            </li>
            <li className={css.navItemWrapper}>
              {shopUrl && (
                <div className={css.buttonNavItem}>
                  <HrefButton
                    size="small"
                    variant="secondary"
                    color="original"
                    title={intl.formatMessage({
                      id: "header/action:go-to-shop",
                    })}
                    href={shopUrl}
                    target="_blank"
                  >
                    <FormattedMessage id="header/section:shop" />
                  </HrefButton>
                </div>
              )}
            </li>
          </ul>
        </div>
      </header>
    </ThemeContext.Provider>
  )
}
