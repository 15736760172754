import React from "react"

import loadable from "@loadable/component"

import { SliceVideoControls } from "./common/SliceTypes"
import { getVideoControlsProps } from "./common/SliceUtils"
import Link from "~/components/Link/Link"
import { PartialRecipeWithVideo } from "~/models/PartialRecipe"
import Vertical from "~/models/Vertical"

const VideoPlayer = loadable(
  () => import("~/components/VideoPlayer/VideoPlayer")
)

export interface RecipeVideoProps {
  recipeUid?: string
  videoControls: SliceVideoControls
  anchorId?: string
}

interface PropTypes extends RecipeVideoProps {
  recipes?: PartialRecipeWithVideo[]
  isPreview?: boolean
  isDarkTheme?: boolean
}

function RecipeVideo({ recipeUid, recipes, ...props }: PropTypes) {
  const currentRecipe =
    recipes && recipes.find(recipe => recipe.id === recipeUid)

  if (!currentRecipe) return null

  const videoControls = getVideoControlsProps(props.videoControls)

  const Video = (
    <VideoPlayer
      poster={currentRecipe.videoPreview}
      src={currentRecipe.hostedVideoUrl}
      verticalColor={Vertical.Original.color}
      format={{ mobile: "square", desktop: "landscape" }}
      {...videoControls}
    />
  )

  if (videoControls?.disableControls) {
    return (
      <Link to="recipes/recipe" params={currentRecipe} title="">
        {Video}
      </Link>
    )
  }

  // Disable the link if has controls
  return Video
}

export default RecipeVideo
