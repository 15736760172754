import { graphql } from "gatsby"

import { VerticalId } from "./Vertical"

export type AccessMode = "public" | "hidden" | "premium"

export interface PartialRecipe {
  id: string
  slug: string
  title: string
  vertical: VerticalId
  webCover: string
  webCoverThumbnail: string
  accessMode: AccessMode
  language: string
}

export interface PartialRecipeWithVideo extends PartialRecipe {
  videoPreview: string
  hostedVideoUrl: string
}

export const partialRecipeQuery = graphql`
  fragment PartialRecipe on Recipe {
    id
    slug
    title
    vertical
    webCover: web_cover
    webCoverThumbnail: web_cover_thumbnail
    language
    accessMode: access_mode
  }
`

export const partialRecipeWithVideoQuery = graphql`
  fragment PartialRecipeWithVideo on Recipe {
    ...PartialRecipe

    videoPreview: video_preview
    hostedVideoUrl: hosted_video_url
  }
`

export interface APIPartialRecipe {
  id: string
  uuid: string
  url_slug: string
  title: string
  posts_count: number
  vertical: string
  web_cover: string
  web_cover_thumbnail: string
  language: string
  access_mode: string
}

export const apiPartialRecipeToPartialRecipe = ({
  id,
  uuid,
  url_slug,
  title,
  posts_count,
  vertical,
  web_cover,
  web_cover_thumbnail,
  language,
  access_mode,
}: APIPartialRecipe): PartialRecipe & { postsCount?: number } => {
  return {
    id: id ?? uuid,
    slug: url_slug,
    title,
    vertical: vertical as VerticalId,
    webCover: web_cover,
    webCoverThumbnail: web_cover_thumbnail,
    language,
    accessMode: access_mode as AccessMode,
    postsCount: posts_count,
  }
}
