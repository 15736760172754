import ClientSideRoute from "./ClientSideRoute"
import DynamicRoute from "./DynamicRoute"
import FallbackRoute from "./FallbackRoute"
import RedirectionRoute from "./RedirectionRoute"
import Route, { Translator } from "./Route"
import StaticRoute from "./StaticRoute"
import VoidRoute from "./VoidRoute"

const translateId = ({
  route,
  translate,
}: {
  route: Route
  translate: Translator
}) => translate(`path:${route.id}`)

const routes: Route[] = [
  new StaticRoute({
    id: "design-system",
    component: "design-system/design-system.tsx",
    disabledInProduction: true,
  }),
  new StaticRoute({
    id: "preview",
    component: "PrismicPreview/PrismicPreview.tsx",
    children: [
      new VoidRoute({
        id: "locale",
        resolvePath: translateId,
        children: [
          new DynamicRoute({
            id: "landing",
            script: "createLandingPagePreview",
            component: "Landing/Landing.tsx",
          }),
        ],
      }),
    ],
  }),
  new DynamicRoute({
    id: "locale",
    resolvePath: translateId,
    script: "createHomePages",
    component: "Landing/Landing.tsx",
    children: [
      new VoidRoute({
        id: "recipes",
        resolvePath: translateId,
        children: [
          new DynamicRoute({
            id: "verticales",
            resolvePath: ({ params }) => `${params.fullPath}`,
            script: "createVerticalPages",
            component: "Category/Vertical.tsx",
          }),
          new DynamicRoute({
            id: "recipe",
            script: "createRecipePages",
            component: "Recipe/RecipeSSGPage.tsx",
            resolvePath: ({ params }) =>
              `${params.vertical}/${params.id}/${params.slug}`,
          }),
          new FallbackRoute({
            id: "RecipeCSRPage",
            component: "Recipe/RecipeCSRPage.tsx",
            matchPath: "/*",
          }),
        ],
      }),
      new DynamicRoute({
        id: "kids",
        script: "createKidsHomeLandingPages",
        component: "Landing/Landing.tsx",
      }),
      new VoidRoute({
        id: "s",
        children: [
          new DynamicRoute({
            id: "landing",
            resolvePath: ({ params }) => `${params.uid}`,
            script: "createLandingPages",
            component: "Landing/Landing.tsx",
          }),
        ],
      }),
      new VoidRoute({
        id: "c",
        children: [
          new DynamicRoute({
            id: "categories",
            resolvePath: ({ params }) =>
              `${params.category}${
                params.ingredient ? `/${params.ingredient}` : ""
              }`,
            script: "createCategoryPages",
            component: "Category/Category.tsx",
            children: [
              //Ingredients (/c/category/ingredient/) are generated into createCategoryPage
            ],
          }),
          new FallbackRoute({
            id: "categoriesFallback",
            component: "Category/Category.tsx",
            matchPath: "/*",
          }),
        ],
      }),
      new VoidRoute({
        id: "h",
        children: [
          new DynamicRoute({
            id: "categories",
            resolvePath: ({ params }) => `${params.slug}`,
            script: "createHubPages",
            component: "Hub/CategoryHub/Hub.tsx",
          }),
          new DynamicRoute({
            id: "home-hub",
            resolvePath: translateId,
            component: "Hub/HomeHub/HomeHub.tsx",
            script: "createHomeHubPages",
          }),
        ],
      }),
      new DynamicRoute({
        id: "shows",
        component: "Shows/HomeShows/HomeShows.tsx",
        script: "createShowsHomePage",
        children: [
          new DynamicRoute({
            id: "shows-listing",
            component: "Shows/ShowListing/ShowListing.tsx",
            resolvePath: ({ params }) => `${params.slug}`,
            script: "createShowPages",
          }),
        ],
      }),
      new StaticRoute({
        id: "all-ingredients-sitemap",
        resolvePath: translateId,
        component: "Sitemaps/IngredientsListPage.tsx",
        children: [
          new DynamicRoute({
            id: "ingredient-sitemap",
            resolvePath: ({ params }) => `${params.ingredient}`,
            script: "createIngredientSitemapPages",
            component: "Sitemaps/IngredientPage.tsx",
          }),
        ],
      }),
      new DynamicRoute({
        id: "community",
        resolvePath: translateId,
        script: "createCommunityHomeLandingPage",
        component: "Landing/Landing.tsx",
        children: [
          new StaticRoute({
            id: "community-feed",
            resolvePath: translateId,
            component: "CommunityFeed/CommunityFeed.tsx",
          }),
          new DynamicRoute({
            id: "cocreation",
            resolvePath: translateId,
            script: "createCocreationLandingPage",
            component: "Landing/Landing.tsx",
            children: [
              new DynamicRoute({
                id: "cocreation-details",
                resolvePath: ({ params }) => `${params.uid}`,
                script: "createCocreationPage",
                component: "Cocreation/Cocreation.tsx",
              }),
            ],
          }),
        ],
      }),
      new DynamicRoute({
        id: "workshop",
        resolvePath: translateId,
        script: "createWorkshopPages",
        component: "Workshop/Workshop.tsx",
      }),
      new DynamicRoute({
        id: "story",
        script: "createOurStoryPages",
        component: "Story/Story.tsx",
      }),
      new DynamicRoute({
        id: "kids-converter",
        resolvePath: translateId,
        script: "createKidsConverterPages",
        component: "Landing/Landing.tsx",
      }),
      new DynamicRoute({
        id: "legal-notices",
        resolvePath: translateId,
        script: "createLegalNoticesPages",
        component: "legal-notices/legal-notices.tsx",
      }),
      new DynamicRoute({
        id: "legal-notices-creators",
        resolvePath: translateId,
        script: "createLegalNoticesCreatorsPages",
        component: "legal-notices/legal-notices-creators.tsx",
      }),
      new StaticRoute({
        id: "app",
        component: "MobileApp/MobileApp.tsx",
      }),
      new StaticRoute({
        id: "search",
        resolvePath: translateId,
        component: "Category/Search.tsx",
      }),
      new StaticRoute({
        id: "profile",
        resolvePath: translateId,
        component: "Profile/Home/Home.tsx",
        children: [
          new VoidRoute({
            id: "u",
            resolvePath: ({ params }) =>
              `u${params?.id ? `/${params?.id}` : ""}`,
            children: [
              new DynamicRoute({
                id: "creator",
                script: "createCreatorPages",
                component: "Profile/PublicProfile/PublicProfileSSG.tsx",
                resolvePath: ({ params }) => `${params.username}`,
              }),
              new FallbackRoute({
                id: "user",
                component: "Profile/PublicProfile/PublicProfile.tsx",
                matchPath: "/:userId",
              }),
            ],
          }),
          new StaticRoute({
            id: "account-premium",
            resolvePath: translateId,
            component: "Account/Premium/Premium.tsx",
          }),
          new StaticRoute({
            id: "account-my-settings",
            resolvePath: translateId,
            component: "Account/Settings/Settings.tsx",
          }),
          new StaticRoute({
            id: "login",
            resolvePath: translateId,
            component: "Profile/Login/Login.tsx",
          }),
          new StaticRoute({
            id: "sign-up",
            resolvePath: translateId,
            component: "Profile/Register/Register.tsx",
          }),
          new StaticRoute({
            id: "send-reset-password",
            resolvePath: translateId,
            component: "Profile/SendResetPassword/SendResetPassword.tsx",
          }),
        ],
      }),
      new VoidRoute({
        id: "account",
        resolvePath: translateId,
        children: [
          new VoidRoute({
            id: "validation",
            children: [
              new FallbackRoute({
                id: "validationPage",
                component: "Account/Validation/Validation.tsx",
                matchPath: "/*",
              }),
            ],
          }),
          new FallbackRoute({
            id: "account-reset-password",
            resolvePath: translateId,
            component: "Account/ResetPassword/ResetPassword.tsx",
            matchPath: "/*",
          }),
        ],
      }),
      new FallbackRoute({
        id: "PostRedirection",
        component: "MobileApp/PostRedirection.tsx",
        matchPath: "/post/:postId",
      }),
      new RedirectionRoute({
        id: "text-the-app",
        fromPath: "/text-the-app",
        toPath: "/app",
        absoluteToPath: true,
        statusCode: 301,
      }),
      new FallbackRoute({
        id: "404",
        script: "create404Pages",
        component: "Landing/Landing.tsx",
        matchPath: "/*",
        statusCode: 404,
      }),
    ],
  }),
  new VoidRoute({
    id: "video",
    children: [
      new DynamicRoute({
        id: "videos",
        resolvePath: ({ params }) => `${params.id}`,
        script: "createVideoPages",
        component: "VideoPage/VideoPage.tsx",
      }),
    ],
  }),
  new VoidRoute({
    id: "rewrite-target",
    children: [
      new VoidRoute({
        id: "locale",
        resolvePath: translateId,
        children: [
          new StaticRoute({
            id: "user-profile-stub",
            component: "Stubs/UserProfileStub.tsx",
          }),
          new StaticRoute({
            id: "community-post-stub",
            component: "Stubs/CommunityPostStub.tsx",
          }),
        ],
      }),
    ],
  }),
]

export default routes
