import React from "react"

import { graphql } from "gatsby"

import SliceSection from "~/components/slices/common/SliceSection/SliceSection"
import { PrismicSlice } from "~/components/slices/common/SliceTypes"
import Video, { VideoProps } from "~/components/slices/Video"
import { Video as IVideo } from "~/models/Video"

interface PropTypes extends PrismicLandingDataBodyVideo {
  videos?: IVideo[]
}

function LandingBodyVideo({ primary, ...props }: PropTypes) {
  return (
    <SliceSection anchorId={primary.anchorId} {...props}>
      <Video {...{ ...primary, ...props }} />
    </SliceSection>
  )
}

export default LandingBodyVideo

export interface PrismicLandingDataBodyVideo extends PrismicSlice {
  sliceType: "video"
  primary: VideoProps
}

export const query = graphql`
  fragment PrismicLandingDataBodyVideo on PrismicLandingDataBodyVideo {
    id
    __typename
    sliceType: slice_type
    sliceLabel: slice_label
    primary {
      mobileVideoUid: mobile_video_uid
      desktopVideoUid: desktop_video_uid
      videoControls: video_controls
      link {
        ...PrismicLink
      }
      anchorId: id
    }
  }
`
