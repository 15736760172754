import React from "react"

import { SearchBoxProvided } from "react-instantsearch-core"
import { connectSearchBox } from "react-instantsearch-dom"

import css from "./SimpleSearchBox.module.scss"

type propType = SearchBoxProvided & {
  onHitEnter: () => void
  setOnFocus: (focus: boolean) => void
}

const SearchBox = ({
  currentRefinement,
  refine,
  onHitEnter,
  setOnFocus,
}: propType) => {
  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter" && onHitEnter) {
      onHitEnter()
    }
  }

  return (
    <input
      type="text"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
      autoFocus
      onKeyDown={handleKeyDown}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
      className={css.searchBox}
    />
  )
}

const SimpleSearchBox = connectSearchBox<propType>(SearchBox)

export default SimpleSearchBox
