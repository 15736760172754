import voca from "voca"

import Vertical from "~/models/Vertical"
import { ChefclubLocale } from "~/utils/locales"

export const getCategoryLink = (locale: ChefclubLocale, slug: string) => {
  const link = `/${locale}/c/${voca.slugify(slug)}`
  return link.slice(-1) === "/" ? link : link + "/"
}

export const getVerticaleLink = (
  locale: ChefclubLocale,
  localisedPath: string,
  verticale: Vertical | null
) => {
  // If no verticale — redirect to default filtered recipes */
  const link = !verticale
    ? `/${locale}/c/${localisedPath}/`
    : `/${locale}/${localisedPath}/${String(verticale).toLowerCase()}/`
  return link
}

export const domainRemover = (url: string) => {
  if (!url) return url

  const matches = url?.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
  const domain = matches && matches[1]

  if (domain === null) {
    return url
  }
  return url.replace(`https://${domain}`, "")
}

export const isExternalLink = (url: string) => {
  if (url === domainRemover(url)) {
    return false
  }
  const link = new URL(url)
  return link.hostname !== "www.chefclub.tv"
}
