import React, { HTMLProps } from "react"

import cn from "classnames"

import BookmarkSVG from "./icons/bookmark.svg"
import BookmarkSelectedSVG from "./icons/bookmark-selected.svg"
import ShareSVG from "./icons/share.svg"
import ShareAndroidSVG from "./icons/share-android.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import Link from "~/components/Link/Link"
import { Body } from "~/components/ui"
import { isAndroid } from "~/utils/detector"
import { ChefclubLocale } from "~/utils/locales"

import css from "./Controls.module.scss"

interface InlineButtonProps {
  label: string
  icon: "share" | "bookmark"
  selected?: boolean
  onClick?: () => void
  href?: string
}

export const InlineButton = ({
  label,
  icon,
  selected,
  onClick = () => null,
  href,
}: InlineButtonProps) => {
  const Tag = href ? "a" : "button"

  const iconHref = {
    share: isAndroid() ? ShareAndroidSVG : ShareSVG,
    bookmark: selected ? BookmarkSelectedSVG : BookmarkSVG,
  }[icon]!

  return (
    <Tag
      className={cn(css.inlineButtonWrapper, { [css.selected]: selected })}
      onClick={onClick}
      href={href}
      title=""
    >
      <span className={css.icon}>
        <ColorableSVG href={iconHref} title="" width={24} height={24} />
      </span>

      <Body className={css.label} variant="body3" Tag="span" bold>
        {label}
      </Body>
    </Tag>
  )
}

interface ChipBodyProps {
  text: string
  selected?: boolean
  hasLink?: boolean
}

interface ChipProps extends HTMLProps<HTMLAnchorElement>, ChipBodyProps {
  to?: string
  queryParam?: string
  disabled?: boolean
  lang?: ChefclubLocale
}

export const Chip = ({
  text,
  selected,
  disabled,
  to,
  queryParam,
  href,
  ...props
}: ChipProps) => {
  const styles = (link: string | undefined) =>
    cn(css.chip, {
      [css.selected]: selected,
      [css.disabled]: disabled,
      [css.link]: !!link,
    })

  const ChipBody = ({ selected, text, hasLink = true }: ChipBodyProps) => (
    <Body
      Tag="span"
      className={!hasLink ? styles(to) : undefined}
      color={selected ? "white" : "body"}
    >
      {text}
    </Body>
  )

  return !disabled && to ? (
    <Link
      className={styles(to)}
      to={to}
      queryParams={queryParam ? { q: queryParam } : undefined}
      {...props}
    >
      <ChipBody selected={selected} text={text} />
    </Link>
  ) : !disabled && href ? (
    <a
      className={styles(href)}
      href={disabled || !href ? undefined : href}
      {...props}
    >
      <ChipBody selected={selected} text={text} />
    </a>
  ) : (
    <ChipBody hasLink={false} selected={selected} text={text} />
  )
}
